import { combineReducers } from "redux";
import { AppState } from "../store/AppState";
import * as ActionTypes from "../actions/actionTypes";
import appOrganizationIds from "./appOrganizationIdsReducer";
import authentication from "./authenticationReducer";
import errors from "./errorsReducer";
import inProgress from "./inProgressReducer";
import licenseAssignments from "./licenseAssignmentsReducer";
import licenses from "./licensesReducer";
import licenseUsage from "./licenseUsageReducer";
import logoutCompleted from "./logoutCompletedReducer";
import organizations from "./organizationsReducer";
import orgGroups from "./orgGroupsReducer";
import orgGroupUserIds from "./orgGroupUserIdsReducer";
import orgInvitations from "./orgInvitationsReducer";
import orgLicenseIds from "./orgLicenseIdsReducer";
import orgOrgGroupIds from "./orgOrgGroupIdsReducer";
import orgOrgInvitationIds from "./orgOrgInvitationIdsReducer";
import orgOrgRoleIds from "./orgOrgRoleIdsReducer";
import orgRoles from "./orgRolesReducer";
import orgRoleUserIds from "./orgRoleUserIdsReducer";
import orgUserIds from "./orgUserIdsReducer";
import pendingAuthentication from "./pendingAuthenticationReducer";
import permissions from "./permissionsReducer";
import selectedOrganizationId from "./selectedOrganizationIdReducer";
import userAvailableLicenses from "./userAvailableLicensesReducer";
import userOrgGroupIds from "./userOrgGroupIdsReducer";
import users from "./usersReducer";

/**
 * Combined reducer for working independently for different branches of the state tree.
 * This is not exposed directly, but only via root reducer that may work on the
 * whole state.
 */
const appReducer = combineReducers({
  appOrganizationIds,
  authentication,
  errors,
  inProgress,
  licenseAssignments,
  licenses,
  licenseUsage,
  logoutCompleted,
  organizations,
  orgGroups,
  orgGroupUserIds,
  orgInvitations,
  orgLicenseIds,
  orgOrgGroupIds,
  orgOrgInvitationIds,
  orgOrgRoleIds,
  orgRoles,
  orgRoleUserIds,
  orgUserIds,
  pendingAuthentication,
  permissions,
  selectedOrganizationId,
  userAvailableLicenses,
  userOrgGroupIds,
  users
});

const rootReducer = (
  state?: AppState,
  action: ActionTypes.AppAction = { type: "NOOP" }
): AppState => {
  if (action.type === ActionTypes.CLEAR_ALL) {
    return appReducer(undefined, action);
  }

  let newState = state;
  let act = action;
  if (act.type === ActionTypes.SET_USERS_IN_ORG_GROUP) {
    // Handle SET_USERS_IN_ORG_GROUP in two parts, first invoking
    // REMOVE_USERS_FROM_ORG_GROUP here and then letting SET_USERS_IN_ORG_GROUP
    // be handled in a similar manner as ADD_USERS_TO_ORG_GROUP
    const setUsersInOrgGroup = act as ActionTypes.SetUsersInOrgGroupAction;
    const currentUsersOfOrgGroup =
      newState &&
      newState.orgGroupUserIds &&
      newState.orgGroupUserIds[setUsersInOrgGroup.orgGroupId]
        ? newState.orgGroupUserIds[setUsersInOrgGroup.orgGroupId]
        : [];
    if (currentUsersOfOrgGroup.length > 0) {
      const removeCurrentUsers: ActionTypes.RemoveUsersFromOrgGroupAction = {
        type: ActionTypes.REMOVE_USERS_FROM_ORG_GROUP,
        orgGroupId: setUsersInOrgGroup.orgGroupId,
        userIds: currentUsersOfOrgGroup,
        orgId: setUsersInOrgGroup.orgId
      };
      newState = appReducer(state as any, removeCurrentUsers);
    }
    const addUsersToOrgGroup: ActionTypes.AddUsersToOrgGroupAction = {
      ...setUsersInOrgGroup,
      type: ActionTypes.ADD_USERS_TO_ORG_GROUP
    };
    act = addUsersToOrgGroup;
  }

  return appReducer(newState as any, act);
};

export default rootReducer;
