import { EntApi } from "../EntApi";
import { LicenseFieldOpts } from "../../model/entitlement/LicenseFieldOpts";
import { QueryAvailableLicensesOpts } from "../../model/entitlement/QueryAvailableLicensesOpts";
import { LicenseWithOwnerAndSingleUserAssignments } from "../../model/entitlement/LicenseWithOwnerAndSingleUserAssignments";
import { LicenseWithCredits } from "../../model/entitlement/LicenseWithCredits";
import { LicenseUsage } from "../../model/entitlement/LicenseUsage";
import { LicenseAssignment } from "../../model/entitlement/LicenseAssignment";
import { ProductItem } from "../../model/entitlement/ProductItem";
import { LicenseOrder } from "../../model/entitlement/LicenseOrder";
import { OrganizationGroup } from "../../model/OrganizationGroup";
import * as EntClient from "../../gen/api/entitlement/entitlement-client";
import { doApiCall } from "../api-util/ApiExceptionUtil";

class RealEnt implements EntApi {
  initialize(initProvider: (name: string) => string): void {
    EntClient.defaults.baseUrl = initProvider("entApiBase");
    EntClient.defaults.headers = EntClient.defaults.headers || {};
    EntClient.defaults.headers["Authorization"] =
      "Bearer " + initProvider("accessToken");
    EntClient.defaults.cache = "no-cache";
  }
  getProductItems(productId: string): Promise<ProductItem[]> {
    throw new Error("Method not implemented.");
  }
  fulfillLicenseOrder(
    orgId: string,
    licenseOrder: LicenseOrder
  ): Promise<LicenseOrder> {
    throw new Error("Method not implemented.");
  }
  changeLicenseOrder(
    orgId: string,
    licenseOrder: LicenseOrder
  ): Promise<LicenseOrder> {
    throw new Error("Method not implemented.");
  }
  cancelLicenseOrder(orgId: string, orderId: string): Promise<LicenseOrder> {
    throw new Error("Method not implemented.");
  }
  listLicenseConsumingOrgGroups(
    orgId: string,
    entId: string
  ): Promise<OrganizationGroup[]> {
    throw new Error("Method not implemented.");
  }
  setLicenseConsumingOrgGroups(
    orgId: string,
    entId: string,
    groupIds: string[]
  ): Promise<void> {
    throw new Error("Method not implemented.");
  }
  addLicenseConsumingOrgGroup(
    orgId: string,
    entId: string,
    groupId: string
  ): Promise<void> {
    throw new Error("Method not implemented.");
  }
  removeLicenseConsumingOrgGroup(
    orgId: string,
    entId: string,
    groupId: string
  ): Promise<void> {
    throw new Error("Method not implemented.");
  }
  listEntitlementLicenses(
    orgId: string,
    entId: string,
    licenseFieldOpts?: LicenseFieldOpts
  ): Promise<LicenseWithCredits[]> {
    return doApiCall(() =>
      EntClient.listEntitlementLicenses(orgId, entId, licenseFieldOpts)
    );
  }
  queryLicenseUsage(
    orgId: string,
    entId: string,
    licenseId: string
  ): Promise<LicenseUsage> {
    return doApiCall(() =>
      EntClient.queryLicenseUsage(orgId, entId, licenseId)
    );
  }
  getLicenseAssignments(
    orgId: string,
    entId: string,
    licenseId: string,
    userId: string
  ): Promise<LicenseAssignment[]> {
    return doApiCall(() =>
      EntClient.getLicenseAssignments(orgId, entId, licenseId, userId)
    );
  }
  manageLicenseAssignments(
    orgId: string,
    entId: string,
    licenseId: string,
    userId: string,
    licenseAssignments: LicenseAssignment[]
  ): Promise<LicenseAssignment[]> {
    return doApiCall(() =>
      EntClient.manageLicenseAssignments(
        orgId,
        entId,
        licenseId,
        userId,
        licenseAssignments
      )
    );
  }
  queryAvailableLicenses(
    userId: string,
    queryAvailableLicensesOpts?: QueryAvailableLicensesOpts
  ): Promise<LicenseWithOwnerAndSingleUserAssignments[]> {
    return doApiCall(() =>
      EntClient.queryAvailableLicenses(userId, queryAvailableLicensesOpts)
    );
  }
  moveLicense(
    orgId: string,
    entId: string,
    licenseId: string,
    destEntId: string,
    licenseFieldOpts?: LicenseFieldOpts
  ): Promise<LicenseWithCredits> {
    throw new Error("Method not implemented.");
  }
}

export default RealEnt;
