import * as ActionTypes from "../actions/actionTypes";
import { LicensesById } from "../store/LicenseState";
import { updateLicense } from "../util/licenseUtil";

export default function licenses(
  state: LicensesById,
  action: ActionTypes.AppAction
): LicensesById | null {
  const currentState = state || ({} as LicensesById);
  switch (action.type) {
    case ActionTypes.LIST_ENT_LICENSES:
    case ActionTypes.LIST_ENT_LICENSES_WITH_USAGE:
    case ActionTypes.QUERY_AVAILABLE_LICENSES:
      const licensesAction = action as ActionTypes.LicensesAction;
      const licenses = licensesAction.licenses;
      const stateAfterOperation = { ...currentState };
      for (const license of licenses) {
        const licenseId = license.id as string;
        stateAfterOperation[licenseId] = updateLicense(
          license,
          stateAfterOperation[licenseId]
        );
      }
      return stateAfterOperation;
    case ActionTypes.QUERY_LICENSE_USAGE:
    case ActionTypes.GET_USER_LICENSE_ASSIGNMENTS:
    case ActionTypes.MANAGE_USER_LICENSE_ASSIGNMENTS:
    case ActionTypes.MANAGE_USERS_LICENSE_ASSIGNMENTS:
      const licenseUsage = action as ActionTypes.LicenseUsageField;
      if (licenseUsage.licenseUsage) {
        const stateAfterQuery = { ...currentState };
        const queriedLicenseId = licenseUsage.licenseUsage.id as string;
        stateAfterQuery[queriedLicenseId] = updateLicense(
          licenseUsage.licenseUsage,
          stateAfterQuery[queriedLicenseId]
        );
        return stateAfterQuery;
      }
      return state;
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}
