import { connect } from "react-redux";
import { AppState } from "../../store/AppState";
import ProgressIndicator, {
  ProgressIndicatorProps as _ProgressIndicatorProps,
  ProgressIndicatorDOMProps
} from "./progress-indicator-container";
import {
  PickReduxDispatchProps,
  PickReduxStateProps
} from "../../util/typeUtil";

interface ProgressIndicatorProps extends ProgressIndicatorDOMProps {}
// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _ProgressIndicatorProps,
  ProgressIndicatorProps
>;
// Dispatch props provided to the wrapped component by this connect component, using mapDispatchToProps
type ReduxDispatchProps = PickReduxDispatchProps<
  _ProgressIndicatorProps,
  ProgressIndicatorProps
>;
function mapStateToProps(state: AppState): ReduxStateProps {
  const { inProgress } = state;
  return {
    show: !!(inProgress && inProgress.length)
  };
}
function mapDispatchToProps(): ReduxDispatchProps {
  return {};
}
export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  ProgressIndicatorProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps
)(ProgressIndicator);
