import * as ActionTypes from "./actionTypes";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../store/AppState";

export function clearAll(): ActionTypes.ClearAllAction {
  return {
    type: ActionTypes.CLEAR_ALL
  };
}

/**
 * Dispatches an action and returns a Promise that the caller can use to
 * wait for the action eventually returned by the action thunk.
 *
 * If the dispatched thunk action returns successfully, the Promise returned
 * by this method is resolved with the action returned by the thunk. If
 * the thunk action returns an error action, the Promise is rejected with
 * an AddErrorAction value.
 * @param thunk The thunk action to dispatch
 * @param dispatch The dispatch function to use
 */
export function waitForDispatch<A extends ActionTypes.AppAction>(
  thunk: ActionTypes.AppThunkAction<A>,
  dispatch: (thunk: ActionTypes.AppThunkAction<A>) => void
): Promise<A> {
  return new Promise<A>((resolve, reject) => {
    dispatch(
      async (
        thunkDispatch: ThunkDispatch<AppState, unknown, ActionTypes.AppAction>,
        getState: () => AppState,
        extraArgument: unknown
      ): Promise<A | ActionTypes.AddErrorAction<any>> => {
        const resultAction = await thunk(
          thunkDispatch,
          getState,
          extraArgument
        );
        if (ActionTypes.isAddErrorAction<any>(resultAction)) {
          const addError = resultAction as ActionTypes.AddErrorAction<any>;
          reject(addError);
        } else {
          resolve(resultAction);
        }
        return resultAction;
      }
    );
  });
}

export * from "./authnActions";
export * from "./errorActions";
export * from "./inProgressActions";
export * from "./orgGroupActions";
export * from "./orgGroupUsersActions";
export * from "./orgInvitationActions";
export * from "./orgRoleActions";
export * from "./orgRoleUsersActions";
export * from "./orgRolePermissionsActions";
export * from "./orgsActions";
export * from "./orgUsersActions";
export * from "./userActions";
export * from "./userOrgGroupsActions";
