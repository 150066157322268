import { getEnvParam, envParamExists } from "../util/env";

/**
 * Environment parameter for IdP API implementation to use.
 */
const API_IMPL_ENV_PARAM_IDP = "REACT_APP_IDP_API_IMPL";

/**
 * Environment parameter for Entitlement API implementation to use.
 */
const API_IMPL_ENV_PARAM_ENT = "REACT_APP_ENT_API_IMPL";

/**
 * Environment parameter for server base url (no trailing slash).
 */
const SRV_BASE_ENV_PARAM = "REACT_APP_SRV_BASE";
const SRV_BASE_DEFAULT = "";

/**
 * Environment parameter for base url of the login UI (sso-ui).
 */
const USER_UI_BASE_ENV_PARAM = "REACT_APP_USER_UI_BASE";
const USER_UI_BASE_DEFAULT = "/user";

/**
 * Enviroment parameter for IdP authorization endpoint path.
 */
const IDP_AUTHZ_PATH_ENV_PARAM = "REACT_APP_IDP_AUTHZ_PATH";

/**
 * Enviroment parameter for IdP single logout endpoint path.
 */
const IDP_SLO_PATH_ENV_PARAM = "REACT_APP_IDP_SLO_PATH";

/**
 * Environment parameter for base url of the IdP API (no trailing slash).
 */
const IDP_API_BASE_ENV_PARAM = "REACT_APP_IDP_API_BASE";
const IDP_API_BASE_DEFAULT = "/api/idp/v1";

/**
 * Environment parameter for base url of the Entitlement API (no trailing slash).
 */
const ENT_API_BASE_ENV_PARAM = "REACT_APP_ENT_API_BASE";
const ENT_API_BASE_DEFAULT = "/api/entitlement/v1";

/**
 * Environment parameter for client id used by this applicaction.
 */
const CLIENT_ID_ENV_PARAM = "REACT_APP_CLIENT_ID";
const CLIENT_ID_DEFAULT = "orgadmin";

/**
 * Environment parameter for route of this application for logout requests and responses
 * from the identity provider.
 */
const LOGOUT_PATH_ENV_PARAM = "REACT_APP_LOGOUT_PATH";
const LOGOUT_PATH_DEFAULT = "/logout";

/**
 * Relative path of the OAuth 2.0 authorization endpoint in the login UI app (sso-ui).
 */
const AUTHZ_PATH = "/oauth20/authz";

/**
 * Relative path of the OAuth 2.0 / 10Duke custom single logout endpoint in the login UI app (sso-ui).
 */
const SLO_PATH = "/oauth20/signout";

/**
 * Relative path of the JWKS endpoint in the IdP.
 */
const JWKS_PATH = "/.well-known/jwks.json";

/**
 * API to select (mock or real).
 */
export enum ApiImplementation {
  real,
  internalMock
}

/**
 * Gets API implementation to use for the IdP API.
 */
export function getSelectedIdpApiImpl(): ApiImplementation {
  const envParam = API_IMPL_ENV_PARAM_IDP;
  const apiImplParam = getEnvParam(envParam);
  const apiImpl: ApiImplementation | undefined = (ApiImplementation as any)[
    apiImplParam
  ];
  if (apiImpl === undefined) {
    throw new Error(`Invalid value of ${envParam}: ${apiImplParam}`);
  }
  return apiImpl;
}

/**
 * Gets API implementation to use for the Entitlement API.
 */
export function getSelectedEntApiImpl(): ApiImplementation {
  const envParam = API_IMPL_ENV_PARAM_ENT;
  const apiImplParam = getEnvParam(envParam);
  const apiImpl: ApiImplementation | undefined = (ApiImplementation as any)[
    apiImplParam
  ];
  if (apiImpl === undefined) {
    throw new Error(`Invalid value of ${envParam}: ${apiImplParam}`);
  }
  return apiImpl;
}

/**
 * Gets base URL of the server (no trailing slash).
 */
export function getSrvBase(): string {
  return getEnvParam(SRV_BASE_ENV_PARAM, SRV_BASE_DEFAULT);
}

/**
 * Gets base URL of the login UI (no trailing slash).
 */
export function getUserUIBase(): string {
  return getEnvParam(
    USER_UI_BASE_ENV_PARAM,
    getSrvBase() + USER_UI_BASE_DEFAULT
  );
}

/**
 * Gets base URL of the IdP API to use (no trailing slash).
 */
export function getIdpApiBase(): string {
  return (
    getSrvBase() + getEnvParam(IDP_API_BASE_ENV_PARAM, IDP_API_BASE_DEFAULT)
  );
}

/**
 * Gets base URL of the IdP API to use (no trailing slash).
 */
export function getEntApiBase(): string {
  return (
    getSrvBase() + getEnvParam(ENT_API_BASE_ENV_PARAM, ENT_API_BASE_DEFAULT)
  );
}

function getIdpAuthzPath(): string {
  return getEnvParam(IDP_AUTHZ_PATH_ENV_PARAM, undefined);
}

function getIdpSloPath(): string {
  return getEnvParam(IDP_SLO_PATH_ENV_PARAM, undefined);
}

/**
 * Gets the OAuth client id used by this application.
 */
export function getClientId(): string {
  return getEnvParam(CLIENT_ID_ENV_PARAM, CLIENT_ID_DEFAULT);
}

/**
 * Gets URL of the OAuth 2.0 authorization endpoint.
 */
export function getAuthzUrl(): URL {
  if (envParamExists(IDP_AUTHZ_PATH_ENV_PARAM)) {
    return new URL(getSrvBase() + getIdpAuthzPath(), window.location.href);
  }

  const loginUIBase = getUserUIBase();
  return new URL(loginUIBase + AUTHZ_PATH, window.location.href);
}

/**
 * Gets URL of the OAuth 2.0 / 10Duke custom single logout endpoint.
 */
export function getSloUrl(): URL {
  if (envParamExists(IDP_SLO_PATH_ENV_PARAM)) {
    return new URL(getSrvBase() + getIdpSloPath(), window.location.href);
  }

  const loginUIBase = getUserUIBase();
  return new URL(loginUIBase + SLO_PATH, window.location.href);
}

/**
 * Gets URL of the JWKS endpoint of the identity provider.
 */
export function getJwksUrl(): URL {
  const srvBase = getSrvBase();
  return new URL(srvBase + JWKS_PATH, window.location.href);
}

/**
 * Gets route of this application for logout requests and responses
 * from the identity provider.
 */
export function getLogoutPath(): string {
  return getEnvParam(LOGOUT_PATH_ENV_PARAM, LOGOUT_PATH_DEFAULT);
}
