import { AppState } from "../store/AppState";
import { ActionSender } from "../model/ActionSender";
import { AppError } from "../model/AppError";

export function resolveErrorsBySenderFromState(
  state: AppState,
  sender: ActionSender
): AppError<any>[] | undefined {
  let retValue: AppError<any>[] | undefined;
  const { errors } = state;
  retValue = errors
    ? errors.filter(err => {
        return err.actionSender.key === sender.key;
      })
    : errors;

  return retValue;
}

export function resolveErrorsByOperationType(
  errors: AppError<any>[] | undefined,
  operationType: string
): AppError<any>[] | undefined {
  return errors
    ? errors.filter(err => err.operationType === operationType)
    : errors;
}
