import { InternalApiError, ApiError } from "../../model/ApiError";
import { HttpError as IdpHttpError } from "../../gen/api/idp/idp-client";
import { HttpError as entHttpError } from "../../gen/api/entitlement/entitlement-client";

/**
 * Call given API function with await.
 *
 * This utility method exist for the sole purpose of converting exceptions
 * thrown by generated rest-clients to the application specific ApiError type.
 * If exception thrown by rest-client is not of type HttpError
 * then no conversion occurs and exception is re-thrown as is.
 *
 * @param method Api method to call.
 */
export async function doApiCall<T>(method: () => Promise<T>): Promise<T> {
  try {
    return await method();
  } catch (error) {
    throw getApiError(error);
  }
}

function getApiError(error: any): ApiError {
  if (error instanceof IdpHttpError || error instanceof entHttpError) {
    const httpError = error as IdpHttpError | entHttpError;
    return new InternalApiError(
      httpError.status.toString(),
      errorToString(httpError.status)
    );
  }

  return error;
}

function errorToString(status: number): string {
  const errorAsString = HTTP_ERROR_STATUS_MAP[status];
  return errorAsString ? errorAsString : "Unknown Error";
}

const HTTP_ERROR_STATUS_MAP: { [key: number]: string } = {
  400: "Bad request",
  401: "Unauthorized",
  402: "Payment Required",
  403: "Forbidden",
  404: "Not Found",
  405: "Method Not Allowed",
  406: "Not Acceptable",
  407: "Proxy Authentication Required",
  408: "Request Timeout",
  409: "Conflict",
  410: "Gone",
  411: "Length Required",
  412: "Precondition Failed",
  413: "Payload Too Large",
  414: "URI Too Long",
  415: "Unsupported Media Type",
  416: "Range Not Satisfiable",
  417: "Expectation Failed",
  418: "I'm a teapot",
  421: "Misdirected Request",
  422: "Unprocessable Entity",
  423: "Locked",
  424: "Failed Dependency",
  425: "Too Early",
  426: "Upgrade Required",
  428: "Precondition Required",
  429: "Too Many Requests",
  431: "Request Header Fields Too Large",
  451: "Unavailable For Legal Reasons",
  500: "Internal Server Error",
  501: "Not Implemented",
  502: "Bad Gateway",
  503: "Service Unavailable",
  504: "Gateway Timeout",
  505: "HTTP Version Not Supported",
  506: "Variant Also Negotiates",
  507: "Insufficient Storage",
  508: "Loop Detected",
  510: "Not Extended",
  511: "Network Authentication Required"
};
