import React from "react";
import { Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createPortal } from "react-dom";

//<editor-fold desc="Props">
export interface ProgressIndicatorDOMProps
  extends React.HTMLAttributes<HTMLDivElement> {}

export interface ProgressIndicatorProps extends ProgressIndicatorDOMProps {
  show: boolean;
}
//</editor-fold>

function ProgressIndicator(props: ProgressIndicatorProps) {
  //<editor-fold desc="Local variables">
  let { show, children, ...other } = props;
  //</editor-fold>
  const target = document.getElementById("notifications") as Element;
  const cont = (
    <Alert variant={"dark"} show={show} className={"order-1 shadow"} {...other}>
      <span className={children ? "mr-2" : undefined}>
        <FontAwesomeIcon icon={"spinner"} spin size={"lg"} />
      </span>
      {children}
    </Alert>
  );
  if (!target) {
    return cont;
  } else {
    // display contents in portal to allow stacking notifications from different origins
    return createPortal(cont, target);
  }
}
export default ProgressIndicator;
