import { connect } from "react-redux";
import App, { AppProps as _AppProps } from "./app-view";
import { AppState } from "../../store/AppState";
import OrganizationUtils from "../../utils/organization";
import {
  PickReduxDispatchProps,
  PickReduxStateProps
} from "../../util/typeUtil";

// Own props, i.e. props that this component takes as input
interface AppProps {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<_AppProps, AppProps>;
// Dispatch props provided to the wrapped component by this connect component, using mapDispatchToProps
type ReduxDispatchProps = PickReduxDispatchProps<_AppProps, AppProps>;

function mapStateToProps(state: AppState): ReduxStateProps {
  const org = OrganizationUtils.resolveSelectedFromState(state);
  const { organizations } = state;
  let hasOrgsToManage = false;
  if (organizations && Object.keys(organizations).length > 0) {
    hasOrgsToManage = true;
  }
  return {
    organizationId: org ? (org.id as string) : null,
    hasOrgsToManage
  };
}

export default connect<ReduxStateProps, ReduxDispatchProps, AppProps, AppState>(
  mapStateToProps
)(App);
