import { connect } from "react-redux";
import { AppState } from "../../../store/AppState";
import MyLicensesPage, {
  MyLicensesDOMProps,
  MyLicensesProps as _MyLicensesProps
} from "./my-licenses-view";
import {
  aggregateUserLicenses,
  getUserLicensedItemAssignments
} from "../../../util/licenseUtil";
import {
  PickReduxDispatchProps,
  PickReduxStateProps
} from "../../../util/typeUtil";
import store from "../../../store";
import { queryAvailableLicenses } from "../../../actions/entActions";

// Own props
interface MyLicensesProps extends MyLicensesDOMProps {}
// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<_MyLicensesProps, MyLicensesProps>;
// Dispatch props provided to the wrapped component by this connect component, using mapDispatchToProps
type ReduxDispatchProps = PickReduxDispatchProps<
  _MyLicensesProps,
  MyLicensesProps
>;

const sender = { key: "myLicensesPage" };

function mapStateToProps(state: AppState): ReduxStateProps {
  const retVal: any = {};
  let licenses2 = undefined;
  let userId;
  const { authentication } = state;
  if (authentication) {
    if (authentication.userId) {
      userId = authentication.userId;

      let licenses1 = getUserLicensedItemAssignments(state, userId as string);
      if (licenses1) {
        licenses2 = licenses1.map(aggregateUserLicenses);
      }
    }
  }
  // include only reserved licenses.
  retVal.licenses = licenses2
    ? licenses2.filter(lic => {
        return lic.assignments && lic.assignments[0] && lic.assignments[0].type
          ? lic.assignments[0].type === "reserved"
          : false;
      })
    : licenses2;
  retVal.userId = userId;
  return retVal as ReduxStateProps;
}
// this will be needed for sure, so I'll just leave it in.
function mapDispatchToProps(dispatch: (t: any) => void): ReduxDispatchProps {
  return {
    onLoadLicenses: onLoadLicensesImpl
  };
}

function onLoadLicensesImpl(userId: string) {
  store.dispatch(queryAvailableLicenses(sender, userId));
}

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  MyLicensesProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps
)(MyLicensesPage);
