import { connect } from "react-redux";
import { AppState } from "../../store/AppState";
import LicenseUsageByUsersModalView, {
  LicenseUsageByUsersModalProps as _LicenseUsageByUsersModalProps
} from "./license-usage-by-users-modal-container";
import { getOrganizationLicenseUsersWithLicensedItemReservations } from "../../util/licenseUtil";
import { Organization } from "../../model/Organization";
import OrganizationUtils from "../../utils/organization";
import { listEntitlementLicensesWithUsage } from "../../actions/entActions";
import { listOrganizationUsers } from "../../actions";
import { ModalProps } from "../modal";
import {
  PickReduxStateProps,
  PickReduxDispatchProps
} from "../../util/typeUtil";
import store from "../../store";

// Own props, i.e. props that this component takes as input
export type LicenseUsageByUsersModalProps = ModalProps;

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _LicenseUsageByUsersModalProps,
  LicenseUsageByUsersModalProps
>;
// Dispatch props provided to the wrapped component by this connect component, using mapDispatchToProps
type ReduxDispatchProps = PickReduxDispatchProps<
  _LicenseUsageByUsersModalProps,
  LicenseUsageByUsersModalProps
>;

const sender = { key: "license-usage-by-users-modal" };

function mapStateToProps(state: AppState): ReduxStateProps {
  const org:
    | Organization
    | undefined = OrganizationUtils.resolveSelectedFromState(state);
  let data = undefined;
  if (org) {
    data = getOrganizationLicenseUsersWithLicensedItemReservations(
      org.id as string,
      state
    );
  }
  return {
    data: data
  };
}

function mapDispatchToProps(dispatch: (t: any) => void): ReduxDispatchProps {
  return {
    onLoadData: onLoadDataImpl
  };
}

function onLoadDataImpl() {
  store.dispatch(listEntitlementLicensesWithUsage(sender, "any"));
  store.dispatch(listOrganizationUsers(sender));
}

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  LicenseUsageByUsersModalProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps
)(LicenseUsageByUsersModalView);
