import { LicenseWithCredits } from "../../../model/entitlement/LicenseWithCredits";
import { LICENSED_ITEM_2 } from "./licensedItems";
import { LICENSE_MODEL_1 } from "./licenseModels";
import { LicenseUserWithAssignmentsAndSessions } from "../../../model/entitlement/LicenseUserWithAssignmentsAndSessions";

const ORG_2_ENTITLEMENT_1_ID = "184e6448-ea3b-4787-bc8d-5d756200fe08";

const ORG_2_LICENSE_1_ID: string = "09ad6c42-b5a8-40e9-8195-691858072bae";
const ORG_2_LICENSE_1: LicenseWithCredits = {
  id: ORG_2_LICENSE_1_ID,
  authorId: "226c7391-77ac-45cd-bdc4-acf17760c0fe",
  created: "2020-06-11T07:00:00.000Z",
  validFrom: "2019-06-11T07:00:00.000Z",
  validUntil: "2021-06-11T07:00:00.000Z",
  active: true,
  entitlementId: ORG_2_ENTITLEMENT_1_ID,
  licensedItem: LICENSED_ITEM_2,
  licenseModelId: LICENSE_MODEL_1.id as string,
  licenseModelName: LICENSE_MODEL_1.name,
  effectiveLicenseModel: LICENSE_MODEL_1,
  seatsTaken: 0,
  seatsReserved: 0,
  seatsTotal: 0,
  seatCountCredits: [
    {
      id: "e4e143a2-3481-4443-914c-288ff2d8dfec",
      authorId: "226c7391-77ac-45cd-bdc4-acf17760c0fe",
      created: "2019-06-11T07:00:00.000Z",
      validFrom: "2019-06-11T07:00:00.000Z",
      validUntil: "2020-06-11T07:00:00.000Z",
      active: true,
      licenseId: ORG_2_LICENSE_1_ID,
      licenseOrderLineId: "0b0ff020-cfc0-4229-af31-bae766b49fa6",
      seatCount: 0,
      seatsConsumed: 0
    }
  ]
};

export const ORG_2_LICENSES: LicenseWithCredits[] = [ORG_2_LICENSE_1];

const ORG_2_LICENSE_1_USERS: LicenseUserWithAssignmentsAndSessions[] = [];

export const ORG_2_LICENSE_USERS_BY_LICENSE_ID = {
  [ORG_2_LICENSE_1.id as string]: ORG_2_LICENSE_1_USERS
};
