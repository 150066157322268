/**
 * 10Duke Entitlement API
 * 1.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
export const defaults: RequestOpts = {
  baseUrl: "http://localhost:8080"
};
export const servers = {
  server1: "http://localhost:8080"
};
type Encoders = Array<(s: string) => string>;
export type RequestOpts = {
  baseUrl?: string;
  fetch?: typeof fetch;
  headers?: Record<string, string | undefined>;
} & Omit<RequestInit, "body" | "headers">;
type FetchRequestOpts = RequestOpts & {
  body?: string | FormData;
};
type JsonRequestOpts = RequestOpts & {
  body: object;
};
type MultipartRequestOpts = RequestOpts & {
  body: Record<string, string | Blob | undefined | any>;
};
export const _ = {
  async fetch(url: string, req?: FetchRequestOpts) {
    const { baseUrl, headers, fetch: customFetch, ...init } = {
      ...defaults,
      ...req
    };
    const href = _.joinUrl(baseUrl, url);
    const res = await (customFetch || fetch)(href, {
      ...init,
      headers: _.stripUndefined({ ...defaults.headers, ...headers })
    });
    if (!res.ok) {
      throw new HttpError(res.status, res.statusText, href);
    }
    return res.text();
  },
  async fetchJson(url: string, req: FetchRequestOpts = {}) {
    const res = await _.fetch(url, {
      ...req,
      headers: {
        ...req.headers,
        Accept: "application/json"
      }
    });
    return res && JSON.parse(res);
  },
  json({ body, headers, ...req }: JsonRequestOpts) {
    return {
      ...req,
      body: JSON.stringify(body),
      headers: {
        ...headers,
        "Content-Type": "application/json"
      }
    };
  },
  form({ body, headers, ...req }: JsonRequestOpts) {
    return {
      ...req,
      body: QS.form(body),
      headers: {
        ...headers,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    };
  },
  multipart({ body, ...req }: MultipartRequestOpts) {
    const data = new FormData();
    Object.entries(body).forEach(([name, value]) => {
      data.append(name, value);
    });
    return {
      ...req,
      body: data
    };
  },
  /**
   * Deeply remove all properties with undefined values.
   */
  stripUndefined<T>(obj: T) {
    return obj && JSON.parse(JSON.stringify(obj));
  },
  // Encode param names and values as URIComponent
  encodeReserved: [encodeURIComponent, encodeURIComponent],
  allowReserved: [encodeURIComponent, encodeURI],
  /**
   * Creates a tag-function to encode template strings with the given encoders.
   */
  encode(encoders: Encoders, delimiter = ",") {
    const q = (v: any, i: number) => {
      const encoder = encoders[i % encoders.length];
      if (typeof v === "object") {
        if (Array.isArray(v)) {
          return v.map(encoder).join(delimiter);
        }
        const flat = Object.entries(v).reduce(
          (flat, entry) => [...flat, ...entry],
          [] as any
        );
        return flat.map(encoder).join(delimiter);
      }
      return encoder(String(v));
    };
    return (strings: TemplateStringsArray, ...values: any[]) => {
      return strings.reduce((prev, s, i) => {
        return `${prev}${s}${q(values[i] || "", i)}`;
      }, "");
    };
  },
  /**
   * Separate array values by the given delimiter.
   */
  delimited(delimiter = ",") {
    return (params: Record<string, any>, encoders = _.encodeReserved) =>
      Object.entries(params)
        .filter(([, value]) => value !== undefined)
        .map(([name, value]) => _.encode(encoders, delimiter)`${name}=${value}`)
        .join("&");
  },
  joinUrl(...parts: Array<string | undefined>) {
    return parts
      .filter(Boolean)
      .join("/")
      .replace(/([^:]\/)\/+/, "$1");
  }
};
/**
 * Functions to serialize query parameters in different styles.
 */
export const QS = {
  /**
   * Join params using an ampersand and prepends a questionmark if not empty.
   */
  query(...params: string[]) {
    const s = params.join("&");
    return s && `?${s}`;
  },
  /**
   * Serializes nested objects according to the `deepObject` style specified in
   * https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#style-values
   */
  deep(params: Record<string, any>, [k, v] = _.encodeReserved): string {
    const qk = _.encode([s => s, k]);
    const qv = _.encode([s => s, v]);
    // don't add index to arrays
    // https://github.com/expressjs/body-parser/issues/289
    const visit = (obj: any, prefix = ""): string =>
      Object.entries(obj)
        .filter(([, v]) => v !== undefined)
        .map(([prop, v]) => {
          const index = Array.isArray(obj) ? "" : prop;
          const key = prefix ? qk`${prefix}[${index}]` : prop;
          if (typeof v === "object") {
            return visit(v, key);
          }
          return qv`${key}=${v}`;
        })
        .join("&");
    return visit(params);
  },
  /**
   * Property values of type array or object generate separate parameters
   * for each value of the array, or key-value-pair of the map.
   * For other types of properties this property has no effect.
   * See https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#encoding-object
   */
  explode(params: Record<string, any>, encoders = _.encodeReserved): string {
    const q = _.encode(encoders);
    return Object.entries(params)
      .filter(([, value]) => value !== undefined)
      .map(([name, value]) => {
        if (Array.isArray(value)) {
          return value.map(v => q`${name}=${v}`).join("&");
        }
        if (typeof value === "object") {
          return QS.explode(value, encoders);
        }
        return q`${name}=${value}`;
      })
      .join("&");
  },
  form: _.delimited(),
  pipe: _.delimited("|"),
  space: _.delimited("%20")
};
export class HttpError extends Error {
  status: number;
  constructor(status: number, message: string, url: string) {
    super(`${url} - ${message} (${status})`);
    this.status = status;
  }
}
export type ApiResult<Fn> = Fn extends (...args: any) => Promise<infer T>
  ? T
  : never;
export type ProductItemsResponse = any;
export type NotFoundErrorResponse = any;
export type ErrorResponse = any;
export type ResourceBase = {
  id?: string;
  created?: string;
  authorId?: string;
  modified?: string;
  editorId?: string;
};
export type LicenseOrderLine = ResourceBase & {
  lineNumber?: string;
  productId?: string;
  productCode?: string;
  quantity?: number;
  orderValidFrom?: string;
  orderValidUntil?: string;
  metadata?: string;
  licenseOrderId?: string;
};
export type LicenseOrder = ResourceBase & {
  orderNumber?: string;
  orderDate?: string;
  canceled?: boolean;
  metadata?: string;
  orderLines?: LicenseOrderLine[];
};
export type LicenseOrderResponse = any;
export type AlreadyExistsErrorResponse = any;
export type OrganizationGroupsResponse = any;
export type LicensesWithCreditsResponse = any;
export type LicenseUsageResponse = any;
export type LicenseAssignmentsResponse = any;
export type ResourceWithValidity = ResourceBase & {
  validFrom?: string;
  validUntil?: string;
};
export type LicenseAssignment = ResourceWithValidity & {
  type?: "reserved" | "denied" | "floating";
};
export type LicenseAssignments = LicenseAssignment[];
export type AvailableLicensesResponse = any;
export type LicenseWithCreditsResponse = any;
/**
 * Gets product items of product(s)
 */
export async function getProductItems(productId: string, opts?: RequestOpts) {
  return (await _.fetch(`/products/${productId}/product-items`, {
    ...opts
  })) as ProductItemsResponse;
}
/**
 * Fulfills a new license order
 */
export async function fulfillLicenseOrder(
  orgId: string,
  licenseOrder: LicenseOrder,
  opts?: RequestOpts
) {
  return (await _.fetch(
    `/organizations/${orgId}/license-orders`,
    _.json({
      ...opts,
      method: "POST",
      body: licenseOrder
    })
  )) as LicenseOrderResponse;
}
/**
 * Changes data of a fulfilled license order
 */
export async function changeLicenseOrder(
  orgId: string,
  licenseOrder: LicenseOrder,
  opts?: RequestOpts
) {
  return (await _.fetch(
    `/organizations/${orgId}/license-orders`,
    _.json({
      ...opts,
      method: "PUT",
      body: licenseOrder
    })
  )) as LicenseOrderResponse;
}
/**
 * Cancels a license order
 */
export async function cancelLicenseOrder(
  orgId: string,
  orderId: string,
  opts?: RequestOpts
) {
  return (await _.fetch(
    `/organizations/${orgId}/license-orders/${orderId}/cancel`,
    {
      ...opts,
      method: "PUT"
    }
  )) as LicenseOrderResponse;
}
/**
 * Gets groups that have been added as consumers of licenses of the entitlement
 */
export async function listLicenseConsumingOrgGroups(
  orgId: string,
  entId: string,
  opts?: RequestOpts
) {
  return (await _.fetch(
    `/organizations/${orgId}/entitlements/${entId}/consuming-groups`,
    {
      ...opts
    }
  )) as OrganizationGroupsResponse;
}
/**
 * Replaces current license consumer groups
 */
export async function setLicenseConsumingOrgGroups(
  orgId: string,
  entId: string,
  body: string[],
  opts?: RequestOpts
) {
  return await _.fetch(
    `/organizations/${orgId}/entitlements/${entId}/consuming-groups`,
    _.json({
      ...opts,
      method: "PUT",
      body
    })
  );
}
/**
 * Adds a group as a consumer of licenses of the entitlement
 */
export async function addLicenseConsumingOrgGroup(
  orgId: string,
  entId: string,
  groupId: string,
  opts?: RequestOpts
) {
  return await _.fetch(
    `/organizations/${orgId}/entitlements/${entId}/consuming-groups/${groupId}`,
    {
      ...opts,
      method: "POST"
    }
  );
}
/**
 * Removes a group from consumers of the entitlement
 */
export async function removeLicenseConsumingOrgGroup(
  orgId: string,
  entId: string,
  groupId: string,
  opts?: RequestOpts
) {
  return await _.fetch(
    `/organizations/${orgId}/entitlements/${entId}/consuming-groups/${groupId}`,
    {
      ...opts,
      method: "DELETE"
    }
  );
}
/**
 * Lists licenses of the entitlement
 */
export async function listEntitlementLicenses(
  orgId: string,
  entId: string,
  {
    includeCredits,
    includeEffectiveModel
  }: {
    includeCredits?: "all" | "valid" | "latestExpiration";
    includeEffectiveModel?: boolean;
  } = {},
  opts?: RequestOpts
) {
  // changed manually from fetch to fetchJson.
  return (await _.fetchJson(
    `/organizations/${orgId}/entitlements/${entId}/licenses${QS.query(
      QS.form({
        includeCredits,
        includeEffectiveModel
      })
    )}`,
    {
      ...opts
    }
  )) as LicensesWithCreditsResponse;
}
/**
 * Gets current usage of the license
 */
export async function queryLicenseUsage(
  orgId: string,
  entId: string,
  licenseId: string,
  opts?: RequestOpts
) {
  // changed manually from fetch to fetchJson.
  return (await _.fetchJson(
    `/organizations/${orgId}/entitlements/${entId}/licenses/${licenseId}/usage`,
    {
      ...opts
    }
  )) as LicenseUsageResponse;
}
/**
 * Gets user's assignments to the license
 */
export async function getLicenseAssignments(
  orgId: string,
  entId: string,
  licenseId: string,
  userId: string,
  opts?: RequestOpts
) {
  // changed manually from fetch to fetchJson.
  return (await _.fetchJson(
    `/organizations/${orgId}/entitlements/${entId}/licenses/${licenseId}/users/${userId}/assignments`,
    {
      ...opts
    }
  )) as LicenseAssignmentsResponse;
}
/**
 * Manages user's license assigments
 */
export async function manageLicenseAssignments(
  orgId: string,
  entId: string,
  licenseId: string,
  userId: string,
  licenseAssignments: LicenseAssignments,
  opts?: RequestOpts
) {
  // changed manually from fetch to fetchJson.
  return (await _.fetchJson(
    `/organizations/${orgId}/entitlements/${entId}/licenses/${licenseId}/users/${userId}/assignments`,
    _.json({
      ...opts,
      method: "PUT",
      body: licenseAssignments
    })
  )) as LicenseAssignmentsResponse;
}
/**
 * Gets current usage of the license
 */
export async function queryAvailableLicenses(
  userId: string,
  {
    version,
    product,
    includeCredits,
    includeEffectiveModel
  }: {
    version?: string;
    product?: string;
    includeCredits?: "all" | "valid" | "latestExpiration";
    includeEffectiveModel?: boolean;
  } = {},
  opts?: RequestOpts
) {
  // changed manually from fetch to fetchJson.
  return (await _.fetchJson(
    `/users/${userId}/available-licenses${QS.query(
      QS.form({
        version,
        product,
        includeCredits,
        includeEffectiveModel
      })
    )}`,
    {
      ...opts
    }
  )) as AvailableLicensesResponse;
}
/**
 * Moves license
 */
export async function moveLicense(
  orgId: string,
  entId: string,
  licenseId: string,
  destEntId: string,
  {
    includeCredits,
    includeEffectiveModel
  }: {
    includeCredits?: "all" | "valid" | "latestExpiration";
    includeEffectiveModel?: boolean;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetch(
    `/organizations/${orgId}/entitlements/${entId}/licenses/${licenseId}/move/${destEntId}${QS.query(
      QS.form({
        includeCredits,
        includeEffectiveModel
      })
    )}`,
    {
      ...opts,
      method: "PUT"
    }
  )) as LicenseWithCreditsResponse;
}
