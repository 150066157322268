import React from "react";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import Screen from "../screen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppError } from "../../model/AppError";
import { Collapse } from "react-bootstrap";
import "./error-screen-view.scss";
//<editor-fold desc="Messages">
const messages = defineMessages({
  metaDescription: {
    id: "error-screen.meta-description",
    defaultMessage: "Authentication failure info page"
  },
  metaTitle: {
    id: "error-screen.meta-title",
    defaultMessage: "Authentication failed"
  }
});
//</editor-fold>

//<editor-fold desc="Props">
export interface ErrorScreenErrorProps {
  showError: boolean;
  onShowError: (b: boolean) => void;
}
export interface ErrorScreenProps extends ErrorScreenErrorProps {
  errors?: AppError<any>[];
}
//</editor-fold>
export default function ErrorScreen(props: ErrorScreenProps) {
  const intl = useIntl();
  const { errors, showError, onShowError } = props;
  return (
    <Screen
      id={"error-screen"}
      data-test-error-screen
      meta={{
        title: intl.formatMessage(messages.metaTitle),
        description: intl.formatMessage(messages.metaDescription)
      }}
      icon={
        <FontAwesomeIcon
          icon={"exclamation-triangle"}
          className={"text-danger"}
          size="4x"
        />
      }
      header={
        <h2 className={"text-danger"}>
          <FormattedMessage id="error-screen.title" defaultMessage="Oh no!" />
        </h2>
      }
    >
      <p>
        <FormattedMessage
          id="error-screen.copy"
          defaultMessage="Something went wrong and authentication has failed. Please try again."
        />
      </p>
      <div className={"details"}>
        <button
          className={"btn btn-light btn-block" + (showError ? " active" : "")}
          data-test-error-details-trigger
          onClick={(e: any) => {
            onShowError(!showError);
          }}
        >
          <FormattedMessage
            id="error-screen.toggle-details.btn-label"
            defaultMessage="{isVisible, select, true {Hide} false {Show} } technical error details"
            values={{ isVisible: showError }}
          />
        </button>
        <Collapse in={showError} data-test-error-details>
          <div className={"collapsible-target"}>
            {errors &&
              (errors as AppError<any>[]).map(err => {
                return <p>{err.apiError.error_description}</p>;
              })}
          </div>
        </Collapse>
      </div>
      <div className={"py-2"}>
        <a
          className={"btn btn-lg btn-block btn-danger"}
          href={process.env.PUBLIC_URL + "/"}
        >
          <FormattedMessage
            id="error-screen.btn-label"
            defaultMessage="Continue"
          />
        </a>
      </div>
    </Screen>
  );
}
