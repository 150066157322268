import { connect } from "react-redux";
import Users, {
  UsersDOMProps,
  UsersProps as _UsersProps,
  UserOrInvitation as _UserOrInvitation,
  UsersModalVisibilityProps
} from "./users-container";
import { AppState } from "../../../store/AppState";
import OrganizationUtils from "../../../utils/organization";
import {
  listOrganizationUsers,
  listOrganizationGroups,
  listOrganizationRoles,
  listUsersInOrganizationRole,
  // setUserSuspended,
  waitForDispatch,
  listOrganizationsOrganizationGroupInvitations,
  createOrganizationGroupInvitation,
  sendOrganizationGroupInvitation,
  deleteOrganizationGroupInvitation,
  revokeOrganizationGroupInvitation,
  addUserToOrgRole,
  removeUserFromOrgRole
} from "../../../actions";

import * as ActionTypes from "../../../actions/actionTypes";
import {
  CreateOrgGroupInvitationAction,
  DeleteOrgGroupInvitationAction,
  RevokeOrgGroupInvitationAction,
  SendOrgGroupInvitationAction
  // SetUserSuspendedAction
} from "../../../actions/actionTypes";
import { OrganizationGroupInvitation } from "../../../model/OrganizationGroupInvitation";
import { User } from "../../../model/User";
import {
  PickReduxDispatchProps,
  PickReduxStateProps
} from "../../../util/typeUtil";
import store from "../../../store";
import { detachUserFromOrganization } from "../../../actions/detachUserFromOrgMultiAction";

export { ModalKeys } from "./users-container";
export type UserOrInvitation = _UserOrInvitation;

export interface UsersProps extends UsersDOMProps, UsersModalVisibilityProps {}

const sender = { key: "users" };

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<_UsersProps, UsersProps>;
// Dispatch props provided to the wrapped component by this connect component, using mapDispatchToProps
type ReduxDispatchProps = PickReduxDispatchProps<_UsersProps, UsersProps>;
function invitationsAsUsers(
  invitations?: OrganizationGroupInvitation[]
): UserOrInvitation[] {
  if (invitations) {
    return invitations.map(val => {
      return {
        displayName: val.recipientName,
        email: val.email,
        // professionalTitle?: string;
        // nickname?: string;
        lastName: "Unknown",
        firstName: "Unknown",
        // picture?: string;
        // phoneNumber?: string;
        // naturalId?: string;
        id: val.id,
        invitation: true,
        invitationState: val.invitationState,
        claimedAt: val.claimedAt,
        declinedAt: val.declinedAt,
        revokedAt: val.revokedAt,
        status: val.invitationState,
        isOrgAdmin: false
      } as UserOrInvitation;
    });
  } else {
    return [];
  }
}
function usersWithStatus(usrs?: User[]): UserOrInvitation[] {
  if (usrs) {
    return usrs.map(val => {
      let status;
      let validUntil;
      if (
        val.validUntil !== null &&
        val.validUntil !== undefined &&
        val.validUntil !== ""
      ) {
        validUntil = new Date(val.validUntil);
      }
      const cur = new Date().getTime();
      if (validUntil && new Date().getTime() > validUntil.getTime()) {
        status = "suspended";
      } else if ((val as any).isOrgAdmin) {
        status = "admin";
      } else if (
        !val.lastSignOn ||
        cur - new Date(val.lastSignOn).getTime() > 30 * 24 * 60 * 60 * 1000
      ) {
        status = "inactive";
      } else {
        status = "member";
      }
      return {
        ...val,
        status
      } as UserOrInvitation;
    });
  } else {
    return [];
  }
}
function mapStateToProps(state: AppState): ReduxStateProps {
  const { authentication } = state;
  const users = OrganizationUtils.resolveOrganizationUsersFromState(state);
  const orgAdmins = OrganizationUtils.resolveOrgAdminsFromState(state, false);
  const orgAdminRoleId = OrganizationUtils.resolveOrgAdminRoleFromState(state);
  const groups = OrganizationUtils.resolveOrganizationGroupsFromState(state);
  const org = OrganizationUtils.resolveSelectedFromState(state);
  const invitations = OrganizationUtils.resolveOrganizationInvitationsFromState(
    state
  );
  let name;
  let id;
  if (authentication) {
    if (authentication.userDisplayName) {
      name = authentication.userDisplayName;
    }
    if (authentication.userId) {
      id = authentication.userId;
    }
  }
  let employeesGroup = undefined;
  if (groups) {
    for (let i = 0; i < groups.length; i += 1) {
      if (groups[i].type === "employees") {
        employeesGroup = groups[i];
        break;
      }
    }
  }
  let merged: UserOrInvitation[] | undefined = undefined;
  if (users && invitations && orgAdmins) {
    merged = usersWithStatus(
      users.map(user => {
        (user as any).isOrgAdmin = orgAdmins.indexOf(user.id as string) !== -1;
        return user;
      })
    );

    merged = merged.concat(
      invitationsAsUsers(
        invitations.filter(inv => {
          return inv.invitationState !== "accepted";
        })
      )
    );
  }

  return {
    users: merged,
    organizationId: org ? (org.id as string) : null,
    employeesGroup,
    userName: name,
    userId: id,
    orgAdminRoleId
  };
}

function mapDispatchToProps(dispatch: (t: any) => void): ReduxDispatchProps {
  return {
    onLoadUsers: onLoadUsersImpl,
    onRemoveUser: onRemoveUserImpl,
    onAssignAdmin: onAssignAdminImpl,
    onUnassignAdmin: onUnassignAdminImpl,
    // onSuspendUser: onSuspendUserImpl,
    // onUnsuspendUser: onUnsuspendUserImpl,
    onLoadEmployeesGroup: onLoadEmployeesGroupImpl,
    onRevokeInvitation: onRevokeInvitationImpl,
    onResendInvitation: onResendInvitationImpl,
    onRemoveInvitation: onRemoveInvitationImpl,
    onCreateInvitation: onCreateInvitationImpl
  };
}

async function onAssignAdminImpl(userId: string, roleId?: string) {
  const action: ActionTypes.AddUserToOrgRoleAction = await waitForDispatch<
    ActionTypes.AddUserToOrgRoleAction
  >(addUserToOrgRole(sender, roleId as string, userId), store.dispatch);
  return action;
}
async function onUnassignAdminImpl(userId: string, roleId?: string) {
  const action: ActionTypes.RemoveUserFromOrgRoleAction = await waitForDispatch<
    ActionTypes.RemoveUserFromOrgRoleAction
  >(removeUserFromOrgRole(sender, roleId as string, userId), store.dispatch);
  return action;
}

async function onLoadUsersImpl() {
  store.dispatch(listOrganizationUsers(sender));
  store.dispatch(listOrganizationsOrganizationGroupInvitations(sender));

  const listOrgRolesAction = await waitForDispatch<
    ActionTypes.ListOrgRolesAction
  >(listOrganizationRoles(sender), store.dispatch);

  listOrgRolesAction.roles.forEach(role => {
    store.dispatch(listUsersInOrganizationRole(sender, role.id as string));
  });
}

async function onRemoveUserImpl(id: string) {
  const action: ActionTypes.MultiAction = await waitForDispatch<
    ActionTypes.MultiAction
  >(detachUserFromOrganization(sender, id), store.dispatch);
  return action;
}

/*
async function onSuspendUserImpl(id: string) {
  const action: SetUserSuspendedAction = await waitForDispatch<
    ActionTypes.SetUserSuspendedAction
  >(setUserSuspended(sender, true, id), store.dispatch);
  return action;
}

async function onUnsuspendUserImpl(id: string) {
  const action: SetUserSuspendedAction = await waitForDispatch<
    ActionTypes.SetUserSuspendedAction
  >(setUserSuspended(sender, false, id), store.dispatch);
  return action;
}
*/
function onLoadEmployeesGroupImpl() {
  store.dispatch(listOrganizationGroups(sender));
}

async function onRevokeInvitationImpl(invitation: OrganizationGroupInvitation) {
  const action: RevokeOrgGroupInvitationAction = await waitForDispatch<
    ActionTypes.RevokeOrgGroupInvitationAction
  >(
    revokeOrganizationGroupInvitation(sender, invitation.id as string),
    store.dispatch
  );
  return action;
}

async function onResendInvitationImpl(invitation: OrganizationGroupInvitation) {
  const action: SendOrgGroupInvitationAction = await waitForDispatch<
    ActionTypes.SendOrgGroupInvitationAction
  >(
    sendOrganizationGroupInvitation(sender, invitation.id as string),
    store.dispatch
  );
  return action;
}

async function onRemoveInvitationImpl(id: string) {
  const action: DeleteOrgGroupInvitationAction = await waitForDispatch<
    ActionTypes.DeleteOrgGroupInvitationAction
  >(deleteOrganizationGroupInvitation(sender, id), store.dispatch);
  return action;
}

async function onCreateInvitationImpl(invitation: OrganizationGroupInvitation) {
  const action: CreateOrgGroupInvitationAction = await waitForDispatch<
    ActionTypes.CreateOrgGroupInvitationAction
  >(createOrganizationGroupInvitation(sender, invitation), store.dispatch);
  if (action && action.invitation) {
    const sendAction: SendOrgGroupInvitationAction = await waitForDispatch<
      ActionTypes.SendOrgGroupInvitationAction
    >(
      sendOrganizationGroupInvitation(sender, action.invitation.id as string),
      store.dispatch
    );
    return sendAction;
  } else {
    return action;
  }
}

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  UsersProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps
)(Users);
