import * as ActionTypes from "./actionTypes";
import { idpApi } from "../api";
import { ActionSender } from "../model/ActionSender";
import { Organization } from "../model/Organization";
import { User } from "../model/User";
import {
  buildActionThunk,
  ensureSelectedOrgId,
  forceUndefined
} from "./actionHelpers";

/**
 * Sets the organization selected to be managed in the application.
 * @param orgId Id of the organization to manage
 */
export function setOrg(orgId: string): ActionTypes.SetOrgAction {
  return {
    type: ActionTypes.SET_ORG,
    id: orgId
  };
}

/**
 * Starts loading data of organizations that the authenticated user
 * can manage in this application.
 * @param sender Component requesting for the action
 */
export function loadOrgs(
  sender: ActionSender
): ActionTypes.AppThunkAction<ActionTypes.LoadOrgsAction> {
  return buildActionThunk<ActionTypes.LoadOrgsAction, Organization[]>(
    sender,
    ActionTypes.LOAD_ORGS,
    async () => await idpApi.getAppOrganizations(),
    (type, organizations) => ({
      type,
      organizations: forceUndefined(organizations)
    })
  );
}

/**
 * Starts loading data of an organization by id.
 * @param sender Component requesting for the action
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function getOrg(
  sender: ActionSender,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.GetOrgAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.GetOrgAction, Organization>(
    sender,
    ActionTypes.GET_ORG,
    async () => await idpApi.getOrganization(orgIdOrDefault),
    (type, organization) => ({
      type,
      organization: forceUndefined(organization)
    })
  );
}

/**
 * Updates organization data by replacing existing data with the given data.
 * @param sender Component requesting for the action
 * @param org The new organization data
 */
export function replaceOrg(
  sender: ActionSender,
  org: Organization
): ActionTypes.AppThunkAction<ActionTypes.ReplaceOrgAction> {
  return buildActionThunk<ActionTypes.ReplaceOrgAction, Organization>(
    sender,
    ActionTypes.REPLACE_ORG,
    async () => await idpApi.replaceOrganization(org),
    (type, organization) => ({
      type,
      organization: forceUndefined(organization)
    })
  );
}

/**
 * Marks second factor authentication (MFA) as required or not required for
 * users of an organization.
 * @param sender Component requesting for the action
 * @param required true to mark MFA required, false to mark MFA optional
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function setMfaRequired(
  sender: ActionSender,
  required: boolean,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.SetMfaRequiredAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.SetMfaRequiredAction, void>(
    sender,
    ActionTypes.SET_MFA_REQUIRED,
    async () => await idpApi.setMfaRequired(required, orgIdOrDefault),
    type => ({
      type,
      orgId: orgIdOrDefault,
      required
    })
  );
}

/**
 * Starts loading data of all members of an organization.
 * @param sender Component requesting for the action
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function listOrganizationUsers(
  sender: ActionSender,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListOrgUsersAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.ListOrgUsersAction, User[]>(
    sender,
    ActionTypes.LIST_ORG_USERS,
    async () => await idpApi.listAllOrganizationUsers(orgIdOrDefault),
    (type, users) => ({
      type,
      users: forceUndefined(users),
      orgId: orgIdOrDefault
    })
  );
}
