import React, { useEffect, useState } from "react";
import ApplyInputView, {
  ApplyInputProps as _ApplyInputProps,
  ApplyInputValueDispatchProps
} from "./apply-input-view";

type ApplyInputProps = Omit<
  _ApplyInputProps,
  keyof ApplyInputValueDispatchProps
>;

function ApplyInput(props: ApplyInputProps) {
  let { value, activeValue, ...inputProps } = props;
  const [inputValue, setInputValue] = useState(value);
  useEffect(() => {
    if (!activeValue || !activeValue.length) {
      setInputValue("");
    }
  }, [activeValue]);

  const handleChange = (val: string) => {
    setInputValue(val);
  };
  return (
    <ApplyInputView
      activeValue={activeValue}
      value={inputValue}
      onValueChange={handleChange}
      {...inputProps}
    />
  );
}
export default ApplyInput;
