import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faCheckCircle,
  faRedo,
  faPowerOff,
  faUserCircle,
  faPen,
  faEllipsisH,
  faColumns,
  faSync,
  faTimes,
  faSort,
  faSortUp,
  faSortDown,
  faFilter,
  faInfoCircle,
  faSpinner,
  faExclamationTriangle,
  faExclamationCircle,
  faChevronUp,
  faChevronRight,
  faChevronDown,
  faChevronLeft,
  faUserCog,
  faUserCheck
} from "@fortawesome/free-solid-svg-icons";

const icons = {
  faCheck,
  faCheckCircle,
  faRedo,
  faPowerOff,
  faUserCircle,
  faPen,
  faEllipsisH,
  faColumns,
  faSync,
  faTimes,
  faSort,
  faSortUp,
  faSortDown,
  faFilter,
  faInfoCircle,
  faSpinner,
  faExclamationTriangle,
  faExclamationCircle,
  faChevronUp,
  faChevronRight,
  faChevronDown,
  faChevronLeft,
  faUserCog,
  faUserCheck
};

let initialized = false;

const initializer = {
  init: () => {
    if (!initialized) {
      library.add(...Object.values(icons));
      initialized = true;
    }
  },
  icons
};

export default initializer;
