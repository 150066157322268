import IdpApiWrapper from "./IdpApiWrapper";
import { User } from "../../model/User";
import { getSelectedOrgId, getAuthentication } from "../../store";
import { Organization } from "../../model/Organization";
import { OrganizationGroup } from "../../model/OrganizationGroup";
import { OrganizationRole } from "../../model/OrganizationRole";
import { OrganizationGroupInvitation } from "../../model/OrganizationGroupInvitation";

/**
 * Wraps the used IpdApi and provides defaults argument values.
 */
export default class IdpApiWithDefaults extends IdpApiWrapper {
  public getAppOrganizations(userId?: string): Promise<Organization[]> {
    return super.getAppOrganizations(this.userIdOrDefault(userId));
  }

  public getOrganization(orgId?: string): Promise<Organization> {
    return super.getOrganization(this.orgIdOrDefault(orgId));
  }

  public setMfaRequired(required: boolean, orgId?: string): Promise<void> {
    return super.setMfaRequired(required, this.orgIdOrDefault(orgId));
  }

  public listAllOrganizationUsers(orgId?: string): Promise<User[]> {
    return super.listAllOrganizationUsers(this.orgIdOrDefault(orgId));
  }

  public listOrganizationGroups(orgId?: string): Promise<OrganizationGroup[]> {
    return super.listOrganizationGroups(this.orgIdOrDefault(orgId));
  }

  public createOrganizationGroup(
    orgGroup: OrganizationGroup,
    orgId?: string
  ): Promise<OrganizationGroup> {
    return super.createOrganizationGroup(orgGroup, this.orgIdOrDefault(orgId));
  }

  public replaceOrganizationGroup(
    orgGroup: OrganizationGroup,
    orgId?: string
  ): Promise<OrganizationGroup> {
    return super.replaceOrganizationGroup(orgGroup, this.orgIdOrDefault(orgId));
  }

  public getOrganizationGroup(
    orgGroupId: string,
    orgId?: string
  ): Promise<OrganizationGroup> {
    return super.getOrganizationGroup(orgGroupId, this.orgIdOrDefault(orgId));
  }

  public deleteOrganizationGroup(
    orgGroupId: string,
    orgId?: string
  ): Promise<void> {
    return super.deleteOrganizationGroup(
      orgGroupId,
      this.orgIdOrDefault(orgId)
    );
  }

  public listOrganizationRoles(orgId?: string): Promise<OrganizationRole[]> {
    return super.listOrganizationRoles(this.orgIdOrDefault(orgId));
  }

  public createOrganizationRole(
    orgRole: OrganizationRole,
    orgId?: string
  ): Promise<OrganizationRole> {
    return super.createOrganizationRole(orgRole, this.orgIdOrDefault(orgId));
  }

  public replaceOrganizationRole(
    orgRole: OrganizationRole,
    orgId?: string
  ): Promise<OrganizationRole> {
    return super.replaceOrganizationRole(orgRole, this.orgIdOrDefault(orgId));
  }

  public getOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<OrganizationRole> {
    return super.getOrganizationRole(orgRoleId, this.orgIdOrDefault(orgId));
  }

  public deleteOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<void> {
    return super.deleteOrganizationRole(orgRoleId, this.orgIdOrDefault(orgId));
  }

  public listUsersInOrganizationGroup(
    groupId: string,
    orgId?: string
  ): Promise<User[]> {
    return super.listUsersInOrganizationGroup(
      groupId,
      this.orgIdOrDefault(orgId)
    );
  }

  public addUsersToOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    return super.addUsersToOrganizationGroup(
      groupId,
      userIds,
      this.orgIdOrDefault(orgId)
    );
  }

  public setUsersInOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    return super.setUsersInOrganizationGroup(
      groupId,
      userIds,
      this.orgIdOrDefault(orgId)
    );
  }

  public removeUsersFromOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    return super.removeUsersFromOrganizationGroup(
      groupId,
      userIds,
      this.orgIdOrDefault(orgId)
    );
  }

  public listOrganizationsOrganizationGroupInvitations(
    orgId?: string
  ): Promise<OrganizationGroupInvitation[]> {
    return super.listOrganizationsOrganizationGroupInvitations(
      this.orgIdOrDefault(orgId)
    );
  }

  public getOrganizationsOrganizationGroupInvitation(
    invitationId: string,
    orgId?: string
  ): Promise<OrganizationGroupInvitation> {
    return super.getOrganizationsOrganizationGroupInvitation(
      invitationId,
      this.orgIdOrDefault(orgId)
    );
  }

  public listUsersInOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<User[]> {
    return super.listUsersInOrganizationRole(
      orgRoleId,
      this.orgIdOrDefault(orgId)
    );
  }

  public addUsersToOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    return super.addUsersToOrganizationRole(
      orgRoleId,
      userIds,
      this.orgIdOrDefault(orgId)
    );
  }

  public getUserInOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<User> {
    return super.getUserInOrganizationRole(
      orgRoleId,
      userId,
      this.orgIdOrDefault(orgId)
    );
  }

  public addUserToOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<void> {
    return super.addUserToOrganizationRole(
      orgRoleId,
      userId,
      this.orgIdOrDefault(orgId)
    );
  }

  public removeUsersFromOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    return super.removeUsersFromOrganizationRole(
      orgRoleId,
      userIds,
      this.orgIdOrDefault(orgId)
    );
  }

  public removeUserFromOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<void> {
    return super.removeUserFromOrganizationRole(
      orgRoleId,
      userId,
      this.orgIdOrDefault(orgId)
    );
  }

  public setUsersInOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    return this.setUsersInOrganizationRole(
      orgRoleId,
      userIds,
      this.orgIdOrDefault(orgId)
    );
  }

  private orgIdOrDefault(orgId?: string): string | undefined {
    return orgId ? orgId : getSelectedOrgId();
  }

  private userIdOrDefault(userId?: string): string | undefined {
    if (userId) {
      return userId;
    }

    const authentication = getAuthentication();

    if (!authentication) {
      throw new Error(
        "User id not provided and authentication object not available"
      );
    }

    return authentication.userId;
  }
}
