import { User } from "../model/User";
import { UserProfile } from "../model/entitlement/UserProfile";
import { TechnicalActor } from "../model/entitlement/TechnicalActor";
import { LicenseUsage } from "../model/entitlement/LicenseUsage";
import { isValid } from "../util/objectUtil";

function resolveDisplayName(
  usr: User | UserProfile | TechnicalActor | undefined,
  fallback: string
): string {
  let retVal = fallback;
  if (usr) {
    if (usr.displayName) {
      retVal = usr.displayName;
    } else {
      const aUsr = usr as User | UserProfile;
      if (aUsr && aUsr.firstName && aUsr.lastName) {
        retVal = aUsr.firstName + " " + aUsr.lastName;
      } else if (aUsr && aUsr.lastName) {
        retVal = aUsr.lastName;
      } else if (aUsr && aUsr.firstName) {
        retVal = aUsr.firstName;
      } else if (aUsr && (aUsr as User) && (aUsr as User).nickname) {
        retVal = (aUsr as User).nickname as string;
      }
    }
  }
  return retVal;
}

function isConsuming(
  usr: User | UserProfile | TechnicalActor,
  lic: LicenseUsage | null | undefined
): boolean {
  let retVal = false;
  if (lic && lic.users) {
    const licUsr = lic.users.find(val => val.id === usr.id);
    if (licUsr && licUsr.assignments) {
      for (let i = 0; i < licUsr.assignments.length; i += 1) {
        if (licUsr.assignments[i].sessions) {
          const sess = licUsr.assignments[i].sessions;
          if (sess) {
            for (let j = 0; j < sess.length; j += 1) {
              retVal = isValid(sess[j]);
              if (retVal) {
                break;
              }
            }
          }
        }
        if (retVal) {
          break;
        }
      }
    }
  }
  return retVal;
}

export default {
  resolveDisplayName,
  isConsuming
};
