import { AppState } from "./AppState";
import _debug from "debug";
const debug = _debug("localStorage");

export function loadState(): AppState | undefined {
  try {
    const serialized = localStorage.getItem("AppState");
    if (serialized === null) {
      return undefined;
    }
    return JSON.parse(serialized);
  } catch (err) {
    return undefined;
  }
}

export function saveState(state: AppState): void {
  try {
    const serialized = JSON.stringify(state);
    localStorage.setItem("AppState", serialized);
  } catch (err) {
    if (debug.enabled) {
      debug(`save state failed: ${err}`);
    }
  }
}
