import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Organization, {
  ModalKeys as OrganizationModalKeys
} from "../../routes/organization";
import SelectOrganizationPage from "../../components/pages/select-organization";
import Licenses, {
  ModalKeys as LicensesModalKeys
} from "../../routes/licenses";
import Users, { ModalKeys as UsersModalKeys } from "../../routes/users";
import NotFound from "../../routes/not-found";
import MyLicenses from "../../routes/my-licenses";
import AppLayout from "../app-layout";
import PageNavigation from "../page-navigation";
import SelectOrganization from "../../routes/select-organization";
import { useIntl, defineMessages } from "react-intl";
import ProgressIndicator from "../progress-indicator";
import { getEnvParam } from "../../util/env";

//<editor-fold desc="Props">
export interface AppProps {
  organizationId: string | null;
  hasOrgsToManage: boolean;
}
//</editor-fold>

//<editor-fold desc="Messages">
const messages = defineMessages({
  navLabelMyLicenses: {
    id: "app.nav.my-licenses-label",
    defaultMessage: "My Licenses"
  },
  navLabelOrganization: {
    id: "app.nav.organization-label",
    defaultMessage: "Organization"
  },
  navLabelUser: {
    id: "app.nav.users-label",
    defaultMessage: "Users"
  },
  navLabelLicenses: {
    id: "app.nav.licenses-label",
    defaultMessage: "Licenses"
  }
});
//</editor-fold>
function isNavActive(
  match: { path: string; isExact: boolean },
  location: { pathname: string },
  modalKeys: any
): boolean {
  let retVal: boolean = false;
  if (match) {
    if (match.isExact) {
      retVal = true;
    } else if (modalKeys) {
      let modal = location.pathname.replace(match.path, "");
      if (modal.startsWith("/")) {
        modal = modal.substr(1);
      }
      // at this point the modal is either modal key or selection id
      if (modal.indexOf("/") > 0) {
        const tmp = modal.split("/");
        modal = tmp[1];
      }
      // now we have the modal key for sure
      retVal = (Object.values(modalKeys) as string[]).indexOf(modal) >= 0;
    }
  }
  return retVal;
}

function App(props: AppProps): JSX.Element {
  //<editor-fold desc="Local variables">
  const intl = useIntl();
  const reactAppBasePath = getEnvParam("REACT_APP_BASE", "");
  const { organizationId, hasOrgsToManage } = props;
  let nav: {
    id: string;
    label: string;
    path: string;
    exact?: boolean;
    isActive?: (
      match: { path: string; isExact: boolean },
      location: { pathname: string }
    ) => boolean;
  }[] = [
    {
      id: "my-licenses",
      label: intl.formatMessage(messages.navLabelMyLicenses),
      path: "/my-licenses"
    }
  ];
  if (hasOrgsToManage) {
    nav = nav.concat([
      {
        id: "organization",
        label: intl.formatMessage(messages.navLabelOrganization),
        path: "/organization",
        isActive: (
          match: { path: string; isExact: boolean },
          location: { pathname: string }
        ) => isNavActive(match, location, OrganizationModalKeys)
      },
      {
        id: "users",
        label: intl.formatMessage(messages.navLabelUser),
        path: "/users",
        isActive: (
          match: { path: string; isExact: boolean },
          location: { pathname: string }
        ) => isNavActive(match, location, UsersModalKeys)
      },
      {
        id: "licenses",
        label: intl.formatMessage(messages.navLabelLicenses),
        path: "/licenses",
        isActive: (
          match: { path: string; isExact: boolean },
          location: { pathname: string }
        ) => isNavActive(match, location, LicensesModalKeys)
      }
    ]);
  }
  //</editor-fold>
  return (
    <Router basename={reactAppBasePath}>
      <AppLayout pageNavigation={<PageNavigation nav={nav} />}>
        <>
          {organizationId || !hasOrgsToManage ? (
            <Switch>
              <Route path="/my-licenses" component={MyLicenses} />
              {hasOrgsToManage && (
                <Route
                  path="/organization/select"
                  component={SelectOrganization}
                />
              )}
              {hasOrgsToManage && (
                <Route
                  path={`/organization/:id([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})?/:modal(${Object.values(
                    OrganizationModalKeys
                  ).join("|")})?`}
                  exact
                  component={Organization}
                />
              )}
              {hasOrgsToManage && (
                <Route
                  path={`/users/:userId([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})?/:modal(${Object.values(
                    UsersModalKeys
                  ).join("|")})?`}
                  exact
                  component={Users}
                />
              )}
              {hasOrgsToManage && (
                <Route
                  path={`/licenses/:licenseId([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})?/:modal(${Object.values(
                    LicensesModalKeys
                  ).join("|")})?`}
                  exact
                  component={Licenses}
                />
              )}
              <Route
                path="/"
                exact
                component={() => <Redirect to={"/my-licenses"} />}
              />
              <Route path="/*" component={NotFound} />
            </Switch>
          ) : (
            <SelectOrganizationPage />
          )}
          <ProgressIndicator data-test-progress-indicator />
        </>
      </AppLayout>
      <div
        id={"notifications"}
        className={"d-flex flex-wrap justify-content-end"}
      ></div>
    </Router>
  );
}

export default App;
