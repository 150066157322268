import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Overlay } from "react-bootstrap";
import { TableColumn } from "../table-view";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import OverlayContent from "../../overlay-content";
import TooltipTrigger from "../../tooltip-trigger";

//<editor-fold desc="Props">
export interface ColumnToolsVisibilityProps {
  show?: boolean;
  onShow: () => void;
  onHide: () => void;
}
export interface ColumnToolsProps extends ColumnToolsVisibilityProps {
  columns: TableColumn[];
  onToggleColumn?: (key: string, visible: boolean) => void;
}
//</editor-fold>

//<editor-fold desc="Messages">
const messages = defineMessages({
  toolsToggleColumnLastVisibleTip: {
    id: "table.tools.toggle-columns-last-visible-tooltip",
    defaultMessage:
      "The last column can not be removed as a minimum of 1 column must be active at all times"
  },
  toolsToggleColumnTip: {
    id: "table.tools.toggle-columns-tooltip",
    defaultMessage: "Toggle columns",
    description: "tooltip for the button"
  }
});
//</editor-fold>

export default function ColumnTools(props: ColumnToolsProps) {
  //<editor-fold desc="Local variables">
  const intl = useIntl();
  const { columns, onToggleColumn, show, onShow, onHide } = props;
  const cols = columns.filter((c: TableColumn) => !c.isTechnical);
  const visibleCols = cols.filter((c: TableColumn) => !c.hidden);
  //</editor-fold>

  //<editor-fold desc="Hooks">
  const triggerRef = useRef(null);
  //</editor-fold>

  return (
    <>
      <Overlay
        placement={"bottom"}
        rootClose={true}
        target={triggerRef.current}
        show={show === true}
        onHide={onHide}
      >
        <OverlayContent className={"dropdown-menu column-tools"}>
          <div data-test-table-columns>
            <div className={"dropdown-header"}>
              <FormattedMessage
                id="table.tools.toggle-columns-title"
                defaultMessage="Toggle columns"
              />
            </div>
            {cols &&
              cols.map((c: TableColumn) => {
                return (
                  <TooltipTrigger
                    key={c.key}
                    tipKey={c.key + "LastVisibleTip"}
                    tip={
                      visibleCols.length < 2 && !c.hidden
                        ? intl.formatMessage(
                            messages.toolsToggleColumnLastVisibleTip
                          )
                        : undefined
                    }
                  >
                    <div
                      className={"dropdown-item"}
                      data-test-table-column={c.key}
                      onClick={
                        visibleCols.length > 1 || !!c.hidden
                          ? (e: any) => {
                              e.preventDefault();
                              if (onToggleColumn) {
                                onToggleColumn(c.key, !!c.hidden);
                              }
                            }
                          : undefined
                      }
                    >
                      <Form.Check
                        id={c.key}
                        readOnly
                        disabled={visibleCols.length < 2 && !c.hidden}
                        custom
                        checked={!c.hidden}
                        type={"checkbox"}
                        label={c.label}
                      />
                    </div>
                  </TooltipTrigger>
                );
              })}
          </div>
        </OverlayContent>
      </Overlay>

      <TooltipTrigger
        tipKey={"toolsToggleColumnTip"}
        tip={intl.formatMessage(messages.toolsToggleColumnTip)}
      >
        <Button
          data-test-table-columns-trigger
          variant={"outline-secondary"}
          ref={triggerRef}
          active={show}
          onClick={onShow}
        >
          <FontAwesomeIcon icon="columns" />
        </Button>
      </TooltipTrigger>
    </>
  );
}
