import React, { useEffect, useState } from "react";
import View, {
  LicenseUsersModalProps as _LicenseUsersModalProps,
  LicenseUsersModalStateProps
} from "./license-users-modal-view";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import UserUtils from "../../utils/user";

import {
  LicenseUsersByReservation,
  resolveFreeSeatsForLicense
} from "../../util/licenseUtil";
import { FeedbackEntry } from "../feedback";
import { User } from "../../model/User";
import { UserProfile } from "../../model/entitlement/UserProfile";
import { TechnicalActor } from "../../model/entitlement/TechnicalActor";

export type LicenseUsersModalProps = Omit<
  _LicenseUsersModalProps,
  keyof LicenseUsersModalStateProps
>;

function sortUserListMembersByResolvedDisplayName(
  obj: any,
  member: string
): any {
  const retVal = { ...obj };
  if (retVal && retVal[member]) {
    retVal[member] = retVal[member].sort(
      (
        a: User | UserProfile | TechnicalActor | undefined,
        b: User | UserProfile | TechnicalActor | undefined
      ) => {
        const an = UserUtils.resolveDisplayName(a, "anonymous");
        const bn = UserUtils.resolveDisplayName(b, "anonymous");

        if (an < bn) {
          return -1;
        } else if (an > bn) {
          return 1;
        } else {
          return 0;
        }
      }
    );
  }
  return retVal;
}

function ensureAlphabeticalLists(
  users: LicenseUsersByReservation | undefined
): LicenseUsersByReservation | undefined {
  let retVal = users;
  retVal = sortUserListMembersByResolvedDisplayName(
    retVal,
    "usersWithReservation"
  );
  retVal = sortUserListMembersByResolvedDisplayName(
    retVal,
    "usersWithoutReservation"
  );
  return retVal;
}

export default function LicenseUsersModal(props: LicenseUsersModalProps) {
  const { users, licenseId, show, license, ...other } = props;
  const [feedback, setFeedback] = useState<FeedbackEntry[]>([]);
  const removeFeedback = (id: string) => {
    setFeedback(feedback.filter(val => val.id !== id));
  };
  const addFeedback = (f: FeedbackEntry[]) => {
    // filter out feedback with same id
    setFeedback(
      feedback
        .filter(val => f.findIndex(fval => fval.id === val.id) < 0)
        .concat(f)
    );
  };
  const [freeSeats, setFreeSeats] = useState<number>(0);
  const [totalSeats, setTotalSeats] = useState<number>(0);
  useEffect(() => {
    if (license !== undefined && license !== null) {
      setFreeSeats(resolveFreeSeatsForLicense(license));
      setTotalSeats(license.seatsTotal ? license.seatsTotal : 0);
    }
  }, [license]);

  const [sUsers, setUsers] = useState<
    LicenseUsersByReservation | undefined | null
  >(users ? ensureAlphabeticalLists(cloneDeep(users)) : users);
  // selection from the unassigned list for assignment
  const [toAssign, setToAssign] = useState<string[]>([]);
  // selection from the assigned list for unassignemnt
  const [toUnassign, setToUnassign] = useState<string[]>([]);
  useEffect(() => {
    if (sUsers === undefined) {
      setUsers(users ? ensureAlphabeticalLists(cloneDeep(users)) : users);
    }
  }, [sUsers, users, setUsers]);
  useEffect(() => {
    if (licenseId) {
      // TODO: useEffect equality issue: this is a potential bug as this effect hook is hit too often due to users changing from every render
      setUsers(users ? ensureAlphabeticalLists(cloneDeep(users)) : users);
    } else {
      setUsers(undefined);
    }
  }, [licenseId, users, setUsers]);
  useEffect(() => {
    if (show !== true) {
      setUsers(undefined);
      setToAssign([]);
      setToUnassign([]);
    }
  }, [show, setUsers]);
  const onAssign = (sel: string[]) => {
    if (
      sel &&
      sel.length &&
      sUsers &&
      users &&
      sUsers.usersWithoutReservation
    ) {
      const ta = sUsers.usersWithoutReservation.filter(val => {
        return sel.indexOf(val.id as string) >= 0;
      });
      const tu = sUsers.usersWithoutReservation.filter(val => {
        return sel.indexOf(val.id as string) < 0;
      });
      const nu = cloneDeep(sUsers);
      nu.usersWithoutReservation = tu;
      nu.usersWithReservation = nu.usersWithReservation.concat(ta);

      let removeFreeSeats = 0;
      ta.forEach(val => {
        if (!UserUtils.isConsuming(val, license)) {
          removeFreeSeats += 1;
        }
      });
      setFreeSeats(freeSeats - removeFreeSeats);
      setUsers(ensureAlphabeticalLists(nu));
      setToAssign([]);
    }
  };
  const onUnassign = (sel: string[]) => {
    if (sel && sel.length && sUsers && users && sUsers.usersWithReservation) {
      const tu = sUsers.usersWithReservation.filter(val => {
        return sel.indexOf(val.id as string) >= 0;
      });
      const ta = sUsers.usersWithReservation.filter(val => {
        return sel.indexOf(val.id as string) < 0;
      });
      const nu = cloneDeep(sUsers);
      nu.usersWithReservation = ta;
      nu.usersWithoutReservation = nu.usersWithoutReservation.concat(tu);
      let addFreeSeats = 0;
      tu.forEach(val => {
        if (!UserUtils.isConsuming(val, license)) {
          addFreeSeats += 1;
        }
      });
      setFreeSeats(freeSeats + addFreeSeats);
      setUsers(ensureAlphabeticalLists(nu));
      setToUnassign([]);
    }
  };
  return (
    <View
      {...other}
      users={sUsers}
      licenseId={licenseId}
      license={license}
      show={show}
      usersChanged={
        !isEqual(
          sUsers,
          users ? ensureAlphabeticalLists(cloneDeep(users)) : users
        )
      }
      feedback={feedback}
      onShowFeedback={addFeedback}
      onHideFeedback={removeFeedback}
      toAssign={toAssign}
      toUnassign={toUnassign}
      onSetToAssign={setToAssign}
      onSetToUnassign={setToUnassign}
      onAssign={onAssign}
      onUnassign={onUnassign}
      freeSeats={freeSeats}
      totalSeats={totalSeats}
    />
  );
}
