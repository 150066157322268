import { APP_ORGS } from "./appOrganizations";
import { ALL_USERS, ALL_USERS_BY_ORG_ID } from "./allUsers";
import { ALL_INVITATIONS, ALL_INVITATIONS_BY_ORG_ID } from "./allInvitations";
import {
  ALL_GROUP_MEMBER_IDS_BY_GROUP_ID,
  ALL_GROUPS_BY_ORG_ID
} from "./allGroups";
import {
  ALL_ROLES_BY_ORG_ID,
  ALL_ROLE_PERMISSION_IDS_BY_ROLE_ID,
  ALL_ROLE_MEMBER_IDS_BY_ROLE_ID
} from "./allRoles";
import { INTERNAL_PERMISSIONS } from "./permissions";
import { LICENSED_ITEMS } from "./licensedItems";
import { ORG_LICENSES, ORG_LICENSE_USERS_BY_LICENSE_ID } from "./orgLicenses";

export let MOCK_DB = {
  APP_ORGS,
  ALL_USERS_BY_ORG_ID,
  ALL_USERS,
  ALL_INVITATIONS,
  ALL_GROUP_MEMBER_IDS_BY_GROUP_ID,
  ALL_GROUPS_BY_ORG_ID,
  ALL_ROLES_BY_ORG_ID,
  ALL_ROLE_MEMBER_IDS_BY_ROLE_ID,
  ALL_ROLE_PERMISSION_IDS_BY_ROLE_ID,
  ALL_ORG_GROUP_INVITATIONS_BY_ORG_ID: ALL_INVITATIONS_BY_ORG_ID,
  INTERNAL_PERMISSIONS,
  LICENSED_ITEMS,
  ORG_LICENSES,
  ORG_LICENSE_USERS_BY_LICENSE_ID
};

export function setMockDb(mockDb: any): void {
  MOCK_DB = mockDb;
}
