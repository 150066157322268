import * as ActionTypes from "../actions/actionTypes";

export default function selectedOrganizationId(
  state: string,
  action: ActionTypes.AppAction
): string | null {
  switch (action.type) {
    case ActionTypes.LOAD_ORGS:
      if (state) {
        const loadOrgsCompleted = action as ActionTypes.LoadOrgsAction;
        const loadedOrgIds = loadOrgsCompleted.organizations.map(
          org => org.id as string
        );
        if (loadedOrgIds.indexOf(state) === -1) {
          return null;
        } else {
          return state;
        }
      } else {
        return null;
      }
    case ActionTypes.SET_ORG:
      const setOrg = action as ActionTypes.SetOrgAction;
      return setOrg.id;
    // case ActionTypes.START_AUTHN:
    // case ActionTypes.SET_LOGOUT_COMPLETED:
    // Unlike many other state fields, do not clear selected org id when starting new authentication or when logging out
    default:
      return state || null;
  }
}
