import React, { useEffect } from "react";
import { User } from "../../model/User";
import { FormattedMessage } from "react-intl";
import Feedback from "../feedback";

//<editor-fold desc="Props">
interface UserDetailsDOMProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "children"> {}
interface UserDetailsProps extends UserDetailsDOMProps {
  user?: User;
  userId?: string;
  onLoadUser?: () => void;
}
//</editor-fold>

export default function UserDetails(props: UserDetailsProps) {
  //<editor-fold desc="Local variables">
  let { user, userId, onLoadUser, ...other } = props;
  if ((userId && !onLoadUser) || (!userId && onLoadUser)) {
    throw new Error(
      "UserDetailsView: Invalid props, userId and onLoadUser are both required when one is defined"
    );
  }
  //</editor-fold>

  //<editor-fold desc="Hooks">
  useEffect(() => {
    if (!user) {
      if (userId && onLoadUser) {
        onLoadUser();
      }
    } else if (userId && userId !== user.id && onLoadUser) {
      onLoadUser();
    }
  }, [onLoadUser, userId, user]);
  //</editor-fold>
  return (
    <div {...other}>
      {!user && !userId && (
        <Feedback type={"danger"} show={true} asChild={true}>
          <p>
            <FormattedMessage
              id="user-details.not-found.title"
              defaultMessage="Something went wrong and the user could not be loaded. The user may have been removed or you don't have sufficient access rights."
              description="title to be shown when there is no user to display"
            />
          </p>
        </Feedback>
      )}
      {user && (
        <ul className={"list-unstyled"}>
          <li>
            <FormattedMessage
              id="user-details.firstName-label"
              defaultMessage="First name"
            />
            : {user.firstName}
          </li>
          <li>
            <FormattedMessage
              id="user-details.lastName-label"
              defaultMessage="Last name"
            />
            : {user.lastName}
          </li>
          <li>
            <FormattedMessage
              id="user-details.nickname-label"
              defaultMessage="Nickname"
            />
            : {user.nickname}
          </li>
          <li>
            <FormattedMessage
              id="user-details.displayName-label"
              defaultMessage="Display name"
            />
            : {user.displayName}
          </li>
          <li>
            <FormattedMessage
              id="user-details.professionalTitle-label"
              defaultMessage="Professional Title"
            />
            : {user.professionalTitle}
          </li>
          <li>
            <FormattedMessage
              id="user-details.email-label"
              defaultMessage="Email"
            />
            : {user.email}
          </li>
          <li>
            <FormattedMessage
              id="user-details.phoneNumber-label"
              defaultMessage="Phone"
            />
            : {user.phoneNumber}
          </li>
        </ul>
      )}
    </div>
  );
}
