import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserUtils from "../../utils/user";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import LicenseUsageByUsersModalView, {
  LicenseUsageByUsersModalProps as _LicenseUsageByUsersModalProps,
  LicenseUsageByUsersModalStateProps,
  LicenseUsageByUsersModalDataEntry
} from "./license-usage-by-users-modal-view";
import { TableColumn } from "../table/table-view";
import LicenseUtils from "../../utils/licensed-item";

const messages = defineMessages({
  columnLabelUser: {
    id: "license-usage-by-users-modal.column-labels.user",
    defaultMessage: "User"
  },
  fallbackUserName: {
    id: "license-usage-by-users-modal.user-name-fallback",
    defaultMessage: "Anonymous"
  },
  licenseNameFallback: {
    id: "license-usage-by-users-modal.license-name-fallback",
    defaultMessage: "Unknown"
  }
});
// Own props, i.e. props that this component takes as input
export type LicenseUsageByUsersModalProps = Omit<
  _LicenseUsageByUsersModalProps,
  keyof LicenseUsageByUsersModalStateProps
>;

function LicenseUsageByUsersModal(props: LicenseUsageByUsersModalProps) {
  const intl = useIntl();
  const { data } = props;
  const columns = ([
    {
      key: "id",
      isTechnical: true,
      label: "id"
    },
    {
      key: "username",
      label: intl.formatMessage(messages.columnLabelUser),
      sortable: true,
      filterable: true
    }
  ] as Array<TableColumn>).concat(
    data && data.licensedItems
      ? (Object.keys(data.licensedItems).map(val => {
          return {
            key: "col" + val.replace(/-/g, ""),
            label: LicenseUtils.resolveDisplayName(
              data.licensedItems[val],
              intl.formatMessage(messages.licenseNameFallback)
            ),
            sortable: true,
            tipRenderer: (props: {
              cell: any;
              row: any;
              rowIndex: Number;
              rendererData: any;
            }) => {
              let retVal;
              const consumption =
                props.row["col" + val.replace(/-/g, "") + "consumption"];
              const reservation =
                props.row["col" + val.replace(/-/g, "") + "reservation"];
              if (reservation !== "true" && consumption !== "true") {
                retVal = "";
              } else {
                retVal = (
                  <>
                    {reservation === "true" && (
                      <div className={"d-block text-left"}>
                        <span className={"indicator reservation mr-2 mb-1"}>
                          <FontAwesomeIcon icon={"check"} />
                        </span>
                        <FormattedMessage
                          id="license-usage-by-users-modal.reservation-label"
                          defaultMessage="Has reservation"
                        />
                      </div>
                    )}
                    {consumption === "true" && (
                      <div className={"d-block text-left"}>
                        <span className={"indicator consumption mr-2  mb-1"}>
                          <FontAwesomeIcon icon={"user-check"} />
                        </span>
                        <FormattedMessage
                          id="license-usage-by-users-modal.consumption-label"
                          defaultMessage="Is active now"
                        />
                      </div>
                    )}
                  </>
                );
              }
              return retVal;
            },
            renderer: (props: {
              cell: any;
              row: any;
              rowIndex: Number;
              rendererData: any;
            }) => {
              let retVal;
              const consumption =
                props.row["col" + val.replace(/-/g, "") + "consumption"];
              const reservation =
                props.row["col" + val.replace(/-/g, "") + "reservation"];
              if (reservation !== "true" && consumption !== "true") {
                retVal = "";
              } else {
                retVal = (
                  <>
                    {reservation === "true" && (
                      <span className={"indicator reservation mx-1"}>
                        <FontAwesomeIcon icon={"check"} />
                      </span>
                    )}
                    {consumption === "true" && (
                      <span className={"indicator consumption mx-1"}>
                        <FontAwesomeIcon icon={"user-check"} />
                      </span>
                    )}
                  </>
                );
              }
              return retVal;
            }
          };
        }) as Array<TableColumn>)
      : ([] as Array<TableColumn>)
  );
  const tableData =
    data && data.users
      ? data.users.map(val => {
          const retVal: LicenseUsageByUsersModalDataEntry = {
            id: val.id as string,
            username: UserUtils.resolveDisplayName(
              val,
              intl.formatMessage(messages.fallbackUserName)
            )
          };
          Object.keys(data.licensedItems).forEach(v => {
            // the column to render, with both values as they're rendered for tooltips concatenated to enable sort and filter
            retVal["col" + v.replace(/-/g, "")] =
              "" +
              (val.licensedItemReservations[v] ? (
                <FormattedMessage
                  id="license-usage-by-users-modal.reservation-label"
                  defaultMessage="Has reservation"
                />
              ) : (
                ""
              )) +
              (val.licensedItemConsumptions[v] ? (
                <FormattedMessage
                  id="license-usage-by-users-modal.consumption-label"
                  defaultMessage="Is active now"
                />
              ) : (
                ""
              ));
            // the columns to pick values from
            retVal["col" + v.replace(/-/g, "") + "reservation"] =
              "" + val.licensedItemReservations[v];
            retVal["col" + v.replace(/-/g, "") + "consumption"] =
              "" + val.licensedItemConsumptions[v];
          });
          return retVal;
        })
      : undefined;
  return (
    <LicenseUsageByUsersModalView
      {...props}
      tableData={tableData}
      columns={columns}
    />
  );
}

export default LicenseUsageByUsersModal;
