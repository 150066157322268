import React, { ReactNode } from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

//<editor-fold desc="Props">
export interface NavItem {
  id: string;
  label: ReactNode;
  path: string;
  exact?: boolean;
  isActive?: (
    match: any, //{ path: string; isExact: boolean },
    location: { pathname: string }
  ) => boolean;
}
interface PageNavigationDOMProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "children"> {}
export interface PageNavigationProps extends PageNavigationDOMProps {
  /**
   * Header for the navigation component
   */
  header?: ReactNode;
  /**
   * The navigation model
   */
  nav?: NavItem[];
  /**
   * Footer for the navigation component
   */
  footer?: ReactNode;
}
//</editor-fold>

function PageNavigation(props: PageNavigationProps) {
  //<editor-fold desc="Local variables">
  let { className, nav, header, footer, ...other } = props;
  //</editor-fold>
  return (
    <div className={className} {...other}>
      {header}
      <div className="content">
        {nav && (
          <nav className="my-2">
            <Nav as="ul" fill className="flex-column">
              {nav.map(itm => (
                <Nav.Item
                  as="li"
                  key={itm.path}
                  className="text-left"
                  data-test-nav={itm.id}
                >
                  <LinkContainer
                    to={itm.path}
                    exact={itm.exact === true}
                    isActive={itm.isActive}
                  >
                    {/* each Nav.Link must include explicitly set active = false, to remove the activity state when another Nav.Link becomes active. */}
                    <Nav.Link active={false}>{itm.label}</Nav.Link>
                  </LinkContainer>
                </Nav.Item>
              ))}
            </Nav>
          </nav>
        )}
      </div>
      {footer}
    </div>
  );
}

export default PageNavigation;
