import { IdpApi } from "../IdpApi";
import { User } from "../../model/User";
import { Organization } from "../../model/Organization";
import { OrganizationGroup } from "../../model/OrganizationGroup";
import { OrganizationRole } from "../../model/OrganizationRole";
import { OrganizationGroupInvitation } from "../../model/OrganizationGroupInvitation";
import { PermissionGrantsForPermission } from "../../model/PermissionGrantsForPermission";
import { InternalPermissionWithGrantedActions } from "../../model/InternalPermissionWithGrantedActions";
import * as IdpClient from "../../gen/api/idp/idp-client";
import { doApiCall } from "../api-util/ApiExceptionUtil";

const defaultListQueryHeaders: { limit: number } = { limit: -1 };

class RealIdp implements IdpApi {
  initialize(initProvider: (name: string) => string): void {
    IdpClient.defaults.baseUrl = initProvider("idpApiBase");
    IdpClient.defaults.headers = IdpClient.defaults.headers || {};
    IdpClient.defaults.headers["Authorization"] =
      "Bearer " + initProvider("accessToken");
    IdpClient.defaults.cache = "no-cache";
  }

  getAppOrganizations(userId?: string): Promise<Organization[]> {
    return doApiCall(() =>
      IdpClient.listAuthorizedOrganizationsOfUser(
        userId as string,
        defaultListQueryHeaders
      )
    );
  }

  getOrganization(orgId?: string): Promise<Organization> {
    return doApiCall(() => IdpClient.getOrganization(orgId as string));
  }

  replaceOrganization(org: Organization): Promise<Organization> {
    return doApiCall(() =>
      IdpClient.replaceOrganization({ ...org, id: org.id as string })
    );
  }

  setMfaRequired(required: boolean, orgId?: string): Promise<void> {
    // Note: IdentityRest service does not support this yet.
    throw new Error("Method not implemented.");
  }

  listAllOrganizationUsers(orgId?: string): Promise<User[]> {
    return doApiCall(() =>
      IdpClient.listOrganizationUsers(orgId as string, defaultListQueryHeaders)
    );
  }

  listOrganizationGroups(orgId?: string): Promise<OrganizationGroup[]> {
    return doApiCall(() =>
      IdpClient.listOrganizationGroups(orgId as string, defaultListQueryHeaders)
    );
  }

  createOrganizationGroup(
    orgGroup: OrganizationGroup,
    orgId?: string
  ): Promise<OrganizationGroup> {
    return doApiCall(() =>
      IdpClient.createOrganizationGroup(orgId as string, {
        ...orgGroup,
        id: orgGroup.id as string
      })
    );
  }

  replaceOrganizationGroup(
    orgGroup: OrganizationGroup,
    orgId?: string
  ): Promise<OrganizationGroup> {
    return doApiCall(() =>
      IdpClient.replaceOrganizationGroup(orgId as string, {
        ...orgGroup,
        id: orgGroup.id as string
      })
    );
  }

  getOrganizationGroup(
    orgGroupId: string,
    orgId?: string
  ): Promise<OrganizationGroup> {
    return doApiCall(() =>
      IdpClient.getOrganizationGroup(orgId as string, orgGroupId)
    );
  }

  async deleteOrganizationGroup(
    orgGroupId: string,
    orgId?: string
  ): Promise<void> {
    await doApiCall(() =>
      IdpClient.deleteOrganizationGroup(orgId as string, orgGroupId)
    );
  }

  listOrganizationRoles(orgId?: string): Promise<OrganizationRole[]> {
    return doApiCall(() =>
      IdpClient.listOrganizationRoles(orgId as string, defaultListQueryHeaders)
    );
  }

  createOrganizationRole(
    orgRole: OrganizationRole,
    orgId?: string
  ): Promise<OrganizationRole> {
    return doApiCall(() =>
      IdpClient.createOrganizationRole(orgId as string, {
        ...orgRole,
        id: orgRole.id as string
      })
    );
  }

  replaceOrganizationRole(
    orgRole: OrganizationRole,
    orgId?: string
  ): Promise<OrganizationRole> {
    return doApiCall(() =>
      IdpClient.replaceOrganizationRole(orgId as string, {
        ...orgRole,
        id: orgRole.id as string
      })
    );
  }

  getOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<OrganizationRole> {
    return doApiCall(() =>
      IdpClient.getOrganizationRole(orgId as string, orgRoleId as string)
    );
  }

  async deleteOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<void> {
    await doApiCall(() =>
      IdpClient.deleteOrganizationRole(orgId as string, orgRoleId)
    );
  }

  getUser(userId: string): Promise<User> {
    return doApiCall(() => IdpClient.getUser(userId));
  }

  async deleteUser(userId: string): Promise<void> {
    await doApiCall(() => IdpClient.deleteUser(userId));
  }

  replaceUser(user: User): Promise<User> {
    return doApiCall(() =>
      IdpClient.replaceUser({ ...user, id: user.id as string })
    );
  }

  setUserSuspended(suspended: boolean, userId: string): Promise<User> {
    return doApiCall(() => IdpClient.setUserActive(userId, !suspended));
  }

  async deleteOtpCredential(userId: string): Promise<void> {
    await doApiCall(() => IdpClient.deleteOtpCredential(userId));
  }

  listOrganizationGroupsOfUser(userId: string): Promise<OrganizationGroup[]> {
    return doApiCall(() =>
      IdpClient.listOrganizationGroupsOfUser(userId, defaultListQueryHeaders)
    );
  }

  async addOrganizationGroupForUser(
    groupId: string,
    userId: string
  ): Promise<void> {
    await doApiCall(() =>
      IdpClient.addOrganizationGroupForUser(userId, groupId)
    );
  }

  async removeOrganizationGroupOfUser(
    groupId: string,
    userId: string
  ): Promise<void> {
    await doApiCall(() =>
      IdpClient.removeOrganizationGroupOfUser(userId, groupId)
    );
  }

  listUsersInOrganizationGroup(
    groupId: string,
    orgId?: string
  ): Promise<User[]> {
    return doApiCall(() =>
      IdpClient.listUsersInOrganizationGroup(
        orgId as string,
        groupId,
        defaultListQueryHeaders
      )
    );
  }

  async addUsersToOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    await doApiCall(() =>
      IdpClient.addUsersToOrganizationGroup(orgId as string, groupId, userIds)
    );
  }

  async setUsersInOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    await doApiCall(() =>
      IdpClient.setUsersInOrganizationGroup(orgId as string, groupId, userIds)
    );
  }

  async removeUsersFromOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    await doApiCall(() =>
      IdpClient.removeUsersFromOrganizationGroup(
        orgId as string,
        groupId,
        userIds
      )
    );
  }

  listOrganizationsOrganizationGroupInvitations(
    orgId?: string
  ): Promise<OrganizationGroupInvitation[]> {
    return doApiCall(() =>
      IdpClient.listOrganizationsOrganizationGroupInvitations(
        orgId as string,
        defaultListQueryHeaders
      )
    );
  }

  getOrganizationsOrganizationGroupInvitation(
    invitationId: string,
    orgId?: string
  ): Promise<OrganizationGroupInvitation> {
    return doApiCall(() =>
      IdpClient.getOrganizationsOrganizationGroupInvitation(
        orgId as string,
        invitationId
      )
    );
  }

  createOrganizationGroupInvitation(
    invitation: OrganizationGroupInvitation
  ): Promise<OrganizationGroupInvitation> {
    return doApiCall(() =>
      IdpClient.createOrganizationGroupInvitation({
        ...invitation,
        id: invitation.id as string
      })
    );
  }

  sendOrganizationGroupInvitation(
    invitationId: string
  ): Promise<OrganizationGroupInvitation> {
    return doApiCall(() =>
      IdpClient.sendOrganizationGroupInvitation(invitationId)
    );
  }

  revokeOrganizationGroupInvitation(
    invitationId: string
  ): Promise<OrganizationGroupInvitation> {
    return doApiCall(() =>
      IdpClient.revokeOrganizationGroupInvitation(invitationId)
    );
  }

  async deleteOrganizationGroupInvitation(invitationId: string): Promise<void> {
    await doApiCall(() =>
      IdpClient.deleteOrganizationGroupInvitation(invitationId)
    );
  }

  listPermissionsOfOrganizationRole(
    orgRoleId: string
  ): Promise<InternalPermissionWithGrantedActions[]> {
    return doApiCall(() =>
      IdpClient.listPermissionsOfOrganizationRole(
        orgRoleId,
        defaultListQueryHeaders
      )
    );
  }

  async addPermissionsForOrganizationRole(
    grants: PermissionGrantsForPermission[],
    orgRoleId: string
  ): Promise<void> {
    await doApiCall(() =>
      IdpClient.addPermissionsForOrganizationRole(
        orgRoleId,
        grants.map(grant => ({
          ...grant,
          id: grant.id as string,
          permissionId: grant.permissionId as string
        }))
      )
    );
  }

  async setPermissionsOfOrganizationRole(
    grants: PermissionGrantsForPermission[],
    orgRoleId: string
  ): Promise<void> {
    await doApiCall(() =>
      IdpClient.setPermissionsOfOrganizationRole(
        orgRoleId,
        grants.map(grant => ({
          ...grant,
          id: grant.id as string,
          permissionId: grant.permissionId as string
        }))
      )
    );
  }

  async removePermissionsOfOrganizationRole(
    permissionIds: string[],
    orgRoleId: string
  ): Promise<void> {
    await doApiCall(() =>
      IdpClient.removePermissionsOfOrganizationRole(orgRoleId, permissionIds)
    );
  }

  async removePermissionOfOrganizationRole(
    permissionId: string,
    orgRoleId: string
  ): Promise<void> {
    await doApiCall(() =>
      IdpClient.removePermissionOfOrganizationRole(orgRoleId, permissionId)
    );
  }

  listUsersInOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<User[]> {
    return doApiCall(() =>
      IdpClient.listUsersInOrganizationRole(
        orgId as string,
        orgRoleId,
        defaultListQueryHeaders
      )
    );
  }

  async addUsersToOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    await doApiCall(() =>
      IdpClient.addUsersToOrganizationRole(orgId as string, orgRoleId, userIds)
    );
  }

  getUserInOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<User> {
    return doApiCall(() =>
      IdpClient.getUserInOrganizationRole(orgId as string, orgRoleId, userId)
    );
  }

  async addUserToOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<void> {
    await doApiCall(() =>
      IdpClient.addUserToOrganizationRole(orgId as string, orgRoleId, userId)
    );
  }

  async removeUsersFromOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    await doApiCall(() =>
      IdpClient.removeUsersFromOrganizationRole(
        orgId as string,
        orgRoleId,
        userIds
      )
    );
  }

  async removeUserFromOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<void> {
    await doApiCall(() =>
      IdpClient.removeUserFromOrganizationRole(
        orgId as string,
        orgRoleId,
        userId
      )
    );
  }

  async setUsersInOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    await doApiCall(() =>
      IdpClient.setUsersInOrganizationRole(orgId as string, orgRoleId, userIds)
    );
  }
}

export default RealIdp;
