import React, { useEffect, useRef } from "react";
import FeebackView, { FeedbackProps as _FeedbackProps } from "./feedback-view";

export type FeedbackProps = _FeedbackProps;

const AUTO_CLOSE_TIMEOUT = 5000;

export default function Feedback(props: FeedbackProps) {
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();
  const onClose = props.onClose;
  const autoclose = props.autoClose;
  useEffect(() => {
    if (autoclose && onClose) {
      timeoutRef.current = setTimeout(onClose, AUTO_CLOSE_TIMEOUT);
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }
  }, [onClose, autoclose]);
  return <FeebackView {...props} />;
}
