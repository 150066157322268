import * as ActionTypes from "./actionTypes";
import { idpApi } from "../api";
import { ActionSender } from "../model/ActionSender";
import {
  buildActionThunk,
  ensureSelectedOrgId,
  forceUndefined
} from "./actionHelpers";
import { OrganizationRole } from "../model/OrganizationRole";

/**
 * Lists organization roles of an organization.
 * @param sender Component requesting for the action
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function listOrganizationRoles(
  sender: ActionSender,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListOrgRolesAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.ListOrgRolesAction, OrganizationRole[]>(
    sender,
    ActionTypes.LIST_ORG_ROLES,
    async () => await idpApi.listOrganizationRoles(orgIdOrDefault),
    (type, roles) => ({
      type,
      roles: forceUndefined(roles),
      orgId: orgIdOrDefault
    })
  );
}

/**
 * Creates a new organization role.
 * @param sender Component requesting for the action
 * @param orgRole OrganizationRole object describing the organization role to create.
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function createOrganizationRole(
  sender: ActionSender,
  orgRole: OrganizationRole,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.CreateOrgRoleAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.CreateOrgRoleAction, OrganizationRole>(
    sender,
    ActionTypes.CREATE_ORG_ROLE,
    async () => await idpApi.createOrganizationRole(orgRole, orgIdOrDefault),
    (type, role) => ({
      type,
      role: forceUndefined(role),
      orgId: orgIdOrDefault
    })
  );
}

/**
 * Updates organization role data by replacing existing data with the given data.
 * @param sender Component requesting for the action
 * @param orgRole OrganizationRole object describing the new organization role data.
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function replaceOrganizationRole(
  sender: ActionSender,
  orgRole: OrganizationRole,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ReplaceOrgRoleAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.ReplaceOrgRoleAction, OrganizationRole>(
    sender,
    ActionTypes.REPLACE_ORG_ROLE,
    async () => await idpApi.replaceOrganizationRole(orgRole, orgIdOrDefault),
    (type, role) => ({
      type,
      role: forceUndefined(role),
      orgId: orgIdOrDefault
    })
  );
}

/**
 * Gets an organization role of an organization by id.
 * @param sender Component requesting for the action
 * @param orgRoleId The organization role id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function getOrganizationRole(
  sender: ActionSender,
  orgRoleId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.GetOrgRoleAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.GetOrgRoleAction, OrganizationRole>(
    sender,
    ActionTypes.GET_ORG_ROLE,
    async () => await idpApi.getOrganizationRole(orgRoleId, orgIdOrDefault),
    (type, role) => ({
      type,
      role: forceUndefined(role),
      orgId: orgIdOrDefault
    })
  );
}

/**
 * Deletes an organization role of an organization by id.
 * @param sender Component requesting for the action
 * @param orgRoleId The organization role id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function deleteOrganizationRole(
  sender: ActionSender,
  orgRoleId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.DeleteOrgRoleAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.DeleteOrgRoleAction, void>(
    sender,
    ActionTypes.DELETE_ORG_ROLE,
    async () => await idpApi.deleteOrganizationRole(orgRoleId, orgIdOrDefault),
    type => ({
      type,
      orgRoleId,
      orgId: orgIdOrDefault
    })
  );
}
