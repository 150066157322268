import * as ActionTypes from "../actions/actionTypes";
import { UserAvailableLicensesByUserId } from "../store/LicenseState";

export default function userAvailableLicenses(
  state: UserAvailableLicensesByUserId,
  action: ActionTypes.AppAction
): UserAvailableLicensesByUserId | null {
  const currentState = state || ({} as UserAvailableLicensesByUserId);
  switch (action.type) {
    case ActionTypes.QUERY_AVAILABLE_LICENSES:
      const queryAvailableLicenses = action as ActionTypes.QueryAvailableLicensesAction;
      const userId = queryAvailableLicenses.userId;
      const licenses = queryAvailableLicenses.licenses;
      const newAvailableLicenses = licenses.map(lic => ({
        licenseId: lic.id as string,
        assignmentIds: lic.assignments
          ? lic.assignments.map(ass => ass.id as string)
          : []
      }));
      return { ...currentState, [userId]: newAvailableLicenses };
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}
