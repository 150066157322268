import { EntApi, ID_ANY } from "../EntApi";
import { ProductItem } from "../../model/entitlement/ProductItem";
import { LicenseOrder } from "../../model/entitlement/LicenseOrder";
import { OrganizationGroup } from "../../model/OrganizationGroup";
import { LicenseFieldOpts } from "../../model/entitlement/LicenseFieldOpts";
import { LicenseWithCredits } from "../../model/entitlement/LicenseWithCredits";
import { LicenseUsage } from "../../model/entitlement/LicenseUsage";
import { LicenseAssignment } from "../../model/entitlement/LicenseAssignment";
import { LicenseSession } from "../../model/entitlement/LicenseSession";
import { QueryAvailableLicensesOpts } from "../../model/entitlement/QueryAvailableLicensesOpts";
import { LicenseWithOwnerAndSingleUserAssignments } from "../../model/entitlement/LicenseWithOwnerAndSingleUserAssignments";
import MockBase from "./MockBase";
import { LicenseUserWithAssignmentsAndSessions } from "../../model/entitlement/LicenseUserWithAssignmentsAndSessions";
import { LicenseAssignmentWithSessions } from "../../model/entitlement/LicenseAssignmentWithSessions";
import { MOCK_DB } from "./mockData";
import { generateRandomUuid } from "../../util/uuidUtil";
import { isValid } from "../../util/objectUtil";
import { aggregateValidSeatCountCreditData } from "../../util/licenseUtil";

class MockEnt extends MockBase implements EntApi {
  public getProductItems(productId: string): Promise<ProductItem[]> {
    throw new Error("Not implemented by the mock API");
  }

  public fulfillLicenseOrder(
    orgId: string,
    licenseOrder: LicenseOrder
  ): Promise<LicenseOrder> {
    throw new Error("Not implemented by the mock API");
  }

  public changeLicenseOrder(
    orgId: string,
    licenseOrder: LicenseOrder
  ): Promise<LicenseOrder> {
    throw new Error("Not implemented by the mock API");
  }

  public cancelLicenseOrder(
    orgId: string,
    orderId: string
  ): Promise<LicenseOrder> {
    throw new Error("Not implemented by the mock API");
  }

  public listLicenseConsumingOrgGroups(
    orgId: string,
    entId: string
  ): Promise<OrganizationGroup[]> {
    throw new Error("Not implemented by the mock API");
  }

  public setLicenseConsumingOrgGroups(
    orgId: string,
    entId: string,
    groupIds: string[]
  ): Promise<void> {
    throw new Error("Not implemented by the mock API");
  }

  public addLicenseConsumingOrgGroup(
    orgId: string,
    entId: string,
    groupId: string
  ): Promise<void> {
    throw new Error("Not implemented by the mock API");
  }

  public removeLicenseConsumingOrgGroup(
    orgId: string,
    entId: string,
    groupId: string
  ): Promise<void> {
    throw new Error("Not implemented by the mock API");
  }

  public async listEntitlementLicenses(
    orgId: string,
    entId: string,
    licenseFieldOpts?: LicenseFieldOpts
  ): Promise<LicenseWithCredits[]> {
    // licenseFieldOpts currently not supported by the mock implementation
    return this.getLicensesInternal(orgId, entId);
  }

  public async queryLicenseUsage(
    orgId: string,
    entId: string,
    licenseId: string
  ): Promise<LicenseUsage> {
    return this.getLicenseUsageInternal(orgId, licenseId);
  }

  public async getLicenseAssignments(
    orgId: string,
    entId: string,
    licenseId: string,
    userId: string
  ): Promise<LicenseAssignment[]> {
    const orgLicenses = this.getLicensesInternal(orgId, entId);
    return this.getLicenseAssignmentsInternal(
      orgLicenses,
      this.getOrgLicenseUsersByLicenseIdMap(orgId),
      licenseId,
      userId
    );
  }

  public async manageLicenseAssignments(
    orgId: string,
    entId: string,
    licenseId: string,
    userId: string,
    licenseAssignments: LicenseAssignment[]
  ): Promise<LicenseAssignment[]> {
    const orgLicenses = this.getLicensesInternal(orgId, entId);
    return this.manageAssignmentsInternal(
      orgLicenses,
      this.getOrgLicenseUsersByLicenseIdMap(orgId),
      licenseId,
      userId,
      licenseAssignments
    );
  }

  public async queryAvailableLicenses(
    userId: string,
    queryAvailableLicensesOpts?: QueryAvailableLicensesOpts
  ): Promise<LicenseWithOwnerAndSingleUserAssignments[]> {
    // queryAvailableLicensesOpts currently not supported by the mock implementation
    const userOrgIds = this.getOrgIdsByUserId(userId);
    const licensesByOrgId: { [orgId: string]: LicenseWithCredits[] } = {};
    for (const userOrgId of userOrgIds) {
      licensesByOrgId[userOrgId] = this.getLicensesInternal(userOrgId, "any");
    }

    const retValue: LicenseWithOwnerAndSingleUserAssignments[] = [];
    for (const orgId in licensesByOrgId) {
      const orgLicenses = licensesByOrgId[orgId];
      for (const orgLicense of orgLicenses) {
        const assignments = this.getLicenseAssignmentsInternal(
          [orgLicense],
          this.getOrgLicenseUsersByLicenseIdMap(orgId),
          orgLicense.id as string,
          userId
        );
        const availableLicense: LicenseWithOwnerAndSingleUserAssignments = {
          ...orgLicense,
          assignments,
          owner: { ...this.getOrgById(orgId), objectType: "Organization" }
        };
        retValue.push(availableLicense);
      }
    }

    return retValue;
  }

  public moveLicense(
    orgId: string,
    entId: string,
    licenseId: string,
    destEntId: string,
    licenseFieldOpts?: LicenseFieldOpts
  ): Promise<LicenseWithCredits> {
    throw new Error("Not implemented by the mock API");
  }

  private getLicensesInternal(
    orgId: string,
    entId: string
  ): LicenseWithCredits[] {
    let licenses: LicenseWithCredits[] | undefined =
      MOCK_DB.ORG_LICENSES[orgId];

    this.undefinedTo404(licenses);

    if (
      entId !== ID_ANY &&
      licenses?.findIndex(lic => entId === lic.entitlementId) === -1
    ) {
      // No entitlement with the given id found
      throw this.build404();
    }

    const foundLicenses = licenses as LicenseWithCredits[];
    return entId === ID_ANY
      ? foundLicenses
      : foundLicenses.filter(lic => entId === lic.entitlementId);
  }

  private getLicenseUsageInternal(
    orgId: string,
    licenseId: string
  ): LicenseUsage {
    let retValue: LicenseUsage | undefined = this.getOrgLicenseUsageByLicenseId(
      MOCK_DB.ORG_LICENSES[orgId],
      this.getOrgLicenseUsersByLicenseIdMap(orgId),
      licenseId
    );

    this.undefinedTo404(retValue);

    return retValue as LicenseUsage;
  }

  private getOrgLicenseUsageByLicenseId(
    orgLicenses: LicenseWithCredits[],
    licenseUsersByLicenseId: {
      [licenseId: string]: LicenseUserWithAssignmentsAndSessions[];
    },
    licenseId: string
  ): LicenseUsage | undefined {
    const license = orgLicenses.find(lic => lic.id === licenseId);
    if (license === undefined) {
      return;
    }

    const licenseUsers = licenseUsersByLicenseId[license.id as string];

    return { ...license, users: licenseUsers };
  }

  private getLicenseAssignmentsInternal(
    licenses: LicenseWithCredits[],
    licenseUsersByLicenseId: {
      [licenseId: string]: LicenseUserWithAssignmentsAndSessions[];
    },
    licenseId: string,
    userId: string
  ): LicenseAssignment[] {
    const licenseUsage = this.getOrgLicenseUsageByLicenseId(
      licenses,
      licenseUsersByLicenseId,
      licenseId
    );
    this.undefinedTo404(licenseUsage);
    if (licenseUsage && licenseUsage.users && licenseUsage.users.length) {
      return licenseUsage.users
        .filter(
          licenseUser => licenseUser?.id === userId && licenseUser.assignments
        )
        .flatMap(
          licenseUser =>
            licenseUser.assignments as LicenseAssignmentWithSessions
        )
        .map(assignmentWithSessions => {
          const { sessions: _, ...other } = assignmentWithSessions;
          return other;
        });
    }
    return [];
  }

  private manageAssignmentsInternal(
    licenses: LicenseWithCredits[],
    licenseUsersByLicenseId: {
      [licenseId: string]: LicenseUserWithAssignmentsAndSessions[];
    },
    licenseId: string,
    userId: string,
    licenseAssignments: LicenseAssignment[]
  ): LicenseAssignment[] {
    const licenseUsage = this.getOrgLicenseUsageByLicenseId(
      licenses,
      licenseUsersByLicenseId,
      licenseId
    );
    this.undefinedTo404(licenseUsage);
    const { users, index } = this.getUserInternal(userId);
    const user = users[index];

    if (user && user.lastName === "McPhailure") {
      throw this.buildTestError();
    }
    const licenseAssignmentsToSet = [...licenseAssignments].map(ass => {
      const obj = { ...ass };
      obj.id = ass.id ? ass.id : generateRandomUuid();
      obj.validFrom =
        obj.validFrom === "now()" ? new Date().toISOString() : obj.validFrom;
      return obj;
    });
    const foundLicenseUsage = licenseUsage as LicenseUsage;
    const licenseUsers = foundLicenseUsage.users || [];
    let licenseUserIndex = licenseUsers.findIndex(
      licenseUser => licenseUser.id === userId
    );
    if (licenseUserIndex !== -1) {
      let licenseUser = licenseUsers[licenseUserIndex];
      if (licenseUser.assignments) {
        for (const assignment of licenseUser.assignments) {
          const index = licenseAssignmentsToSet.findIndex(
            ass => ass.id === assignment.id
          );
          if (index !== -1) {
            licenseUser.assignments[index] = {
              ...licenseAssignmentsToSet[index],
              sessions: licenseUser.assignments[index].sessions
            };
            licenseAssignmentsToSet.splice(index);
          }
        }
        licenseUser.assignments.push(...licenseAssignmentsToSet);
      } else {
        licenseUser.assignments = licenseAssignmentsToSet;
      }
    } else {
      const newLicenseUser = { ...user, assignments: licenseAssignmentsToSet };
      licenseUsers.push(newLicenseUser);
    }

    licenseUsersByLicenseId[licenseId] = licenseUsers;

    const license = licenses.find(
      license => license.id === licenseId
    ) as LicenseWithCredits;
    this.updateSeatUsageByAssignments(license, licenseUsers);

    return this.getLicenseAssignmentsInternal(
      licenses,
      licenseUsersByLicenseId,
      licenseId,
      userId
    );
  }

  private updateSeatUsageByAssignments(
    license: LicenseWithCredits,
    licenseUsers: LicenseUserWithAssignmentsAndSessions[]
  ): void {
    if (!license.seatCountCredits) {
      return;
    }

    const allAssignments = licenseUsers
      .filter(licUser => licUser.assignments)
      .map(licUser => licUser.assignments as LicenseAssignmentWithSessions[])
      .flatMap(ass => ass);

    const validReservations = allAssignments.filter(ass =>
      isValidReservation(ass)
    );

    const validSessions = allAssignments
      .filter(ass => ass.sessions)
      .flatMap(ass => ass.sessions)
      .filter(session => session)
      .filter(session => isValid(session as LicenseSession));

    let seatsTaken: number = 0;
    allAssignments.forEach(ass => {
      if (isValidReservation(ass)) {
        seatsTaken++;
      } else if (ass.sessions) {
        for (const session of ass.sessions) {
          if (isValid(session)) {
            seatsTaken++;
            break;
          }
        }
      }
    });

    // Aggregate seat count credits which is a ahortcut for the mock implementation,
    // doesn't work correctly if there are multiple credits
    const aggregatedSeatCountCredit = aggregateValidSeatCountCreditData(
      license.seatCountCredits
    );
    aggregatedSeatCountCredit.seatsConsumed = validSessions.length;
    license.seatCountCredits = [aggregatedSeatCountCredit];

    license.seatsTaken = seatsTaken;
    license.seatsReserved = validReservations.length;
  }

  private getOrgLicenseUsersByLicenseIdMap(
    orgId: string
  ): { [licenseId: string]: LicenseUserWithAssignmentsAndSessions[] } {
    const retValue = MOCK_DB.ORG_LICENSE_USERS_BY_LICENSE_ID[orgId];
    if (retValue === undefined) {
      throw new Error(`Unknown orgId ${orgId}`);
    }
    return retValue;
  }
}

function isValidReservation(assignment: LicenseAssignment): boolean {
  if (!assignment) {
    return false;
  }

  return isValid(assignment) && assignment.type === "reserved";
}

export default MockEnt;
