import * as ActionTypes from "../actions/actionTypes";
import {
  LicenseUsageDataByLicenseId,
  LicenseUsageData,
  LicenseUserData
} from "../store/LicenseState";
import { LicenseUsage } from "../model/entitlement/LicenseUsage";
import { LicenseAssignment } from "../model/entitlement/LicenseAssignment";

export default function licenseUsage(
  state: LicenseUsageDataByLicenseId,
  action: ActionTypes.AppAction
): LicenseUsageDataByLicenseId | null {
  const currentState = state || ({} as LicenseUsageDataByLicenseId);
  switch (action.type) {
    case ActionTypes.QUERY_LICENSE_USAGE:
      const queryLicenseUsage = action as ActionTypes.QueryLicenseUsageAction;
      const licenseUsage = queryLicenseUsage.licenseUsage;
      return updateLicenseUsageToState(currentState, licenseUsage);
    case ActionTypes.LIST_ENT_LICENSES_WITH_USAGE:
      const listEntLicensesWithUsage = action as ActionTypes.ListEntLicensesWithUsageAction;
      if (
        listEntLicensesWithUsage.licenses &&
        listEntLicensesWithUsage.licenses.length
      ) {
        let stateAfterListEntLicensesWithUsage = { ...currentState };
        for (const lic of listEntLicensesWithUsage.licenses) {
          // Licenses returned by this action are always of type LicenseUsage
          const licUsage = lic as LicenseUsage;
          stateAfterListEntLicensesWithUsage = updateLicenseUsageToState(
            stateAfterListEntLicensesWithUsage,
            licUsage
          );
        }
        return stateAfterListEntLicensesWithUsage;
      }
      return currentState;
    case ActionTypes.GET_USER_LICENSE_ASSIGNMENTS:
    case ActionTypes.MANAGE_USER_LICENSE_ASSIGNMENTS:
      const licenseAssignmentsAction = action as ActionTypes.LicenseAssignmentManagementAction;
      return updateManageAssignmentsToState(
        currentState,
        licenseAssignmentsAction.licenseId,
        licenseAssignmentsAction.userId,
        licenseAssignmentsAction.licenseAssignments,
        licenseAssignmentsAction.licenseUsage
      );
    case ActionTypes.MANAGE_USERS_LICENSE_ASSIGNMENTS:
      const manageUsersAssignments = action as ActionTypes.ManageUsersLicenseAssignmentsAction;
      return Object.keys(manageUsersAssignments.userAssignments).reduce<
        LicenseUsageDataByLicenseId
      >(
        (updatedState, userId) =>
          updateManageAssignmentsToState(
            updatedState,
            manageUsersAssignments.licenseId,
            userId,
            manageUsersAssignments.userAssignments[userId],
            manageUsersAssignments.licenseUsage
          ),
        currentState
      );
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}

function updateLicenseUsageToState(
  state: LicenseUsageDataByLicenseId,
  licenseUsage: LicenseUsage
): LicenseUsageDataByLicenseId {
  const stateAfterQuery = { ...state };
  const queriedLicenseId = licenseUsage.id as string;
  const usageData: LicenseUsageData = {
    licenseId: queriedLicenseId,
    users: licenseUsage.users
      ? licenseUsage.users.map(licUser => ({
          userId: licUser.id as string,
          assignmentIds: licUser.assignments
            ? licUser.assignments.map(ass => ass.id as string)
            : []
        }))
      : []
  };
  stateAfterQuery[queriedLicenseId] = usageData;
  return stateAfterQuery;
}

function updateManageAssignmentsToState(
  state: LicenseUsageDataByLicenseId,
  licenseId: string,
  userId: string,
  licenseAssignments: LicenseAssignment[],
  licenseUsage?: LicenseUsage
): LicenseUsageDataByLicenseId {
  let stateAfterManageAssignments = { ...state };
  const assignmentIds = licenseAssignments.map(ass => ass.id as string);
  const newLicenseUserData: LicenseUserData = { userId, assignmentIds };
  const existingUsageData = stateAfterManageAssignments[licenseId];
  if (existingUsageData) {
    const usersAfterGetAssignments = [...existingUsageData.users];
    const index = usersAfterGetAssignments.findIndex(
      licUser => licUser.userId === userId
    );
    if (index === -1) {
      usersAfterGetAssignments.push(newLicenseUserData);
    } else {
      const userAfterGetAssignments = {
        ...usersAfterGetAssignments[index]
      };
      const assignmentIdsAfterGetAssignments = Array.from(
        new Set<string>([
          ...userAfterGetAssignments.assignmentIds,
          ...newLicenseUserData.assignmentIds
        ]).values()
      );
      userAfterGetAssignments.assignmentIds = assignmentIdsAfterGetAssignments;
      usersAfterGetAssignments[index] = userAfterGetAssignments;
    }
    const newUsageData = { ...existingUsageData };
    newUsageData.users = usersAfterGetAssignments;
    stateAfterManageAssignments[licenseId] = newUsageData;
  } else {
    stateAfterManageAssignments[licenseId] = {
      licenseId,
      users: [newLicenseUserData]
    };
  }

  if (licenseUsage) {
    stateAfterManageAssignments = updateLicenseUsageToState(
      stateAfterManageAssignments,
      licenseUsage
    );
  }

  return stateAfterManageAssignments;
}
