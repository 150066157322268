import React, { ReactNode } from "react";
import BSModal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import TooltipTrigger from "../tooltip-trigger";

//<editor-fold desc="Props">
/**
 * To ensure consistency we define button properties as interface and create the actual button instances in render.
 */
export interface ModalActionButton {
  /**
   * Button label
   */
  label: ReactNode;
  /**
   * Optional tooltip
   */
  tooltip?: string;

  disabled?: boolean;
}

export interface ModalDOMProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {}
export interface ModalProps extends ModalDOMProps {
  /**
   * The title of the modal dialog
   */
  title: ReactNode;
  size?: "sm" | "lg" | "xl";
  /**
   * event for primary button click
   */
  onPrimaryAction?: () => void;
  /**
   * event for secondary button click.
   */
  onSecondaryAction?: () => void;
  /**
   * Toggles the close functionality from header and modal backdrop
   */
  closable?: boolean;
  /**
   * Properties for creating the primary button
   */
  primaryButton?: ModalActionButton;
  /**
   * Properties for creating the secondary button
   */
  secondaryButton?: ModalActionButton;
  /**
   * Visibility state of the modal dialog
   */
  show?: boolean;
  /**
   * Event handler for the close action. Must be defined if closable === true, as the dialog is stateless and it's the responsibility of the wrapping component to toggle the visibility state
   */
  onClose?: () => void;
}
//</editor-fold>

function Modal(props: ModalProps) {
  //<editor-fold desc="Local variables">
  let {
    children,
    title,
    show,
    primaryButton,
    onPrimaryAction,
    secondaryButton,
    onSecondaryAction,
    closable,
    onClose,
    size,
    ...other
  } = props;
  if (primaryButton && !onPrimaryAction) {
    throw new Error(
      "Modal: Invalid params. onPrimaryAction is required with primaryButton"
    );
  }
  if (secondaryButton && !onSecondaryAction) {
    throw new Error(
      "Modal: Invalid params. onSecondaryAction is required with secondaryButton"
    );
  }
  //</editor-fold>

  return (
    <BSModal show={show} onHide={onClose} size={size} {...other}>
      <BSModal.Header closeButton={closable}>
        <BSModal.Title>{title}</BSModal.Title>
      </BSModal.Header>
      <BSModal.Body>{children}</BSModal.Body>
      {(primaryButton || secondaryButton) && (
        <BSModal.Footer className={"clearfix"}>
          {primaryButton && (
            <TooltipTrigger
              tipKey={"primaryButtonTip"}
              tip={primaryButton.tooltip}
              wrapDisabled={primaryButton.disabled}
              className={primaryButton.disabled ? "order-2" : undefined}
            >
              <Button
                data-test-modal-primary-button
                variant={"primary"}
                onClick={onPrimaryAction}
                disabled={primaryButton.disabled}
                className={!primaryButton.disabled ? "order-2" : undefined}
              >
                {primaryButton.label}
              </Button>
            </TooltipTrigger>
          )}
          {secondaryButton && (
            <TooltipTrigger
              tipKey={"secondaryButtonTip"}
              tip={secondaryButton.tooltip}
              wrapDisabled={secondaryButton.disabled}
            >
              <Button
                data-test-modal-secondary-button
                variant={"secondary"}
                onClick={onSecondaryAction}
                disabled={secondaryButton.disabled}
                className={"order-1"}
              >
                {secondaryButton.label}
              </Button>
            </TooltipTrigger>
          )}
        </BSModal.Footer>
      )}
    </BSModal>
  );
}

export default Modal;
