import React from "react";
/**
 * A wrapper to counter the poor design choices in bootstrap lib
 */
export default React.forwardRef(
  (
    {
      placement,
      scheduleUpdate,
      arrowProps,
      outOfBoundaries,
      show,
      ...props
    }: any,
    ref
  ) => {
    return (
      <div style={props.style} ref={ref} {...props}>
        {props.children}
      </div>
    );
  }
);
