import React from "react";
import Page, { PageDOMProps } from "../page";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { ListGroup } from "react-bootstrap";
import { Organization } from "../../../model/Organization";

//<editor-fold desc="Props">

export interface SelectOrganizationDOMProps extends PageDOMProps {}

export interface SelectOrganizationProps extends SelectOrganizationDOMProps {
  selectedOrganization?: Organization | null;
  organizations: Organization[];
  onSelectOrganization?: (orgId: string) => void;
}
//</editor-fold>

//<editor-fold desc="Messages">
const messages = defineMessages({
  title: {
    id: "pages.select-organization.title",
    defaultMessage: "Select Organization",
    description: "Title for the Select Organization screen"
  },
  description: {
    id: "pages.select-organization.meta-description",
    defaultMessage: "Select Organization screen",
    description: "Description for the Select Organization screen"
  }
});
//</editor-fold>

function SelectOrganization(props: SelectOrganizationProps) {
  //<editor-fold desc="Local variables">
  const {
    selectedOrganization,
    organizations,
    onSelectOrganization,
    ...other
  } = props;
  const intl = useIntl();
  const title = intl.formatMessage(messages.title);
  const description = intl.formatMessage(messages.description);
  const selectHandler = (e: any) => {
    if (onSelectOrganization) {
      onSelectOrganization(e.currentTarget.dataset.key);
    }
  };
  //</editor-fold>

  //<editor-fold desc="Partials">
  const orgList = organizations
    ? organizations.map(org => {
        return (
          <ListGroup.Item
            key={org.id}
            data-test-select-organization={org.id}
            action
            onClick={selectHandler}
            data-key={org.id}
            active={
              selectedOrganization ? selectedOrganization.id === org.id : false
            }
          >
            {org.name}
          </ListGroup.Item>
        );
      })
    : null;
  //</editor-fold>

  return (
    <Page
      data-test-select-organization-page
      header={<h1>{title}</h1>}
      meta={{
        title,
        description
      }}
      {...other}
    >
      {orgList && <ListGroup>{orgList}</ListGroup>}
      {!orgList && (
        <p>
          <FormattedMessage
            id="pages.select-organization.no-organizations-found-message"
            defaultMessage="You have no organizations to mange"
            description="Label to be shown when the user has no organizations to manage."
          />
        </p>
      )}
    </Page>
  );
}
export default SelectOrganization;
