import { connect } from "react-redux";
import { AppState } from "../../../store/AppState";
import OrganizationUtils from "../../../utils/organization";
import { Organization } from "../../../model/Organization";
import {
  listOrganizationRoles,
  listOrganizationUsers,
  listUsersInOrganizationRole
} from "../../../actions";
import OrganizationPage, {
  OrganizationDOMProps,
  OrganizationProps as _OrganizationProps,
  OrganizationModalProps
} from "./organization-view";
import * as ActionTypes from "../../../actions/actionTypes";
import { replaceOrg, waitForDispatch } from "../../../actions";
import { User } from "../../../model/User";
import {
  aggregateValidSeatCountCreditDataForLicense,
  getOrganizationLicenses
} from "../../../util/licenseUtil";
import { listEntitlementLicensesWithUsage } from "../../../actions/entActions";
import {
  PickReduxDispatchProps,
  PickReduxStateProps
} from "../../../util/typeUtil";
import store from "../../../store";

export { ModalKeys } from "./organization-view";

interface OrganizationProps
  extends OrganizationDOMProps,
    OrganizationModalProps {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _OrganizationProps,
  OrganizationProps
>;
// Dispatch props provided to the wrapped component by this connect component, using mapDispatchToProps
type ReduxDispatchProps = PickReduxDispatchProps<
  _OrganizationProps,
  OrganizationProps
>;

function mapStateToProps(state: AppState): ReduxStateProps {
  const retVal: any = {};
  const org:
    | Organization
    | undefined = OrganizationUtils.resolveSelectedFromState(state);
  let orgLicenses = undefined;
  if (!org) {
    throw new Error("No organization");
  }
  orgLicenses = getOrganizationLicenses(org.id as string, "any", state);
  if (orgLicenses) {
    orgLicenses = orgLicenses.map(aggregateValidSeatCountCreditDataForLicense);
  }
  retVal.organization = org;
  retVal.adminCount = OrganizationUtils.resolveOrgAdminCountFromState(state);
  retVal.members = OrganizationUtils.resolveOrganizationUsersFromState(state);
  const cur = new Date().getTime();
  retVal.licenses = orgLicenses;
  retVal.inactiveCount = retVal.members
    ? retVal.members.filter((u: User) => {
        return u.lastSignOn
          ? cur - new Date(u.lastSignOn).getTime() > 30 * 24 * 60 * 60 * 1000
          : true;
      }).length
    : 0;
  return retVal as ReduxStateProps;
}

const sender = { key: "organizationPage" };

function mapDispatchToProps(dispatch: (t: any) => void): ReduxDispatchProps {
  return {
    onUpdateOrganization: onUpdateOrganizationImpl,
    onLoadMembers: onLoadMembersImpl,
    onLoadLicenses: onLoadLicensesImpl,
    onLoadAdminCount: onLoadAdminCountImpl
  };
}

async function onUpdateOrganizationImpl(org: Organization) {
  const action = await waitForDispatch<ActionTypes.ReplaceOrgAction>(
    replaceOrg(sender, org),
    store.dispatch
  );
  return action.organization;
}

function onLoadMembersImpl() {
  store.dispatch(listOrganizationUsers(sender));
}

function onLoadLicensesImpl() {
  store.dispatch(listEntitlementLicensesWithUsage(sender, "any"));
}

async function onLoadAdminCountImpl() {
  const action = await waitForDispatch<ActionTypes.ListOrgRolesAction>(
    listOrganizationRoles(sender),
    store.dispatch
  );
  action.roles
    .filter(role => role.designator === "orgadmin")
    .forEach(role => {
      store.dispatch(listUsersInOrganizationRole(sender, role.id as string));
    });
}

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  OrganizationProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationPage);
