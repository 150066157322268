import React from "react";
import {
  ButtonGroup,
  Dropdown,
  Nav,
  Navbar,
  NavDropdown
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfileImage from "../profile-image";
import { FormattedMessage } from "react-intl";
import "./app-header.scss";
import { Organization } from "../../model/Organization";
import { Link } from "react-router-dom";
import logo from "./logo.png";
import { getClientId, getUserUIBase } from "../../api/ApiImplementation";
import { getEnvParam } from "../../util/env";

//<editor-fold desc="Props">
export interface AppHeaderUser {
  id: string;
  name?: string;
  email?: string;
}
export interface AppHeaderDOMProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "children"> {}

export interface AppHeaderProps extends AppHeaderDOMProps {
  user?: AppHeaderUser;
  selectedOrganization?: Organization;
  organizations?: Organization[];
  onSelectOrganization?: (orgId: string) => void;
}
//</editor-fold>

function AppHeader(props: AppHeaderProps) {
  //<editor-fold desc="Local variables">
  const {
    user,
    selectedOrganization,
    onSelectOrganization,
    organizations,
    ...other
  } = props;
  const idp_base = getUserUIBase();
  const organizationDropdownItemClickHandler = (e: any) => {
    if (onSelectOrganization) {
      onSelectOrganization(e.currentTarget.dataset.key);
    }
  };
  //</editor-fold>

  //<editor-fold desc="Partials">
  const orgDropdown =
    organizations && organizations.length > 1 ? (
      <Dropdown as={ButtonGroup} className={"ml-2"} alignRight>
        <Link
          data-test-select-organization-link
          to={"/organization/select/"}
          className={
            selectedOrganization
              ? "btn btn-sm btn-outline-light"
              : "btn btn-sm btn-light text-danger"
          }
        >
          {selectedOrganization ? (
            selectedOrganization.name
          ) : (
            <FormattedMessage
              id="app-header.select-org-no-org-label"
              defaultMessage="Select organization"
            />
          )}
        </Link>
        <Dropdown.Toggle
          split
          variant="outline-light"
          size="sm"
          id="dropdown-basic"
          data-test-select-organization-tool
        />
        <Dropdown.Menu className={"mt-2"}>
          <Dropdown.Header className={"d-flex"}>
            <FormattedMessage
              id="app-header.select-org-title"
              defaultMessage="Select active organization"
            />
          </Dropdown.Header>
          {organizations &&
            organizations.length &&
            organizations.map(itm => {
              return (
                <Dropdown.Item
                  key={itm.id}
                  data-test-select-organization={itm.id}
                  onClick={organizationDropdownItemClickHandler}
                  data-key={itm.id}
                  active={
                    selectedOrganization && selectedOrganization.id === itm.id
                  }
                >
                  <span>{itm.name}</span>
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
    ) : null;

  const userDropdown = (
    <>
      {user && (
        <NavDropdown
          data-test-user-menu={user.id as string}
          title={
            <>
              <ProfileImage className="mr-2" />
              <span className="navbar-toggler-icon"></span>
            </>
          }
          id="header-nav-profile-dropdown"
        >
          <Dropdown.Header className={"d-flex"}>
            <ProfileImage className="mr-2" />
            <span>
              {user.name ? (
                user.name
              ) : (
                <FormattedMessage
                  id="app-header.no-username-label"
                  defaultMessage="Unknown"
                  description="Label to be shown when the users name is not known"
                />
              )}
              {user.email && (
                <>
                  <br />
                  <em>{user.email}</em>
                </>
              )}
            </span>
          </Dropdown.Header>
          <NavDropdown.Item href={idp_base} target={"_blank"} active={false}>
            <FontAwesomeIcon icon="user-cog" className="mr-2" />
            <FormattedMessage
              id="app-header.nav.my-profile-label"
              defaultMessage="My Profile"
            />
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item
            href={
              idp_base +
              "/logout?client_id=" +
              encodeURIComponent(getClientId()) +
              "&next=" +
              encodeURIComponent(
                getEnvParam("REACT_APP_SRV_BASE", "") +
                  getEnvParam("REACT_APP_BASE", "")
              )
            }
          >
            <FontAwesomeIcon icon="power-off" className="mr-2" />
            <FormattedMessage
              id="app-header.nav.logout-label"
              defaultMessage="Logout"
            />
          </NavDropdown.Item>
        </NavDropdown>
      )}
      {!user && (
        <Navbar.Text>
          <FormattedMessage
            id="app-header.no-user-label"
            defaultMessage="Guest"
            description="Label to be shown when there is no login state"
          />
        </Navbar.Text>
      )}
    </>
  );
  //</editor-fold>

  return (
    <header data-test-header id="header" {...other}>
      <Navbar variant="dark" fixed={"top"}>
        <Navbar.Brand
          data-test-logo
          href="https://geograph.tech/"
          target="_blank"
        >
          <img src={logo} alt="Geograph" />
        </Navbar.Brand>
        <div
          className={
            "ml-auto d-inline-flex justify-content-end align-items-center"
          }
        >
          {orgDropdown}
          <Nav className={"ml-2"}>{userDropdown}</Nav>
        </div>
      </Navbar>
    </header>
  );
}

export default AppHeader;
