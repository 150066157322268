import React, { useEffect } from "react";
import Page, { PageDOMProps } from "../page";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import Table from "../../table";
import { Button, Dropdown, Form } from "react-bootstrap";
import { User } from "../../../model/User";
import { OrganizationGroup } from "../../../model/OrganizationGroup";
import "./users-view.scss";
import Modal from "../../modal";
import UserDetails from "../../user-details";
import {
  CreateOrgGroupInvitationAction,
  SendOrgGroupInvitationAction,
  RevokeOrgGroupInvitationAction,
  // SetUserSuspendedAction,
  DeleteOrgGroupInvitationAction,
  MultiAction,
  AddUserToOrgRoleAction,
  RemoveUserFromOrgRoleAction
} from "../../../actions/actionTypes";
import Feedback, { FeedbackEntry } from "../../feedback";
import { useForm } from "react-hook-form";
import { OrganizationGroupInvitation } from "../../../model/OrganizationGroupInvitation";
import UserLicensesModalView from "../../user-licenses-modal";
import { getEnvParam } from "../../../util/env";
import { findFirstAddErrorAction } from "../../../actions/actionHelpers";
import TooltipTrigger from "../../tooltip-trigger";

/**
 * Environment parameter for invitation welcome url.
 */
const APP_INVITATION_WELCOME_URL = "REACT_APP_INVITATION_WELCOME_URL";

export enum ModalKeys {
  invite = "invite",
  // suspend = "suspend",
  // unsuspend = "unsuspend",
  licenses = "licenses",
  remove = "remove",
  show = "show",
  revoke = "revoke",
  resend = "resend",
  removeInvitation = "remove-invitation",
  assignAdmin = "assign-admin",
  unassignAdmin = "unassign-admin"
}
export interface UserOrInvitation extends User {
  status?:
    | "accepted"
    | "created"
    | "updated"
    | "declined"
    | "deliveryFailed"
    | "deliveryRequested"
    | "emailNotAvailable"
    | "revoked"
    | "inactive"
    | "admin"
    | "member"
    | "suspended";
  invitationState?:
    | "accepted"
    | "created"
    | "updated"
    | "declined"
    | "deliveryFailed"
    | "deliveryRequested"
    | "emailNotAvailable"
    | "revoked";
  claimedAt?: string;
  declinedAt?: string;
  revokedAt?: string;
  isOrgAdmin: boolean;
  /*
  OrganizationGroupInvitation for reference, necessary fields copied in as optional fields.
  organizationRoleIds: string[];
  groupIds: string[];
  organizationId: string;
  clientRoleIds: string[];
  email: string;
  memberStatus?: "invited" | "user";
  recipientIsNewUser?: boolean;
  invitationState?:
    | "accepted"
    | "created"
  | "updated"
    | "declined"
    | "deliveryFailed"
    | "deliveryRequested"
    | "emailNotAvailable"
    | "revoked";
  clientData?: string;
  memberWelcomeUrl: string;
  invitationCompletedUrl: string;
  claimedAt?: string;
  declinedAt?: string;
  revokedAt?: string;
  recipientName: string;
  inviterName: string;
  recipientCountryCode?: string;
  recipientLanguageCode?: string;
  invitationScopeInformation?: string;
   */
}
//<editor-fold desc="Props">

export interface UsersModalVisibilityProps {
  showModal?: { key: ModalKeys; userId?: string };
  onShowModal: (key: ModalKeys, userId?: string) => void;
  onHideModal: () => void;
}
export interface UsersDOMProps extends PageDOMProps {}

export interface UsersStateProps {
  feedback?: FeedbackEntry[];
  onShowFeedback: (feedback: FeedbackEntry) => void;
  onHideFeedback: (id: string) => void;
  activeSearch?: string;
  onSetActiveSearch: (s: string) => void;
  selected: UserOrInvitation[];
  onSetSelected: (selection: UserOrInvitation[]) => void;
}
export interface UsersProps
  extends UsersStateProps,
    UsersDOMProps,
    UsersModalVisibilityProps {
  userName?: string;
  userId?: string;
  organizationId: string | null;
  employeesGroup?: OrganizationGroup;
  orgAdminRoleId?: string;
  onLoadEmployeesGroup: () => void;
  users?: UserOrInvitation[];
  onRemoveInvitation: (id: string) => Promise<DeleteOrgGroupInvitationAction>;
  onCreateInvitation: (
    invitation: OrganizationGroupInvitation
  ) => Promise<CreateOrgGroupInvitationAction | SendOrgGroupInvitationAction>;
  onRevokeInvitation: (
    invitation: OrganizationGroupInvitation
  ) => Promise<RevokeOrgGroupInvitationAction>;
  onResendInvitation: (
    invitation: OrganizationGroupInvitation
  ) => Promise<SendOrgGroupInvitationAction>;
  onLoadUsers: () => void;
  onRemoveUser: (id: string) => Promise<MultiAction>;
  onAssignAdmin: (
    userId: string,
    roleId?: string
  ) => Promise<AddUserToOrgRoleAction>;
  onUnassignAdmin: (
    userId: string,
    roleId?: string
  ) => Promise<RemoveUserFromOrgRoleAction>;
  // onSuspendUser: (id: string) => Promise<SetUserSuspendedAction>;
  // onUnsuspendUser: (id: string) => Promise<SetUserSuspendedAction>;
}
//</editor-fold>

//<editor-fold desc="Messages">
const statusMessages = defineMessages({
  accepted: {
    id: "pages.users.statusValues.accepted",
    defaultMessage: "Accepted invitation",
    description: "label for the status"
  },
  created: {
    id: "pages.users.statusValues.created",
    defaultMessage: "Unsent invitation",
    description: "label for the status"
  },
  updated: {
    id: "pages.users.statusValues.updated",
    defaultMessage: "Updated invitation",
    description: "label for the status"
  },
  declined: {
    id: "pages.users.statusValues.declined",
    defaultMessage: "Declined invitation",
    description: "label for the status"
  },
  deliveryFailed: {
    id: "pages.users.statusValues.deliveryFailed",
    defaultMessage: "Failed invitation",
    description: "label for the status"
  },
  deliveryRequested: {
    id: "pages.users.statusValues.deliveryRequested",
    defaultMessage: "Pending invitation",
    description: "label for the status"
  },
  emailNotAvailable: {
    id: "pages.users.statusValues.emailNotAvailable",
    defaultMessage: "Failed invitation",
    description: "label for the status"
  },
  revoked: {
    id: "pages.users.statusValues.revoked",
    defaultMessage: "Revoked invitation",
    description: "label for the status"
  },
  inactive: {
    id: "pages.users.statusValues.inactive",
    defaultMessage: "Inactive",
    description: "label for the status"
  },
  admin: {
    id: "pages.users.statusValues.admin",
    defaultMessage: "Admin",
    description: "label for the status"
  },
  member: {
    id: "pages.users.statusValues.member",
    defaultMessage: "Active",
    description: "label for the status"
  },
  suspended: {
    id: "pages.users.statusValues.suspended",
    defaultMessage: "Suspended",
    description: "label for the status"
  },
  unknown: {
    id: "pages.users.statusValues.unknown",
    defaultMessage: "Unknown",
    description: "label for the status"
  }
});
const statusTipMessages = defineMessages({
  accepted: {
    id: "pages.users.statusTips.accepted",
    defaultMessage: "Accepted invitation",
    description: "label for the status"
  },
  created: {
    id: "pages.users.statusTips.created",
    defaultMessage: "Unsent invitation",
    description: "label for the status"
  },
  updated: {
    id: "pages.users.statusTips.updated",
    defaultMessage: "Updated invitation",
    description: "label for the status"
  },
  declined: {
    id: "pages.users.statusTips.declined",
    defaultMessage: "Declined invitation",
    description: "label for the status"
  },
  deliveryFailed: {
    id: "pages.users.statusTips.deliveryFailed",
    defaultMessage: "Failed invitation",
    description: "label for the status"
  },
  deliveryRequested: {
    id: "pages.users.statusTips.deliveryRequested",
    defaultMessage: "Pending invitation",
    description: "label for the status"
  },
  emailNotAvailable: {
    id: "pages.users.statusTips.emailNotAvailable",
    defaultMessage: "Failed invitation",
    description: "label for the status"
  },
  revoked: {
    id: "pages.users.statusTips.revoked",
    defaultMessage: "Revoked invitation",
    description: "label for the status"
  },
  inactive: {
    id: "pages.users.statusTips.inactive",
    defaultMessage: "Inactive: The user has not logged in for 30 days",
    description: "label for the status"
  },
  admin: {
    id: "pages.users.statusTips.admin",
    defaultMessage: "Admin",
    description: "label for the status"
  },
  member: {
    id: "pages.users.statusTips.member",
    defaultMessage: "Active",
    description: "label for the status"
  },
  suspended: {
    id: "pages.users.statusTips.suspended",
    defaultMessage: "Suspended",
    description: "label for the status"
  },
  unknown: {
    id: "pages.users.statusTips.unknown",
    defaultMessage: "Unknown",
    description: "label for the status"
  }
});
const messages = defineMessages({
  invitationRevokedFailureMessage: {
    id: "pages.users.invitation-revoke-failed",
    defaultMessage: "Revoking invitation for {name} failed: {msg}",
    description: "failure notification"
  },
  invitationRevokedSuccessMessage: {
    id: "pages.users.invitation-revoked",
    defaultMessage: "Invitation for {name} revoked",
    description: "success notification"
  },
  revokeInvitationModalTitle: {
    id: "pages.users.revoke-invitation-modal.title",
    defaultMessage: "Revoke invitation",
    description: "the title of the modal"
  },
  revokeInvitationModalPrimaryButtonLabel: {
    id: "pages.users.revoke-invitation-modal.primary-button-label",
    defaultMessage: "Revoke",
    description: "the label for the button"
  },
  revokeInvitationModalSecondaryButtonLabel: {
    id: "pages.users.revoke-invitation-modal.secondary-button-label",
    defaultMessage: "Close",
    description: "the label for the button"
  },
  invitationResendFailureMessage: {
    id: "pages.users.invitation-resend-failed",
    defaultMessage: "Resending invitation for {name} failed: {msg}",
    description: "failure notification"
  },
  invitationResendSuccessMessage: {
    id: "pages.users.invitation-resend",
    defaultMessage: "Invitation for {name} resent",
    description: "success notification"
  },
  resendInvitationModalTitle: {
    id: "pages.users.resend-invitation-modal.title",
    defaultMessage: "Resend invitation",
    description: "the title of the modal"
  },
  resendInvitationModalPrimaryButtonLabel: {
    id: "pages.users.Resend-invitation-modal.primary-button-label",
    defaultMessage: "Resend",
    description: "the label for the button"
  },
  resendInvitationModalSecondaryButtonLabel: {
    id: "pages.users.resend-invitation-modal.secondary-button-label",
    defaultMessage: "Close",
    description: "the label for the button"
  },
  invitationSuccessMessage: {
    id: "pages.users.invitation-sent",
    defaultMessage: "Invitation sent to {email}",
    description: "success message for sent invitation"
  },
  invitationFailureMessage: {
    id: "pages.users.invitation-failed",
    defaultMessage: "Inviting user failed",
    description: "unspecified invitation error message"
  },
  sendInvitationFailed: {
    id: "pages.users.send-invitation-failed",
    defaultMessage: "Sending invitation email failed",
    description: "Sending invitation failed error message"
  },
  createInvitationFailed: {
    id: "pages.users.create-invitation-failed",
    defaultMessage: "Creating invitation failed",
    description: "creating invitation failed error message"
  },
  tableHeaderInviteButtonLabel: {
    id: "pages.users.table-header.invite-button-label",
    defaultMessage: "Invite",
    description: "Label for the button"
  },
  userRemovedSuccessMessage: {
    id: "pages.users.userRemovedSuccessMessage",
    defaultMessage: "{name} removed from organization",
    description: "Success notification"
  },
  userRemovedFailureMessage: {
    id: "pages.users.userRemovedFailureMessage",
    defaultMessage: "Removing {name} from organization failed: {msg}",
    description: "Failure notification"
  },
  assignAdminSuccessMessage: {
    id: "pages.users.assignAdminSuccessMessage",
    defaultMessage: "Admin access assigned for {name}",
    description: "Success notification"
  },
  assignAdminFailureMessage: {
    id: "pages.users.assignAdminFailureMessage",
    defaultMessage: "Assigning Admin access for {name} failed: {msg}",
    description: "Failure notification"
  },
  unassignAdminSuccessMessage: {
    id: "pages.users.unassignAdminSuccessMessage",
    defaultMessage: "Admin access unassigned for {name}",
    description: "Success notification"
  },
  unassignAdminFailureMessage: {
    id: "pages.users.unassignAdminFailureMessage",
    defaultMessage: "Unassigning Admin access for {name} failed: {msg}",
    description: "Failure notification"
  },

  invitationRemovedSuccessMessage: {
    id: "pages.users.invitationRemovedSuccessMessage",
    defaultMessage: "Invitation for {name} removed from organization",
    description: "Success notification"
  },
  invitationRemovedFailureMessage: {
    id: "pages.users.invitationRemovedFailureMessage",
    defaultMessage:
      "Removing invitation for {name} from organization failed: {msg}",
    description: "Success notification"
  },
  /*
  userUnsuspendedSuccessMessage: {
    id: "pages.users.userUnsuspendedSuccessMessage",
    defaultMessage: "{name} has been unsuspended",
    description: "Success notification"
  },
  userUnsuspendedFailureMessage: {
    id: "pages.users.userUnsuspendedFailureMessage",
    defaultMessage: "Unsuspending {name} failed: {msg}",
    description: "Success notification"
  },
  userSuspendedSuccessMessage: {
    id: "pages.users.userSuspendedSuccessMessage",
    defaultMessage: "{name} has been suspended",
    description: "Success notification"
  },
  userSuspendedFailureMessage: {
    id: "pages.users.userSuspendedFailureMessage",
    defaultMessage: "Suspending {name} failed: {msg}",
    description: "Success notification"
  },
  */
  title: {
    id: "pages.users.title",
    defaultMessage: "Manage users",
    description: "Title for the Manage users screen"
  },
  description: {
    id: "pages.users.meta-description",
    defaultMessage: "Organization user management",
    description: "Description for the Manage users screen"
  },
  cellHeaderId: {
    id: "pages.users.cell-header.id",
    defaultMessage: "Id",
    description: "Heading for the cell"
  },
  cellHeaderStatus: {
    id: "pages.users.cell-header.status",
    defaultMessage: "Status",
    description: "Heading for the cell"
  },
  cellHeaderDisplayName: {
    id: "pages.users.cell-header.display-name",
    defaultMessage: "Display name",
    description: "Heading for the cell"
  },
  cellHeaderEmail: {
    id: "pages.users.cell-header.email",
    defaultMessage: "Email",
    description: "Heading for the cell"
  },
  cellHeaderProfessionalTitle: {
    id: "pages.users.cell-header.professional-title",
    defaultMessage: "Professional title",
    description: "Heading for the cell"
  },
  cellHeaderNickname: {
    id: "pages.users.cell-header.nickname",
    defaultMessage: "Nickname",
    description: "Heading for the cell"
  },
  cellHeaderLastName: {
    id: "pages.users.cell-header.last-name",
    defaultMessage: "Last name",
    description: "Heading for the cell"
  },
  cellHeaderFirstName: {
    id: "pages.users.cell-header.first-name",
    defaultMessage: "First name",
    description: "Heading for the cell"
  },
  cellHeaderPhoneNumber: {
    id: "pages.users.cell-header.phone-number",
    defaultMessage: "Phone number",
    description: "Heading for the cell"
  },
  rowToolsAssignAdminLabel: {
    id: "pages.users.row-tools.assign-admin-label",
    defaultMessage: "Assign Admin access",
    description: "Label for the tool"
  },
  rowToolsUnassignAdminLabel: {
    id: "pages.users.row-tools.unassign-admin-label",
    defaultMessage: "Unassign Admin access",
    description: "Label for the tool"
  },
  rowToolsUnassignAdminBlockedTooltip: {
    id: "pages.users.row-tools.unassign-admin-blocked-tooltip",
    defaultMessage: "You can't unassign yourself",
    description: "Label for the tool"
  },
  rowToolsRemoveUserBlockedTooltip: {
    id: "pages.users.row-tools.remove-user-blocked-tooltip",
    defaultMessage: "You can't remove yourself",
    description: "Label for the tool"
  },
  rowToolsRemoveUserLabel: {
    id: "pages.users.row-tools.remove-user-label",
    defaultMessage: "Remove",
    description: "Label for the tool"
  },
  /*
  rowToolsSuspendUserLabel: {
    id: "pages.users.row-tools.suspend-user-label",
    defaultMessage: "Suspend",
    description: "Label for the tool"
  },
  */
  rowToolsShowUserLabel: {
    id: "pages.users.row-tools.show-user-label",
    defaultMessage: "View details",
    description: "Label for the tool"
  },
  rowToolsUserLicensesLabel: {
    id: "pages.users.row-tools.user-licenses-label",
    defaultMessage: "Edit licenses",
    description: "Label for the tool"
  },
  /*
  rowToolsUnsuspendUserLabel: {
    id: "pages.users.row-tools.unsuspend-user-label",
    defaultMessage: "Unsuspend",
    description: "Label for the tool"
  },
  */
  rowToolsRevokeInvitationLabel: {
    id: "pages.users.row-tools.revoke-invitation-label",
    defaultMessage: "Revoke",
    description: "Label for the tool"
  },
  rowToolsResendInvitationLabel: {
    id: "pages.users.row-tools.resend-invitation-label",
    defaultMessage: "Resend",
    description: "Label for the tool"
  },
  rowToolsDeleteInvitationLabel: {
    id: "pages.users.row-tools.delete-invitation-label",
    defaultMessage: "Remove",
    description: "Label for the tool"
  },
  showUserModalTitle: {
    id: "pages.users.show-user-modal.title",
    defaultMessage: "User details",
    description: "modal title"
  },
  showUserModalUserNotFoundTitle: {
    id: "pages.users.show-user-modal.user-not-found.title",
    defaultMessage: "User not found",
    description: "failure title"
  },
  showUserModalPrimaryButtonLabel: {
    id: "pages.users.show-user-modal.primary-button-label",
    defaultMessage: "Close",
    description: "button label"
  },
  showInviteModalTitle: {
    id: "pages.users.show-invite-modal.title",
    defaultMessage: "Invite user",
    description: "modal title"
  },
  showInviteModalPrimaryButtonLabel: {
    id: "pages.users.show-invite-modal.primary-button-label",
    defaultMessage: "Invite",
    description: "button label"
  },
  showInviteModalPrimaryButtonErrorTip: {
    id: "pages.users.show-invite-modal.primary-button-error-tip",
    defaultMessage: "Please correct errors before sending invitation",
    description: "tooltip for the save button when there are validation errors"
  },
  showInviteModalPrimaryButtonNoChangesTip: {
    id: "pages.users.show-invite-modal.primary-button-no-changes-tip",
    defaultMessage: "Please fill in the fields first",
    description: "tooltip for the save button when there is nothing to creates"
  },
  showInviteModalSecondaryButtonLabel: {
    id: "pages.users.show-invite-modal.secondary-button-label",
    defaultMessage: "Cancel",
    description: "button label"
  },
  /*
  suspendUserModalTitle: {
    id: "pages.users.suspend-user-modal.title",
    defaultMessage: "Suspend user",
    description: "modal title"
  },
  suspendUserModalPrimaryButtonLabel: {
    id: "pages.users.suspend-user-modal.primary-button-label",
    defaultMessage: "Suspend",
    description: "button label"
  },
  suspendUserModalSecondaryButtonLabel: {
    id: "pages.users.suspend-user-modal.secondary-button-label",
    defaultMessage: "Cancel",
    description: "button label"
  },
  unsuspendUserModalTitle: {
    id: "pages.users.unsuspend-user-modal.title",
    defaultMessage: "Unsuspend user",
    description: "modal title"
  },
  unsuspendUserModalPrimaryButtonLabel: {
    id: "pages.users.unsuspend-user-modal.primary-button-label",
    defaultMessage: "Unsuspend",
    description: "button label"
  },
  unsuspendUserModalSecondaryButtonLabel: {
    id: "pages.users.unsuspend-user-modal.secondary-button-label",
    defaultMessage: "Cancel",
    description: "button label"
  },
  */
  removeUserModalNotFoundTitle: {
    id: "pages.users.remove-user-modal.not-found.title",
    defaultMessage: "User not found",
    description: "modal title when user not found"
  },
  removeUserModalTitle: {
    id: "pages.users.remove-user-modal.title",
    defaultMessage: "Remove user from organization",
    description: "modal title"
  },
  removeUserModalPrimaryButtonLabel: {
    id: "pages.users.remove-user-modal.primary-button-label",
    defaultMessage: "Remove",
    description: "button label"
  },
  removeUserModalCloseButtonLabel: {
    id: "pages.users.remove-user-modal.close-button-label",
    defaultMessage: "Close",
    description: "button label"
  },
  removeUserModalSecondaryButtonLabel: {
    id: "pages.users.remove-user-modal.secondary-button-label",
    defaultMessage: "Cancel",
    description: "button label"
  },
  assignAdminModalNotFoundTitle: {
    id: "pages.users.assign-admin-modal.not-found.title",
    defaultMessage: "User not found",
    description: "modal title when user not found"
  },
  assignAdminModalTitle: {
    id: "pages.users.assign-admin-modal.title",
    defaultMessage: "Assign Admin access",
    description: "modal title"
  },
  assignAdminModalPrimaryButtonLabel: {
    id: "pages.users.assign-admin-modal.primary-button-label",
    defaultMessage: "Assign",
    description: "button label"
  },
  assignAdminModalCloseButtonLabel: {
    id: "pages.users.assign-admin-modal.close-button-label",
    defaultMessage: "Close",
    description: "button label"
  },
  assignAdminModalSecondaryButtonLabel: {
    id: "pages.users.assign-admin-modal.secondary-button-label",
    defaultMessage: "Cancel",
    description: "button label"
  },

  unassignAdminModalNotFoundTitle: {
    id: "pages.users.unassign-admin-modal.not-found.title",
    defaultMessage: "User not found",
    description: "modal title when user not found"
  },
  unassignAdminModalTitle: {
    id: "pages.users.unassign-admin-modal.title",
    defaultMessage: "Unassign Admin access",
    description: "modal title"
  },
  unassignAdminModalPrimaryButtonLabel: {
    id: "pages.users.unassign-admin-modal.primary-button-label",
    defaultMessage: "Unassign",
    description: "button label"
  },
  unassignAdminModalCloseButtonLabel: {
    id: "pages.users.unassign-admin-modal.close-button-label",
    defaultMessage: "Close",
    description: "button label"
  },
  unassignAdminModalSecondaryButtonLabel: {
    id: "pages.users.unassign-admin-modal.secondary-button-label",
    defaultMessage: "Cancel",
    description: "button label"
  },

  unknownUserLabel: {
    id: "pages.users.unknown-user-label",
    defaultMessage: "Unknown",
    description: "Shown where user name can't be resolved"
  },

  removeInvitationModalTitle: {
    id: "pages.users.remove-invitation-modal.title",
    defaultMessage: "Remove invitation to organization",
    description: "modal title"
  },
  removeInvitationModalPrimaryButtonLabel: {
    id: "pages.users.remove-invitation-modal.primary-button-label",
    defaultMessage: "Remove",
    description: "button label"
  },
  removeInvitationModalCloseButtonLabel: {
    id: "pages.users.remove-invitation-modal.close-button-label",
    defaultMessage: "Close",
    description: "button label"
  },
  removeInvitationModalSecondaryButtonLabel: {
    id: "pages.users.remove-invitation-modal.secondary-button-label",
    defaultMessage: "Cancel",
    description: "button label"
  },
  inviteAssignAdminLabel: {
    id: "pages.users.show-invite-modal.assign-admin-label",
    defaultMessage: "Assign Admin access",
    description: "field label"
  },
  inviteInviteeNameFieldLabel: {
    id: "pages.users.show-invite-modal.recipient-name-label",
    defaultMessage: "Name",
    description: "field label"
  },
  inviteInviteeNameRequired: {
    id: "pages.users.show-invite-modal.recipient-name-required",
    defaultMessage: "Recipient name is required",
    description: "error info"
  },
  inviteInviteeEmailFieldLabel: {
    id: "pages.users.show-invite-modal.email-label",
    defaultMessage: "Email",
    description: "field label"
  },
  inviteInviteeEmailRequired: {
    id: "pages.users.show-invite-modal.email-required",
    defaultMessage: "Recipient email is required",
    description: "error info"
  },
  inviteInviteeEmailInvalid: {
    id: "pages.users.show-invite-modal.email-invalid",
    defaultMessage: "The email address is not valid",
    description: "error info"
  }
});
//</editor-fold>

//<editor-fold desc="Utils">

const resolveValidityClassName = (key: string, errors: any) =>
  key in errors ? "is-invalid" : undefined;
const isSuspended = (usr: UserOrInvitation): boolean => {
  let retVal;
  if (
    usr.validUntil === null ||
    usr.validUntil === undefined ||
    usr.validUntil === ""
  ) {
    retVal = false;
  } else {
    const d = new Date(usr.validUntil);
    retVal = new Date().getTime() > d.getTime();
  }
  return retVal;
};
const isInvitation = (usr: UserOrInvitation): boolean => {
  let retVal;
  retVal =
    usr.status === "accepted" ||
    usr.status === "created" ||
    usr.status === "updated" ||
    usr.status === "declined" ||
    usr.status === "deliveryFailed" ||
    usr.status === "deliveryRequested" ||
    usr.status === "emailNotAvailable" ||
    usr.status === "revoked";
  return retVal;
};
const resolveRowClasses = (row: UserOrInvitation, rowIndex: number) => {
  const t: string[] = [];
  if (isInvitation(row)) {
    t.push("invitation");
    if (row.invitationState) {
      t.push(row.invitationState.toLowerCase());
    }
  } else if (isSuspended(row)) {
    t.push("suspended");
  }
  return t.length ? t.join(" ") : undefined;
};

const resolveUser = (
  userId: string | undefined,
  users: UserOrInvitation[] | undefined
) => {
  let retVal: UserOrInvitation | undefined;
  if (userId && users) {
    retVal = users.find(val => val.id === userId);
  }
  return retVal;
};
const resolveUserName = (
  userId: string | undefined,
  users: UserOrInvitation[] | undefined
) => {
  let retVal = "";
  const user = resolveUser(userId, users);
  if (user) {
    if (user.displayName) {
      retVal = user.displayName;
    } else if (user.firstName && user.lastName) {
      retVal = user.firstName + " " + user.lastName;
    } else if (user.nickname) {
      retVal = user.nickname;
    }
  }
  return retVal;
};
const resolveUserLabel = (
  userId: string | undefined,
  users: UserOrInvitation[] | undefined,
  intl: { formatMessage: (v: any, v2?: any) => string }
) => {
  let retVal;
  const name = resolveUserName(userId, users);
  if (name) {
    retVal = name;
  } else {
    retVal = intl.formatMessage(messages.unknownUserLabel);
  }

  return retVal;
};

//</editor-fold>

function Users(props: UsersProps) {
  //<editor-fold desc="Local variables">
  const {
    users,
    onLoadUsers,
    selected,
    onSetSelected,
    employeesGroup,
    onLoadEmployeesGroup,
    onRemoveUser,
    // onSuspendUser,
    // onUnsuspendUser,
    onShowModal,
    onHideModal,
    showModal,
    activeSearch,
    onSetActiveSearch,
    onShowFeedback,
    onHideFeedback,
    feedback,
    onAssignAdmin,
    onUnassignAdmin,
    onCreateInvitation,
    onRevokeInvitation,
    onResendInvitation,
    onRemoveInvitation,
    organizationId,
    userName,
    userId,
    orgAdminRoleId,
    ...others
  } = props;
  const onSubmitInvitation = (d: any) => {
    let data: any = {
      email: d.email,
      recipientName: d.recipientName
    };
    data.organizationId = organizationId as string;
    data.groupIds = employeesGroup ? [employeesGroup.id as string] : [];
    data.organizationRoleIds = d.assignAdmin ? [orgAdminRoleId] : [];
    data.memberWelcomeUrl = getEnvParam(APP_INVITATION_WELCOME_URL);
    data.inviterName = userName;
    data.validFrom = "now()";
    return onCreateInvitation(data as OrganizationGroupInvitation);
  };
  const modalUser: UserOrInvitation | undefined = showModal
    ? resolveUser(showModal.userId, users)
    : undefined;

  // this is more like a variable than a hook
  const intl = useIntl();
  const title = intl.formatMessage(messages.title);
  const description = intl.formatMessage(messages.description);
  const statusCellRenderer = (props: {
    cell: any;
    row: any;
    rowIndex: Number;
    rendererData: any;
  }) => {
    const cv = props.cell as
      | "accepted"
      | "created"
      | "updated"
      | "declined"
      | "deliveryFailed"
      | "deliveryRequested"
      | "emailNotAvailable"
      | "revoked"
      | "inactive"
      | "admin"
      | "member"
      | "suspended";
    if (cv) {
      return <>{intl.formatMessage(statusMessages[cv])}</>;
    } else {
      return <>{intl.formatMessage(statusMessages.unknown)}</>;
    }
  };
  const statusCellTipRenderer = (props: {
    cell: any;
    row: any;
    rowIndex: Number;
    rendererData: any;
  }) => {
    const cv = props.cell as
      | "accepted"
      | "created"
      | "updated"
      | "declined"
      | "deliveryFailed"
      | "deliveryRequested"
      | "emailNotAvailable"
      | "revoked"
      | "inactive"
      | "admin"
      | "member"
      | "suspended";
    if (cv) {
      return <>{intl.formatMessage(statusTipMessages[cv])}</>;
    } else {
      return <>{intl.formatMessage(statusTipMessages.unknown)}</>;
    }
  };
  const columns = [
    {
      key: "id",
      label: intl.formatMessage(messages.cellHeaderId),
      isTechnical: true,
      hidden: true
    },
    {
      key: "status",
      label: intl.formatMessage(messages.cellHeaderStatus),
      sortable: true,
      renderer: statusCellRenderer,
      tipRenderer: statusCellTipRenderer
    },
    {
      key: "displayName",
      label: intl.formatMessage(messages.cellHeaderDisplayName),
      sortable: true
    },
    {
      key: "firstName",
      label: intl.formatMessage(messages.cellHeaderFirstName),
      sortable: true
    },
    {
      key: "nickname",
      label: intl.formatMessage(messages.cellHeaderNickname),
      sortable: true,
      hidden: true
    },
    {
      key: "lastName",
      label: intl.formatMessage(messages.cellHeaderLastName),
      sortable: true
    },
    {
      key: "professionalTitle",
      label: intl.formatMessage(messages.cellHeaderProfessionalTitle),
      sortable: true,
      hidden: true
    },
    {
      key: "email",
      label: intl.formatMessage(messages.cellHeaderEmail),
      sortable: true
    },
    {
      key: "phoneNumber",
      label: intl.formatMessage(messages.cellHeaderPhoneNumber),
      sortable: true,
      hidden: true
    }
  ];
  //</editor-fold>

  //<editor-fold desc="Hooks">

  const { register, handleSubmit, errors, formState } = useForm({
    mode: "onBlur",
    defaultValues: {}
  });
  // resets selection when users are undefined, and clears out selected items that are not part of updated data
  useEffect(() => {
    if (selected.length) {
      if (users === undefined) {
        onSetSelected([]);
      } else {
        const ids = users.map((usr: UserOrInvitation) => usr.id);
        const newS = selected.filter((itm: any) => {
          return ids.indexOf(itm.id) >= 0;
        });
        if (newS.length !== selected.length) {
          onSetSelected(newS);
        }
      }
    }
  }, [users, selected, onSetSelected]);
  useEffect(() => {
    if (users === undefined) {
      onLoadUsers();
    }
  }, [users, onLoadUsers]);
  useEffect(() => {
    if (employeesGroup === undefined) {
      onLoadEmployeesGroup();
    }
  }, [employeesGroup, onLoadEmployeesGroup]);

  //</editor-fold>
  return (
    <Page
      header={<h1>{title}</h1>}
      data-test-users-page
      id={"users-page"}
      meta={{
        title,
        description
      }}
      {...others}
    >
      {feedback &&
        feedback.map(f => {
          return (
            <Feedback
              key={"users_" + f.id}
              type={f.type}
              show={true}
              onClose={() => {
                onHideFeedback(f.id);
              }}
              autoClose={f.autoclose === true}
            >
              {f.msg}
            </Feedback>
          );
        })}
      <Table<UserOrInvitation>
        data-test-users-table
        selection={{
          multi: false,
          selectAll: false
        }}
        header={
          <Button
            data-test-invite-user-trigger
            variant={"outline-secondary"}
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              onShowModal(ModalKeys.invite);
            }}
            className={"mr-auto"}
          >
            {intl.formatMessage(messages.tableHeaderInviteButtonLabel)}
          </Button>
        }
        search={true}
        activeSearch={activeSearch}
        onSearch={onSetActiveSearch}
        rowClasses={resolveRowClasses}
        selected={selected}
        onSelectionChanged={onSetSelected}
        columns={columns}
        columnToggle
        reload={true}
        onReload={onLoadUsers}
        data={users ? users : undefined}
        identifyingColumn={"id"}
        pagination={false}
        rowTools={(props: { rowEntry: UserOrInvitation }) => {
          if (isInvitation(props.rowEntry)) {
            return (
              <>
                {props.rowEntry.invitationState &&
                  props.rowEntry.invitationState !== "declined" &&
                  props.rowEntry.invitationState !== "revoked" && (
                    <>
                      <Dropdown.Item
                        data-test-row-tool-invitation-item
                        data-test-row-tool-revoke-invitation
                        onClick={(e: any) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onShowModal(ModalKeys.revoke, props.rowEntry.id);
                        }}
                      >
                        {intl.formatMessage(
                          messages.rowToolsRevokeInvitationLabel
                        )}
                      </Dropdown.Item>
                      <Dropdown.Item
                        data-test-row-tool-invitation-item
                        data-test-row-tool-resend-invitation
                        onClick={(e: any) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onShowModal(ModalKeys.resend, props.rowEntry.id);
                        }}
                      >
                        {intl.formatMessage(
                          messages.rowToolsResendInvitationLabel
                        )}
                      </Dropdown.Item>
                    </>
                  )}
                <Dropdown.Item
                  data-test-row-tool-invitation-item
                  data-test-row-tool-remove-invitation
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onShowModal(ModalKeys.removeInvitation, props.rowEntry.id);
                  }}
                >
                  {intl.formatMessage(messages.rowToolsDeleteInvitationLabel)}
                </Dropdown.Item>
              </>
            );
          } else {
            // const suspended = isSuspended(props.rowEntry);
            return (
              <>
                <Dropdown.Item
                  data-test-row-tool-user-item
                  data-test-row-tool-user-details
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onShowModal(ModalKeys.show, props.rowEntry.id);
                  }}
                >
                  {intl.formatMessage(messages.rowToolsShowUserLabel)}
                </Dropdown.Item>
                <Dropdown.Item
                  data-test-row-tool-user-item
                  data-test-row-tool-user-licenses
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onShowModal(ModalKeys.licenses, props.rowEntry.id);
                  }}
                >
                  {intl.formatMessage(messages.rowToolsUserLicensesLabel)}
                </Dropdown.Item>
                {orgAdminRoleId && !props.rowEntry.isOrgAdmin && (
                  <Dropdown.Item
                    data-test-row-tool-user-item
                    data-test-row-tool-assign-admin
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onShowModal(ModalKeys.assignAdmin, props.rowEntry.id);
                    }}
                  >
                    {intl.formatMessage(messages.rowToolsAssignAdminLabel)}
                  </Dropdown.Item>
                )}
                {orgAdminRoleId &&
                  props.rowEntry.isOrgAdmin &&
                  props.rowEntry.id === userId && (
                    <TooltipTrigger
                      tip={intl.formatMessage(
                        messages.rowToolsUnassignAdminBlockedTooltip
                      )}
                      tipKey={"unassignBlockedTip"}
                      placement={"auto"}
                      wrapDisabled={true}
                    >
                      <Dropdown.Item
                        data-test-row-tool-user-item
                        data-test-row-tool-unassign-admin
                        onClick={(e: any) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onShowModal(
                            ModalKeys.unassignAdmin,
                            props.rowEntry.id
                          );
                        }}
                        disabled={true}
                      >
                        {intl.formatMessage(
                          messages.rowToolsUnassignAdminLabel
                        )}
                      </Dropdown.Item>
                    </TooltipTrigger>
                  )}
                {orgAdminRoleId &&
                  props.rowEntry.isOrgAdmin &&
                  props.rowEntry.id !== userId && (
                    <Dropdown.Item
                      data-test-row-tool-user-item
                      data-test-row-tool-unassign-admin
                      onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onShowModal(ModalKeys.unassignAdmin, props.rowEntry.id);
                      }}
                    >
                      {intl.formatMessage(messages.rowToolsUnassignAdminLabel)}
                    </Dropdown.Item>
                  )}
                {props.rowEntry.id === userId && (
                  <TooltipTrigger
                    tip={intl.formatMessage(
                      messages.rowToolsRemoveUserBlockedTooltip
                    )}
                    tipKey={"removeUSerBlockedTip"}
                    placement={"auto"}
                    wrapDisabled={true}
                  >
                    <Dropdown.Item
                      data-test-row-tool-user-item
                      data-test-row-tool-user-remove
                      onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onShowModal(ModalKeys.remove, props.rowEntry.id);
                      }}
                      disabled={true}
                    >
                      {intl.formatMessage(messages.rowToolsRemoveUserLabel)}
                    </Dropdown.Item>
                  </TooltipTrigger>
                )}
                {props.rowEntry.id !== userId && (
                  <Dropdown.Item
                    data-test-row-tool-user-item
                    data-test-row-tool-user-remove
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onShowModal(ModalKeys.remove, props.rowEntry.id);
                    }}
                  >
                    {intl.formatMessage(messages.rowToolsRemoveUserLabel)}
                  </Dropdown.Item>
                )}
                {/*
                {suspended && (
                  <Dropdown.Item
                    data-test-row-tool-user-item
                    data-test-row-tool-user-unsuspend
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onShowModal(ModalKeys.unsuspend, props.rowEntry.id);
                    }}
                  >
                    {intl.formatMessage(messages.rowToolsUnsuspendUserLabel)}
                  </Dropdown.Item>
                )}
                {!suspended && (
                  <Dropdown.Item
                    data-test-row-tool-user-item
                    data-test-row-tool-user-suspend
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onShowModal(ModalKeys.suspend, props.rowEntry.id);
                    }}
                  >
                    {intl.formatMessage(messages.rowToolsSuspendUserLabel)}
                  </Dropdown.Item>
                )}
                */}
              </>
            );
          }
        }}
      />
      {orgAdminRoleId && (
        <Modal
          data-test-assign-admin-dialog={
            showModal && showModal.userId ? showModal.userId : true
          }
          title={
            modalUser
              ? intl.formatMessage(messages.assignAdminModalTitle)
              : intl.formatMessage(messages.assignAdminModalNotFoundTitle)
          }
          show={showModal && showModal.key === ModalKeys.assignAdmin}
          onClose={onHideModal}
          primaryButton={
            modalUser && !modalUser.isOrgAdmin
              ? {
                  label: intl.formatMessage(
                    messages.assignAdminModalPrimaryButtonLabel
                  )
                }
              : {
                  label: intl.formatMessage(
                    messages.assignAdminModalCloseButtonLabel
                  )
                }
          }
          onPrimaryAction={() => {
            if (
              showModal &&
              showModal.userId &&
              modalUser &&
              !modalUser.isOrgAdmin
            ) {
              onAssignAdmin(showModal.userId, orgAdminRoleId).then(
                res => {
                  onShowFeedback({
                    id: "assignAdmin_" + showModal.userId,
                    msg: intl.formatMessage(
                      messages.assignAdminSuccessMessage,
                      {
                        name: resolveUserName(showModal.userId, users)
                      }
                    ),
                    autoclose: true,
                    type: "success"
                  });
                },
                rej => {
                  onShowFeedback({
                    id: "assignAdmin_" + showModal.userId,
                    msg: intl.formatMessage(
                      messages.assignAdminFailureMessage,
                      {
                        name: resolveUserName(showModal.userId, users)
                      }
                    ),
                    type: "danger"
                  });
                }
              );
            }
            onHideModal();
          }}
          secondaryButton={
            modalUser && !modalUser.isOrgAdmin
              ? {
                  label: intl.formatMessage(
                    messages.assignAdminModalSecondaryButtonLabel
                  )
                }
              : undefined
          }
          onSecondaryAction={modalUser && onHideModal}
        >
          <>
            {showModal && modalUser && (
              <>
                {modalUser.isOrgAdmin && (
                  <Feedback type={"danger"} show={true} asChild={true}>
                    <p>
                      <FormattedMessage
                        id="pages.users.assign-admin-modal.user-already-admin.title"
                        defaultMessage="{name} already has Admin access"
                        description="title to be shown when the user is already an admin"
                        values={{
                          name: resolveUserLabel(
                            showModal ? modalUser.id : undefined,
                            users,
                            intl
                          )
                        }}
                      />
                    </p>
                  </Feedback>
                )}
                {!modalUser.isOrgAdmin && (
                  <p>
                    <FormattedMessage
                      id="pages.users.assign-admin-modal.copy"
                      defaultMessage="Are you sure you wish to assign Admin access for {name}?"
                      values={{
                        name: resolveUserLabel(
                          showModal ? modalUser.id : undefined,
                          users,
                          intl
                        )
                      }}
                    />
                  </p>
                )}
              </>
            )}
            {showModal && !modalUser && (
              <Feedback type={"danger"} show={true} asChild={true}>
                <p>
                  <FormattedMessage
                    id="pages.users.assign-admin-modal.not-found.copy"
                    defaultMessage="Something went wrong and the user could not be loaded. The user may have been removed or you don't have sufficient access rights."
                    description="title to be shown when there is no user to display"
                  />
                </p>
              </Feedback>
            )}
          </>
        </Modal>
      )}
      {orgAdminRoleId && (
        <Modal
          data-test-unassign-admin-dialog={
            showModal && showModal.userId ? showModal.userId : true
          }
          title={
            modalUser
              ? intl.formatMessage(messages.unassignAdminModalTitle)
              : intl.formatMessage(messages.unassignAdminModalNotFoundTitle)
          }
          show={showModal && showModal.key === ModalKeys.unassignAdmin}
          onClose={onHideModal}
          primaryButton={
            modalUser && modalUser.isOrgAdmin && modalUser.id !== userId
              ? {
                  label: intl.formatMessage(
                    messages.unassignAdminModalPrimaryButtonLabel
                  )
                }
              : {
                  label: intl.formatMessage(
                    messages.unassignAdminModalCloseButtonLabel
                  )
                }
          }
          onPrimaryAction={() => {
            if (
              showModal &&
              showModal.userId &&
              modalUser &&
              modalUser.isOrgAdmin &&
              userId !== showModal.userId
            ) {
              onUnassignAdmin(showModal.userId, orgAdminRoleId).then(
                res => {
                  onShowFeedback({
                    id: "unassignAdmin_" + showModal.userId,
                    msg: intl.formatMessage(
                      messages.unassignAdminSuccessMessage,
                      {
                        name: resolveUserName(showModal.userId, users)
                      }
                    ),
                    autoclose: true,
                    type: "success"
                  });
                },
                rej => {
                  onShowFeedback({
                    id: "unassignAdmin_" + showModal.userId,
                    msg: intl.formatMessage(
                      messages.unassignAdminFailureMessage,
                      {
                        name: resolveUserName(showModal.userId, users)
                      }
                    ),
                    type: "danger"
                  });
                }
              );
            }
            onHideModal();
          }}
          secondaryButton={
            modalUser && modalUser.isOrgAdmin && userId !== modalUser.id
              ? {
                  label: intl.formatMessage(
                    messages.unassignAdminModalSecondaryButtonLabel
                  )
                }
              : undefined
          }
          onSecondaryAction={modalUser && onHideModal}
        >
          <>
            {showModal && modalUser && (
              <>
                {modalUser.id === userId && (
                  <Feedback type={"danger"} show={true} asChild={true}>
                    <p>
                      <FormattedMessage
                        id="pages.users.unassign-admin-modal.user-is-you.title"
                        defaultMessage="{name}, you're not allowed to unassign yourself"
                        description="title to be shown when the user is you"
                        values={{
                          name: resolveUserLabel(
                            showModal ? modalUser.id : undefined,
                            users,
                            intl
                          )
                        }}
                      />
                    </p>
                  </Feedback>
                )}
                {!modalUser.isOrgAdmin && modalUser.id !== userId && (
                  <Feedback type={"danger"} show={true} asChild={true}>
                    <p>
                      <FormattedMessage
                        id="pages.users.unassign-admin-modal.user-not-admin.title"
                        defaultMessage="{name} does not have Admin access to unassign"
                        description="title to be shown when the user is not an admin"
                        values={{
                          name: resolveUserLabel(
                            showModal ? modalUser.id : undefined,
                            users,
                            intl
                          )
                        }}
                      />
                    </p>
                  </Feedback>
                )}
                {modalUser.isOrgAdmin && modalUser.id !== userId && (
                  <p>
                    <FormattedMessage
                      id="pages.users.unassign-admin-modal.copy"
                      defaultMessage="Are you sure you wish to unassign Admin access for {name}?"
                      values={{
                        name: resolveUserLabel(
                          showModal ? modalUser.id : undefined,
                          users,
                          intl
                        )
                      }}
                    />
                  </p>
                )}
              </>
            )}
            {showModal && !modalUser && (
              <Feedback type={"danger"} show={true} asChild={true}>
                <p>
                  <FormattedMessage
                    id="pages.users.unassign-admin-modal.not-found.copy"
                    defaultMessage="Something went wrong and the user could not be loaded. The user may have been removed or you don't have sufficient access rights."
                    description="title to be shown when there is no user to display"
                  />
                </p>
              </Feedback>
            )}
          </>
        </Modal>
      )}
      <Modal
        data-test-remove-user-dialog={
          showModal && showModal.userId ? showModal.userId : true
        }
        title={
          modalUser
            ? intl.formatMessage(messages.removeUserModalTitle)
            : intl.formatMessage(messages.removeUserModalNotFoundTitle)
        }
        show={showModal && showModal.key === ModalKeys.remove}
        onClose={onHideModal}
        primaryButton={
          modalUser && modalUser.id !== userId
            ? {
                label: intl.formatMessage(
                  messages.removeUserModalPrimaryButtonLabel
                )
              }
            : {
                label: intl.formatMessage(
                  messages.removeUserModalCloseButtonLabel
                )
              }
        }
        onPrimaryAction={() => {
          if (
            showModal &&
            showModal.userId &&
            modalUser &&
            modalUser.id !== userId
          ) {
            onRemoveUser(showModal.userId).then(
              res => {
                const firstError = findFirstAddErrorAction(res.results);
                if (firstError) {
                  onShowFeedback({
                    id: "remove_" + showModal.userId,
                    msg: intl.formatMessage(
                      messages.userRemovedFailureMessage,
                      {
                        name: resolveUserName(showModal.userId, users),
                        msg: firstError.error.apiError.error_description
                      }
                    ),
                    type: "danger"
                  });
                } else {
                  onShowFeedback({
                    id: "remove_" + showModal.userId,
                    msg: intl.formatMessage(
                      messages.userRemovedSuccessMessage,
                      {
                        name: resolveUserName(showModal.userId, users)
                      }
                    ),
                    autoclose: true,
                    type: "success"
                  });
                }
              },
              rej => {
                onShowFeedback({
                  id: "remove_" + showModal.userId,
                  msg: intl.formatMessage(messages.userRemovedFailureMessage, {
                    name: resolveUserName(showModal.userId, users)
                  }),
                  type: "danger"
                });
              }
            );
          }
          onHideModal();
        }}
        secondaryButton={
          modalUser && modalUser.id !== userId
            ? {
                label: intl.formatMessage(
                  messages.removeUserModalSecondaryButtonLabel
                )
              }
            : undefined
        }
        onSecondaryAction={
          modalUser && modalUser.id !== userId ? onHideModal : undefined
        }
      >
        <>
          {showModal && modalUser && (
            <>
              {modalUser.id === userId && (
                <Feedback type={"danger"} show={true} asChild={true}>
                  <p>
                    <FormattedMessage
                      id="pages.users.remove-user-modal.user-is-you.title"
                      defaultMessage="{name}, you're not allowed to remove yourself"
                      description="title to be shown when the user is you"
                      values={{
                        name: resolveUserLabel(
                          showModal ? modalUser.id : undefined,
                          users,
                          intl
                        )
                      }}
                    />
                  </p>
                </Feedback>
              )}
              {modalUser.id !== userId && (
                <p>
                  <FormattedMessage
                    id="pages.users.remove-user-modal.copy"
                    defaultMessage="Are you sure you wish to remove {name} from this organization?"
                    values={{
                      name: resolveUserLabel(
                        showModal ? modalUser.id : undefined,
                        users,
                        intl
                      )
                    }}
                  />
                </p>
              )}
            </>
          )}
          {showModal && !modalUser && (
            <Feedback type={"danger"} show={true} asChild={true}>
              <p>
                <FormattedMessage
                  id="pages.users.remove-user-modal.not-found.copy"
                  defaultMessage="Something went wrong and the user could not be loaded. The user may have been removed or you don't have sufficient access rights."
                  description="title to be shown when there is no user to display"
                />
              </p>
            </Feedback>
          )}
        </>
      </Modal>

      <Modal
        title={
          modalUser
            ? intl.formatMessage(messages.showUserModalTitle)
            : intl.formatMessage(messages.showUserModalUserNotFoundTitle)
        }
        show={showModal && showModal.key === ModalKeys.show}
        onClose={onHideModal}
        primaryButton={{
          label: intl.formatMessage(messages.showUserModalPrimaryButtonLabel)
        }}
        onPrimaryAction={onHideModal}
      >
        <UserDetails
          data-test-user-details
          user={
            showModal && showModal.key === ModalKeys.show
              ? modalUser
              : undefined
          }
        />
      </Modal>

      <Modal
        data-test-invite-user-dialog
        title={intl.formatMessage(messages.showInviteModalTitle)}
        show={showModal && showModal.key === ModalKeys.invite}
        onClose={onHideModal}
        primaryButton={{
          label: intl.formatMessage(messages.showInviteModalPrimaryButtonLabel),
          disabled:
            (errors !== undefined &&
              errors !== null &&
              Object.keys(errors).length > 0) ||
            !formState.dirty,
          tooltip:
            errors !== undefined &&
            errors !== null &&
            Object.keys(errors).length > 0
              ? intl.formatMessage(
                  messages.showInviteModalPrimaryButtonErrorTip
                )
              : !formState.dirty
              ? intl.formatMessage(
                  messages.showInviteModalPrimaryButtonNoChangesTip
                )
              : ""
        }}
        onPrimaryAction={() => {
          handleSubmit(data => {
            onSubmitInvitation(data).then(
              res => {
                onShowFeedback({
                  id: "invitation",
                  msg: intl.formatMessage(messages.invitationSuccessMessage, {
                    email: res.invitation.email
                  }),
                  autoclose: true,
                  type: "success"
                });
                onHideModal();
              },
              rej => {
                let message;
                if (rej.error) {
                  if (
                    rej.error.operationType === "CREATE_ORG_GROUP_INVITATION"
                  ) {
                    message = messages.createInvitationFailed;
                  } else if (
                    rej.error.operationType === "SEND_ORG_GROUP_INVITATION"
                  ) {
                    message = messages.sendInvitationFailed;
                  }
                }
                if (!message) {
                  message = messages.invitationFailureMessage;
                }
                onShowFeedback({
                  id: "invitation",
                  msg: intl.formatMessage(message),
                  type: "danger"
                });
                onHideModal();
              }
            );
          })();
        }}
        secondaryButton={{
          label: intl.formatMessage(
            messages.showInviteModalSecondaryButtonLabel
          )
        }}
        onSecondaryAction={onHideModal}
      >
        <>
          <Form noValidate>
            <Form.Group controlId="inviteeName">
              <Form.Label>
                {intl.formatMessage(messages.inviteInviteeNameFieldLabel)}
              </Form.Label>
              <Form.Control
                data-test-invite-user-dialog-recipient-name
                name="recipientName"
                ref={register({ required: true }) as RBRef}
                className={resolveValidityClassName("recipientName", errors)}
              />
              {errors &&
                errors.recipientName &&
                errors.recipientName.type &&
                errors.recipientName.type === "required" && (
                  <Form.Control.Feedback type="invalid">
                    {intl.formatMessage(messages.inviteInviteeNameRequired)}
                  </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group controlId="inviteeEmail">
              <Form.Label>
                {intl.formatMessage(messages.inviteInviteeEmailFieldLabel)}
              </Form.Label>
              <Form.Control
                data-test-invite-user-dialog-recipient-email
                name="email"
                ref={
                  register({
                    required: true /*
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: intl.formatMessage(
                        messages.inviteInviteeEmailInvalid
                      )
                    }*/
                  }) as RBRef
                }
                className={resolveValidityClassName("email", errors)}
              />
              {errors &&
                errors.email &&
                errors.email.type &&
                errors.email.type === "required" && (
                  <Form.Control.Feedback type="invalid">
                    {intl.formatMessage(messages.inviteInviteeEmailRequired)}
                  </Form.Control.Feedback>
                )}
              {errors &&
                errors.email &&
                errors.email.type &&
                errors.email.type === "pattern" && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email.message}
                  </Form.Control.Feedback>
                )}
            </Form.Group>
            {orgAdminRoleId && (
              <Form.Group controlId="assignAdmin">
                <Form.Check
                  data-test-invite-user-dialog-assign-admin
                  name="assignAdmin"
                  ref={register({ required: false }) as RBRef}
                  className={resolveValidityClassName("assignAdmin", errors)}
                  custom
                  type={"checkbox"}
                  label={intl.formatMessage(messages.inviteAssignAdminLabel)}
                />
              </Form.Group>
            )}
          </Form>
        </>
      </Modal>
      {/*
      <Modal
        data-test-suspend-member-dialog
        title={intl.formatMessage(messages.suspendUserModalTitle)}
        show={showModal && showModal.key === ModalKeys.suspend}
        onClose={onHideModal}
        primaryButton={
          modalUser && !isSuspended(modalUser)
            ? {
                label: intl.formatMessage(
                  messages.suspendUserModalPrimaryButtonLabel
                )
              }
            : {
                label: intl.formatMessage(
                  messages.suspendUserModalSecondaryButtonLabel
                )
              }
        }
        onPrimaryAction={() => {
          if (showModal && modalUser && !isSuspended(modalUser)) {
            onSuspendUser(modalUser.id as string).then(
              res => {
                onShowFeedback({
                  id: ("suspend_" + res.user.id) as string,
                  msg: intl.formatMessage(
                    messages.userSuspendedSuccessMessage,
                    {
                      name: resolveUserName(res.user.id as string, users)
                    }
                  ),
                  autoclose: true,
                  type: "success"
                });
              },
              rej => {
                onShowFeedback({
                  id: ("suspend_" + modalUser.id) as string,
                  msg: intl.formatMessage(
                    messages.userSuspendedFailureMessage,
                    {
                      name: resolveUserName(modalUser.id as string, users),
                      msg: rej.error.apiError.error_description
                    }
                  ),
                  type: "danger"
                });
              }
            );
          }
          onHideModal();
        }}
        secondaryButton={
          modalUser && !isSuspended(modalUser)
            ? {
                label: intl.formatMessage(
                  messages.suspendUserModalSecondaryButtonLabel
                )
              }
            : undefined
        }
        onSecondaryAction={modalUser && onHideModal}
      >
        <>
          {showModal && modalUser && (
            <>
              {!isSuspended(modalUser) && (
                <p>
                  <FormattedMessage
                    id="pages.users.suspend-user-modal.copy"
                    defaultMessage="Are you sure you wish to suspend {name}?"
                    values={{
                      name: resolveUserLabel(
                        showModal ? modalUser.id : undefined,
                        users,
                        intl
                      )
                    }}
                  />
                </p>
              )}
              {isSuspended(modalUser) && (
                <Feedback type={"danger"} show={true} asChild={true}>
                  <p>
                    <FormattedMessage
                      id="pages.users.suspend-user-modal.user-suspended.title"
                      defaultMessage="{name} is already suspended"
                      description="title to be shown when the user is already suspended"
                      values={{
                        name: resolveUserLabel(
                          showModal ? modalUser.id : undefined,
                          users,
                          intl
                        )
                      }}
                    />
                  </p>
                </Feedback>
              )}
            </>
          )}
          {showModal && !modalUser && (
            <Feedback type={"danger"} show={true} asChild={true}>
              <p>
                <FormattedMessage
                  id="pages.users.suspend-user-modal.not-found.title"
                  defaultMessage="Something went wrong and the user could not be loaded. The user may have been removed or you don't have sufficient access rights."
                  description="title to be shown when there is no user to display"
                />
              </p>
            </Feedback>
          )}
        </>
      </Modal>
      <Modal
        data-test-unsuspend-member-dialog
        title={intl.formatMessage(messages.unsuspendUserModalTitle)}
        show={showModal && showModal.key === ModalKeys.unsuspend}
        onClose={onHideModal}
        primaryButton={
          modalUser && isSuspended(modalUser)
            ? {
                label: intl.formatMessage(
                  messages.unsuspendUserModalPrimaryButtonLabel
                )
              }
            : {
                label: intl.formatMessage(
                  messages.unsuspendUserModalSecondaryButtonLabel
                )
              }
        }
        onPrimaryAction={() => {
          if (showModal && modalUser && isSuspended(modalUser)) {
            onUnsuspendUser(modalUser.id as string).then(
              res => {
                onShowFeedback({
                  id: ("suspend_" + res.user.id) as string,
                  msg: intl.formatMessage(
                    messages.userUnsuspendedSuccessMessage,
                    {
                      name: resolveUserName(res.user.id as string, users)
                    }
                  ),
                  autoclose: true,
                  type: "success"
                });
              },
              rej => {
                onShowFeedback({
                  id: ("suspend_" + showModal.userId) as string,
                  msg: intl.formatMessage(
                    messages.userUnsuspendedFailureMessage,
                    {
                      name: resolveUserName(showModal.userId as string, users),
                      msg: rej.error.apiError.error_description
                    }
                  ),
                  type: "danger"
                });
              }
            );
          }
          onHideModal();
        }}
        secondaryButton={
          modalUser && isSuspended(modalUser)
            ? {
                label: intl.formatMessage(
                  messages.unsuspendUserModalSecondaryButtonLabel
                )
              }
            : undefined
        }
        onSecondaryAction={modalUser && onHideModal}
      >
        <>
          {showModal && modalUser && (
            <>
              {isSuspended(modalUser) && (
                <p>
                  <FormattedMessage
                    id="pages.users.unsuspend-user-modal.copy"
                    defaultMessage="Are you sure you wish to unsuspend {name}?"
                    values={{
                      name: resolveUserLabel(
                        showModal ? modalUser.id : undefined,
                        users,
                        intl
                      )
                    }}
                  />
                </p>
              )}
              {!isSuspended(modalUser) && (
                <Feedback type={"danger"} show={true} asChild={true}>
                  <p>
                    <FormattedMessage
                      id="pages.users.unsuspend-user-modal.user-unsuspended.title"
                      defaultMessage="{name} is not suspended"
                      description="title to be shown when the user is not suspended"
                      values={{
                        name: resolveUserLabel(
                          showModal ? modalUser.id : undefined,
                          users,
                          intl
                        )
                      }}
                    />
                  </p>
                </Feedback>
              )}
            </>
          )}
          {showModal && !modalUser && (
            <Feedback type={"danger"} show={true} asChild={true}>
              <p>
                <FormattedMessage
                  id="pages.users.unsuspend-user-modal.not-found.title"
                  defaultMessage="Something went wrong and the user could not be loaded. The user may have been removed or you don't have sufficient access rights."
                  description="title to be shown when there is no user to display"
                />
              </p>
            </Feedback>
          )}
        </>
      </Modal>
      */}
      <UserLicensesModalView
        data-test-user-licenses
        show={showModal && showModal.key === ModalKeys.licenses}
        userId={modalUser ? modalUser.id : undefined}
        onClose={onHideModal}
      />
      <Modal
        data-test-revoke-invitation-dialog
        title={intl.formatMessage(messages.revokeInvitationModalTitle)}
        show={showModal && showModal.key === ModalKeys.revoke}
        onClose={onHideModal}
        primaryButton={
          modalUser && isInvitation(modalUser) && modalUser.status !== "revoked"
            ? {
                label: intl.formatMessage(
                  messages.revokeInvitationModalPrimaryButtonLabel
                )
              }
            : {
                label: intl.formatMessage(
                  messages.revokeInvitationModalSecondaryButtonLabel
                )
              }
        }
        onPrimaryAction={() => {
          if (
            showModal &&
            modalUser &&
            isInvitation(modalUser) &&
            modalUser.status !== "revoked"
          ) {
            onRevokeInvitation(
              (modalUser as any) as OrganizationGroupInvitation
            ).then(
              res => {
                onShowFeedback({
                  id: ("revoke_" + res.invitation.id) as string,
                  msg: intl.formatMessage(
                    messages.invitationRevokedSuccessMessage,
                    {
                      name: resolveUserName(res.invitation.id as string, users)
                    }
                  ),
                  autoclose: true,
                  type: "success"
                });
              },
              rej => {
                onShowFeedback({
                  id: ("revoke_" + modalUser.id) as string,
                  msg: intl.formatMessage(
                    messages.invitationRevokedFailureMessage,
                    {
                      name: resolveUserName(modalUser.id as string, users),
                      msg: rej.error.apiError.error_description
                    }
                  ),
                  type: "danger"
                });
              }
            );
          }
          onHideModal();
        }}
        secondaryButton={
          modalUser && isInvitation(modalUser) && modalUser.status !== "revoked"
            ? {
                label: intl.formatMessage(
                  messages.revokeInvitationModalSecondaryButtonLabel
                )
              }
            : undefined
        }
        onSecondaryAction={modalUser && onHideModal}
      >
        <>
          {showModal && modalUser && (
            <>
              {isInvitation(modalUser) &&
              modalUser.status !== "revoked" &&
              modalUser.status !== "declined" ? (
                <p>
                  <FormattedMessage
                    id="pages.users.revoke-invitation-modal.copy"
                    defaultMessage="Are you sure you wish to revoke {name}?"
                    values={{
                      name: resolveUserLabel(
                        showModal ? modalUser.id : undefined,
                        users,
                        intl
                      )
                    }}
                  />
                </p>
              ) : (
                undefined
              )}
              {isInvitation(modalUser) && modalUser.status === "declined" ? (
                <Feedback type={"danger"} show={true} asChild={true}>
                  <p>
                    <FormattedMessage
                      id="pages.users.revoke-invitation-modal.invitation-not-revokable-declined.title"
                      defaultMessage="Invitation for {name} has been declined and can no longer be revoked"
                      description="title to be shown when the invitation is not revokable due to it being declined"
                      values={{
                        name: resolveUserLabel(
                          showModal ? modalUser.id : undefined,
                          users,
                          intl
                        )
                      }}
                    />
                  </p>
                </Feedback>
              ) : (
                undefined
              )}
              {isInvitation(modalUser) && modalUser.status === "revoked" ? (
                <Feedback type={"danger"} show={true} asChild={true}>
                  <p>
                    <FormattedMessage
                      id="pages.users.revoke-invitation-modal.invitation-revoked.title"
                      defaultMessage="Invitation for {name} is already revoked"
                      description="title to be shown when the invitation is already revoked"
                      values={{
                        name: resolveUserLabel(
                          showModal ? modalUser.id : undefined,
                          users,
                          intl
                        )
                      }}
                    />
                  </p>
                </Feedback>
              ) : (
                undefined
              )}
              {!isInvitation(modalUser) && (
                <Feedback type={"danger"} show={true} asChild={true}>
                  <p>
                    <FormattedMessage
                      id="pages.users.revoke-invitation-modal.not-found.copy"
                      defaultMessage="No matching invitation found"
                      description="title to be shown when there is no invitation to display"
                    />
                  </p>
                </Feedback>
              )}
            </>
          )}
          {showModal && !modalUser && (
            <Feedback type={"danger"} show={true} asChild={true}>
              <p>
                <FormattedMessage
                  id="pages.users.revoke-invitation-modal.not-found.copy"
                  defaultMessage="No matching invitation found"
                  description="title to be shown when there is no invitation to display"
                />
              </p>
            </Feedback>
          )}
        </>
      </Modal>
      <Modal
        data-test-resend-invitation-dialog
        title={intl.formatMessage(messages.resendInvitationModalTitle)}
        show={showModal && showModal.key === ModalKeys.resend}
        onClose={onHideModal}
        primaryButton={
          modalUser &&
          isInvitation(modalUser) &&
          modalUser.status !== "revoked" &&
          modalUser.status !== "declined" &&
          modalUser.status !== "accepted"
            ? {
                label: intl.formatMessage(
                  messages.resendInvitationModalPrimaryButtonLabel
                )
              }
            : {
                label: intl.formatMessage(
                  messages.resendInvitationModalSecondaryButtonLabel
                )
              }
        }
        onPrimaryAction={() => {
          if (
            showModal &&
            modalUser &&
            isInvitation(modalUser) &&
            modalUser.status !== "revoked" &&
            modalUser.status !== "declined" &&
            modalUser.status !== "accepted"
          ) {
            onResendInvitation(
              (modalUser as any) as OrganizationGroupInvitation
            ).then(
              res => {
                onShowFeedback({
                  id: ("resend_" + res.invitation.id) as string,
                  msg: intl.formatMessage(
                    messages.invitationResendSuccessMessage,
                    {
                      name: resolveUserName(res.invitation.id as string, users)
                    }
                  ),
                  autoclose: true,
                  type: "success"
                });
              },
              rej => {
                onShowFeedback({
                  id: ("revoke_" + modalUser.id) as string,
                  msg: intl.formatMessage(
                    messages.invitationResendFailureMessage,
                    {
                      name: resolveUserName(modalUser.id as string, users),
                      msg: rej.error.apiError.error_description
                    }
                  ),
                  type: "danger"
                });
              }
            );
          }
          onHideModal();
        }}
        secondaryButton={
          modalUser &&
          isInvitation(modalUser) &&
          modalUser.status !== "revoked" &&
          modalUser.status !== "declined" &&
          modalUser.status !== "accepted"
            ? {
                label: intl.formatMessage(
                  messages.resendInvitationModalSecondaryButtonLabel
                )
              }
            : undefined
        }
        onSecondaryAction={modalUser && onHideModal}
      >
        <>
          {showModal && modalUser && (
            <>
              {isInvitation(modalUser) &&
                modalUser.status !== "revoked" &&
                modalUser.status !== "declined" &&
                modalUser.status !== "accepted" && (
                  <p>
                    <FormattedMessage
                      id="pages.users.resend-invitation-modal.copy"
                      defaultMessage="Resend the invitation for {name}?"
                      values={{
                        name: resolveUserLabel(
                          showModal ? modalUser.id : undefined,
                          users,
                          intl
                        )
                      }}
                    />
                  </p>
                )}
              {isInvitation(modalUser) &&
                (modalUser.status === "revoked" ||
                  modalUser.status === "declined" ||
                  modalUser.status === "accepted") && (
                  <Feedback type={"danger"} show={true} asChild={true}>
                    <p>
                      <FormattedMessage
                        id="pages.users.resend-invitation-modal.invitation-closed.title"
                        defaultMessage="Invitation for {name} is already closed and can no longer be resent."
                        description="title to be shown when the invitation is already closed"
                        values={{
                          name: resolveUserLabel(
                            showModal ? modalUser.id : undefined,
                            users,
                            intl
                          )
                        }}
                      />
                    </p>
                  </Feedback>
                )}
              {!isInvitation(modalUser) && (
                <Feedback type={"danger"} show={true} asChild={true}>
                  <p>
                    <FormattedMessage
                      id="pages.users.resend-invitation-modal.not-found.copy"
                      defaultMessage="No matching invitation found"
                      description="title to be shown when there is no invitation to display"
                    />
                  </p>
                </Feedback>
              )}
            </>
          )}
          {showModal && !modalUser && (
            <Feedback type={"danger"} show={true} asChild={true}>
              <p>
                <FormattedMessage
                  id="pages.users.resend-invitation-modal.not-found.copy"
                  defaultMessage="No matching invitation found"
                  description="title to be shown when there is no invitation to display"
                />
              </p>
            </Feedback>
          )}
        </>
      </Modal>
      <Modal
        data-test-remove-invitation-dialog={
          showModal && showModal.userId ? showModal.userId : true
        }
        title={intl.formatMessage(messages.removeInvitationModalTitle)}
        show={showModal && showModal.key === ModalKeys.removeInvitation}
        onClose={onHideModal}
        primaryButton={
          modalUser && isInvitation(modalUser)
            ? {
                label: intl.formatMessage(
                  messages.removeInvitationModalPrimaryButtonLabel
                )
              }
            : {
                label: intl.formatMessage(
                  messages.removeInvitationModalCloseButtonLabel
                )
              }
        }
        onPrimaryAction={() => {
          if (
            showModal &&
            modalUser &&
            showModal.userId &&
            isInvitation(modalUser)
          ) {
            onRemoveInvitation(showModal.userId).then(
              res => {
                onShowFeedback({
                  id: "removeInvitation_" + res.invitationId,
                  msg: intl.formatMessage(
                    messages.invitationRemovedSuccessMessage,
                    {
                      name: resolveUserName(res.invitationId, users)
                    }
                  ),
                  autoclose: true,
                  type: "success"
                });
              },
              rej => {
                onShowFeedback({
                  id: "removeInvitation_" + showModal.userId,
                  msg: intl.formatMessage(
                    messages.invitationRemovedFailureMessage,
                    {
                      name: resolveUserName(showModal.userId, users),
                      msg: rej.error.apiError.error_description
                    }
                  ),
                  type: "danger"
                });
              }
            );
          }
          onHideModal();
        }}
        secondaryButton={
          modalUser && isInvitation(modalUser)
            ? {
                label: intl.formatMessage(
                  messages.removeInvitationModalSecondaryButtonLabel
                )
              }
            : undefined
        }
        onSecondaryAction={modalUser && onHideModal}
      >
        <>
          {showModal && modalUser && isInvitation(modalUser) && (
            <p>
              <FormattedMessage
                id="pages.users.remove-invitation-modal.copy"
                defaultMessage="Are you sure you wish to remove the invitation for {name} from this organization?"
                values={{
                  name: resolveUserLabel(
                    showModal ? modalUser.id : undefined,
                    users,
                    intl
                  )
                }}
              />
            </p>
          )}
          {showModal && (!modalUser || !isInvitation(modalUser)) && (
            <Feedback type={"danger"} show={true} asChild={true}>
              <p>
                <FormattedMessage
                  id="pages.users.remove-invitation-modal.not-found.copy"
                  defaultMessage="No matching invitation found"
                  description="title to be shown when there is no user to display"
                />
              </p>
            </Feedback>
          )}
        </>
      </Modal>
    </Page>
  );
}

/**
 Not sure what this is, but seems to be required for the ref to function
 **/
type RBRef = string & ((ref: Element | null) => void);
export default Users;
