import React, { useState } from "react";
import UsersView, {
  UsersProps as _UsersProps,
  UsersStateProps,
  UsersDOMProps as _UsersDOMProps,
  UsersModalVisibilityProps as _UsersModalVisibilityProps,
  UserOrInvitation as _UserOrInvitation
} from "./users-view";
import { FeedbackEntry } from "../../feedback";
export { ModalKeys } from "./users-view";

export type UsersDOMProps = _UsersDOMProps;
export type UsersModalVisibilityProps = _UsersModalVisibilityProps;
export type UserOrInvitation = _UserOrInvitation;

export interface UsersProps extends Omit<_UsersProps, keyof UsersStateProps> {}

function Users(props: UsersProps) {
  const [selected, setSelected] = useState([] as UserOrInvitation[]);
  const [activeSearch, onSetActiveSearch] = useState("");
  const [feedback, setFeedback] = useState<FeedbackEntry[]>([]);
  const removeFeedback = (id: string) => {
    setFeedback(feedback.filter(val => val.id !== id));
  };
  const addFeedback = (f: FeedbackEntry) => {
    // filter out feedback with same id
    setFeedback(feedback.filter(val => val.id !== f.id).concat(f));
  };
  const viewProps: _UsersProps = {
    selected: selected,
    activeSearch: activeSearch,
    feedback: feedback,
    onSetActiveSearch: onSetActiveSearch,
    onSetSelected: setSelected,
    onShowFeedback: addFeedback,
    onHideFeedback: removeFeedback,
    ...props
  };
  return <UsersView {...viewProps} />;
}
export default Users;
