import React from "react";
import DOMPurify from "dompurify";
import { Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createPortal } from "react-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./feedback-view.scss";
export interface FeedbackEntry {
  id: string;
  msg: string;
  type: "danger" | "warning" | "success" | "light" | "dark" | "info";
  autoclose?: boolean;
}

export interface FeedbackProps extends React.HTMLAttributes<HTMLDivElement> {
  show: boolean;
  type:
    | "dark"
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light";
  autoClose?: boolean;
  onClose?: () => void;
  /*
   * true renders the element in the normal dom flow, false|undefined uses a notification portal target
   */
  asChild?: boolean;
}
//</editor-fold>

function Feedback(props: FeedbackProps) {
  //<editor-fold desc="Local variables">
  let {
    show,
    type,
    onClose,
    children,
    autoClose,
    asChild,
    dangerouslySetInnerHTML
  } = props;
  const icons = {
    dark: "info-circle",
    primary: "info-circle",
    secondary: "info-circle",
    success: "check",
    danger: "exclamation-triangle",
    warning: "exclamation-circle",
    info: "info-circle",
    light: "info-circle"
  };
  let className = "feedback ";
  if (autoClose) {
    className = "feedback auto-close shadow";
  } else if (!asChild) {
    className = "feedback shadow";
  }
  //</editor-fold>
  const cont = (
    <Alert
      data-test-feedback={type}
      variant={type}
      show={show}
      onClose={onClose}
      className={className}
      dismissible={!asChild}
    >
      <div className={"d-flex flex-row align-items-stretch"}>
        <div
          className={
            "feedback-icon d-flex align-items-center" +
            (children || dangerouslySetInnerHTML ? " pr-3 mr-3" : "")
          }
        >
          <FontAwesomeIcon
            icon={icons[type] as IconProp}
            size={!asChild ? "lg" : "2x"}
          />
        </div>
        {children && <div>{children}</div>}
        {dangerouslySetInnerHTML && (
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(dangerouslySetInnerHTML.__html)
            }}
          ></div>
        )}
      </div>
    </Alert>
  );
  const target = document.getElementById("notifications") as Element;
  if (asChild || !target) {
    return cont;
  } else {
    // display contents in portal to allow stacking notifications from different origins
    return createPortal(cont, target);
  }
}
export default Feedback;
