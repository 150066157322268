import { ProductItem } from "../model/entitlement/ProductItem";
import { LicenseFieldOpts } from "../model/entitlement/LicenseFieldOpts";
import { LicenseWithCredits } from "../model/entitlement/LicenseWithCredits";
import { LicenseUsage } from "../model/entitlement/LicenseUsage";
import { LicenseAssignment } from "../model/entitlement/LicenseAssignment";
import { QueryAvailableLicensesOpts } from "../model/entitlement/QueryAvailableLicensesOpts";
import { LicenseWithOwnerAndSingleUserAssignments } from "../model/entitlement/LicenseWithOwnerAndSingleUserAssignments";
import { LicenseOrder } from "../model/entitlement/LicenseOrder";
import { OrganizationGroup } from "../model/OrganizationGroup";

/**
 * Special value that can be used in some API operations to denote that object with any id is allowed.
 */
export const ID_ANY = "any";

export interface EntApi {
  /**
   * Initializes the entitlement implementation.
   * @param initProvider Function to provide required initialization parameters.
   */
  initialize(initProvider: (name: string) => string): void;

  /**
   * Gets product items of a product.
   * @param productId The product (product package) id.
   * @returns Array of ProductItem objects describing the licensed items as used
   *    in the product, or an empty list if no items configured for the product.
   */
  getProductItems(productId: string): Promise<ProductItem[]>;

  /**
   * Fulfills a new license order.
   * @param orgId The organization id.
   * @param licenseOrder The LicenseOrder.
   * @returns The fulfilled LicenseOrder.
   */
  fulfillLicenseOrder(
    orgId: string,
    licenseOrder: LicenseOrder
  ): Promise<LicenseOrder>;

  /**
   * Changes data of a fulfilled license order.
   * @param orgId The organization id.
   * @param licenseOrder The LicenseOrder.
   * @returns New version of the LicenseOrder after the operation.
   */
  changeLicenseOrder(
    orgId: string,
    licenseOrder: LicenseOrder
  ): Promise<LicenseOrder>;

  /**
   * Cancels a license order.
   * @param orgId The organization id.
   * @param orderId Id of the LicenseOrder.
   * @returns New version of the LicenseOrder after the operation (the cancelled order).
   */
  cancelLicenseOrder(orgId: string, orderId: string): Promise<LicenseOrder>;

  /**
   * Gets groups that have been added as consumers of licenses of the entitlement.
   * @param orgId The organization id.
   * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
   * @returns New version of the LicenseOrder after the operation (the cancelled order).
   */
  listLicenseConsumingOrgGroups(
    orgId: string,
    entId: string
  ): Promise<OrganizationGroup[]>;

  /**
   * Replaces current license consumer groups with new groups.
   * @param orgId The organization id.
   * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
   * @param groupIds The new organization group ids.
   */
  setLicenseConsumingOrgGroups(
    orgId: string,
    entId: string,
    groupIds: string[]
  ): Promise<void>;

  /**
   * Adds a group as a consumer of licenses of the entitlement.
   * @param orgId The organization id.
   * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
   * @param groupId Id of the group to add.
   */
  addLicenseConsumingOrgGroup(
    orgId: string,
    entId: string,
    groupId: string
  ): Promise<void>;

  /**
   * Removes a group from consumers of the entitlement.
   * @param orgId The organization id.
   * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
   * @param groupId Id of the group to remove.
   */
  removeLicenseConsumingOrgGroup(
    orgId: string,
    entId: string,
    groupId: string
  ): Promise<void>;

  /**
   * Lists licenses of the entitlement.
   * @param orgId The organization id.
   * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
   * @param licenseFieldOpts Options for including additional fields in the response.
   * @returns Array of LicenseWithCredits objects, fields of each object set according
   *    to the given licenseFieldOpts.
   */
  listEntitlementLicenses(
    orgId: string,
    entId: string,
    licenseFieldOpts?: LicenseFieldOpts
  ): Promise<LicenseWithCredits[]>;

  /**
   * Gets current usage of the license.
   * @param orgId The organization id.
   * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
   * @param licenseId Id of the license.
   * @returns LicenseUsage object describing usage of the given licese.
   */
  queryLicenseUsage(
    orgId: string,
    entId: string,
    licenseId: string
  ): Promise<LicenseUsage>;

  /**
   * Gets user's assignments to the license.
   * @param orgId The organization id.
   * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
   * @param licenseId Id of the license.
   * @param userId Id of the user.
   * @returns Array of LicenseAssignment object descriging user's assignments to the license,
   *    or an empty array if not assignments found.
   */
  getLicenseAssignments(
    orgId: string,
    entId: string,
    licenseId: string,
    userId: string
  ): Promise<LicenseAssignment[]>;

  /**
   * Manages user's license assigments.
   * @param orgId The organization id.
   * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
   * @param licenseId Id of the license.
   * @param userId Id of the user.
   * @param licenseAssignments New license assignments to set for the user to the license.
   * @returns Array of LicenseAssignment object descriging user's assignments to the license
   *    after the operation.
   */
  manageLicenseAssignments(
    orgId: string,
    entId: string,
    licenseId: string,
    userId: string,
    licenseAssignments: LicenseAssignment[]
  ): Promise<LicenseAssignment[]>;

  /**
   * Queries information of licenses available to a user.
   * @param userId Id of the user.
   * @param queryAvailableLicensesOpts Options for the operation.
   * @returns Array of objects with information of licenses available to the user,
   *    or an empty array if no licenses available.
   */
  queryAvailableLicenses(
    userId: string,
    queryAvailableLicensesOpts?: QueryAvailableLicensesOpts
  ): Promise<LicenseWithOwnerAndSingleUserAssignments[]>;

  /**
   * Moves a license from one entitlement to another.
   * @param orgId The organization id.
   * @param entId Id of the source entitlement.
   * @param licenseId Id of the license.
   * @param destEntId Id of the destination entitlement.
   * @param licenseFieldOpts Options for including additional fields in the response.
   * @return LicenseWithCredits object describing the license after the operation,
   *    fields of each object set according to the given licenseFieldOpts.
   */
  moveLicense(
    orgId: string,
    entId: string,
    licenseId: string,
    destEntId: string,
    licenseFieldOpts?: LicenseFieldOpts
  ): Promise<LicenseWithCredits>;
}
