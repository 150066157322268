import { IdpApi } from "../IdpApi";
import { Organization } from "../../model/Organization";
import { User } from "../../model/User";
import { OrganizationGroup } from "../../model/OrganizationGroup";
import { OrganizationRole } from "../../model/OrganizationRole";
import { OrganizationGroupInvitation } from "../../model/OrganizationGroupInvitation";
import { InternalPermissionWithGrantedActions } from "../../model/InternalPermissionWithGrantedActions";
import { PermissionGrantsForPermission } from "../../model/PermissionGrantsForPermission";

/**
 * Wraps another IdpApi implementation, just calling through to methods
 * of the wrapped IpdApi. This enables selectively overriding methods
 * to change / enhance behavior of the API.
 */
export default abstract class IdpApiWrapper implements IdpApi {
  private wrapped: IdpApi;

  constructor(wrapped: IdpApi) {
    this.wrapped = wrapped;
  }

  initialize(initProvider: (name: string) => string): void {
    this.getWrapped().initialize(initProvider);
  }

  protected getWrapped(): IdpApi {
    return this.wrapped;
  }

  public getAppOrganizations(userId?: string): Promise<Organization[]> {
    return this.getWrapped().getAppOrganizations(userId);
  }

  public getOrganization(orgId?: string): Promise<Organization> {
    return this.getWrapped().getOrganization(orgId);
  }

  public replaceOrganization(org: Organization): Promise<Organization> {
    return this.getWrapped().replaceOrganization(org);
  }

  public setMfaRequired(required: boolean, orgId?: string): Promise<void> {
    return this.getWrapped().setMfaRequired(required, orgId);
  }

  public listAllOrganizationUsers(orgId?: string): Promise<User[]> {
    return this.getWrapped().listAllOrganizationUsers(orgId);
  }

  public listOrganizationGroups(orgId?: string): Promise<OrganizationGroup[]> {
    return this.getWrapped().listOrganizationGroups(orgId);
  }

  public createOrganizationGroup(
    orgGroup: OrganizationGroup,
    orgId?: string
  ): Promise<OrganizationGroup> {
    return this.getWrapped().createOrganizationGroup(orgGroup, orgId);
  }

  public replaceOrganizationGroup(
    orgGroup: OrganizationGroup,
    orgId?: string
  ): Promise<OrganizationGroup> {
    return this.getWrapped().replaceOrganizationGroup(orgGroup, orgId);
  }

  public getOrganizationGroup(
    orgGroupId: string,
    orgId?: string
  ): Promise<OrganizationGroup> {
    return this.getWrapped().getOrganizationGroup(orgGroupId, orgId);
  }

  public deleteOrganizationGroup(
    orgGroupId: string,
    orgId?: string
  ): Promise<void> {
    return this.getWrapped().deleteOrganizationGroup(orgGroupId, orgId);
  }

  public listOrganizationRoles(orgId?: string): Promise<OrganizationRole[]> {
    return this.getWrapped().listOrganizationRoles(orgId);
  }

  public createOrganizationRole(
    orgRole: OrganizationRole,
    orgId?: string
  ): Promise<OrganizationRole> {
    return this.getWrapped().createOrganizationRole(orgRole, orgId);
  }

  public replaceOrganizationRole(
    orgRole: OrganizationRole,
    orgId?: string
  ): Promise<OrganizationRole> {
    return this.getWrapped().replaceOrganizationRole(orgRole, orgId);
  }

  public getOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<OrganizationRole> {
    return this.getWrapped().getOrganizationRole(orgRoleId, orgId);
  }

  public deleteOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<void> {
    return this.getWrapped().deleteOrganizationRole(orgRoleId, orgId);
  }

  public getUser(userId: string): Promise<User> {
    return this.getWrapped().getUser(userId);
  }

  public deleteUser(userId: string): Promise<void> {
    return this.getWrapped().deleteUser(userId);
  }

  public replaceUser(user: User): Promise<User> {
    return this.getWrapped().replaceUser(user);
  }

  public setUserSuspended(suspended: boolean, userId: string): Promise<User> {
    return this.getWrapped().setUserSuspended(suspended, userId);
  }

  public deleteOtpCredential(userId: string): Promise<void> {
    return this.getWrapped().deleteOtpCredential(userId);
  }

  public listOrganizationGroupsOfUser(
    userId: string
  ): Promise<OrganizationGroup[]> {
    return this.getWrapped().listOrganizationGroupsOfUser(userId);
  }

  public addOrganizationGroupForUser(
    groupId: string,
    userId: string
  ): Promise<void> {
    return this.getWrapped().addOrganizationGroupForUser(groupId, userId);
  }

  public removeOrganizationGroupOfUser(
    groupId: string,
    userId: string
  ): Promise<void> {
    return this.getWrapped().removeOrganizationGroupOfUser(groupId, userId);
  }

  public listUsersInOrganizationGroup(
    groupId: string,
    orgId?: string
  ): Promise<User[]> {
    return this.getWrapped().listUsersInOrganizationGroup(groupId, orgId);
  }

  public addUsersToOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    return this.getWrapped().addUsersToOrganizationGroup(
      groupId,
      userIds,
      orgId
    );
  }

  public setUsersInOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    return this.getWrapped().setUsersInOrganizationGroup(
      groupId,
      userIds,
      orgId
    );
  }

  public removeUsersFromOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    return this.getWrapped().removeUsersFromOrganizationGroup(
      groupId,
      userIds,
      orgId
    );
  }

  public listOrganizationsOrganizationGroupInvitations(
    orgId?: string
  ): Promise<OrganizationGroupInvitation[]> {
    return this.getWrapped().listOrganizationsOrganizationGroupInvitations(
      orgId
    );
  }

  public getOrganizationsOrganizationGroupInvitation(
    invitationId: string,
    orgId?: string
  ): Promise<OrganizationGroupInvitation> {
    return this.getWrapped().getOrganizationsOrganizationGroupInvitation(
      invitationId,
      orgId
    );
  }

  public createOrganizationGroupInvitation(
    invitation: OrganizationGroupInvitation
  ): Promise<OrganizationGroupInvitation> {
    return this.getWrapped().createOrganizationGroupInvitation(invitation);
  }

  public sendOrganizationGroupInvitation(
    invitationId: string
  ): Promise<OrganizationGroupInvitation> {
    return this.getWrapped().sendOrganizationGroupInvitation(invitationId);
  }

  public revokeOrganizationGroupInvitation(
    invitationId: string
  ): Promise<OrganizationGroupInvitation> {
    return this.getWrapped().revokeOrganizationGroupInvitation(invitationId);
  }

  public deleteOrganizationGroupInvitation(
    invitationId: string
  ): Promise<void> {
    return this.getWrapped().deleteOrganizationGroupInvitation(invitationId);
  }

  public listPermissionsOfOrganizationRole(
    orgRoleId: string
  ): Promise<InternalPermissionWithGrantedActions[]> {
    return this.getWrapped().listPermissionsOfOrganizationRole(orgRoleId);
  }

  public addPermissionsForOrganizationRole(
    grants: PermissionGrantsForPermission[],
    orgRoleId: string
  ): Promise<void> {
    return this.getWrapped().addPermissionsForOrganizationRole(
      grants,
      orgRoleId
    );
  }

  public setPermissionsOfOrganizationRole(
    grants: PermissionGrantsForPermission[],
    orgRoleId: string
  ): Promise<void> {
    return this.getWrapped().setPermissionsOfOrganizationRole(
      grants,
      orgRoleId
    );
  }

  public removePermissionsOfOrganizationRole(
    permissionIds: string[],
    orgRoleId: string
  ): Promise<void> {
    return this.getWrapped().removePermissionsOfOrganizationRole(
      permissionIds,
      orgRoleId
    );
  }

  public removePermissionOfOrganizationRole(
    permissionId: string,
    orgRoleId: string
  ): Promise<void> {
    return this.getWrapped().removePermissionOfOrganizationRole(
      permissionId,
      orgRoleId
    );
  }

  public listUsersInOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<User[]> {
    return this.getWrapped().listUsersInOrganizationRole(orgRoleId, orgId);
  }

  public addUsersToOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    return this.getWrapped().addUsersToOrganizationRole(
      orgRoleId,
      userIds,
      orgId
    );
  }

  public getUserInOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<User> {
    return this.getWrapped().getUserInOrganizationRole(
      orgRoleId,
      userId,
      orgId
    );
  }

  public addUserToOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<void> {
    return this.getWrapped().addUserToOrganizationRole(
      orgRoleId,
      userId,
      orgId
    );
  }

  public removeUsersFromOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    return this.getWrapped().removeUsersFromOrganizationRole(
      orgRoleId,
      userIds,
      orgId
    );
  }

  public removeUserFromOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<void> {
    return this.getWrapped().removeUserFromOrganizationRole(
      orgRoleId,
      userId,
      orgId
    );
  }

  public setUsersInOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    return this.getWrapped().setUsersInOrganizationRole(
      orgRoleId,
      userIds,
      orgId
    );
  }
}
