import React, { useState } from "react";
import LicensesView, {
  LicensesProps as _LicensesProps,
  LicensesDOMProps as _LicensesDOMProps,
  LicensesModalVisibilityProps as _LicensesModalVisibilityProps,
  LicensesStateProps
} from "./licenses-view";
import { LicenseWithCredits } from "../../../model/entitlement/LicenseWithCredits";
export { ModalKeys } from "./licenses-view";
export type LicensesModalVisibilityProps = _LicensesModalVisibilityProps;
export type LicensesDOMProps = _LicensesDOMProps;
export interface LicensesProps
  extends Omit<_LicensesProps, keyof LicensesStateProps> {}
function Licenses(props: LicensesProps) {
  // selection from the table
  const [selected, setSelected] = useState<LicenseWithCredits[]>([]);
  const [activeSearch, onSetActiveSearch] = useState("");
  const viewProps: _LicensesProps = {
    selected: selected,
    activeSearch: activeSearch,
    onSetActiveSearch: onSetActiveSearch,
    onSetSelected: setSelected,
    ...props
  };
  return <LicensesView {...viewProps} />;
}
export default Licenses;
