import * as ActionTypes from "./actionTypes";
import { ActionSender } from "../model/ActionSender";
import { removeUserFromOrganization } from "./orgUsersActions";
import {
  ensureSelectedOrgId,
  buildMultiActionThunk,
  EventOperation,
  EventOperationProvider
} from "./actionHelpers";
import { queryAvailableLicenses, manageLicenseAssignments } from "./entActions";

/**
 * Detach user from organization and set related reservations to floating.
 *
 * @param sender Sender of action.
 * @param userId ID of user to be detached.
 * @param orgId Target organization for detachment of user.
 */
export function detachUserFromOrganization(
  sender: ActionSender,
  userId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.MultiAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);

  return buildMultiActionThunk(
    sender,
    new DetachUserFromOrgProvider(sender, userId, orgIdOrDefault),
    false
  );
}

enum DetachUserState {
  INIT = 0,
  QUERY_AVAILABLE_LICENSES,
  MANAGE_LICENSE_ASSIGMENTS,
  REMOVE_FROM_ORGANIZATION,
  DONE
}

class DetachUserFromOrgProvider
  implements EventOperationProvider<ActionTypes.AppAction> {
  private state: DetachUserState;
  private sender: ActionSender;
  private userId: string;
  private orgId: string;
  private licenseManagementOperations: EventOperation<ActionTypes.AppAction>[];

  constructor(sender: ActionSender, userId: string, orgId: string) {
    this.state = DetachUserState.INIT;
    this.sender = sender;
    this.userId = userId;
    this.orgId = orgId;
    this.licenseManagementOperations = [];
  }

  /**
   * Method for providing next thunk/function to be executed in this chain.
   * @param sender
   * @param multiAction
   */
  next(
    sender: ActionSender,
    multiAction: ActionTypes.MultiAction
  ): EventOperation<ActionTypes.AppAction> | null {
    if (this.state === DetachUserState.INIT) {
      this.state = DetachUserState.QUERY_AVAILABLE_LICENSES;
      return {
        thunk: queryAvailableLicenses(this.sender, this.userId)
      };
    }

    if (this.state === DetachUserState.QUERY_AVAILABLE_LICENSES) {
      this.licenseManagementOperations = this.buildLicenseManagementOperations(
        multiAction.results[0] as ActionTypes.QueryAvailableLicensesAction
      );

      this.state = DetachUserState.MANAGE_LICENSE_ASSIGMENTS;
    }

    if (this.state === DetachUserState.MANAGE_LICENSE_ASSIGMENTS) {
      if (this.licenseManagementOperations.length !== 0) {
        return this.licenseManagementOperations.pop() as EventOperation<
          ActionTypes.AppAction
        >;
      } else {
        this.state = DetachUserState.REMOVE_FROM_ORGANIZATION;
      }
    }

    if (this.state === DetachUserState.REMOVE_FROM_ORGANIZATION) {
      this.state = DetachUserState.DONE;
      return {
        thunk: removeUserFromOrganization(this.sender, this.userId, this.orgId)
      };
    }

    return null;
  }

  /**
   * Build required license management operations based on data returned by getAvailableLicenses() action thunk.
   *
   * @param availableLicensesAction
   */
  private buildLicenseManagementOperations(
    availableLicensesAction: ActionTypes.QueryAvailableLicensesAction
  ): EventOperation<ActionTypes.AppAction>[] {
    const ops: EventOperation<ActionTypes.AppAction>[] = [];

    for (const license of availableLicensesAction.licenses) {
      if (!license.assignments || license.assignments.length === 0) {
        continue;
      }

      // Filter only reserved assigments.
      const assignments = license.assignments.filter(
        ass => ass.type && ass.type === "reserved"
      );

      // Mark reserved as floating.
      assignments.forEach(ass => (ass.type = "floating"));

      // Create thunks to execute.
      ops.push({
        thunk: manageLicenseAssignments(
          this.sender,
          "any",
          license.id as string,
          this.userId,
          assignments,
          this.orgId
        )
      });
    }

    return ops;
  }
}
