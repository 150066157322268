import React, { useEffect } from "react";
import Page, { PageDOMProps } from "../page";
import { useIntl, defineMessages } from "react-intl";
import Table from "../../table";
import { LicenseWithCredits } from "../../../model/entitlement/LicenseWithCredits";
import LicenseUsersModal from "../../license-users-modal";
import LicenseUtils from "../../../utils/licensed-item";
import { resolveFreeSeatsForLicense } from "../../../util/licenseUtil";

export enum ModalKeys {
  assign = "assign"
}

//<editor-fold desc="Props">

export interface LicensesDOMProps extends PageDOMProps {}
export interface LicensesStateProps {
  activeSearch?: string;
  onSetActiveSearch: (s: string) => void;
  selected: LicenseWithCredits[];
  onSetSelected: (selection: LicenseWithCredits[]) => void;
}
export interface LicensesModalVisibilityProps {
  showModal?: { key: ModalKeys; licenseId?: string };
  onShowModal: (key: ModalKeys, licenseId?: string) => void;
  onHideModal: () => void;
}
export interface LicensesProps
  extends LicensesStateProps,
    LicensesDOMProps,
    LicensesModalVisibilityProps {
  licenses?: LicenseWithCredits[];
  onLoadLicenses: () => void;
}
//</editor-fold>

const messages = defineMessages({
  columnLabelsProduct: {
    id: "pages.licenses.column-labels.product",
    defaultMessage: "Product"
  },
  columnLabelsSeatCount: {
    id: "pages.licenses.column-labels.seat-count",
    defaultMessage: "Total seats"
  },
  columnLabelsSeatsInUse: {
    id: "pages.licenses.column-labels.seats-in-use",
    defaultMessage: "Assigned"
  },
  columnLabelsSeatsNotInUse: {
    id: "pages.licenses.column-labels.seats-not-in-use",
    defaultMessage: "Unassigned"
  },
  title: {
    id: "pages.licenses.title",
    defaultMessage: "Manage Licenses",
    description: "Title for the Licenses screen"
  },
  description: {
    id: "pages.licenses.meta-description",
    defaultMessage: "Organization license management",
    description: "Description for the Licenses screen"
  },
  noLicenseName: {
    id: "pages.licenses.no-license-name",
    defaultMessage: "Unknown"
  }
});

function Licenses(props: LicensesProps) {
  //<editor-fold desc="Local variables">
  const {
    licenses,
    onLoadLicenses,
    onShowModal,
    onHideModal,
    showModal,
    selected,
    onSetSelected,
    activeSearch,
    onSetActiveSearch,
    ...other
  } = props;
  const intl = useIntl();
  const title = intl.formatMessage(messages.title);
  const description = intl.formatMessage(messages.description);
  const columns = [
    {
      key: "id",
      label: "id",
      hidden: true
    },
    {
      key: "licensedItem.displayName",
      label: intl.formatMessage(messages.columnLabelsProduct),
      sortable: true,
      rendererData: {
        resolveValue: (itm: any) => {
          return LicenseUtils.resolveDisplayName(
            itm.licensedItem,
            intl.formatMessage(messages.noLicenseName)
          );
        }
      },
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return <>{props.rendererData.resolveValue(props.row)}</>;
      }
    },
    {
      key: "seatsTotal",
      label: intl.formatMessage(messages.columnLabelsSeatCount),
      sortable: true
    },
    {
      key: "seatsTaken",
      label: intl.formatMessage(messages.columnLabelsSeatsInUse),
      sortable: true
    },
    {
      key: "unassignedSeats",
      isDummy: true,
      label: intl.formatMessage(messages.columnLabelsSeatsNotInUse),
      sortable: true,
      rendererData: {
        resolveValue: resolveFreeSeatsForLicense
      },
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return <>{props.rendererData.resolveValue(props.row)}</>;
      }
    }
  ];
  //</editor-fold>

  useEffect(() => {
    if (licenses === undefined) {
      onLoadLicenses();
    }
  }, [licenses, onLoadLicenses]);
  // resets selection when users are undefined, and clears out selected items that are not part of updated data
  useEffect(() => {
    if (selected.length) {
      if (licenses === undefined) {
        onSetSelected([]);
      } else {
        const ids = licenses.map(lic => lic.id);
        const newS = selected.filter((itm: any) => {
          return ids.indexOf(itm.id) >= 0;
        });
        if (newS.length !== selected.length) {
          onSetSelected(newS);
        }
      }
    }
  }, [licenses, selected, onSetSelected]);

  const handleClose = () => {
    onSetSelected([]);
    onHideModal();
  };
  const handleSelect = (sel: LicenseWithCredits[]) => {
    onSetSelected(sel);
    onShowModal(ModalKeys.assign, sel[0].id);
  };
  return (
    <Page
      data-test-licenses-page
      header={<h1>{title}</h1>}
      meta={{
        title,
        description
      }}
      {...other}
    >
      <Table<LicenseWithCredits>
        data-test-license-table
        selection={{
          multi: false,
          selectColumnType: "checkbox"
        }}
        selected={selected}
        onSelectionChanged={handleSelect}
        reload={true}
        onReload={onLoadLicenses}
        search={true}
        activeSearch={activeSearch}
        onSearch={onSetActiveSearch}
        columns={columns}
        data={licenses ? (licenses as LicenseWithCredits[]) : undefined}
        identifyingColumn={"id"}
        pagination={false}
      ></Table>
      <LicenseUsersModal
        licenseId={showModal ? showModal.licenseId : undefined}
        show={showModal && showModal.key === ModalKeys.assign}
        onClose={handleClose}
      />
    </Page>
  );
}
export default Licenses;
