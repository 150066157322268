import { connect } from "react-redux";
import { AppState } from "../../store/AppState";
import UserLicensesModalView, {
  UserLicensesModalDataProps,
  UserLicensesModalVisibilityProps,
  UserLicensesModalProps as _UserLicensesModalProps
} from "./user-licenses-modal-container";
import {
  manageLicenseAssignments,
  queryAvailableLicenses
} from "../../actions/entActions";
import { queryAvailableLicensesWithUsage } from "../../actions/queryAvailableLicensesWithUsageMultiAction";
import {
  getUserLicensedItemAssignments,
  sortUserLicensedItemAssignmentsDataForManageReservation
} from "../../util/licenseUtil";
import { UserLicensedItemAssignments } from "../../model/entitlement/UserLicensedItemAssignments";
import {
  PickReduxStateProps,
  PickReduxDispatchProps
} from "../../util/typeUtil";
import { getUser, waitForDispatch } from "../../actions";
import * as ActionTypes from "../../actions/actionTypes";
import store from "../../store";
import OrganizationUtils from "../../utils/organization";

const sender = { key: "users-licenses-modal" };

// Own props, i.e. props that this component takes as input
export interface UserLicensesModalProps
  extends UserLicensesModalDataProps,
    UserLicensesModalVisibilityProps {}

type ReduxStateProps = PickReduxStateProps<
  _UserLicensesModalProps,
  UserLicensesModalProps
>;
type ReduxDispatchProps = PickReduxDispatchProps<
  _UserLicensesModalProps,
  UserLicensesModalProps
>;

function mapStateToProps(
  state: AppState,
  ownProps: UserLicensesModalProps
): ReduxStateProps {
  const { userId } = ownProps;
  let user;
  let licenses = undefined;
  if (userId) {
    user = state && state.users ? state.users[userId] : undefined;
    const org = OrganizationUtils.resolveSelectedFromState(state);
    licenses = getUserLicensedItemAssignments(state, userId, org?.id);
    if (licenses) {
      licenses = licenses.map(l => {
        return sortUserLicensedItemAssignmentsDataForManageReservation(l);
      });
    }
  }

  return {
    user: user,
    licenses: licenses
  };
}
function mapDispatchToProps(
  dispatch: (t: any) => void,
  ownProps: UserLicensesModalProps
): ReduxDispatchProps {
  return {
    onLoadUser: onLoadUserImpl,
    onApply: onApplyImpl,
    onLoadLicenses: onLoadLicensesImpl
  };
}

function onLoadUserImpl(userId: string) {
  if (userId) {
    store.dispatch(getUser(sender, userId));
  }
}

async function onApplyImpl(
  lics: UserLicensedItemAssignments[] | undefined,
  userId: string
): Promise<Array<ActionTypes.ManageUserLicenseAssignmentsAction | any>> {
  if (lics && userId) {
    const actions = lics.map(async lic => {
      if (userId) {
        const action = await waitForDispatch<
          ActionTypes.ManageUserLicenseAssignmentsAction
        >(
          manageLicenseAssignments(
            sender,
            "any",
            lic.assignments[0].license.id as string,
            userId,
            lic.assignments[0].assignments
          ),
          store.dispatch
        );

        // trigger load to update changed redux state
        store.dispatch(queryAvailableLicenses(sender, userId));
        return action;
      }
    });
    return Promise.resolve(
      Promise.allSettled(actions).then(res => {
        return res.map(r => (r.status === "fulfilled" ? r.value : r.reason));
      })
    );
  } else {
    return Promise.resolve([undefined]);
  }
}

function onLoadLicensesImpl(userId: string) {
  if (userId) {
    store.dispatch(queryAvailableLicensesWithUsage(sender, userId));
  }
}

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  UserLicensesModalProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps
)(UserLicensesModalView);
