import React, { ReactNode } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/Overlay";
import "./tooltip-trigger-view.scss";
//<editor-fold desc="Props">
export interface TooltipTriggerProps {
  tipKey: string;
  tip?: string;
  wrapDisabled?: boolean;
  children: ReactNode;
  placement?: Placement;
}
//</editor-fold>

function TooltipTrigger(props: any) {
  const { tipKey, tip, wrapDisabled, children, placement, className } = props;
  let content;
  if (wrapDisabled) {
    content = (
      <div className={"tooltip-disabled-wrapper " + className}>{children}</div>
    );
  } else {
    content = children;
  }
  return (
    <>
      {tip && (
        <OverlayTrigger
          placement={placement || "auto"}
          key={tipKey}
          overlay={<Tooltip id={tipKey + `-tip`}>{tip}</Tooltip>}
          popperConfig={{
            modifiers: [
              {
                name: "flip",
                options: {
                  boundary: "viewport"
                }
              },
              {
                name: "preventOverflow",
                options: {
                  boundariesElement: "viewport",
                  altAxis: true
                }
              }
            ]
          }}
        >
          {content}
        </OverlayTrigger>
      )}
      {!tip && children}
    </>
  );
}
export default TooltipTrigger;
