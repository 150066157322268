import * as ActionTypes from "../actions/actionTypes";
import { UserOrgGroupIdsByUserId } from "../store/UserState";

export default function userOrgGroupIds(
  state: UserOrgGroupIdsByUserId,
  action: ActionTypes.AppAction
): UserOrgGroupIdsByUserId | null {
  const currentState = state || ({} as UserOrgGroupIdsByUserId);
  switch (action.type) {
    case ActionTypes.LIST_ORG_GROUPS_OF_USER:
      const listOrgGroupsOfUser = action as ActionTypes.ListOrgGroupsOfUserAction;
      const orgGroupIds = listOrgGroupsOfUser.groups.map(
        group => group.id as string
      );
      return { ...currentState, [listOrgGroupsOfUser.userId]: orgGroupIds };
    case ActionTypes.ADD_USERS_TO_ORG_GROUP:
      const addUsersToOrgGroup = action as ActionTypes.AddUsersToOrgGroupAction;
      let afterAddUsersToOrgGroup = currentState;
      for (const userIdToAdd of addUsersToOrgGroup.userIds) {
        const beforeAddUsers = afterAddUsersToOrgGroup[userIdToAdd] || [];
        const afterAddUsers = [
          ...beforeAddUsers,
          addUsersToOrgGroup.orgGroupId
        ];
        afterAddUsersToOrgGroup = {
          ...afterAddUsersToOrgGroup,
          [userIdToAdd]: afterAddUsers
        };
      }
      return afterAddUsersToOrgGroup;
    case ActionTypes.REMOVE_USERS_FROM_ORG_GROUP:
      const removeUsersFromOrgGroup = action as ActionTypes.RemoveUsersFromOrgGroupAction;
      let afterRemoveUsersFromOrgGroup = currentState;
      for (const userIdToRemove of removeUsersFromOrgGroup.userIds) {
        const beforeRemoveUsers =
          afterRemoveUsersFromOrgGroup[userIdToRemove] || [];
        const afterRemoveUsers = beforeRemoveUsers.filter(
          orgGroupId => orgGroupId !== removeUsersFromOrgGroup.orgGroupId
        );
        afterRemoveUsersFromOrgGroup = {
          ...afterRemoveUsersFromOrgGroup,
          [userIdToRemove]: afterRemoveUsers
        };
      }
      return afterRemoveUsersFromOrgGroup;
    case ActionTypes.ADD_ORG_GROUP_FOR_USER:
      const addOrgGroupForUser = action as ActionTypes.AddOrgGroupForUserAction;
      const beforeAddGroup = currentState[addOrgGroupForUser.userId] || [];
      const afterAddGroup = [...beforeAddGroup, addOrgGroupForUser.orgGroupId];
      return { ...currentState, [addOrgGroupForUser.userId]: afterAddGroup };
    case ActionTypes.REMOVE_ORG_GROUP_OF_USER:
      const removeOrgGroupOfUser = action as ActionTypes.RemoveOrgGroupOfUserAction;
      const beforeRemoveGroup = currentState[removeOrgGroupOfUser.userId] || [];
      const afterRemoveGroup = beforeRemoveGroup.filter(
        orgGroupId => removeOrgGroupOfUser.orgGroupId !== orgGroupId
      );
      return {
        ...currentState,
        [removeOrgGroupOfUser.userId]: afterRemoveGroup
      };
    case ActionTypes.DELETE_USER:
      const deleteUser = action as ActionTypes.DeleteUserAction;
      const { [deleteUser.userId]: _, ...remaining } = currentState;
      return remaining;
    case ActionTypes.REMOVE_USER_FROM_ORG:
      const removeUserFromOrg = action as ActionTypes.RemoveUserFromOrgAction;
      if (removeUserFromOrg.userId in currentState) {
        return {
          ...currentState,
          [removeUserFromOrg.userId]: currentState[
            removeUserFromOrg.userId
          ].filter(
            userGroupId =>
              removeUserFromOrg.orgGroupIds.indexOf(userGroupId) === -1
          )
        };
      }
      return currentState;
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}
