import { connect } from "react-redux";
import { AppState } from "../../store/AppState";
import LicenseUsersModal, {
  LicenseUsersModalProps as _LicenseUsersModalProps
} from "./license-users-modal-container";
import {
  manageUsersLicenseAssignments,
  queryAvailableLicenses,
  queryLicenseUsage
} from "../../actions/entActions";
import {
  getLicenseAssignmentsForManageReservations,
  getOrganizationUsersByLicenseReservation,
  LicenseUsersByReservation,
  populateLicenseAssignments
} from "../../util/licenseUtil";
import * as ActionTypes from "../../actions/actionTypes";
import { Organization } from "../../model/Organization";
import OrganizationUtils from "../../utils/organization";
import { listOrganizationUsers, waitForDispatch } from "../../actions";
import { LicensesById } from "../../store/LicenseState";
import { LicenseUsage } from "../../model/entitlement/LicenseUsage";
import { ActionSender } from "../../model/ActionSender";
import {
  resolveErrorsByOperationType,
  resolveErrorsBySenderFromState
} from "../../utils/error";
import { QUERY_LICENSE_USAGE } from "../../actions/actionTypes";
import {
  LicenseUsersModalLicenseIdProps,
  LicenseUsersModalVisibilityProps
} from "./license-users-modal-view";
import {
  PickReduxDispatchProps,
  PickReduxStateProps
} from "../../util/typeUtil";
import store from "../../store";

// Own props, i.e. props that this component takes as input
interface LicenseUsersModalProps
  extends LicenseUsersModalLicenseIdProps,
    LicenseUsersModalVisibilityProps {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _LicenseUsersModalProps,
  LicenseUsersModalProps
>;
// Dispatch props provided to the wrapped component by this connect component, using mapDispatchToProps
type ReduxDispatchProps = PickReduxDispatchProps<
  _LicenseUsersModalProps,
  LicenseUsersModalProps
>;

const sender: ActionSender = { key: "license-users-modal" };

function mapStateToProps(
  state: AppState,
  ownProps: LicenseUsersModalProps
): ReduxStateProps {
  const { licenseId } = ownProps;
  const org:
    | Organization
    | undefined = OrganizationUtils.resolveSelectedFromState(state);
  const myErrors = resolveErrorsBySenderFromState(state, sender);
  let licenseLoadErrors = resolveErrorsByOperationType(
    myErrors,
    QUERY_LICENSE_USAGE
  );
  if (licenseId && licenseLoadErrors && licenseLoadErrors.length > 0) {
    licenseLoadErrors = licenseLoadErrors.filter(err => {
      return (
        (err.action as ActionTypes.QueryLicenseUsageAction).licenseId ===
        licenseId
      );
    });
  }
  let users = undefined;
  let license = undefined;
  if (licenseId && org) {
    license = state.licenses
      ? ((state.licenses as LicensesById)[licenseId] as LicenseUsage)
      : undefined;
    if (license) {
      license = populateLicenseAssignments(license, state);
      // this requires the license, so no point in trying without it
      users = getOrganizationUsersByLicenseReservation(
        org.id as string,
        licenseId,
        state
      );
    } else if (licenseLoadErrors && licenseLoadErrors.length > 0) {
      license = null;
      users = null;
    }
  }

  return {
    errors: myErrors,
    users: users,
    license: license
  };
}
function mapDispatchToProps(
  dispatch: (t: any) => void,
  ownProps: LicenseUsersModalProps
): ReduxDispatchProps {
  return {
    onApply: onApplyImpl,
    onLoadLicense: onLoadLicenseImpl,
    onLoadUsers: onLoadUsersImpl
  };
}

function onLoadLicenseImpl(licenseId: string) {
  if (licenseId) {
    store.dispatch(queryLicenseUsage(sender, "any", licenseId));
  }
}

function onLoadUsersImpl(licenseId: string) {
  if (licenseId) {
    store.dispatch(listOrganizationUsers(sender));
    store.dispatch(queryLicenseUsage(sender, "any", licenseId));
  }
}

async function onApplyImpl(
  usrs: LicenseUsersByReservation | undefined
): Promise<ActionTypes.ManageUsersLicenseAssignmentsAction | undefined> {
  if (usrs) {
    const changed = getLicenseAssignmentsForManageReservations(
      usrs.usersWithReservation,
      usrs.usersWithoutReservation
    );
    const result = await waitForDispatch<
      ActionTypes.ManageUsersLicenseAssignmentsAction
    >(
      manageUsersLicenseAssignments(sender, "any", usrs.licenseId, changed),
      store.dispatch
    );
    // trigger load to update changed redux state
    Object.keys(changed).forEach(v => {
      store.dispatch(queryAvailableLicenses(sender, v));
    });
    return result;
  } else {
    return Promise.resolve(undefined);
  }
}

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  LicenseUsersModalProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps
)(LicenseUsersModal);
