import React, { ReactNode } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";

import "./table-view.scss";
import { Alert, Button, ButtonGroup, Dropdown, Form } from "react-bootstrap";
import RowToolsToggle from "./row-tools-toggle";
import ColumnTools from "./column-tools";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ApplyInput from "../apply-input";
import TooltipTrigger from "../tooltip-trigger";

//<editor-fold desc="Props">
export interface TableColumn {
  /**
   * The data field this column refers to
   */
  key: string;
  /**
   * Label for the column
   */
  label: string;
  /**
   * Marks the column as technical. Technical columns are never to be shown to the user, but the data may still be required.
   */
  isTechnical?: boolean;
  /**
   * Toggles the visibility of the column. Eg. id is commonly a required identification column, but there's little point in visualising the uuid in the table
   */
  hidden?: boolean;
  /**
   * Toggles the availability of sort functionality
   */
  sortable?: boolean;
  /**
   * Marks the column as dummy, ie. has no data field
   */
  isDummy?: boolean;
  /**
   * Custom renderer for the cell
   * @param props
   *  props.cell Either the data for the cell, or some metadata describing the cell. Undocumented in the underlying component. Needs to be clarified once figured out.
   *  props.row The whole data entry that makes up the row.
   *  props.rowIndex Index of the row within the table.
   *  props.rendererData Additional data that can be passed into the renderer by populating the rendererData field for the column definition.
   */
  renderer?: (props: {
    cell: any;
    row: any;
    rowIndex: Number;
    rendererData: any;
  }) => ReactNode;
  /**
   * Custom renderer for the cell tooltip
   * @param props
   *  props.cell Either the data for the cell, or some metadata describing the cell. Undocumented in the underlying component. Needs to be clarified once figured out.
   *  props.row The whole data entry that makes up the row.
   *  props.rowIndex Index of the row within the table.
   *  props.rendererData Additional data that can be passed into the renderer by populating the rendererData field for the column definition.
   */
  tipRenderer?: (props: {
    cell: any;
    row: any;
    rowIndex: Number;
    rendererData: any;
  }) => ReactNode;
  /**
   * Custom data passed to the renderer function.
   * The return value of a member resolveValue: (row:any) => any is used for searching, if available and column is marked as isDummy: true
   */
  rendererData?: any;
}

interface TableDOMProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "children"> {}
export interface TableStateProps {
  onSort: (column: string, ascending: boolean) => void;
  onToggleColumn?: (key: string, visible: boolean) => void;
}
interface TableSortProps {
  /**
   * Active sort for the table.
   */
  sort?: {
    /**
     * data entry member
     */
    column: string;
    /**
     * toggles the sort order
     */
    ascending: boolean;
  };
}
export interface TableProps<D>
  extends TableStateProps,
    TableSortProps,
    TableDOMProps {
  /**
   *
   */
  search?: boolean;
  /**
   * Allows for the visible column selection to be controlled
   */
  columnToggle?: boolean;
  /**
   * Toggles the reload button visibility
   */
  reload?: boolean;
  /**
   * Tools to be included as dropdown menu items within a tools column.
   * @param props
   *   props.rowEntry The data entry for the row in question.
   * @returns JSX fragment containing the dropdown menuitems
   *
   */
  rowTools?: (props: { rowEntry: D }) => ReactNode;
  /**
   * Row class resolver
   * @param row
   * @param rowIndex
   */
  rowClasses?: (row: D, rowIndex: number) => string | undefined;
  /**
   * toggles the pagination buttons
   */
  pagination: boolean;
  /**
   * Optional header element to include with the table
   */
  header?: ReactNode;
  /**
   * Defines the selection model and state. This table is stateless so it's the callers responsibility to handle the selection state.
   */
  selection?: {
    /**
     * toggles the selection controls
     */
    fixed?: boolean;
    /**
     * toggles multi vs single select
     */
    multi?: boolean;
    /**
     * Toggles select all button visibility
     */
    selectAll?: boolean;
    /**
     *
     */
    selectColumnType?: "checkbox" | "radio";
  };
  /**
   * Sets selection state.
   */
  selected?: D[];
  /**
   * Select callback.
   * @param selection
   */
  onSelectionChanged?: (selection: D[]) => void;
  /**
   * Handler for data reload
   */
  onReload?: () => void;
  /**
   * Data entries for the table
   */
  data: D[] | undefined;

  /**
   * Name of the data entry member that is used as row identification. Usually 'id'
   */
  identifyingColumn: string;
  /**
   * Defines the columns for the table
   */
  columns: Array<TableColumn>;
  onSearch?: (s: string) => void;
  activeSearch?: string;
}

interface AsRBT2ColumnProps
  extends Pick<TableStateProps, "onSort">,
    TableSortProps {}
//</editor-fold>

//<editor-fold desc="Utils">
/**
 * Converts sorting props to react-bootstrap-table2 format
 * @param sort
 */
function asRBT2DefaultSorted(sort?: { column: string; ascending: boolean }) {
  return {
    dataField: sort ? sort.column : undefined,
    order: sort ? (sort.ascending ? "asc" : "desc") : undefined,
    sortCaret: (order: string, column: any) => {
      return (
        <span className={"duke-sort fa-layers fa-fw"}>
          <FontAwesomeIcon icon={"sort"}></FontAwesomeIcon>
          {order === "asc" && (
            <FontAwesomeIcon
              icon={"sort-up"}
              className={"active"}
            ></FontAwesomeIcon>
          )}
          {order === "desc" && (
            <FontAwesomeIcon
              icon={"sort-down"}
              className={"active"}
            ></FontAwesomeIcon>
          )}
        </span>
      );
    }
  };
}

/**
 * Converts column props to react-bootstrap-table2 format
 * @param col
 * @param props
 */
function asRBT2Column(col: TableColumn, props: AsRBT2ColumnProps): any {
  const headerAndCellClasses = () => {
    const retVal = [];
    if (props.sort && props.sort.column === col.key) {
      retVal.push("active-sort");
    }
    return retVal.length ? retVal.join(" ") : undefined;
  };
  return {
    dataField: col.key,
    text: col.label,
    sort: col.sortable === true,
    onSort: (field: any, order: any) => {
      props.onSort(field, order === "asc");
    },
    hidden: col.hidden || col.isTechnical,
    isDummyField: col.isDummy === true,
    classes: (cell: any, row: any, rowIndex: any, colIndex: any) => {
      return headerAndCellClasses();
    },
    headerClasses: (column: any, colIndex: any) => {
      return headerAndCellClasses();
    },
    formatter: (cell: any, row: any, rowIndex: number, rendererData: any) => {
      // values must be converted to strings here.
      const tipRenderer = col.tipRenderer || col.renderer;
      return (
        <TooltipTrigger
          tip={
            tipRenderer
              ? tipRenderer({ cell, row, rowIndex, rendererData })
              : "" +
                (cell as string) /* <- does not make much sense but some how the string concat is required, or the tip will fail to render properly*/
          }
          tipKey={rowIndex + "_" + col.key}
        >
          <div className={"duke-cell"}>
            {col.renderer
              ? col.renderer({ cell, row, rowIndex, rendererData })
              : "" + (cell !== undefined ? (cell as string) : "")}
          </div>
        </TooltipTrigger>
      );
    },
    formatExtraData: col.rendererData ? col.rendererData : undefined,
    headerFormatter: (column: any, columnIndex: number, components: any) => {
      return (
        <TooltipTrigger
          tip={column.text}
          tipKey={column.dataField}
          placement={"auto"}
        >
          <div className={"duke-header-cell"}>
            {components && components.sortElement && (
              <div className={"duke-column-tools"}>
                {components.sortElement}
              </div>
            )}
            <div className={"duke-cell"}>{column.text}</div>
          </div>
        </TooltipTrigger>
      );
    }
  };
}
//</editor-fold>

//<editor-fold desc="Messages">
const messages = defineMessages({
  paginationLabelFirstPage: {
    id: "table.pagination.first-page-label",
    defaultMessage: "First",
    description: "the text of first page button"
  },
  paginationLabelPreviousPage: {
    id: "table.pagination.previous-page-label",
    defaultMessage: "Prev",
    description: "the text of previous page button"
  },
  paginationLabelNextPage: {
    id: "table.pagination.next-page-label",
    defaultMessage: "Next",
    description: "the text of next page button"
  },
  paginationLabelLastPage: {
    id: "table.pagination.last-page-label",
    defaultMessage: "Last",
    description: "the text of last page button"
  },
  paginationTipNextPage: {
    id: "table.pagination.next-page-tooltip",
    defaultMessage: "Go to next",
    description: "the tooltip of next page button"
  },
  paginationTipPreviousPage: {
    id: "table.pagination.previous-page-tooltip",
    defaultMessage: "Go to previous",
    description: "the tooltip of previous page button"
  },
  paginationTipFirstPage: {
    id: "table.pagination.first-page-tooltip",
    defaultMessage: "Go to first",
    description: "the tooltip of first page button"
  },
  paginationTipLastPage: {
    id: "table.pagination.last-page-tooltip",
    defaultMessage: "Go to last",
    description: "the tooltip of last page button"
  },
  toolsReloadTip: {
    id: "table.tools.reload-tooltip",
    defaultMessage: "Reload data",
    description: "the tooltip of reload button"
  },
  toolsSearchApplyLabel: {
    id: "table.tools.search-apply-label",
    defaultMessage: "Search",
    description: "the label of the search button"
  },
  toolsSearchClearDirtyInputTip: {
    id: "table.tools.search-clear-dirty-tip",
    defaultMessage: "Clear unapplied search input",
    description: "the tooltip of the clear unapplied input icon button"
  },
  toolsSearchClearInputTip: {
    id: "table.tools.search-clear-tip",
    defaultMessage: "Clear search",
    description: "the tooltip of the clear search icon button"
  }
});
//</editor-fold>
function Table<D>(props: TableProps<D>) {
  //<editor-fold desc="Local variables">
  let retVal;
  const intl = useIntl();
  const paginationTexts = {
    firstPageText: intl.formatMessage(messages.paginationLabelFirstPage),
    prePageText: intl.formatMessage(messages.paginationLabelPreviousPage),
    nextPageText: intl.formatMessage(messages.paginationLabelNextPage),
    lastPageText: intl.formatMessage(messages.paginationLabelLastPage),
    nextPageTitle: intl.formatMessage(messages.paginationTipNextPage),
    prePageTitle: intl.formatMessage(messages.paginationTipPreviousPage),
    firstPageTitle: intl.formatMessage(messages.paginationTipFirstPage),
    lastPageTitle: intl.formatMessage(messages.paginationTipLastPage)
  };
  let {
    className,
    columns,
    data,
    identifyingColumn,
    sort,
    onSort,
    pagination,
    selection,
    rowTools,
    header,
    columnToggle,
    onToggleColumn,
    reload,
    onReload,
    search,
    activeSearch,
    onSearch,
    onSelectionChanged,
    selected,
    rowClasses,
    ...other
  } = props;
  if (selection && !selection.fixed && !onSelectionChanged) {
    throw new Error(
      "Invalid Table props (view): onSelectionChanged is required if the selection is not fixed"
    );
  }
  if (columnToggle && !onToggleColumn) {
    throw new Error(
      "Invalid Table props (view): toggleColumn is required if columnToggle is allowed"
    );
  }
  if (reload && !onReload) {
    throw new Error(
      "Invalid Table props (view): onReload is required if reload is allowed"
    );
  }
  if (search && !onSearch) {
    throw new Error(
      "Invalid Table props (view): onSearch is required if search is allowed"
    );
  }
  const cols = columns.map((val: TableColumn, ind: number) => {
    return asRBT2Column(val, {
      sort: sort,
      onSort: onSort
    });
  });
  if (rowTools) {
    // add tools column as last column
    cols.push({
      dataField: "rowTools",
      headerClasses: "row-tools-cell",
      text: "",
      isDummyField: true,
      formatter: (cell: any, row: any, rowIndex: number, rendererData: any) => {
        return (
          <Dropdown alignRight>
            <Dropdown.Toggle
              as={RowToolsToggle}
              id={"rowTools_" + rowIndex}
              data-test-row-tool-trigger
            />
            <Dropdown.Menu data-test-row-tool-items>
              {rowTools ? rowTools({ rowEntry: row }) : null}
            </Dropdown.Menu>
          </Dropdown>
        );
      }
    });
  }
  const activeSort = asRBT2DefaultSorted(sort);
  /**
   * convert our props to react-bootstrap-table2 format
   */
  const selectRow = selection
    ? {
        mode: selection.selectColumnType
          ? selection.selectColumnType
          : selection.multi
          ? "checkbox"
          : "radio",
        selected: selected
          ? selected.map(itm => (itm as any)[identifyingColumn])
          : [],
        onSelect: (row: D, isSelect: boolean, rowIndex: number, e: any) => {
          if (selection && onSelectionChanged) {
            let newSel: D[] = [];
            if (selected && selection.multi) {
              newSel = newSel.concat(selected);
            }
            if (isSelect) {
              newSel = newSel.concat(row);
            } else if (selection.multi) {
              newSel = newSel.filter(
                itm =>
                  (itm as any)[identifyingColumn] !==
                  (row as any)[identifyingColumn]
              );
            }
            onSelectionChanged(newSel);
          }
        },
        onSelectAll: (isSelect: boolean, rows: D[], e: any) => {
          if (selection && onSelectionChanged && selection.multi) {
            if (isSelect) {
              onSelectionChanged([...rows]);
            } else {
              onSelectionChanged([]);
            }
          }
        },
        hideSelectAll: selection.fixed || !selection.selectAll,
        hideSelectColumn:
          !selection.selectColumnType && (selection.fixed || !selection.multi),
        clickToSelect: !selection.fixed,
        classes: "table-active",
        selectionRenderer: (props: {
          mode: string;
          checked: boolean;
          disabled: boolean;
        }) => (
          <Form.Check
            custom
            checked={props.checked}
            readOnly
            type={props.mode === "radio" ? "radio" : "checkbox"}
            disabled={props.disabled}
            label={""}
          />
        ),
        selectionHeaderRenderer: (props: {
          mode: string;
          checked: boolean;
          indeterminate: boolean;
        }) => (
          <Form.Check
            className={props.indeterminate ? "indeterminate" : undefined}
            custom
            readOnly
            checked={props.checked}
            type={props.mode === "radio" ? "radio" : "checkbox"}
            label={""}
          />
        )
      }
    : undefined;

  const includeTools = columnToggle || reload || search;
  const includeHeader = header || includeTools;
  //</editor-fold>

  //<editor-fold desc="Partials">
  const noDataIndicator = () => {
    return (
      <>
        {data === undefined && (
          <FontAwesomeIcon
            icon={"spinner"}
            spin
            size={"3x"}
            className={"text-muted m-2"}
          />
        )}
        {data !== undefined && (
          <Alert variant={"danger"} data-test-table-empty-alert>
            <h4 className={"alert-title"}>
              <FormattedMessage
                id="table.no-data-title"
                defaultMessage="No matching items found"
                description="title to be shown when the table is empty"
              />
            </h4>
            {search && activeSearch && activeSearch.length && (
              <p className={"mb-0"}>
                <Button
                  variant={"danger"}
                  onClick={() => {
                    if (onSearch) {
                      onSearch("");
                    }
                  }}
                >
                  <FormattedMessage
                    id="table.no-data-clear-search-label"
                    defaultMessage="Clear active search"
                    description="button label to be shown when the table is empty and there is an active search"
                  />
                </Button>
              </p>
            )}
          </Alert>
        )}
      </>
    );
  };
  //</editor-fold>

  retVal = (
    <div className={"duke-table"} {...other}>
      {includeHeader && (
        <header
          className={
            "duke-table-header py-2 clearfix d-flex flex-row justify-content-end"
          }
        >
          {header}
          {includeTools && (
            <>
              {search && onSearch && (
                <ApplyInput
                  data-test-table-search
                  applyLabel={intl.formatMessage(
                    messages.toolsSearchApplyLabel
                  )}
                  clearDirtyInputTip={intl.formatMessage(
                    messages.toolsSearchClearDirtyInputTip
                  )}
                  clearInputTip={intl.formatMessage(
                    messages.toolsSearchClearInputTip
                  )}
                  activeValue={activeSearch}
                  onApply={onSearch}
                />
              )}
              <ButtonGroup>
                {columnToggle && (
                  <ColumnTools
                    columns={columns}
                    onToggleColumn={onToggleColumn}
                  />
                )}
                {reload && (
                  <TooltipTrigger
                    tipKey={"toolsReloadTip"}
                    tip={intl.formatMessage(messages.toolsReloadTip)}
                  >
                    <Button
                      data-test-table-reload-trigger
                      variant={"outline-secondary"}
                      onClick={onReload}
                    >
                      <FontAwesomeIcon icon="sync" />
                    </Button>
                  </TooltipTrigger>
                )}
              </ButtonGroup>
            </>
          )}
        </header>
      )}
      <BootstrapTable
        hover={!!selection}
        striped
        className={className}
        bootstrap4
        noDataIndication={noDataIndicator}
        data={data || ([] as D[])}
        columns={cols}
        keyField={identifyingColumn}
        sort={activeSort}
        pagination={
          pagination ? paginationFactory({ ...paginationTexts }) : undefined
        }
        selectRow={selectRow}
        rowClasses={rowClasses}
      />
    </div>
  );

  return retVal;
}
export default Table;
