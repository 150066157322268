import React, { ReactNode } from "react";
import { LicenseWithCredits } from "../../model/entitlement/LicenseWithCredits";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import LicenseUtils from "../../utils/licensed-item";
const MILLIS_FOR_30_DAYS = 30 * 24 * 60 * 60 * 1000;
const messages = defineMessages({
  noLicenseName: {
    id: "license-summary-content.no-license-name",
    defaultMessage: "Unknown"
  }
});
interface LicenseSummaryContentProps {
  licenses?: LicenseWithCredits[];
  hideSeats?: boolean;
  createManageAssignmentsLink?: (lic: LicenseWithCredits) => ReactNode;
}
function LicenseSummaryContent(props: LicenseSummaryContentProps) {
  const intl = useIntl();
  const { licenses, createManageAssignmentsLink, hideSeats } = props;
  return (
    <>
      {(!licenses || !licenses.length) && (
        <p data-test-license-summary-content>
          <FormattedMessage
            id="license-summary-content.no-licenses-copy"
            defaultMessage="No licenses to show"
          />
        </p>
      )}
      {licenses && licenses.length > 0 && (
        <ul data-test-license-summary-content className={"list-unstyled"}>
          {licenses.map(lic => {
            const cud = new Date().getTime();
            const vfd =
              lic && lic.validFrom
                ? new Date(lic.validFrom).getTime()
                : undefined;
            const vud =
              lic && lic.validUntil
                ? new Date(lic.validUntil).getTime()
                : undefined;
            const expired = (vud && vud < cud) || (vfd && vfd > cud) || !vfd;
            const aboutToExpire =
              vud && !expired ? vud < cud + MILLIS_FOR_30_DAYS : false;
            return (
              <li
                data-test-license-summary-item
                key={"itm" + (lic.id as string)}
              >
                <div className={"d-block clearfix mb-1"}>
                  <span className={"pt-1 d-inline-block mr-2 mb-1"}>
                    {lic &&
                      lic.licensedItem &&
                      LicenseUtils.resolveDisplayName(
                        lic.licensedItem,
                        intl.formatMessage(messages.noLicenseName)
                      )}
                    {aboutToExpire && (
                      <span
                        className={"ml-2 badge badge-warning"}
                        data-test-license-summary-item-expiring
                      >
                        <FormattedMessage
                          id="license-summary-content.about-to-expire-label"
                          defaultMessage="Expires soon"
                        />
                      </span>
                    )}
                    {expired && (
                      <span
                        className={"ml-2 badge badge-danger"}
                        data-test-license-summary-item-expired
                      >
                        <FormattedMessage
                          id="license-summary-content.expired-label"
                          defaultMessage="Expired"
                        />
                      </span>
                    )}
                  </span>
                  {createManageAssignmentsLink &&
                    createManageAssignmentsLink(lic)}
                </div>
                <div className={"d-block ml-4"}>
                  <FormattedMessage
                    id="license-summary-content.active-dates-label"
                    defaultMessage="Active dates: "
                  />
                  <FormattedMessage
                    id="license-summary-content.active-dates-value"
                    defaultMessage="{from} - {to}"
                    values={{
                      from: lic.validFrom
                        ? intl.formatDate(new Date(lic.validFrom).getTime())
                        : "",
                      to: lic.validUntil
                        ? intl.formatDate(new Date(lic.validUntil).getTime())
                        : ""
                    }}
                  />
                </div>
                {!hideSeats && (
                  <div className={"d-block ml-4"}>
                    <FormattedMessage
                      id="license-summary-content.seats-label"
                      defaultMessage="Seats: "
                    />
                    <FormattedMessage
                      id="license-summary-content.seats-value"
                      defaultMessage="{used, number} of {total, number} used"
                      values={{
                        used: lic.seatsTaken,
                        total: lic.seatsTotal
                      }}
                    />
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
}
export default LicenseSummaryContent;
