export function getEnvParam(envParam: string, defaultValue?: string): string {
  const apiImplParam = process.env[envParam];
  if (apiImplParam === undefined) {
    if (defaultValue === undefined) {
      throw new Error(`${envParam} env parameter must be specified`);
    }
    return defaultValue;
  }
  return apiImplParam;
}

export function envParamExists(envParam: string): boolean {
  const apiImplParam = process.env[envParam];
  return !(apiImplParam === undefined);
}
