import { Action } from "redux";
import { Organization } from "../model/Organization";
import { AppError } from "../model/AppError";
import { Authentication } from "../model/Authentication";
import { User } from "../model/User";
import { OrganizationGroup } from "../model/OrganizationGroup";
import { OrganizationRole } from "../model/OrganizationRole";
import { OrganizationGroupInvitation } from "../model/OrganizationGroupInvitation";
import { InternalPermissionWithGrantedActions } from "../model/InternalPermissionWithGrantedActions";
import { PermissionGrantsForPermission } from "../model/PermissionGrantsForPermission";
import { InProgress } from "../model/InProgress";
import { ThunkAction } from "redux-thunk";
import { AppState } from "../store/AppState";
import { LogoutCompleted } from "../model/LogoutCompleted";
import { LicenseFieldOpts } from "../model/entitlement/LicenseFieldOpts";
import { LicenseUsage } from "../model/entitlement/LicenseUsage";
import { LicenseAssignment } from "../model/entitlement/LicenseAssignment";
import { QueryAvailableLicensesOpts } from "../model/entitlement/QueryAvailableLicensesOpts";
import { LicenseWithOwnerAndSingleUserAssignments } from "../model/entitlement/LicenseWithOwnerAndSingleUserAssignments";

// base interface for actions of this application
export type AppAction = Action<string>;

// thunk action type for thunk that returns given action type or AddErrorAction
export type AppThunkAction<A extends AppAction> = ThunkAction<
  Promise<A | AddErrorAction<A>>,
  AppState,
  unknown,
  AppAction
>;

/*
 * action types (used as "type" field value in Redux actions) and interfaces describing each action
 */

// pseudo-action object used for carrying data of multiple actions
export const MULTI_ACTION = "MULTI_ACTION";
export type ActionResult<A extends AppAction> = A | AddErrorAction<A>;
export interface MultiAction extends AppAction {
  // results of the actual actions carried out
  results: ActionResult<AppAction>[];
}

// add error info
export const ADD_ERROR = "ADD_ERROR";
export interface AddErrorAction<A extends AppAction> extends AppAction {
  error: AppError<A>;
}
export function isAddErrorAction<A extends AppAction>(
  obj: any
): obj is AddErrorAction<A> {
  return "type" in obj && obj["type"] === ADD_ERROR;
}

// remove error with the given error id from the application state
export const CLEAR_ERROR = "CLEAR_ERROR";
export interface ClearErrorAction extends AppAction {
  errorId: string;
}

// remove all errors from the application state
export const CLEAR_ALL_ERRORS = "CLEAR_ALL_ERRORS";
export interface ClearAllErrorsAction extends AppAction {}

// add in-progress info
export const ADD_IN_PROGRESS = "ADD_IN_PROGRESS";
export interface AddInProgressAction<A extends AppAction> extends AppAction {
  operation: InProgress<A>;
}

// remove in-progress info with the given operation id from the application state
export const CLEAR_IN_PROGRESS = "CLEAR_IN_PROGRESS";
export interface ClearInProgressAction extends AppAction {
  operationId: string;
}

// remove all in-progress infos from the application state
export const CLEAR_ALL_IN_PROGRESS_INFOS = "CLEAR_ALL_IN_PROGRESS_INFOS";
export interface ClearAllInProgressInfosAction extends AppAction {}

// request clearing the whole redux store, mainly for test purposes
export const CLEAR_ALL = "CLEAR_ALL";
export interface ClearAllAction extends AppAction {}

// start authentication process
export const START_AUTHN = "START_AUTHN";
export interface StartAuthnAction extends AppAction {
  nonce: string;
  nonceIssuedAt: number;
}

// set authenticated user
export const SET_AUTHN = "SET_AUTHN";
export interface SetAuthnAction extends AppAction {
  authn: Authentication;
}

// set logout completed info
export const SET_LOGOUT_COMPLETED = "LOGOUT_COMPLETED";
export interface SetLogoutCompletedAction extends AppAction {
  logoutCompleted: LogoutCompleted;
}

// clears states related to user authentication and pending authentication
export const CLEAR_AUTHN_STATUS = "CLEAR_AUTHN_STATUS";
export interface ClearAuthnStatusAction extends AppAction {}

// loading completed for organizations that can managed by the authenticated user
export const LOAD_ORGS = "LOAD_ORGS";
export interface LoadOrgsAction extends AppAction {
  organizations: Organization[];
}

// loading org by id completed
export const GET_ORG = "GET_ORG";
export interface GetOrgAction extends AppAction {
  organization: Organization;
}

// update org by replacing org data with new data completed
export const REPLACE_ORG = "REPLACE_ORG";
export interface ReplaceOrgAction extends AppAction {
  organization: Organization;
}

// setting MFA requirement for org completed
export const SET_MFA_REQUIRED = "SET_MFA_REQUIRED";
export interface SetMfaRequiredAction extends AppAction {
  orgId: string;
  required: boolean;
}

// set organization to manage
export const SET_ORG = "SET_ORG";
export interface SetOrgAction extends AppAction {
  id: string;
}

// loading completed for all members of an organization
export const LIST_ORG_USERS = "LIST_ORG_USERS";
export interface ListOrgUsersAction extends AppAction {
  orgId: string;
  users: User[];
}

// loading completed for all groups of an organization
export const LIST_ORG_GROUPS = "LIST_ORG_GROUPS";
export interface ListOrgGroupsAction extends AppAction {
  orgId: string;
  groups: OrganizationGroup[];
}

// create organization group completed
export const CREATE_ORG_GROUP = "CREATE_ORG_GROUP";
export interface CreateOrgGroupAction extends AppAction {
  orgId: string;
  group: OrganizationGroup;
}

// update organization group by replacing group data with new data completed
export const REPLACE_ORG_GROUP = "REPLACE_ORG_GROUP";
export interface ReplaceOrgGroupAction extends AppAction {
  orgId: string;
  group: OrganizationGroup;
}

// get organization group completed
export const GET_ORG_GROUP = "GET_ORG_GROUP";
export interface GetOrgGroupAction extends AppAction {
  orgId: string;
  group: OrganizationGroup;
}

// delete organization group completed
export const DELETE_ORG_GROUP = "DELETE_ORG_GROUP";
export interface DeleteOrgGroupAction extends AppAction {
  orgId: string;
  orgGroupId: string;
}

// loading completed for all roles of an organization
export const LIST_ORG_ROLES = "LIST_ORG_ROLES";
export interface ListOrgRolesAction extends AppAction {
  orgId: string;
  roles: OrganizationRole[];
}

// create organization role completed
export const CREATE_ORG_ROLE = "CREATE_ORG_ROLE";
export interface CreateOrgRoleAction extends AppAction {
  orgId: string;
  role: OrganizationRole;
}

// update organization role by replacing role data with new data completed
export const REPLACE_ORG_ROLE = "REPLACE_ORG_ROLE";
export interface ReplaceOrgRoleAction extends AppAction {
  orgId: string;
  role: OrganizationRole;
}

// get organization role completed
export const GET_ORG_ROLE = "GET_ORG_ROLE";
export interface GetOrgRoleAction extends AppAction {
  orgId: string;
  role: OrganizationRole;
}

// delete organization role completed
export const DELETE_ORG_ROLE = "DELETE_ORG_ROLE";
export interface DeleteOrgRoleAction extends AppAction {
  orgId: string;
  orgRoleId: string;
}

// get user completed
export const GET_USER = "GET_USER";
export interface GetUserAction extends AppAction {
  user: User;
}

// delete user completed
export const DELETE_USER = "DELETE_USER";
export interface DeleteUserAction extends AppAction {
  userId: string;
}

// update user by replacing user data with new data completed
export const REPLACE_USER = "REPLACE_USER";
export interface ReplaceUserAction extends AppAction {
  user: User;
}

// setting user suspension status completed
export const SET_USER_SUSPENDED = "SET_USER_SUSPENDED";
export interface SetUserSuspendedAction extends AppAction {
  user: User;
  suspended: boolean;
}

// delete user's MFA configuration (TOTP credentials) completed
export const DELETE_OTP_CREDENTIAL = "DELETE_OTP_CREDENTIAL";
export interface DeleteOtpCredentialAction extends AppAction {
  userId: string;
}

// list organization groups in which user is a member completed
export const LIST_ORG_GROUPS_OF_USER = "LIST_ORG_GROUPS_OF_USER";
export interface ListOrgGroupsOfUserAction extends AppAction {
  userId: string;
  groups: OrganizationGroup[];
}

// add user as member of organization group completed
export const ADD_ORG_GROUP_FOR_USER = "ADD_ORG_GROUP_FOR_USER";
export interface AddOrgGroupForUserAction extends AppAction {
  userId: string;
  orgGroupId: string;
}

// remove user from being member of organization group completed
export const REMOVE_ORG_GROUP_OF_USER = "REMOVE_ORG_GROUP_OF_USER";
export interface RemoveOrgGroupOfUserAction extends AppAction {
  userId: string;
  orgGroupId: string;
}

// list users who are members of organization group completed
export const LIST_USERS_IN_ORG_GROUP = "LIST_USERS_IN_ORG_GROUP";
export interface ListUsersInOrgGroupAction extends AppAction {
  orgGroupId: string;
  orgId: string;
  users: User[];
}

// add users to organization role completed.
export const ADD_USERS_TO_ORG_ROLE = "ADD_USERS_TO_ORG_ROLE";
export interface AddUsersToOrgRoleAction extends AppAction {
  orgRoleId: string;
  orgId: string;
  userIds: string[];
}

// remove users from organization role completed.
export const REMOVE_USERS_FROM_ORG_ROLE = "REMOVE_USERS_FROM_ORG_ROLE";
export interface RemoveUsersFromOrgRoleAction extends AppAction {
  orgRoleId: string;
  orgId: string;
  userIds: string[];
}

// add user to organization role completed.
export const ADD_USER_TO_ORG_ROLE = "ADD_USER_TO_ORG_ROLE";
export interface AddUserToOrgRoleAction extends AppAction {
  orgRoleId: string;
  orgId: string;
  userId: string;
}

// remove user from organization role completed.
export const REMOVE_USER_FROM_ORG_ROLE = "REMOVE_USER_FROM_ORG_ROLE";
export interface RemoveUserFromOrgRoleAction extends AppAction {
  orgRoleId: string;
  orgId: string;
  userId: string;
}

// add users as members of organization group completed
export const ADD_USERS_TO_ORG_GROUP = "ADD_USERS_TO_ORG_GROUP";
export interface AddUsersToOrgGroupAction extends AppAction {
  orgGroupId: string;
  orgId: string;
  userIds: string[];
}

// set users as members of organization group completed
export const SET_USERS_IN_ORG_GROUP = "SET_USERS_IN_ORG_GROUP";
export interface SetUsersInOrgGroupAction extends AddUsersToOrgGroupAction {}

// remove users from being members of organization group completed
export const REMOVE_USERS_FROM_ORG_GROUP = "REMOVE_USERS_FROM_ORG_GROUP";
export interface RemoveUsersFromOrgGroupAction extends AppAction {
  orgGroupId: string;
  orgId: string;
  userIds: string[];
}

// remove user from all groups and roles of organization
export const REMOVE_USER_FROM_ORG = "REMOVE_USER_FROM_ORG";
export interface RemoveUserFromOrgAction extends AppAction {
  userId: string;
  orgId: string;
  orgGroupIds: string[];
  orgRoleIds: string[];
}

// list organization group invitations of an organization completed
export const LIST_ORG_ORG_GROUP_INVITATIONS = "LIST_ORG_ORG_GROUP_INVITATIONS";
export interface ListOrgOrgGroupInvitationsAction extends AppAction {
  orgId: string;
  invitations: OrganizationGroupInvitation[];
}

// get organization group invitation of an organization completed
export const GET_ORG_ORG_GROUP_INVITATION = "GET_ORG_ORG_GROUP_INVITATION";
export interface GetOrgOrgGroupInvitationAction extends AppAction {
  orgId: string;
  invitation: OrganizationGroupInvitation;
}

// create organization group invitation completed
export const CREATE_ORG_GROUP_INVITATION = "CREATE_ORG_GROUP_INVITATION";
export interface CreateOrgGroupInvitationAction extends AppAction {
  orgId: string;
  invitation: OrganizationGroupInvitation;
}

// send organization group invitation completed
export const SEND_ORG_GROUP_INVITATION = "SEND_ORG_GROUP_INVITATION";
export interface SendOrgGroupInvitationAction extends AppAction {
  orgId: string;
  invitation: OrganizationGroupInvitation;
}

// remove organization group invitation completed
export const REVOKE_ORG_GROUP_INVITATION = "REVOKE_ORG_GROUP_INVITATION";
export interface RevokeOrgGroupInvitationAction extends AppAction {
  orgId: string;
  invitation: OrganizationGroupInvitation;
}

// remove organization group invitation completed
export const DELETE_ORG_GROUP_INVITATION = "DELETE_ORG_GROUP_INVITATION";
export interface DeleteOrgGroupInvitationAction extends AppAction {
  orgId: string;
  invitationId: string;
}

// list permissions of organization role completed
export const LIST_PERMISSIONS_OF_ORG_ROLE = "LIST_PERMISSIONS_OF_ORG_ROLE";
export interface ListPermissionsOfOrgRoleAction extends AppAction {
  orgId: string;
  orgRoleId: string;
  permissions: InternalPermissionWithGrantedActions[];
}

// add permissions for organization role completed
export const ADD_PERMISSIONS_FOR_ORG_ROLE = "ADD_PERMISSIONS_FOR_ORG_ROLE";
export interface AddPermissionsForOrgRoleAction extends AppAction {
  orgId: string;
  orgRoleId: string;
  grants: PermissionGrantsForPermission[];
}

// set permissions of organization role completed
export const SET_PERMISSIONS_OF_ORG_ROLE = "SET_PERMISSIONS_OF_ORG_ROLE";
export interface SetPermissionsOfOrgRoleAction extends AppAction {
  orgId: string;
  orgRoleId: string;
  grants: PermissionGrantsForPermission[];
}

// remove permissions of organization role completed
export const REMOVE_PERMISSIONS_OF_ORG_ROLE = "REMOVE_PERMISSIONS_OF_ORG_ROLE";
export interface RemovePermissionsOfOrgRoleAction extends AppAction {
  orgId: string;
  orgRoleId: string;
  permissionIds: string[];
}

// remove a permission of organization role completed
export const REMOVE_PERMISSION_OF_ORG_ROLE = "REMOVE_PERMISSION_OF_ORG_ROLE";
export interface RemovePermissionOfOrgRoleAction extends AppAction {
  orgId: string;
  orgRoleId: string;
  permissionId: string;
}

export const LIST_USERS_IN_ORG_ROLE = "LIST_USERS_IN_ORG_ROLE";
export interface ListUsersInOrgRoleAction extends AppAction {
  orgRoleId: string;
  orgId: string;
  users: User[];
}

export interface LicensesAction extends AppAction {
  licenses: LicenseWithOwnerAndSingleUserAssignments[];
}

export const LIST_ENT_LICENSES = "LIST_ENT_LICENSES";
export interface ListEntLicensesAction extends LicensesAction {
  entId: string;
  orgId: string;
  licenseFieldOpts?: LicenseFieldOpts;
  // Actual type of licenses is LicenseWithCredits for this action.
  // This action extends LicensesAction for simpler processing.
  // licenses: LicenseWithCredits[];
}

export interface LicenseUsageField {
  licenseUsage?: LicenseUsage;
}

export const QUERY_LICENSE_USAGE = "QUERY_LICENSE_USAGE";
export interface QueryLicenseUsageAction extends AppAction, LicenseUsageField {
  entId: string;
  orgId: string;
  licenseId: string;
  licenseUsage: LicenseUsage;
}

export const LIST_ENT_LICENSES_WITH_USAGE = "LIST_ENT_LICENSES_WITH_USAGE";
export interface ListEntLicensesWithUsageAction extends ListEntLicensesAction {
  // Actual type of licenses is LicenseUsage for this action.
  // This action extends ListEntLicensesAction / LicensesAction for simpler processing.
  // licenses: LicenseUsage[];
}

export interface LicenseAssignmentAction extends AppAction {
  licenseAssignments: LicenseAssignment[];
}

export interface LicenseAssignmentManagementBaseAction
  extends LicenseAssignmentAction {
  entId: string;
  orgId: string;
  licenseId: string;
}

export interface LicenseAssignmentManagementAction
  extends LicenseAssignmentManagementBaseAction,
    LicenseUsageField {
  userId: string;
  // New license usage is populated in ManageUserLicenseAssignmentsAction but not in
  // GetUserLicenseAssignmentsAction. Declared here to simplify processing in reducers.
  licenseUsage?: LicenseUsage;
}

export const GET_USER_LICENSE_ASSIGNMENTS = "GET_USER_LICENSE_ASSIGNMENTS";
export interface GetUserLicenseAssignmentsAction
  extends LicenseAssignmentManagementAction {}

export const MANAGE_USER_LICENSE_ASSIGNMENTS =
  "MANAGE_USER_LICENSE_ASSIGNMENTS";
export interface ManageUserLicenseAssignmentsAction
  extends LicenseAssignmentManagementAction {
  licenseUsage: LicenseUsage;
  errors: { [userId: string]: any };
}

export const MANAGE_USERS_LICENSE_ASSIGNMENTS =
  "MANAGE_USERS_LICENSE_ASSIGNMENTS";
export interface ManageUsersLicenseAssignmentsAction
  extends LicenseAssignmentManagementBaseAction,
    LicenseUsageField {
  userAssignments: { [userId: string]: LicenseAssignment[] };
  licenseUsage: LicenseUsage;
  errors: { [userId: string]: any };
}

export const QUERY_AVAILABLE_LICENSES = "QUERY_AVAILABLE_LICENSES";
export interface QueryAvailableLicensesAction
  extends LicensesAction,
    LicenseAssignmentAction {
  userId: string;
  queryAvailableLicensesOpts?: QueryAvailableLicensesOpts;
}
