import { ValidityFields } from "../model/ValidityFields";

/**
 * Determines object equality recursively.
 * @param a The first object to compare.
 * @param b The second object to compare.
 */
export function objectsAreEqual(a?: any, b?: any): boolean {
  if (a === undefined) return b === undefined;
  if (b === undefined) return false;
  if (a === null) return b === null;
  if (b === null) return false;

  let aPropCount = 0;
  for (let prop in a) {
    if (a.hasOwnProperty(prop)) {
      aPropCount++;
      if (b.hasOwnProperty(prop)) {
        if (typeof a[prop] === "object") {
          if (!objectsAreEqual(a[prop], b[prop])) return false;
        } else {
          if (a[prop] !== b[prop]) return false;
        }
      } else {
        return false;
      }
    }
  }

  const bPropCount = Object.keys(b).filter(key => b.hasOwnProperty(key)).length;
  return aPropCount === bPropCount;
}

/**
 * Gets validUntil member as number from obj
 * @param obj The object with validity.
 * @returns Value of validUntil field of the given object
 */
export function getValidUntil(obj: ValidityFields): number | undefined {
  const assignmentValidUntil = obj.validUntil
    ? new Date(obj.validUntil).getTime()
    : undefined;
  return assignmentValidUntil;
}

/**
 * Checks if the given object is valid.
 * @param obj The object with validity.
 * @returns true if the object is currently valid, false otherwise
 */
export function isValid(obj: ValidityFields): boolean {
  const now = new Date().getTime();

  // check valid from.
  let validFrom;
  if (obj.validFrom === "now()") {
    validFrom = now;
  } else if (obj.validFrom) {
    validFrom = new Date(obj.validFrom).getTime();
  }
  if (!validFrom || validFrom > now) {
    return false;
  }

  let validUntil;
  if (obj.validUntil === "now()") {
    validUntil = now;
  } else if (obj.validUntil) {
    validUntil = new Date(obj.validUntil).getTime();
  }
  if (validUntil && validUntil <= now) {
    return false;
  }

  // all good.
  return true;
}
