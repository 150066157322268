import * as ActionTypes from "../actions/actionTypes";
import { LicenseIdsByOrgId } from "../store/LicenseState";
import { ID_ANY } from "../api/EntApi";

export default function orgLicenseIds(
  state: LicenseIdsByOrgId,
  action: ActionTypes.AppAction
): LicenseIdsByOrgId | null {
  const currentState = state || ({} as LicenseIdsByOrgId);
  switch (action.type) {
    case ActionTypes.LIST_ENT_LICENSES:
    case ActionTypes.LIST_ENT_LICENSES_WITH_USAGE:
      const listEntLicenses = action as ActionTypes.ListEntLicensesAction;
      const stateAfterListEntLicenses = { ...currentState };
      const existingLicenseIds =
        stateAfterListEntLicenses[listEntLicenses.orgId];
      const newLicenseIds = listEntLicenses.licenses.map(
        lic => lic.id as string
      );
      if (
        ID_ANY === listEntLicenses.entId ||
        existingLicenseIds === undefined ||
        existingLicenseIds.length === 0
      ) {
        stateAfterListEntLicenses[listEntLicenses.orgId] = newLicenseIds;
      } else {
        const licenseIdsAfterListEntLicenses = new Set<string>([
          ...existingLicenseIds,
          ...newLicenseIds
        ]);
        stateAfterListEntLicenses[listEntLicenses.orgId] = Array.from(
          licenseIdsAfterListEntLicenses.values()
        );
      }
      return stateAfterListEntLicenses;
    case ActionTypes.QUERY_AVAILABLE_LICENSES:
      const queryAvailableLicenses = action as ActionTypes.QueryAvailableLicensesAction;
      const result = { ...currentState };
      queryAvailableLicenses.licenses
        .filter(license => {
          return license.owner && license.owner.objectType === "Organization";
        })
        .forEach(licenseOwnedByOrg => {
          const licenseId = licenseOwnedByOrg.id;
          const orgId = licenseOwnedByOrg.owner?.id;
          if (licenseId && orgId) {
            if (result[orgId]) {
              result[orgId] = [...result[orgId]];
            } else {
              result[orgId] = [];
            }

            if (!result[orgId].includes(licenseId)) {
              result[orgId].push(licenseId);
            }
          }
        });

      return result;
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}
