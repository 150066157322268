import React from "react";
import CssUtils from "../../utils/css-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./profile-image.scss";

//<editor-fold desc="Props">
interface ProfileImageDOMProps
  extends Omit<React.HTMLAttributes<HTMLSpanElement>, "children"> {}

interface ProfileImageProps extends ProfileImageDOMProps {
  userId?: string;
}
//</editor-fold>

export default function ProfileImage(props: ProfileImageProps) {
  //<editor-fold desc="Local variables">
  let retVal;
  let { className, ...other } = props;

  className = CssUtils.ensureClassNames(className, [
    "profile-image",
    "d-inline-block"
  ]);
  //</editor-fold>

  if (props.userId) {
    retVal = (
      <span className={className} {...other}>
        <img className="img-fluid" alt="?" />
      </span>
    );
  } else {
    retVal = (
      <span className={className} {...other}>
        <FontAwesomeIcon icon="user-circle" />
      </span>
    );
  }

  return retVal;
}
