import * as ActionTypes from "./actionTypes";
import { entApi } from "../api";
import { ActionSender } from "../model/ActionSender";
import {
  buildActionThunk,
  ensureSelectedOrgId,
  forceUndefined
} from "./actionHelpers";
import { LicenseFieldOpts } from "../model/entitlement/LicenseFieldOpts";
import { LicenseWithCredits } from "../model/entitlement/LicenseWithCredits";
import { LicenseUsage } from "../model/entitlement/LicenseUsage";
import { LicenseAssignment } from "../model/entitlement/LicenseAssignment";
import { LicenseWithOwnerAndSingleUserAssignments } from "../model/entitlement/LicenseWithOwnerAndSingleUserAssignments";
import { QueryAvailableLicensesOpts } from "../model/entitlement/QueryAvailableLicensesOpts";
import { updateLicense } from "../util/licenseUtil";

/**
 * Lists licenses of an entitlement.
 * @param sender Component requesting for the action
 * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
 * @param licenseFieldOpts Options for including additional fields in the response.
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function listEntitlementLicenses(
  sender: ActionSender,
  entId: string,
  licenseFieldOpts?: LicenseFieldOpts,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListEntLicensesAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.ListEntLicensesAction,
    LicenseWithCredits[]
  >(
    sender,
    ActionTypes.LIST_ENT_LICENSES,
    async () =>
      await entApi.listEntitlementLicenses(
        orgIdOrDefault,
        entId,
        licenseFieldOpts
      ),
    (type, licenses) => ({
      type,
      entId,
      orgId: orgIdOrDefault,
      licenseFieldOpts,
      licenses: forceUndefined(licenses)
    })
  );
}

/**
 * Gets current usage of a license.
 * @param sender Component requesting for the action
 * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
 * @param licenseId Id of the license.
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function queryLicenseUsage(
  sender: ActionSender,
  entId: string,
  licenseId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.QueryLicenseUsageAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.QueryLicenseUsageAction, LicenseUsage>(
    sender,
    ActionTypes.QUERY_LICENSE_USAGE,
    async () =>
      await entApi.queryLicenseUsage(orgIdOrDefault, entId, licenseId),
    (type, licenseUsage) => ({
      type,
      entId,
      orgId: orgIdOrDefault,
      licenseId,
      licenseUsage: forceUndefined(licenseUsage)
    })
  );
}

/**
 * Lists licenses of an entitlement with license usage information.
 * @param sender Component requesting for the action
 * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
 * @param licenseFieldOpts Options for including additional fields in the response.
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function listEntitlementLicensesWithUsage(
  sender: ActionSender,
  entId: string,
  licenseFieldOpts?: LicenseFieldOpts,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListEntLicensesWithUsageAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.ListEntLicensesWithUsageAction,
    LicenseUsage[]
  >(
    sender,
    ActionTypes.LIST_ENT_LICENSES_WITH_USAGE,
    async () =>
      await listEntitlementLicensesWithUsageInternal(
        orgIdOrDefault,
        entId,
        licenseFieldOpts
      ),
    (type, licenses) => ({
      type,
      entId,
      orgId: orgIdOrDefault,
      licenseFieldOpts,
      licenses: forceUndefined(licenses)
    })
  );
}

/**
 * Lists licenses of an entitlement with license usage information.
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
 * @param licenseFieldOpts Options for including additional fields in the response.
 */
async function listEntitlementLicensesWithUsageInternal(
  orgId: string,
  entId: string,
  licenseFieldOpts?: LicenseFieldOpts
): Promise<LicenseUsage[]> {
  const entLicenses = await entApi.listEntitlementLicenses(
    orgId,
    entId,
    licenseFieldOpts
  );
  const licenseUsages = await Promise.all(
    entLicenses.map(entLicense =>
      entApi.queryLicenseUsage(orgId, entId, entLicense.id as string)
    )
  );
  const licenseUsagesByLicenseId = licenseUsages.reduce<{
    [licenseId: string]: LicenseUsage;
  }>((map, licUsage) => {
    map[licUsage.id as string] = licUsage;
    return map;
  }, {});
  return entLicenses.map(entLicense =>
    updateLicense(licenseUsagesByLicenseId[entLicense.id as string], entLicense)
  );
}

/**
 * Gets user's assignments to the license.
 * @param sender Component requesting for the action
 * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
 * @param licenseId Id of the license.
 * @param userId Id of the user.
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function getLicenseAssignments(
  sender: ActionSender,
  entId: string,
  licenseId: string,
  userId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.GetUserLicenseAssignmentsAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.GetUserLicenseAssignmentsAction,
    LicenseAssignment[]
  >(
    sender,
    ActionTypes.GET_USER_LICENSE_ASSIGNMENTS,
    async () =>
      await entApi.getLicenseAssignments(
        orgIdOrDefault,
        entId,
        licenseId,
        userId
      ),
    (type, licenseAssignments) => ({
      type,
      entId,
      orgId: orgIdOrDefault,
      licenseId,
      userId,
      licenseAssignments: forceUndefined(licenseAssignments)
    })
  );
}

/**
 * Manages user's license assigments.
 * @param sender Component requesting for the action
 * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
 * @param licenseId Id of the license.
 * @param userId Id of the user.
 * @param newLicenseAssignments New license assignments to set for the user to the license.
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function manageLicenseAssignments(
  sender: ActionSender,
  entId: string,
  licenseId: string,
  userId: string,
  newLicenseAssignments: LicenseAssignment[],
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ManageUserLicenseAssignmentsAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.ManageUserLicenseAssignmentsAction,
    {
      userAssignments: { [userId: string]: LicenseAssignment[] };
      licenseUsage: LicenseUsage;
      errors: { [userId: string]: any };
    }
  >(
    sender,
    ActionTypes.MANAGE_USER_LICENSE_ASSIGNMENTS,
    async () =>
      await manageUsersLicenseAssignmentsInternal(
        orgIdOrDefault,
        entId,
        licenseId,
        { [userId]: newLicenseAssignments }
      ),
    (type, manageResult) => ({
      type,
      entId,
      orgId: orgIdOrDefault,
      licenseId,
      userId,
      licenseAssignments: manageResult
        ? Object.values(manageResult.userAssignments).flatMap(
            assignments => assignments
          )
        : forceUndefined<LicenseAssignment[]>(),
      licenseUsage: forceUndefined(manageResult?.licenseUsage),
      errors: forceUndefined(manageResult?.errors)
    })
  );
}

/**
 * Manages license assigments for several users.
 * @param sender Component requesting for the action
 * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
 * @param licenseId Id of the license.
 * @param userAssignments New license assignments to set by user id.
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function manageUsersLicenseAssignments(
  sender: ActionSender,
  entId: string,
  licenseId: string,
  userAssignments: { [userId: string]: LicenseAssignment[] },
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ManageUsersLicenseAssignmentsAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.ManageUsersLicenseAssignmentsAction,
    {
      userAssignments: { [userId: string]: LicenseAssignment[] };
      licenseUsage: LicenseUsage;
      errors: { [userId: string]: any };
    }
  >(
    sender,
    ActionTypes.MANAGE_USERS_LICENSE_ASSIGNMENTS,
    async () =>
      await manageUsersLicenseAssignmentsInternal(
        orgIdOrDefault,
        entId,
        licenseId,
        userAssignments
      ),
    (type, manageResult) => ({
      type,
      entId,
      orgId: orgIdOrDefault,
      licenseId,
      userAssignments: forceUndefined(manageResult?.userAssignments),
      licenseAssignments: manageResult
        ? Object.values(manageResult.userAssignments).flatMap(
            assignments => assignments
          )
        : forceUndefined<LicenseAssignment[]>(),
      licenseUsage: forceUndefined(manageResult?.licenseUsage),
      errors: forceUndefined(manageResult?.errors)
    })
  );
}

/**
 * Manages license assigments for several users.
 * @param orgId The organization id.
 * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
 * @param licenseId Id of the license.
 * @param userAssignments New license assignments to set by user id.
 * @returns New license assignments by user id and new license usage of the license.
 */
async function manageUsersLicenseAssignmentsInternal(
  orgId: string,
  entId: string,
  licenseId: string,
  userAssignments: { [userId: string]: LicenseAssignment[] }
): Promise<{
  userAssignments: { [userId: string]: LicenseAssignment[] };
  licenseUsage: LicenseUsage;
  errors: { [userId: string]: any };
}> {
  const userIds = Object.keys(userAssignments);
  const manageResults = await Promise.allSettled(
    userIds.map(userId =>
      entApi.manageLicenseAssignments(
        orgId,
        entId,
        licenseId,
        userId,
        userAssignments[userId].map(assignment => {
          // Remove sessions property that is passed here to prevent
          // rest service failing with unsupported member "sessions".
          // "sessions" is member of LicenseAssignmentWithSessions but not LicenseAssignment.
          const { sessions: _, ...other } = assignment as any;
          return other;
        })
      )
    )
  );
  const licenseUsage = await entApi.queryLicenseUsage(orgId, entId, licenseId);
  const retValue: {
    userAssignments: { [userId: string]: LicenseAssignment[] };
    licenseUsage: LicenseUsage;
    errors: { [userId: string]: any };
  } = { userAssignments: {}, licenseUsage, errors: {} };
  for (let i = 0; i < userIds.length; i++) {
    if (manageResults[i].status === "fulfilled") {
      retValue.userAssignments[userIds[i]] = (manageResults[
        i
      ] as PromiseFulfilledResult<LicenseAssignment[]>).value;
    } else {
      retValue.errors[userIds[i]] = (manageResults[
        i
      ] as PromiseRejectedResult).reason;
    }
  }
  return retValue;
}

/**
 * Queries information of licenses available to a user.
 * @param sender Component requesting for the action
 * @param userId Id of the user.
 * @param queryAvailableLicensesOpts Options for the operation.
 */
export function queryAvailableLicenses(
  sender: ActionSender,
  userId: string,
  queryAvailableLicensesOpts?: QueryAvailableLicensesOpts
): ActionTypes.AppThunkAction<ActionTypes.QueryAvailableLicensesAction> {
  return buildActionThunk<
    ActionTypes.QueryAvailableLicensesAction,
    LicenseWithOwnerAndSingleUserAssignments[]
  >(
    sender,
    ActionTypes.QUERY_AVAILABLE_LICENSES,
    async () =>
      await entApi.queryAvailableLicenses(userId, queryAvailableLicensesOpts),
    (type, availableLicenses) => ({
      type,
      userId,
      queryAvailableLicensesOpts,
      licenses: forceUndefined(availableLicenses),
      licenseAssignments: availableLicenses
        ? availableLicenses
            .filter(lic => lic.assignments && lic.assignments.length !== 0)
            .flatMap(lic => lic.assignments as LicenseAssignment[])
        : forceUndefined<LicenseAssignment[]>()
    })
  );
}
