import * as ActionTypes from "../actions/actionTypes";
import { LicenseAssignmentsById } from "../store/LicenseState";
import { LicenseAssignmentWithSessions } from "../model/entitlement/LicenseAssignmentWithSessions";
import { updateAssignment } from "../util/licenseUtil";
import { LicenseUsage } from "../model/entitlement/LicenseUsage";

export default function licenseAssignments(
  state: LicenseAssignmentsById,
  action: ActionTypes.AppAction
): LicenseAssignmentsById | null {
  const currentState = state || ({} as LicenseAssignmentsById);
  switch (action.type) {
    case ActionTypes.QUERY_LICENSE_USAGE:
      const queryLicenseUsage = action as ActionTypes.QueryLicenseUsageAction;
      const usage = queryLicenseUsage.licenseUsage;
      return updateLicenseUsageToState(currentState, usage);
    case ActionTypes.LIST_ENT_LICENSES_WITH_USAGE:
      const listEntLicensesWithUsage = action as ActionTypes.ListEntLicensesWithUsageAction;
      if (
        listEntLicensesWithUsage.licenses &&
        listEntLicensesWithUsage.licenses.length
      ) {
        let stateAfterListEntLicensesWithUsage = { ...currentState };
        for (const lic of listEntLicensesWithUsage.licenses) {
          // Licenses returned by this action are always of type LicenseUsage
          const licUsage = lic as LicenseUsage;
          stateAfterListEntLicensesWithUsage = updateLicenseUsageToState(
            stateAfterListEntLicensesWithUsage,
            licUsage
          );
        }
        return stateAfterListEntLicensesWithUsage;
      }
      return currentState;
    case ActionTypes.GET_USER_LICENSE_ASSIGNMENTS:
    case ActionTypes.MANAGE_USER_LICENSE_ASSIGNMENTS:
    case ActionTypes.MANAGE_USERS_LICENSE_ASSIGNMENTS:
    case ActionTypes.QUERY_AVAILABLE_LICENSES:
      const licenseAssignmentsAction = action as ActionTypes.LicenseAssignmentAction;
      const stateAfterGetAssignments = { ...currentState };
      for (const userAssignment of licenseAssignmentsAction.licenseAssignments) {
        const userAssignmentId = userAssignment.id as string;
        stateAfterGetAssignments[userAssignmentId] = updateAssignment(
          userAssignment,
          stateAfterGetAssignments[userAssignmentId]
        );
      }
      return stateAfterGetAssignments;
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}

function updateLicenseUsageToState(
  state: LicenseAssignmentsById,
  usage: LicenseUsage
): LicenseAssignmentsById {
  if (usage.users && usage.users.length !== 0) {
    const usageAssignments = usage.users
      .filter(
        licUser => licUser.assignments && licUser.assignments.length !== 0
      )
      .flatMap(
        licUser => licUser.assignments as LicenseAssignmentWithSessions[]
      );
    const stateAfterQuery = { ...state };
    for (const usageAssignment of usageAssignments) {
      const usageAssignmentId = usageAssignment.id as string;
      stateAfterQuery[usageAssignmentId] = updateAssignment(
        usageAssignment,
        stateAfterQuery[usageAssignmentId]
      );
    }
    return stateAfterQuery;
  }
  return state;
}
