/**
 * 10Duke Identity Provider REST API
 * v1.exp
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
export const defaults: RequestOpts = {
  baseUrl: "http://api.10duke.com/"
};
export const servers = {
  server1: "http://api.10duke.com/",
  server2: "https://api.10duke.com/"
};
type Encoders = Array<(s: string) => string>;
export type RequestOpts = {
  baseUrl?: string;
  fetch?: typeof fetch;
  headers?: Record<string, string | undefined>;
} & Omit<RequestInit, "body" | "headers">;
type FetchRequestOpts = RequestOpts & {
  body?: string | FormData;
};
type JsonRequestOpts = RequestOpts & {
  body: object;
};
type MultipartRequestOpts = RequestOpts & {
  body: Record<string, string | Blob | undefined | any>;
};
export const _ = {
  async fetch(url: string, req?: FetchRequestOpts) {
    const { baseUrl, headers, fetch: customFetch, ...init } = {
      ...defaults,
      ...req
    };
    const href = _.joinUrl(baseUrl, url);
    const res = await (customFetch || fetch)(href, {
      ...init,
      headers: _.stripUndefined({ ...defaults.headers, ...headers })
    });
    if (!res.ok) {
      throw new HttpError(res.status, res.statusText, href);
    }
    return res.text();
  },
  async fetchJson(url: string, req: FetchRequestOpts = {}) {
    const res = await _.fetch(url, {
      ...req,
      headers: {
        ...req.headers,
        Accept: "application/json"
      }
    });
    return res && JSON.parse(res);
  },
  json({ body, headers, ...req }: JsonRequestOpts) {
    return {
      ...req,
      body: JSON.stringify(body),
      headers: {
        ...headers,
        "Content-Type": "application/json"
      }
    };
  },
  form({ body, headers, ...req }: JsonRequestOpts) {
    return {
      ...req,
      body: QS.form(body),
      headers: {
        ...headers,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    };
  },
  multipart({ body, ...req }: MultipartRequestOpts) {
    const data = new FormData();
    Object.entries(body).forEach(([name, value]) => {
      data.append(name, value);
    });
    return {
      ...req,
      body: data
    };
  },
  /**
   * Deeply remove all properties with undefined values.
   */
  stripUndefined<T>(obj: T) {
    return obj && JSON.parse(JSON.stringify(obj));
  },
  // Encode param names and values as URIComponent
  encodeReserved: [encodeURIComponent, encodeURIComponent],
  allowReserved: [encodeURIComponent, encodeURI],
  /**
   * Creates a tag-function to encode template strings with the given encoders.
   */
  encode(encoders: Encoders, delimiter = ",") {
    const q = (v: any, i: number) => {
      const encoder = encoders[i % encoders.length];
      if (typeof v === "object") {
        if (Array.isArray(v)) {
          return v.map(encoder).join(delimiter);
        }
        const flat = Object.entries(v).reduce(
          (flat, entry) => [...flat, ...entry],
          [] as any
        );
        return flat.map(encoder).join(delimiter);
      }
      return encoder(String(v));
    };
    return (strings: TemplateStringsArray, ...values: any[]) => {
      return strings.reduce((prev, s, i) => {
        return `${prev}${s}${q(values[i] || "", i)}`;
      }, "");
    };
  },
  /**
   * Separate array values by the given delimiter.
   */
  delimited(delimiter = ",") {
    return (params: Record<string, any>, encoders = _.encodeReserved) =>
      Object.entries(params)
        .filter(([, value]) => value !== undefined)
        .map(([name, value]) => _.encode(encoders, delimiter)`${name}=${value}`)
        .join("&");
  },
  joinUrl(...parts: Array<string | undefined>) {
    return parts
      .filter(Boolean)
      .join("/")
      .replace(/([^:]\/)\/+/, "$1");
  }
};
/**
 * Functions to serialize query parameters in different styles.
 */
export const QS = {
  /**
   * Join params using an ampersand and prepends a questionmark if not empty.
   */
  query(...params: string[]) {
    const s = params.join("&");
    return s && `?${s}`;
  },
  /**
   * Serializes nested objects according to the `deepObject` style specified in
   * https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#style-values
   */
  deep(params: Record<string, any>, [k, v] = _.encodeReserved): string {
    const qk = _.encode([s => s, k]);
    const qv = _.encode([s => s, v]);
    // don't add index to arrays
    // https://github.com/expressjs/body-parser/issues/289
    const visit = (obj: any, prefix = ""): string =>
      Object.entries(obj)
        .filter(([, v]) => v !== undefined)
        .map(([prop, v]) => {
          const index = Array.isArray(obj) ? "" : prop;
          const key = prefix ? qk`${prefix}[${index}]` : prop;
          if (typeof v === "object") {
            return visit(v, key);
          }
          return qv`${key}=${v}`;
        })
        .join("&");
    return visit(params);
  },
  /**
   * Property values of type array or object generate separate parameters
   * for each value of the array, or key-value-pair of the map.
   * For other types of properties this property has no effect.
   * See https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#encoding-object
   */
  explode(params: Record<string, any>, encoders = _.encodeReserved): string {
    const q = _.encode(encoders);
    return Object.entries(params)
      .filter(([, value]) => value !== undefined)
      .map(([name, value]) => {
        if (Array.isArray(value)) {
          return value.map(v => q`${name}=${v}`).join("&");
        }
        if (typeof value === "object") {
          return QS.explode(value, encoders);
        }
        return q`${name}=${value}`;
      })
      .join("&");
  },
  form: _.delimited(),
  pipe: _.delimited("|"),
  space: _.delimited("%20")
};
export class HttpError extends Error {
  status: number;
  constructor(status: number, message: string, url: string) {
    super(`${url} - ${message} (${status})`);
    this.status = status;
  }
}
export type ApiResult<Fn> = Fn extends (...args: any) => Promise<infer T>
  ? T
  : never;
export type OrganizationGroupInvitation = {
  organizationId: string;
  organizationRoleIds: string[];
  groupIds: string[];
  clientRoleIds: string[];
  email: string;
  claimedAt?: string;
  declinedAt?: string;
  revokedAt?: string;
  inviterName: string;
  invitationScopeInformation?: string;
  clientData?: string;
  memberWelcomeUrl: string;
  invitationCompletedUrl: string;
  recipientName: string;
  recipientCountryCode?: string;
  recipientLanguageCode?: string;
  invitationState?:
    | "accepted"
    | "created"
    | "updated"
    | "declined"
    | "deliveryFailed"
    | "deliveryRequested"
    | "emailNotAvailable"
    | "revoked";
  memberStatus?: "invited" | "user";
  recipientIsNewUser?: boolean;
  id: string;
  created?: string;
  authorId?: string;
  modified?: string;
  editorId?: string;
  validFrom?: string;
  validUntil?: string;
};
export type InvitationTokenResponse = {
  email: string;
  token: string;
};
export type GrantedPermissionActions = {
  allowedActions: string[];
};
export type InternalPermissionWithGrantedActions = {
  name: string;
  description?: string;
  created?: string;
  authorId?: string;
  modified?: string;
  editorId?: string;
  id: string;
  grantedActions?: GrantedPermissionActions;
};
export type PermissionGrantsForPermission = {
  permissionId?: string;
  grantedActions?: string[];
  created?: string;
  authorId?: string;
  modified?: string;
  editorId?: string;
  id: string;
};
export type Organization = {
  name: string;
  type?: string;
  description?: string;
  created?: string;
  authorId?: string;
  modified?: string;
  editorId?: string;
  id: string;
};
export type OrganizationGroup = {
  name: string;
  type?: string;
  description?: string;
  created?: string;
  authorId?: string;
  modified?: string;
  editorId?: string;
  id: string;
};
export type Address = {
  country?: string;
  region?: string;
  formatted?: string;
  streetAddress?: string;
  locality?: string;
  postalCode?: string;
  created?: string;
  authorId?: string;
  modified?: string;
  editorId?: string;
  id: string;
};
export type User = {
  displayName?: string;
  address?: Address;
  email: string;
  nickname?: string;
  professionalTitle?: string;
  picture?: string;
  phoneNumber?: string;
  lastSignOn?: string;
  firstName: string;
  lastName: string;
  created?: string;
  authorId?: string;
  modified?: string;
  editorId?: string;
  id: string;
  validFrom?: string;
  validUntil?: string;
};
export type OrganizationRole = {
  name: string;
  description?: string;
  designator?: string;
  created?: string;
  authorId?: string;
  modified?: string;
  editorId?: string;
  id: string;
};
export type OrganizationRoleWithPermissionsWithGrantedActions = {
  name: string;
  description?: string;
  designator?: string;
  created?: string;
  authorId?: string;
  modified?: string;
  editorId?: string;
  id: string;
  permissions?: InternalPermissionWithGrantedActions[];
};
export type Permission = {
  name: string;
  description?: string;
  created?: string;
  authorId?: string;
  modified?: string;
  editorId?: string;
  id: string;
};
export type InternalRole = {
  name: string;
  description?: string;
  designator?: string;
  created?: string;
  authorId?: string;
  modified?: string;
  editorId?: string;
  id: string;
};
export type UserInvitation = {
  internalRoles: string[];
  email: string;
  claimedAt?: string;
  declinedAt?: string;
  revokedAt?: string;
  inviterName: string;
  invitationScopeInformation?: string;
  clientData?: string;
  memberWelcomeUrl: string;
  invitationCompletedUrl: string;
  recipientName: string;
  recipientCountryCode?: string;
  recipientLanguageCode?: string;
  invitationState?:
    | "accepted"
    | "created"
    | "updated"
    | "declined"
    | "deliveryFailed"
    | "deliveryRequested"
    | "emailNotAvailable"
    | "revoked";
  memberStatus?: "invited" | "user";
  recipientIsNewUser?: boolean;
  id: string;
  created?: string;
  authorId?: string;
  modified?: string;
  editorId?: string;
  validFrom?: string;
  validUntil?: string;
};
export type RecoveryEmail = {
  value?: string;
  validatedAt?: string;
  created?: string;
  authorId?: string;
  modified?: string;
  editorId?: string;
  id: string;
};
export type ValidationTokenResponse = {
  email: string;
  token: string;
};
export type OtpCredential = {
  secret?: string;
  verified?: boolean;
  qrCode?: string;
  keyUri?: string;
  created?: string;
  authorId?: string;
  modified?: string;
  editorId?: string;
  id: string;
};
/**
 * List invitations
 */
export async function listOrganizationGroupInvitations(
  {
    offset,
    limit,
    order,
    asc
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson("/organization-group-invitations", {
    ...opts,
    headers: {
      ...(opts && opts.headers),
      offset: offset !== undefined ? String(offset) : undefined,
      limit: limit !== undefined ? String(limit) : undefined,
      order,
      asc: asc !== undefined ? String(asc) : undefined
    }
  })) as OrganizationGroupInvitation[];
}
/**
 * Creates an invitation
 */
export async function createOrganizationGroupInvitation(
  organizationGroupInvitation: OrganizationGroupInvitation,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    "/organization-group-invitations",
    _.json({
      ...opts,
      method: "POST",
      body: organizationGroupInvitation
    })
  )) as OrganizationGroupInvitation;
}
/**
 * Accepts an invitation
 */
export async function acceptOrganizationGroupInvitation(
  body: {
    token: string;
  },
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    "/organization-group-invitations/accept",
    _.form({
      ...opts,
      method: "PUT",
      body
    })
  )) as OrganizationGroupInvitation;
}
/**
 * Lists accepted invitations
 */
export async function listAcceptedOrganizationGroupInvitations(
  {
    offset,
    limit,
    order,
    asc
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson("/organization-group-invitations/accepted", {
    ...opts,
    headers: {
      ...(opts && opts.headers),
      offset: offset !== undefined ? String(offset) : undefined,
      limit: limit !== undefined ? String(limit) : undefined,
      order,
      asc: asc !== undefined ? String(asc) : undefined
    }
  })) as OrganizationGroupInvitation[];
}
/**
 * Declines an invitation
 */
export async function declineOrganizationGroupInvitation(
  body: {
    token: string;
  },
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    "/organization-group-invitations/decline",
    _.form({
      ...opts,
      method: "PUT",
      body
    })
  )) as OrganizationGroupInvitation;
}
/**
 * Lists declined invitations
 */
export async function listDeclinedOrganizationGroupInvitations(
  {
    offset,
    limit,
    order,
    asc
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson("/organization-group-invitations/declined", {
    ...opts,
    headers: {
      ...(opts && opts.headers),
      offset: offset !== undefined ? String(offset) : undefined,
      limit: limit !== undefined ? String(limit) : undefined,
      order,
      asc: asc !== undefined ? String(asc) : undefined
    }
  })) as OrganizationGroupInvitation[];
}
/**
 * Lists open invitations
 */
export async function listOpenOrganizationGroupInvitations(
  {
    offset,
    limit,
    order,
    asc
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson("/organization-group-invitations/open", {
    ...opts,
    headers: {
      ...(opts && opts.headers),
      offset: offset !== undefined ? String(offset) : undefined,
      limit: limit !== undefined ? String(limit) : undefined,
      order,
      asc: asc !== undefined ? String(asc) : undefined
    }
  })) as OrganizationGroupInvitation[];
}
/**
 * Lists revoked invitations
 */
export async function listRevokedOrganizationGroupInvitations(
  {
    offset,
    limit,
    order,
    asc
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson("/organization-group-invitations/revoked", {
    ...opts,
    headers: {
      ...(opts && opts.headers),
      offset: offset !== undefined ? String(offset) : undefined,
      limit: limit !== undefined ? String(limit) : undefined,
      order,
      asc: asc !== undefined ? String(asc) : undefined
    }
  })) as OrganizationGroupInvitation[];
}
/**
 * Creates a new invitation and sends it by email to the recipient
 */
export async function createAndSendOrganizationGroupInvitation(
  organizationGroupInvitation: OrganizationGroupInvitation,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    "/organization-group-invitations/send",
    _.json({
      ...opts,
      method: "POST",
      body: organizationGroupInvitation
    })
  )) as OrganizationGroupInvitation;
}
/**
 * Reads an invitation
 */
export async function getOrganizationGroupInvitation(
  invitationId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(`/organization-group-invitations/${invitationId}`, {
    ...opts
  })) as OrganizationGroupInvitation;
}
/**
 * Deletes an invitation
 */
export async function deleteOrganizationGroupInvitation(
  invitationId: string,
  opts?: RequestOpts
) {
  return await _.fetch(`/organization-group-invitations/${invitationId}`, {
    ...opts,
    method: "DELETE"
  });
}
/**
 * Creates a new invitation token
 */
export async function createOrganizationGroupInvitationToken(
  invitationId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/organization-group-invitations/${invitationId}/create-token`,
    {
      ...opts,
      method: "POST"
    }
  )) as InvitationTokenResponse;
}
/**
 * Revokes an invitation
 */
export async function revokeOrganizationGroupInvitation(
  invitationId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/organization-group-invitations/${invitationId}/revoke`,
    {
      ...opts,
      method: "PUT"
    }
  )) as OrganizationGroupInvitation;
}
/**
 * Sends the invitation by email to the recipient
 */
export async function sendOrganizationGroupInvitation(
  invitationId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/organization-group-invitations/${invitationId}/send`,
    {
      ...opts,
      method: "PUT"
    }
  )) as OrganizationGroupInvitation;
}
/**
 * Gets all permissions of an organization role
 */
export async function listPermissionsOfOrganizationRole(
  roleId: string,
  {
    offset,
    limit,
    order,
    asc,
    filter
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
    filter?: string;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/organization-roles/${roleId}/permissions${QS.query(
      QS.form({
        filter
      })
    )}`,
    {
      ...opts,
      headers: {
        ...(opts && opts.headers),
        offset: offset !== undefined ? String(offset) : undefined,
        limit: limit !== undefined ? String(limit) : undefined,
        order,
        asc: asc !== undefined ? String(asc) : undefined
      }
    }
  )) as InternalPermissionWithGrantedActions[];
}
/**
 * Adds multiple permissions for an organization role
 */
export async function addPermissionsForOrganizationRole(
  roleId: string,
  body: PermissionGrantsForPermission[],
  opts?: RequestOpts
) {
  return await _.fetch(
    `/organization-roles/${roleId}/permissions`,
    _.json({
      ...opts,
      method: "POST",
      body
    })
  );
}
/**
 * Sets permissions of an organization role
 */
export async function setPermissionsOfOrganizationRole(
  roleId: string,
  body: PermissionGrantsForPermission[],
  opts?: RequestOpts
) {
  return await _.fetch(
    `/organization-roles/${roleId}/permissions`,
    _.json({
      ...opts,
      method: "PUT",
      body
    })
  );
}
/**
 * Removes permissions of an organization role
 */
export async function removePermissionsOfOrganizationRole(
  roleId: string,
  body: string[],
  opts?: RequestOpts
) {
  return await _.fetch(
    `/organization-roles/${roleId}/permissions`,
    _.json({
      ...opts,
      method: "DELETE",
      body
    })
  );
}
/**
 * Gets a permission of an organization role
 */
export async function getPermissionOfOrganizationRole(
  roleId: string,
  permissionId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/organization-roles/${roleId}/permissions/${permissionId}`,
    {
      ...opts
    }
  )) as InternalPermissionWithGrantedActions;
}
/**
 * Removes a permission of an organization role
 */
export async function removePermissionOfOrganizationRole(
  roleId: string,
  permissionId: string,
  opts?: RequestOpts
) {
  return await _.fetch(
    `/organization-roles/${roleId}/permissions/${permissionId}`,
    {
      ...opts,
      method: "DELETE"
    }
  );
}
/**
 * Lists organizations
 */
export async function listOrganizations(
  {
    offset,
    limit,
    order,
    asc
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson("/organizations", {
    ...opts,
    headers: {
      ...(opts && opts.headers),
      offset: offset !== undefined ? String(offset) : undefined,
      limit: limit !== undefined ? String(limit) : undefined,
      order,
      asc: asc !== undefined ? String(asc) : undefined
    }
  })) as Organization[];
}
/**
 * Creates a new organization
 */
export async function createOrganization(
  organization: Organization,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    "/organizations",
    _.json({
      ...opts,
      method: "POST",
      body: organization
    })
  )) as Organization;
}
/**
 * Replaces data of an organization
 */
export async function replaceOrganization(
  organization: Organization,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    "/organizations",
    _.json({
      ...opts,
      method: "PUT",
      body: organization
    })
  )) as Organization;
}
/**
 * Reads an organization
 */
export async function getOrganization(
  organizationId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(`/organizations/${organizationId}`, {
    ...opts
  })) as Organization;
}
/**
 * Deletes an organization
 */
export async function deleteOrganization(
  organizationId: string,
  opts?: RequestOpts
) {
  return await _.fetch(`/organizations/${organizationId}`, {
    ...opts,
    method: "DELETE"
  });
}
/**
 * Lists all organization group invitations
 */
export async function listOrganizationsOrganizationGroupInvitations(
  organizationId: string,
  {
    offset,
    limit,
    order,
    asc
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/organizations/${organizationId}/group-invitations`,
    {
      ...opts,
      headers: {
        ...(opts && opts.headers),
        offset: offset !== undefined ? String(offset) : undefined,
        limit: limit !== undefined ? String(limit) : undefined,
        order,
        asc: asc !== undefined ? String(asc) : undefined
      }
    }
  )) as OrganizationGroupInvitation[];
}
/**
 * Reads an organization group invitation
 */
export async function getOrganizationsOrganizationGroupInvitation(
  organizationId: string,
  invitationId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/organizations/${organizationId}/group-invitations/${invitationId}`,
    {
      ...opts
    }
  )) as OrganizationGroupInvitation;
}
/**
 * Lists organization groups
 */
export async function listOrganizationGroups(
  organizationId: string,
  {
    offset,
    limit,
    order,
    asc
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson(`/organizations/${organizationId}/groups`, {
    ...opts,
    headers: {
      ...(opts && opts.headers),
      offset: offset !== undefined ? String(offset) : undefined,
      limit: limit !== undefined ? String(limit) : undefined,
      order,
      asc: asc !== undefined ? String(asc) : undefined
    }
  })) as OrganizationGroup[];
}
/**
 * Creates a new organization group
 */
export async function createOrganizationGroup(
  organizationId: string,
  organizationGroup: OrganizationGroup,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/organizations/${organizationId}/groups`,
    _.json({
      ...opts,
      method: "POST",
      body: organizationGroup
    })
  )) as OrganizationGroup;
}
/**
 * Replaces data of an organization group
 */
export async function replaceOrganizationGroup(
  organizationId: string,
  organizationGroup: OrganizationGroup,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/organizations/${organizationId}/groups`,
    _.json({
      ...opts,
      method: "PUT",
      body: organizationGroup
    })
  )) as OrganizationGroup;
}
/**
 * Reads an organization group
 */
export async function getOrganizationGroup(
  organizationId: string,
  groupId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/organizations/${organizationId}/groups/${groupId}`,
    {
      ...opts
    }
  )) as OrganizationGroup;
}
/**
 * Deletes a group
 */
export async function deleteOrganizationGroup(
  organizationId: string,
  groupId: string,
  opts?: RequestOpts
) {
  return await _.fetch(`/organizations/${organizationId}/groups/${groupId}`, {
    ...opts,
    method: "DELETE"
  });
}
/**
 * Gets organization group members
 */
export async function listUsersInOrganizationGroup(
  organizationId: string,
  groupId: string,
  {
    offset,
    limit,
    order,
    asc,
    filter
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
    filter?: string;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/organizations/${organizationId}/groups/${groupId}/users${QS.query(
      QS.form({
        filter
      })
    )}`,
    {
      ...opts,
      headers: {
        ...(opts && opts.headers),
        offset: offset !== undefined ? String(offset) : undefined,
        limit: limit !== undefined ? String(limit) : undefined,
        order,
        asc: asc !== undefined ? String(asc) : undefined
      }
    }
  )) as User[];
}
/**
 * Adds multiple users to an organization group
 */
export async function addUsersToOrganizationGroup(
  organizationId: string,
  groupId: string,
  body: string[],
  opts?: RequestOpts
) {
  return await _.fetch(
    `/organizations/${organizationId}/groups/${groupId}/users`,
    _.json({
      ...opts,
      method: "POST",
      body
    })
  );
}
/**
 * Sets members of a group
 */
export async function setUsersInOrganizationGroup(
  organizationId: string,
  groupId: string,
  body: string[],
  opts?: RequestOpts
) {
  return await _.fetch(
    `/organizations/${organizationId}/groups/${groupId}/users`,
    _.json({
      ...opts,
      method: "PUT",
      body
    })
  );
}
/**
 * Removes users from a group
 */
export async function removeUsersFromOrganizationGroup(
  organizationId: string,
  groupId: string,
  body: string[],
  opts?: RequestOpts
) {
  return await _.fetch(
    `/organizations/${organizationId}/groups/${groupId}/users`,
    _.json({
      ...opts,
      method: "DELETE",
      body
    })
  );
}
/**
 * Gets a user of an organization group
 */
export async function getUserInOrganizationGroup(
  organizationId: string,
  groupId: string,
  userId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/organizations/${organizationId}/groups/${groupId}/users/${userId}`,
    {
      ...opts
    }
  )) as User;
}
/**
 * Adds a user to an organization group
 */
export async function addUserToOrganizationGroup(
  organizationId: string,
  groupId: string,
  userId: string,
  opts?: RequestOpts
) {
  return await _.fetch(
    `/organizations/${organizationId}/groups/${groupId}/users/${userId}`,
    {
      ...opts,
      method: "POST"
    }
  );
}
/**
 * Removes a user from a group
 */
export async function removeUserFromOrganizationGroup(
  organizationId: string,
  groupId: string,
  userId: string,
  opts?: RequestOpts
) {
  return await _.fetch(
    `/organizations/${organizationId}/groups/${groupId}/users/${userId}`,
    {
      ...opts,
      method: "DELETE"
    }
  );
}
/**
 * Lists organization roles
 */
export async function listOrganizationRoles(
  organizationId: string,
  {
    offset,
    limit,
    order,
    asc
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/organizations/${organizationId}/organization-roles`,
    {
      ...opts,
      headers: {
        ...(opts && opts.headers),
        offset: offset !== undefined ? String(offset) : undefined,
        limit: limit !== undefined ? String(limit) : undefined,
        order,
        asc: asc !== undefined ? String(asc) : undefined
      }
    }
  )) as OrganizationRole[];
}
/**
 * Creates a new organization role
 */
export async function createOrganizationRole(
  organizationId: string,
  organizationRole: OrganizationRole,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/organizations/${organizationId}/organization-roles`,
    _.json({
      ...opts,
      method: "POST",
      body: organizationRole
    })
  )) as OrganizationRole;
}
/**
 * Replaces data of an organization role
 */
export async function replaceOrganizationRole(
  organizationId: string,
  organizationRoleWithPermissionsWithGrantedActions: OrganizationRoleWithPermissionsWithGrantedActions,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/organizations/${organizationId}/organization-roles`,
    _.json({
      ...opts,
      method: "PUT",
      body: organizationRoleWithPermissionsWithGrantedActions
    })
  )) as OrganizationRole;
}
/**
 * Reads an organization role
 */
export async function getOrganizationRole(
  organizationId: string,
  roleId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/organizations/${organizationId}/organization-roles/${roleId}`,
    {
      ...opts
    }
  )) as OrganizationRole;
}
/**
 * Deletes an organization role
 */
export async function deleteOrganizationRole(
  organizationId: string,
  roleId: string,
  opts?: RequestOpts
) {
  return await _.fetch(
    `/organizations/${organizationId}/organization-roles/${roleId}`,
    {
      ...opts,
      method: "DELETE"
    }
  );
}
/**
 * Gets organization role members
 */
export async function listUsersInOrganizationRole(
  organizationId: string,
  roleId: string,
  {
    offset,
    limit,
    order,
    asc,
    filter
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
    filter?: string;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/organizations/${organizationId}/organization-roles/${roleId}/users${QS.query(
      QS.form({
        filter
      })
    )}`,
    {
      ...opts,
      headers: {
        ...(opts && opts.headers),
        offset: offset !== undefined ? String(offset) : undefined,
        limit: limit !== undefined ? String(limit) : undefined,
        order,
        asc: asc !== undefined ? String(asc) : undefined
      }
    }
  )) as User[];
}
/**
 * Adds multiple users to an organization role
 */
export async function addUsersToOrganizationRole(
  organizationId: string,
  roleId: string,
  body: string[],
  opts?: RequestOpts
) {
  return await _.fetch(
    `/organizations/${organizationId}/organization-roles/${roleId}/users`,
    _.json({
      ...opts,
      method: "POST",
      body
    })
  );
}
/**
 * Sets members of a role
 */
export async function setUsersInOrganizationRole(
  organizationId: string,
  roleId: string,
  body: string[],
  opts?: RequestOpts
) {
  return await _.fetch(
    `/organizations/${organizationId}/organization-roles/${roleId}/users`,
    _.json({
      ...opts,
      method: "PUT",
      body
    })
  );
}
/**
 * Removes users from a role
 */
export async function removeUsersFromOrganizationRole(
  organizationId: string,
  roleId: string,
  body: string[],
  opts?: RequestOpts
) {
  return await _.fetch(
    `/organizations/${organizationId}/organization-roles/${roleId}/users`,
    _.json({
      ...opts,
      method: "DELETE",
      body
    })
  );
}
/**
 * Gets a user of an organization role
 */
export async function getUserInOrganizationRole(
  organizationId: string,
  roleId: string,
  userId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/organizations/${organizationId}/organization-roles/${roleId}/users/${userId}`,
    {
      ...opts
    }
  )) as User;
}
/**
 * Adds a user to an organization role
 */
export async function addUserToOrganizationRole(
  organizationId: string,
  roleId: string,
  userId: string,
  opts?: RequestOpts
) {
  return await _.fetch(
    `/organizations/${organizationId}/organization-roles/${roleId}/users/${userId}`,
    {
      ...opts,
      method: "POST"
    }
  );
}
/**
 * Removes a user from a role
 */
export async function removeUserFromOrganizationRole(
  organizationId: string,
  roleId: string,
  userId: string,
  opts?: RequestOpts
) {
  return await _.fetch(
    `/organizations/${organizationId}/organization-roles/${roleId}/users/${userId}`,
    {
      ...opts,
      method: "DELETE"
    }
  );
}
/**
 * Lists organization users
 */
export async function listOrganizationUsers(
  organizationId: string,
  {
    offset,
    limit,
    order,
    asc,
    filter
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
    filter?: string;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/organizations/${organizationId}/users${QS.query(
      QS.form({
        filter
      })
    )}`,
    {
      ...opts,
      headers: {
        ...(opts && opts.headers),
        offset: offset !== undefined ? String(offset) : undefined,
        limit: limit !== undefined ? String(limit) : undefined,
        order,
        asc: asc !== undefined ? String(asc) : undefined
      }
    }
  )) as User[];
}
/**
 * Gets an organization user
 */
export async function getOrganizationUser(
  organizationId: string,
  userId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/organizations/${organizationId}/users/${userId}`,
    {
      ...opts
    }
  )) as User;
}
/**
 * Lists permissions
 */
export async function listPermissions(
  {
    offset,
    limit,
    order,
    asc
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson("/permissions", {
    ...opts,
    headers: {
      ...(opts && opts.headers),
      offset: offset !== undefined ? String(offset) : undefined,
      limit: limit !== undefined ? String(limit) : undefined,
      order,
      asc: asc !== undefined ? String(asc) : undefined
    }
  })) as Permission[];
}
/**
 * Creates a new permission
 */
export async function createPermission(
  permission: Permission,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    "/permissions",
    _.json({
      ...opts,
      method: "POST",
      body: permission
    })
  )) as Permission;
}
/**
 * Replace a permission
 */
export async function replacePermission(
  permission: Permission,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    "/permissions",
    _.json({
      ...opts,
      method: "PUT",
      body: permission
    })
  )) as Permission;
}
/**
 * Reads a permission
 */
export async function getPermission(permissionId: string, opts?: RequestOpts) {
  return (await _.fetchJson(`/permissions/${permissionId}`, {
    ...opts
  })) as Permission;
}
/**
 * Deletes a permission
 */
export async function deletePermission(
  permissionId: string,
  opts?: RequestOpts
) {
  return await _.fetch(`/permissions/${permissionId}`, {
    ...opts,
    method: "DELETE"
  });
}
/**
 * Lists roles
 */
export async function listRoles(
  {
    offset,
    limit,
    order,
    asc
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson("/roles", {
    ...opts,
    headers: {
      ...(opts && opts.headers),
      offset: offset !== undefined ? String(offset) : undefined,
      limit: limit !== undefined ? String(limit) : undefined,
      order,
      asc: asc !== undefined ? String(asc) : undefined
    }
  })) as InternalRole[];
}
/**
 * Creates a new role
 */
export async function createRole(
  internalRole: InternalRole,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    "/roles",
    _.json({
      ...opts,
      method: "POST",
      body: internalRole
    })
  )) as InternalRole;
}
/**
 * Replaces data of a role
 */
export async function replaceRole(
  internalRole: InternalRole,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    "/roles",
    _.json({
      ...opts,
      method: "PUT",
      body: internalRole
    })
  )) as InternalRole;
}
/**
 * Reads a role
 */
export async function getRole(roleId: string, opts?: RequestOpts) {
  return (await _.fetchJson(`/roles/${roleId}`, {
    ...opts
  })) as InternalRole;
}
/**
 * Deletes a role
 */
export async function deleteRole(roleId: string, opts?: RequestOpts) {
  return await _.fetch(`/roles/${roleId}`, {
    ...opts,
    method: "DELETE"
  });
}
/**
 * Gets all permissions of a role
 */
export async function listPermissionsOfRole(
  roleId: string,
  {
    offset,
    limit,
    order,
    asc,
    filter
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
    filter?: string;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/roles/${roleId}/permissions${QS.query(
      QS.form({
        filter
      })
    )}`,
    {
      ...opts,
      headers: {
        ...(opts && opts.headers),
        offset: offset !== undefined ? String(offset) : undefined,
        limit: limit !== undefined ? String(limit) : undefined,
        order,
        asc: asc !== undefined ? String(asc) : undefined
      }
    }
  )) as InternalPermissionWithGrantedActions[];
}
/**
 * Adds multiple permissions for a role
 */
export async function addPermissionsForRole(
  roleId: string,
  body: PermissionGrantsForPermission[],
  opts?: RequestOpts
) {
  return await _.fetch(
    `/roles/${roleId}/permissions`,
    _.json({
      ...opts,
      method: "POST",
      body
    })
  );
}
/**
 * Sets permissions of a role
 */
export async function setPermissionsOfRole(
  roleId: string,
  body: PermissionGrantsForPermission[],
  opts?: RequestOpts
) {
  return await _.fetch(
    `/roles/${roleId}/permissions`,
    _.json({
      ...opts,
      method: "PUT",
      body
    })
  );
}
/**
 * Removes permissions of a role
 */
export async function removePermissionsOfRole(
  roleId: string,
  body: string[],
  opts?: RequestOpts
) {
  return await _.fetch(
    `/roles/${roleId}/permissions`,
    _.json({
      ...opts,
      method: "DELETE",
      body
    })
  );
}
/**
 * Gets a permission of a role
 */
export async function getPermissionOfRole(
  roleId: string,
  permissionId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(`/roles/${roleId}/permissions/${permissionId}`, {
    ...opts
  })) as InternalPermissionWithGrantedActions;
}
/**
 * Removes a permission of a role
 */
export async function removePermissionOfRole(
  roleId: string,
  permissionId: string,
  opts?: RequestOpts
) {
  return await _.fetch(`/roles/${roleId}/permissions/${permissionId}`, {
    ...opts,
    method: "DELETE"
  });
}
/**
 * List invitations
 */
export async function listUserInvitations(
  {
    offset,
    limit,
    order,
    asc
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson("/user-invitations", {
    ...opts,
    headers: {
      ...(opts && opts.headers),
      offset: offset !== undefined ? String(offset) : undefined,
      limit: limit !== undefined ? String(limit) : undefined,
      order,
      asc: asc !== undefined ? String(asc) : undefined
    }
  })) as UserInvitation[];
}
/**
 * Creates an invitation
 */
export async function createUserInvitation(
  userInvitation: UserInvitation,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    "/user-invitations",
    _.json({
      ...opts,
      method: "POST",
      body: userInvitation
    })
  )) as UserInvitation;
}
/**
 * Accepts an invitation.
 */
export async function acceptUserInvitation(
  body: {
    token: string;
  },
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    "/user-invitations/accept",
    _.form({
      ...opts,
      method: "PUT",
      body
    })
  )) as UserInvitation;
}
/**
 * Lists accepted invitations
 */
export async function listAcceptedUserInvitations(
  {
    offset,
    limit,
    order,
    asc
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson("/user-invitations/accepted", {
    ...opts,
    headers: {
      ...(opts && opts.headers),
      offset: offset !== undefined ? String(offset) : undefined,
      limit: limit !== undefined ? String(limit) : undefined,
      order,
      asc: asc !== undefined ? String(asc) : undefined
    }
  })) as UserInvitation[];
}
/**
 * Declines an invitation.
 */
export async function declineUserInvitation(
  body: {
    token: string;
  },
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    "/user-invitations/decline",
    _.form({
      ...opts,
      method: "PUT",
      body
    })
  )) as UserInvitation;
}
/**
 * Lists declined invitations
 */
export async function listDeclinedUserInvitations(
  {
    offset,
    limit,
    order,
    asc
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson("/user-invitations/declined", {
    ...opts,
    headers: {
      ...(opts && opts.headers),
      offset: offset !== undefined ? String(offset) : undefined,
      limit: limit !== undefined ? String(limit) : undefined,
      order,
      asc: asc !== undefined ? String(asc) : undefined
    }
  })) as UserInvitation[];
}
/**
 * Lists open invitations
 */
export async function listOpenUserInvitations(
  {
    offset,
    limit,
    order,
    asc
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson("/user-invitations/open", {
    ...opts,
    headers: {
      ...(opts && opts.headers),
      offset: offset !== undefined ? String(offset) : undefined,
      limit: limit !== undefined ? String(limit) : undefined,
      order,
      asc: asc !== undefined ? String(asc) : undefined
    }
  })) as UserInvitation[];
}
/**
 * Lists revoked invitations
 */
export async function listRevokedUserInvitations(
  {
    offset,
    limit,
    order,
    asc
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson("/user-invitations/revoked", {
    ...opts,
    headers: {
      ...(opts && opts.headers),
      offset: offset !== undefined ? String(offset) : undefined,
      limit: limit !== undefined ? String(limit) : undefined,
      order,
      asc: asc !== undefined ? String(asc) : undefined
    }
  })) as UserInvitation[];
}
/**
 * Reads an invitation
 */
export async function getUserInvitation(
  invitationId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(`/user-invitations/${invitationId}`, {
    ...opts
  })) as UserInvitation;
}
/**
 * Deletes an invitation
 */
export async function deleteUserInvitation(
  invitationId: string,
  opts?: RequestOpts
) {
  return await _.fetch(`/user-invitations/${invitationId}`, {
    ...opts,
    method: "DELETE"
  });
}
/**
 * Creates a new invitation token
 */
export async function createUserInvitationToken(
  invitationId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(`/user-invitations/${invitationId}/create-token`, {
    ...opts,
    method: "POST"
  })) as InvitationTokenResponse;
}
/**
 * Revokes an invitation
 */
export async function revokeUserInvitation(
  invitationId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(`/user-invitations/${invitationId}/revoke`, {
    ...opts,
    method: "PUT"
  })) as UserInvitation;
}
/**
 * Sends the invitation by email to the recipient
 */
export async function sendUserInvitation(
  invitationId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(`/user-invitations/${invitationId}/send`, {
    ...opts,
    method: "PUT"
  })) as UserInvitation;
}
/**
 * Lists all users
 */
export async function listUsers(
  {
    offset,
    limit,
    order,
    asc
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson("/users", {
    ...opts,
    headers: {
      ...(opts && opts.headers),
      offset: offset !== undefined ? String(offset) : undefined,
      limit: limit !== undefined ? String(limit) : undefined,
      order,
      asc: asc !== undefined ? String(asc) : undefined
    }
  })) as User[];
}
/**
 * Creates a new user
 */
export async function createUser(user: User, opts?: RequestOpts) {
  return (await _.fetchJson(
    "/users",
    _.json({
      ...opts,
      method: "POST",
      body: user
    })
  )) as User;
}
/**
 * Replaces data of a user
 */
export async function replaceUser(user: User, opts?: RequestOpts) {
  return (await _.fetchJson(
    "/users",
    _.json({
      ...opts,
      method: "PUT",
      body: user
    })
  )) as User;
}
/**
 * Completes recovery email validation
 */
export async function validateUserRecoveryEmail(
  body: {
    email: string;
    token: string;
  },
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    "/users/recovery-emails/validate",
    _.form({
      ...opts,
      method: "PUT",
      body
    })
  )) as RecoveryEmail;
}
/**
 * Reads a user
 */
export async function getUser(userId: string, opts?: RequestOpts) {
  return (await _.fetchJson(`/users/${userId}`, {
    ...opts
  })) as User;
}
/**
 * Deletes a user
 */
export async function deleteUser(userId: string, opts?: RequestOpts) {
  return await _.fetch(`/users/${userId}`, {
    ...opts,
    method: "DELETE"
  });
}
/**
 * Lists authorized organizations of a user
 */
export async function listAuthorizedOrganizationsOfUser(
  userId: string,
  {
    offset,
    limit,
    order,
    asc
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson(`/users/${userId}/authorized-organizations`, {
    ...opts,
    headers: {
      ...(opts && opts.headers),
      offset: offset !== undefined ? String(offset) : undefined,
      limit: limit !== undefined ? String(limit) : undefined,
      order,
      asc: asc !== undefined ? String(asc) : undefined
    }
  })) as Organization[];
}
/**
 * Change email for a user
 */
export async function changeEmail(
  userId: string,
  body: {
    password?: string;
    email: string;
  },
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/users/${userId}/changeEmail`,
    _.form({
      ...opts,
      method: "PUT",
      body
    })
  )) as User;
}
/**
 * Lists organization group invitations
 */
export async function listUsersOrganizationGroupInvitations(
  userId: string,
  {
    offset,
    limit,
    order,
    asc
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson(`/users/${userId}/organization-group-invitations`, {
    ...opts,
    headers: {
      ...(opts && opts.headers),
      offset: offset !== undefined ? String(offset) : undefined,
      limit: limit !== undefined ? String(limit) : undefined,
      order,
      asc: asc !== undefined ? String(asc) : undefined
    }
  })) as OrganizationGroupInvitation[];
}
/**
 * Reads an organization group invitation
 */
export async function getUsersOrganizationGroupInvitation(
  userId: string,
  invitationId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/users/${userId}/organization-group-invitations/${invitationId}`,
    {
      ...opts
    }
  )) as OrganizationGroupInvitation;
}
/**
 * Lists organization groups assigned to a user
 */
export async function listOrganizationGroupsOfUser(
  userId: string,
  {
    offset,
    limit,
    order,
    asc,
    filter
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
    filter?: string;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/users/${userId}/organization-groups${QS.query(
      QS.form({
        filter
      })
    )}`,
    {
      ...opts,
      headers: {
        ...(opts && opts.headers),
        offset: offset !== undefined ? String(offset) : undefined,
        limit: limit !== undefined ? String(limit) : undefined,
        order,
        asc: asc !== undefined ? String(asc) : undefined
      }
    }
  )) as OrganizationGroup[];
}
/**
 * Adds a user to an organization group
 */
export async function addOrganizationGroupForUser(
  userId: string,
  groupId: string,
  opts?: RequestOpts
) {
  return await _.fetch(`/users/${userId}/organization-groups/${groupId}`, {
    ...opts,
    method: "POST"
  });
}
/**
 * Removes a user from an organization group
 */
export async function removeOrganizationGroupOfUser(
  userId: string,
  groupId: string,
  opts?: RequestOpts
) {
  return await _.fetch(`/users/${userId}/organization-groups/${groupId}`, {
    ...opts,
    method: "DELETE"
  });
}
/**
 * Lists recovery emails
 */
export async function listUserRecoveryEmails(
  userId: string,
  {
    offset,
    limit,
    order,
    asc
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson(`/users/${userId}/recovery-emails`, {
    ...opts,
    headers: {
      ...(opts && opts.headers),
      offset: offset !== undefined ? String(offset) : undefined,
      limit: limit !== undefined ? String(limit) : undefined,
      order,
      asc: asc !== undefined ? String(asc) : undefined
    }
  })) as RecoveryEmail[];
}
/**
 * Creates a new recovery email
 */
export async function createUserRecoveryEmail(
  userId: string,
  recoveryEmail: RecoveryEmail,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/users/${userId}/recovery-emails`,
    _.json({
      ...opts,
      method: "POST",
      body: recoveryEmail
    })
  )) as RecoveryEmail;
}
/**
 * Replaces data of a recovery email
 */
export async function replaceUserRecoveryEmail(
  userId: string,
  recoveryEmail: RecoveryEmail,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/users/${userId}/recovery-emails`,
    _.json({
      ...opts,
      method: "PUT",
      body: recoveryEmail
    })
  )) as RecoveryEmail;
}
/**
 * Reads a recovery email
 */
export async function getUserRecoveryEmail(
  userId: string,
  emailId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(`/users/${userId}/recovery-emails/${emailId}`, {
    ...opts
  })) as RecoveryEmail;
}
/**
 * Deletes a recovery email
 */
export async function deleteUserRecoveryEmail(
  userId: string,
  emailId: string,
  opts?: RequestOpts
) {
  return await _.fetch(`/users/${userId}/recovery-emails/${emailId}`, {
    ...opts,
    method: "DELETE"
  });
}
/**
 * Creates a new recovery email validation token.
 */
export async function createUserRecoveryEmailValidationToken(
  userId: string,
  emailId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/users/${userId}/recovery-emails/${emailId}/create-token`,
    {
      ...opts,
      method: "POST"
    }
  )) as ValidationTokenResponse;
}
/**
 * Creates a new recovery email validation token and sends verification email
 */
export async function sendUserRecoveryEmailValidation(
  userId: string,
  emailId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/users/${userId}/recovery-emails/${emailId}/send-validation`,
    {
      ...opts,
      method: "PUT"
    }
  )) as RecoveryEmail;
}
/**
 * Gets all roles of a user
 */
export async function listRolesOfUser(
  userId: string,
  {
    offset,
    limit,
    order,
    asc,
    filter
  }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
    filter?: string;
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/users/${userId}/roles${QS.query(
      QS.form({
        filter
      })
    )}`,
    {
      ...opts,
      headers: {
        ...(opts && opts.headers),
        offset: offset !== undefined ? String(offset) : undefined,
        limit: limit !== undefined ? String(limit) : undefined,
        order,
        asc: asc !== undefined ? String(asc) : undefined
      }
    }
  )) as InternalRole[];
}
/**
 * Adds multiple roles for a user
 */
export async function addRolesForUser(
  userId: string,
  body: string[],
  opts?: RequestOpts
) {
  return await _.fetch(
    `/users/${userId}/roles`,
    _.json({
      ...opts,
      method: "POST",
      body
    })
  );
}
/**
 * Sets roles of a user
 */
export async function setRolesOfUser(
  userId: string,
  body: string[],
  opts?: RequestOpts
) {
  return await _.fetch(
    `/users/${userId}/roles`,
    _.json({
      ...opts,
      method: "PUT",
      body
    })
  );
}
/**
 * Removes roles of a user
 */
export async function removeRolesOfUser(
  userId: string,
  body: string[],
  opts?: RequestOpts
) {
  return await _.fetch(
    `/users/${userId}/roles`,
    _.json({
      ...opts,
      method: "DELETE",
      body
    })
  );
}
/**
 * Gets a role of a user
 */
export async function getRoleOfUser(
  userId: string,
  roleId: string,
  opts?: RequestOpts
) {
  return (await _.fetchJson(`/users/${userId}/roles/${roleId}`, {
    ...opts
  })) as InternalRole;
}
/**
 * Add a role for a user
 */
export async function addRoleForUser(
  userId: string,
  roleId: string,
  opts?: RequestOpts
) {
  return await _.fetch(`/users/${userId}/roles/${roleId}`, {
    ...opts,
    method: "POST"
  });
}
/**
 * Removes a role of a user
 */
export async function removeRoleOfUser(
  userId: string,
  roleId: string,
  opts?: RequestOpts
) {
  return await _.fetch(`/users/${userId}/roles/${roleId}`, {
    ...opts,
    method: "DELETE"
  });
}
/**
 * Gets credential of a user
 */
export async function getOtpCredential(
  userId: string,
  {
    expand
  }: {
    expand?: "qrCode"[];
  } = {},
  opts?: RequestOpts
) {
  return await _.fetch(
    `/users/${userId}/security/totp${QS.query(
      QS.explode({
        expand
      })
    )}`,
    {
      ...opts
    }
  );
}
/**
 * Creates a new credential for a user
 */
export async function createOtpCredential(
  userId: string,
  {
    expand
  }: {
    expand?: "qrCode"[];
  } = {},
  opts?: RequestOpts
) {
  return (await _.fetchJson(
    `/users/${userId}/security/totp${QS.query(
      QS.explode({
        expand
      })
    )}`,
    {
      ...opts,
      method: "POST"
    }
  )) as OtpCredential;
}
/**
 * Deletes credential of a user
 */
export async function deleteOtpCredential(userId: string, opts?: RequestOpts) {
  return await _.fetch(`/users/${userId}/security/totp`, {
    ...opts,
    method: "DELETE"
  });
}
/**
 * Gets credential of a user as an image (QR-code).
 */
export async function getMfaImage(userId: string, opts?: RequestOpts) {
  return await _.fetch(`/users/${userId}/security/totp/qr`, {
    ...opts
  });
}
/**
 * Verifies credential of a user
 */
export async function verifyOtpCredential(
  userId: string,
  body: string[],
  opts?: RequestOpts
) {
  return await _.fetch(
    `/users/${userId}/security/totp/verify`,
    _.json({
      ...opts,
      method: "PUT",
      body
    })
  );
}
/**
 * Activate or suspend user
 */
export async function setUserActive(
  userId: string,
  status: boolean,
  opts?: RequestOpts
) {
  return (await _.fetchJson(`/users/${userId}/setActive/${status}`, {
    ...opts,
    method: "PUT"
  })) as User;
}
/**
 * Sets password for a user
 */
export async function setPassword(
  userId: string,
  body: {
    password: string;
    validate?: boolean;
    resetExisting?: boolean;
  },
  opts?: RequestOpts
) {
  return await _.fetch(
    `/users/${userId}/setPassword`,
    _.form({
      ...opts,
      method: "POST",
      body
    })
  );
}
