import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import logo from "./logo-purple.png";
import "./screen-view.scss";

//<editor-fold desc="Props">

interface ScreenDOMProps extends React.HTMLAttributes<HTMLDivElement> {}
interface ScreenProps extends ScreenDOMProps {
  /**
   * HTML meta data for the page
   */
  meta?: {
    /**
     * HTML>HEAD>TITLE
     */
    title: string;
    /**
     * HTML>HEAD>META[description]
     */
    description: string;
  };
  /**
   * Header for the page content
   */
  header?: ReactNode;
  /**
   * Icon to be displayed before logo
   */
  icon?: ReactNode;
}

export default function Screen(props: ScreenProps) {
  const { meta, header, children, className, icon, ...other } = props;
  return (
    <>
      {meta && (
        <Helmet>
          {meta.title && <title>{meta.title}</title>}
          {meta.description && (
            <meta name="description" content={meta.description} />
          )}
        </Helmet>
      )}
      <article
        className={className + " screen mt-4 p-4 page flex-grow-1 flex-column"}
        {...other}
      >
        <header className={"pb-4 mb-4"}>
          {icon && <div className={"mb-2 pb-4 text-center"}>{icon}</div>}
          <h1 className={"text-center"}>
            <img src={logo} alt={"Geograph"} title={""} />
          </h1>
        </header>
        <div className={"row"}>
          <div className={"col-12 col-md-6 offset-md-3"}>
            <div className={"card"}>
              {header && <header className={"card-header"}>{header}</header>}
              <div className={"card-body"}>{children}</div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
