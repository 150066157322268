import * as ActionTypes from "../actions/actionTypes";
import { OrgGroupUserIdsByOrgGroupId } from "../store/OrganizationGroupState";

export default function orgGroupUserIds(
  state: OrgGroupUserIdsByOrgGroupId,
  action: ActionTypes.AppAction
): OrgGroupUserIdsByOrgGroupId | null {
  const currentState = state || ({} as OrgGroupUserIdsByOrgGroupId);
  switch (action.type) {
    case ActionTypes.LIST_USERS_IN_ORG_GROUP:
      const listUsersInOrgGroup = action as ActionTypes.ListUsersInOrgGroupAction;
      const userIds = listUsersInOrgGroup.users.map(user => user.id as string);
      return { ...currentState, [listUsersInOrgGroup.orgGroupId]: userIds };
    case ActionTypes.ADD_USERS_TO_ORG_GROUP:
      const addUsersToOrgGroup = action as ActionTypes.AddUsersToOrgGroupAction;
      const beforeAddUsers = currentState[addUsersToOrgGroup.orgGroupId] || [];
      const afterAddUsers = [...beforeAddUsers, ...addUsersToOrgGroup.userIds];
      return {
        ...currentState,
        [addUsersToOrgGroup.orgGroupId]: afterAddUsers
      };
    case ActionTypes.SET_USERS_IN_ORG_GROUP:
      const setUsersInOrgGroup = action as ActionTypes.SetUsersInOrgGroupAction;
      return {
        ...currentState,
        [setUsersInOrgGroup.orgGroupId]: setUsersInOrgGroup.userIds
      };
    case ActionTypes.REMOVE_USERS_FROM_ORG_GROUP:
      const removeUsersFromOrgGroup = action as ActionTypes.RemoveUsersFromOrgGroupAction;
      const beforeRemoveUsers =
        currentState[removeUsersFromOrgGroup.orgGroupId] || [];
      const afterRemoveUsers = beforeRemoveUsers.filter(
        userId => removeUsersFromOrgGroup.userIds.indexOf(userId) === -1
      );
      return {
        ...currentState,
        [removeUsersFromOrgGroup.orgGroupId]: afterRemoveUsers
      };
    case ActionTypes.ADD_ORG_GROUP_FOR_USER:
      const addOrgGroupForUser = action as ActionTypes.AddOrgGroupForUserAction;
      const beforeAddGroup = currentState[addOrgGroupForUser.orgGroupId] || [];
      const afterAddGroup = [...beforeAddGroup, addOrgGroupForUser.userId];
      return {
        ...currentState,
        [addOrgGroupForUser.orgGroupId]: afterAddGroup
      };
    case ActionTypes.REMOVE_ORG_GROUP_OF_USER:
      const removeOrgGroupOfUser = action as ActionTypes.RemoveOrgGroupOfUserAction;
      const beforeRemoveGroup =
        currentState[removeOrgGroupOfUser.orgGroupId] || [];
      const afterRemoveGroup = beforeRemoveGroup.filter(
        userId => removeOrgGroupOfUser.userId !== userId
      );
      return {
        ...currentState,
        [removeOrgGroupOfUser.orgGroupId]: afterRemoveGroup
      };
    case ActionTypes.DELETE_USER:
      const deleteUser = action as ActionTypes.DeleteUserAction;
      let result = currentState;
      for (const orgGroupId in result) {
        const userIdsAfterDelete = result[orgGroupId].filter(
          userId => userId !== deleteUser.userId
        );
        result = { ...result, [orgGroupId]: userIdsAfterDelete };
      }
      return result;
    case ActionTypes.REMOVE_USER_FROM_ORG:
      const removeUserFromOrg = action as ActionTypes.RemoveUserFromOrgAction;
      let resultOfRemoveUserFromOrg = currentState;
      for (const removedFromOrgGroupId of removeUserFromOrg.orgGroupIds) {
        if (removedFromOrgGroupId in resultOfRemoveUserFromOrg) {
          const userIdsBeforeRemoveUserFromOrg =
            resultOfRemoveUserFromOrg[removedFromOrgGroupId];
          const userIdsAfterRemoveUserFromOrg = userIdsBeforeRemoveUserFromOrg.filter(
            userId => userId !== removeUserFromOrg.userId
          );
          resultOfRemoveUserFromOrg = {
            ...resultOfRemoveUserFromOrg,
            [removedFromOrgGroupId]: userIdsAfterRemoveUserFromOrg
          };
        }
      }
      return resultOfRemoveUserFromOrg;
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}
