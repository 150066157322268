import * as ActionTypes from "../actions/actionTypes";
import { OrgGroupIdsByOrgId } from "../store/OrganizationGroupState";

export default function orgOrgGroupIds(
  state: OrgGroupIdsByOrgId,
  action: ActionTypes.AppAction
): OrgGroupIdsByOrgId | null {
  const currentState = state || ({} as OrgGroupIdsByOrgId);
  switch (action.type) {
    case ActionTypes.LIST_ORG_GROUPS:
      const listOrgGroups = action as ActionTypes.ListOrgGroupsAction;
      const newOrgGroupIds = listOrgGroups.groups.map(
        group => group.id as string
      );
      return { ...currentState, [listOrgGroups.orgId]: newOrgGroupIds };
    case ActionTypes.CREATE_ORG_GROUP:
      const createOrgGroup = action as ActionTypes.CreateOrgGroupAction;
      const beforeCreate = currentState[createOrgGroup.orgId] || [];
      const afterCreate = [...beforeCreate, createOrgGroup.group.id as string];
      return {
        ...currentState,
        [createOrgGroup.orgId]: afterCreate
      };
    case ActionTypes.DELETE_ORG_GROUP:
      const delOrgGroup = action as ActionTypes.DeleteOrgGroupAction;
      const beforeDelete = currentState[delOrgGroup.orgId] || [];
      const afterDelete = beforeDelete.filter(
        groupId => groupId !== delOrgGroup.orgGroupId
      );
      return {
        ...currentState,
        [delOrgGroup.orgId]: afterDelete
      };
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}
