import React, { useEffect, useState } from "react";
import View, {
  UserLicensesModalProps as _UserLicensesModalProps,
  UserLicensesModalDataProps as _UserLicensesModalDataProps,
  UserLicensesModalVisibilityProps as _UserLicensesModalVisibilityProps,
  UserLicensesModalStateProps
} from "./user-licenses-modal-view";
import { UserLicensedItemAssignments } from "../../model/entitlement/UserLicensedItemAssignments";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { FeedbackEntry } from "../feedback";

export type UserLicensesModalVisibilityProps = _UserLicensesModalVisibilityProps;
export type UserLicensesModalDataProps = _UserLicensesModalDataProps;

export type UserLicensesModalProps = Omit<
  _UserLicensesModalProps,
  keyof UserLicensesModalStateProps
>;

export default function UserLicensesModal(props: UserLicensesModalProps) {
  const { licenses, userId, show, ...other } = props;
  const [feedback, setFeedback] = useState<FeedbackEntry[]>([]);
  const removeFeedback = (id: string) => {
    setFeedback(feedback.filter(val => val.id !== id));
  };
  const addFeedback = (f: FeedbackEntry) => {
    // filter out feedback with same id
    setFeedback(feedback.filter(val => val.id !== f.id).concat(f));
  };
  const [slicenses, setLicenses] = useState<
    UserLicensedItemAssignments[] | undefined
  >(cloneDeep(licenses));
  useEffect(() => {
    if (slicenses === undefined) {
      setLicenses(cloneDeep(licenses));
    }
  }, [slicenses, licenses]);
  useEffect(() => {
    if (userId) {
      // TODO: useEffect equality issue: this is a potential bu as the effect is hit on every render
      setLicenses(cloneDeep(licenses));
    } else {
      setLicenses(undefined);
    }
  }, [userId, licenses]);
  useEffect(() => {
    if (show !== true) {
      setLicenses(undefined);
    }
  }, [show]);
  const onToggleReservation = (id: string) => {
    if (slicenses) {
      const clone = cloneDeep(slicenses) as UserLicensedItemAssignments[];
      const ind = clone.findIndex((itm: UserLicensedItemAssignments) => {
        return itm.licensedItemId === id;
      });
      const itemAssignmentClone = clone[ind].assignments[0];
      const userIsConsuming = clone[ind].isConsuming;

      let isReserve = false;
      if (
        itemAssignmentClone.assignments &&
        itemAssignmentClone.assignments.length &&
        itemAssignmentClone.assignments[0] &&
        itemAssignmentClone.assignments[0].id
      ) {
        // real 2nd level assignment
        if (
          itemAssignmentClone.assignments[0].type &&
          itemAssignmentClone.assignments[0].type === "reserved"
        ) {
          isReserve = false;
          itemAssignmentClone.assignments[0].type = "floating";
        } else {
          isReserve = true;
          itemAssignmentClone.assignments[0].type = "reserved";
        }
      } else if (
        itemAssignmentClone.assignments &&
        itemAssignmentClone.assignments.length
      ) {
        // dummy 2nd level, remove checked by removing dummy
        isReserve = false;
        itemAssignmentClone.assignments = [];
      } else {
        // no 2nd level, add checked by adding dummy
        isReserve = true;
        itemAssignmentClone.assignments = [{ type: "reserved" }];
      }
      if (isReserve) {
        if (itemAssignmentClone && itemAssignmentClone.assignments) {
          if (itemAssignmentClone.assignments[0].validFrom) {
            if (
              new Date(
                itemAssignmentClone.assignments[0].validFrom as string
              ).getTime() > new Date().getTime()
            ) {
              itemAssignmentClone.assignments[0].validFrom = "now()";
            }
          } else {
            itemAssignmentClone.assignments[0].validFrom = "now()";
          }
          if (itemAssignmentClone.assignments[0].validUntil) {
            if (
              new Date(
                itemAssignmentClone.assignments[0].validUntil as string
              ).getTime() < new Date().getTime()
            ) {
              itemAssignmentClone.assignments[0].validUntil = undefined;
            }
          }
        }

        // do not modify seat counters for clone if user is consuming selected license.
        if (!userIsConsuming) {
          if (itemAssignmentClone.license.seatsTaken !== undefined) {
            itemAssignmentClone.license.seatsTaken += 1;
          } else {
            // fallback.
            itemAssignmentClone.license.seatsTaken = 1;
          }
        }
      } else {
        // do not modify seat counters for clone if user is consuming selected license.
        if (!userIsConsuming) {
          if (itemAssignmentClone.license.seatsTaken !== undefined) {
            itemAssignmentClone.license.seatsTaken -= 1;
          } else {
            // fallback.
            itemAssignmentClone.license.seatsTaken = 0;
          }
        }
      }

      setLicenses(clone);
    }
  };
  return (
    <View
      {...other}
      feedback={feedback}
      onShowFeedback={addFeedback}
      onHideFeedback={removeFeedback}
      licenses={slicenses}
      onToggleReservation={onToggleReservation}
      userId={userId}
      show={show}
      licensesChanged={!isEqual(slicenses, licenses)}
    />
  );
}
