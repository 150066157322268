import { Jose } from "jose-jwe-jws";
import oidcTokenHash from "oidc-token-hash";
import store, {
  getPendingAuthentication,
  saveStore,
  getAuthentication
} from "../store";
import { isAuthenticatedAndAuthorized } from "./authentication";
import {
  startAuthnWithGeneratedNonce,
  addErrorForAuthnResponse,
  addErrorForErrorFromServer,
  setAuthn,
  clearAuthnStatus,
  setLogoutCompleted
} from "../actions";
import { Unsubscribe } from "redux";
import { PendingAuthentication } from "../model/PendingAuthentication";
import { generateRandomString } from "./random";
import { ApiError } from "../model/ApiError";
import { Authentication } from "../model/Authentication";
import { getMockSignerPrivateKey, getMockSignerPublicKey } from "./mockKeys";
import _debug from "debug";
const debug = _debug("AppGatekeeperAuthenticator:base");

/**
 * OpenID Connect address record. See
 * https://openid.net/specs/openid-connect-core-1_0.html#AddressClaim
 */
interface Address {
  formatted?: string;
  street_address?: string;
  locality?: string;
  region?: string;
  postal_code?: string;
  country?: string;
}

/**
 * OpenID Connect ID token fields. See
 * https://openid.net/specs/openid-connect-core-1_0.html#IDToken
 * and
 * https://openid.net/specs/openid-connect-core-1_0.html#StandardClaims
 */
interface IdTokenFields {
  iss: string;
  sub: string;
  aud: string;
  exp: number;
  iat: number;
  auth_time?: number;
  nonce: string;
  acr?: string;
  amr?: string[];
  azp?: string;
  at_hash: string;
  name?: string;
  given_name?: string;
  family_name?: string;
  middle_name?: string;
  nickname?: string;
  preferred_username?: string;
  profile?: string;
  picture?: string;
  website?: string;
  email: string;
  email_verified?: boolean;
  gender?: string;
  birthdate?: string;
  zoneinfo?: string;
  locale?: string;
  phone_number?: string;
  phone_number_verified?: boolean;
  address?: Address;
  updated_at?: number;
  [key: string]: any;
}

enum AuthnHandlingStatus {
  /**
   * Authenticator has processed one of the possible authentication or logout operations
   * and there was nothing to be done, continue to the next operation.
   */
  Continue,

  /**
   * Browser has been redirected to another route of this application. The application should cease
   * all processing, and not render the UI.
   */
  Redirected,

  /**
   * Authenticator has completed handling with one of the following end states:
   *
   * - authenticated, SET_AUTHN action dispatched for setting the authenticated user to the Redux store
   * - logout completed, logout response received from the identity provider
   *
   */
  Handled
}

class AuthnResponseError extends Error {
  error: ApiError;
  constructor(error: ApiError, message?: string) {
    super(message || error.error_description || error.error);
    this.error = error;
  }
}

/**
 * Authenticator intended to be called before rendering the application.
 * Checks if there is a valid authentication and OAuth authorization,
 * and redirects to authentication by setting window.location.href
 * if no valid authentication found.
 *
 * This base class implements mock authentication process by directly
 * issuing redirect representing authentication response from the server.
 * Authentication against an external OpenID Connect (OIDC) provider
 * can be implemented in a derived class.
 */
export default class AppGatekeeperAuthenticatorBase {
  /**
   * Stored nonce expiration in seconds.
   */
  public static readonly NONCE_EXPIRES_IN = 300;

  /**
   * ID token must not be issued in the future, but allow some leeway when checking iat.
   * Leeway in seconds.
   */
  public static readonly ID_TOKEN_IAT_LEEWAY = 300;

  private static readonly STATE_PARAM_URL = "url";
  private static readonly STATE_PARAM_STATE = "state";

  private unsubscribeStateChange?: Unsubscribe;

  private authnUri: URL;
  private sloUri: URL;
  private redirectUri: URL;
  private clientId: string;
  private logoutPath: string;

  /**
   * Initializes a new instance of the authenticator.
   * @param authnUri URL of identity provider endpoint for authentication.
   * @param sloUri URL of identity provider endpoint for single logout (with 10Duke custom SLO protocol).
   * @param clientId OAuth client id used by this application when communicating with the IdP.
   * @param redirectUri OAuth redirect_uri for redirecting back to this application from the IdP.
   * @param logoutPath Local route path of this application for logout requests and logout
   *    responses from the IdP.
   */
  public constructor(
    authnUri: URL,
    sloUri: URL,
    clientId: string,
    redirectUri: URL,
    logoutPath: string
  ) {
    this.unsubscribeStateChange = undefined;
    this.authnUri = authnUri;
    this.sloUri = sloUri;
    this.clientId = clientId;
    this.redirectUri = redirectUri;
    this.logoutPath = logoutPath;
  }

  /**
   * Handles authentication, including logout.
   *
   * Checks if there is a valid authentication, or if there is an authentication response waiting
   * to be processed. Starts new authentication process if necessary.
   *
   * For logout, handles logout requests and logout responses from the identity provider.
   *
   * @param state Opaque state of the calling context to pass through the authentication
   *    process if this call starts authentication.
   * @returns true if application may continue running normally, false if browser is
   *    being redirected and there is nothing that should be done in the application
   */
  public async handleAuthentication(state?: string): Promise<boolean> {
    const logoutRequestHandlingStatus = this.handleLogoutRequest();
    if (logoutRequestHandlingStatus !== AuthnHandlingStatus.Continue) {
      debug("logout request found and handled");
      return false;
    }

    const logoutResponseHandlingStatus = this.handleLogoutResponse();
    if (logoutResponseHandlingStatus === AuthnHandlingStatus.Handled) {
      debug("logout response found and handled");
      return true;
    }

    const authnRespHandlingStatus = await this.handleAuthenticationResponse();
    if (authnRespHandlingStatus === AuthnHandlingStatus.Handled) {
      debug(
        "authentication response found and handled by either setting authentication status or by setting error status"
      );
      return true;
    }
    if (authnRespHandlingStatus === AuthnHandlingStatus.Redirected) {
      debug(
        "redirected authentication response to URL saved in the OAuth state"
      );
      return false;
    }

    const authentication = store.getState() && store.getState().authentication;
    const authenticated = isAuthenticatedAndAuthorized(authentication);
    if (authenticated) {
      debug("existing authentication found: %o", {
        ...authentication,
        accessToken: "***"
      });
      return true;
    }

    // Start authentication by first setting app state to reflect that there
    // is a pending authentication, then handling the state change and redirecting
    // to IdP for authentication
    this.unsubscribeStateChange = store.subscribe(() =>
      this.onStateChange(state)
    );
    debug("starting authentication");
    store.dispatch(startAuthnWithGeneratedNonce());
    return false;
  }

  /**
   * Checks if received an authentication response waiting to be handled, and
   * handles the response as necessary.
   */
  private async handleAuthenticationResponse(): Promise<AuthnHandlingStatus> {
    const authnResponse = this.readAuthenticationResponse();
    if (authnResponse === undefined) {
      debug("no authentication response found");
      return AuthnHandlingStatus.Continue;
    }

    const redirectTarget = await this.redirectAuthnResponse(authnResponse);
    if (redirectTarget) {
      debug(
        "authentication response redirected to %s",
        AppGatekeeperAuthenticatorBase.getUrlWithoutHash(redirectTarget)
      );
      return AuthnHandlingStatus.Redirected;
    }

    const error = authnResponse.get("error");
    if (error) {
      debug("authentication response has error sent by server: %s", error);
      this.clearAuthenticationStatus();
      this.notifyErrorResponse(authnResponse);
      return AuthnHandlingStatus.Handled;
    }

    try {
      await this.authenticate(authnResponse);
    } catch (err) {
      debug("authentication error: %o", err);
      this.clearAuthenticationStatus();
      this.notifyAuthnResponseError(err);
    }

    return AuthnHandlingStatus.Handled;
  }

  private clearAuthenticationStatus(): void {
    debug("clearing authentication status");
    store.dispatch(clearAuthnStatus());
  }

  /**
   * Validates authentication response from server and sets local authentication state.
   * Specs for validating response:
   * https://openid.net/specs/openid-connect-core-1_0.html#ImplicitAuthResponseValidation
   * https://tools.ietf.org/html/rfc6749#section-4.2.2
   * https://tools.ietf.org/html/rfc6749#section-10.12
   * @param authnResponse Authentication response from server.
   */
  private async authenticate(authnResponse: URLSearchParams): Promise<void> {
    debug("authentication response: %s", authnResponse.toString());

    if ("bearer" !== authnResponse.get("token_type")?.toLowerCase()) {
      throw new AuthnResponseError({
        error: "invalid_authn_response",
        error_description: "missing token_type"
      });
    }
    if (!authnResponse.has("expires_in")) {
      throw new AuthnResponseError({
        error: "invalid_authn_response",
        error_description: "missing expires_in"
      });
    }

    const idToken = await this.validateIdToken(authnResponse);
    debug("valid id token found: %o", idToken);
    const accessToken = this.validateAccessToken(authnResponse, idToken);
    const expiresIn = Number.parseInt(
      authnResponse.get("expires_in") as string
    );
    const userDisplayName = this.buildUserDisplayName(idToken);

    let state: string | undefined = undefined;
    const responseState = authnResponse.get("state");
    if (responseState && responseState.length > 0) {
      const stateParams = new URLSearchParams(responseState);
      state =
        stateParams.get(AppGatekeeperAuthenticatorBase.STATE_PARAM_STATE) ||
        undefined;
    }

    const now = new Date().getTime();
    const authentication: Authentication = {
      accessToken,
      accessTokenReceived: now,
      accessTokenExpiresIn: expiresIn,
      userId: idToken.sub,
      authnIssued: idToken.iat * 1000,
      authnExpires: idToken.exp * 1000,
      userDisplayName,
      userEmail: idToken.email,
      userLocale: idToken.locale,
      state
    };
    debug("setting authentication: %o", {
      ...authentication,
      accessToken: "***"
    });
    if (!isAuthenticatedAndAuthorized(authentication)) {
      throw new AuthnResponseError({
        error: "authentication_not_valid",
        error_description:
          "authentication data received from server is not valid"
      });
    }

    store.dispatch(setAuthn(authentication));
  }

  private buildUserDisplayName(idToken: IdTokenFields): string {
    if (idToken.name) {
      return idToken.name;
    }

    if (idToken.family_name || idToken.given_name) {
      let result = "";
      if (idToken.given_name) {
        result += idToken.given_name;
        if (idToken.family_name) {
          result += " ";
        }
      }
      if (idToken.family_name) {
        result += idToken.family_name;
      }
      return result;
    }

    return idToken.email;
  }

  private validateAccessToken(
    authnResponse: URLSearchParams,
    idToken: IdTokenFields
  ): string {
    const accessToken = authnResponse.get("access_token") as string;
    const expectedHash = oidcTokenHash.generate(accessToken, "RS256");
    if (expectedHash !== idToken.at_hash) {
      throw new AuthnResponseError({
        error: "invalid_access_token",
        error_description: "at_hash does not match"
      });
    }
    return accessToken;
  }

  private async validateIdToken(
    authnResponse: URLSearchParams
  ): Promise<IdTokenFields> {
    const idTokenPayload = await this.verifyIdTokenSignatureAndNonce(
      authnResponse
    );

    const expectedIssuer = AppGatekeeperAuthenticatorBase.getIdpIssuerId(
      this.authnUri
    );
    if (idTokenPayload.iss !== expectedIssuer) {
      throw new AuthnResponseError({
        error: "invalid_id_token",
        error_description: `invalid iss, got ${idTokenPayload.iss}, expected ${expectedIssuer}`
      });
    }
    if (idTokenPayload.aud !== this.clientId) {
      throw new AuthnResponseError({
        error: "invalid_id_token",
        error_description: `invalid aud, got ${idTokenPayload.aud}, expected ${this.clientId}`
      });
    }
    const epochSecsNow = Math.floor(new Date().getTime() / 1000);
    if (epochSecsNow > idTokenPayload.exp) {
      throw new AuthnResponseError({
        error: "id_token_expired",
        error_description:
          "ID token has expired. Please check the clock on your local machine, and enable syncing with internet time server (NTP server) if possible."
      });
    }
    if (
      idTokenPayload.iat >
      epochSecsNow + AppGatekeeperAuthenticatorBase.ID_TOKEN_IAT_LEEWAY
    ) {
      throw new AuthnResponseError({
        error: "id_token_issued_in_future",
        error_description:
          'ID token "issued" timestamp is in the future. Please check the clock on your local machine, and enable syncing with internet time server (NTP server) if possible.'
      });
    }
    return idTokenPayload;
  }

  private isStoredNonceValid(nonceIssuedAt: number): boolean {
    return (
      new Date().getTime() <
      nonceIssuedAt + AppGatekeeperAuthenticatorBase.NONCE_EXPIRES_IN * 1000
    );
  }

  private async verifyIdTokenSignatureAndNonce(
    authnResponse: URLSearchParams
  ): Promise<IdTokenFields> {
    if (!authnResponse.has("id_token")) {
      throw new AuthnResponseError({
        error: "missing_id_token",
        error_description: "id_token must be specified"
      });
    }
    let expectedNonce;
    if (!window || !(window as any).Cypress) {
      const pendingAuthentication = getPendingAuthentication();
      expectedNonce = pendingAuthentication?.nonce;
      const nonceIssuedAt = pendingAuthentication?.nonceIssuedAt;
      if (expectedNonce === undefined || nonceIssuedAt === undefined) {
        throw new AuthnResponseError({
          error: "missing_stored_nonce",
          error_description:
            "Unexpected authentication response received from server, was authentication started in this application?"
        });
      }
      if (!this.isStoredNonceValid(nonceIssuedAt)) {
        throw new AuthnResponseError({
          error: "stored_nonce_expired",
          error_description:
            "Received authentication response from server but the authentication process has expired, please try again."
        });
      }
    }

    const idToken = authnResponse.get("id_token") as string;
    const verificationResult = await this.verifyIdToken(idToken);
    const idTokenFields = JSON.parse(
      verificationResult.payload
    ) as IdTokenFields;
    if (
      (!window || !(window as any).Cypress) &&
      expectedNonce !== idTokenFields.nonce
    ) {
      throw new AuthnResponseError({
        error: "nonce_mismatch",
        error_description: "invalid nonce in ID token received from the server"
      });
    }
    return idTokenFields;
  }

  private async verifyIdToken(idToken: string): Promise<any> {
    const cryptographer = new Jose.WebCryptographer();
    cryptographer.setContentSignAlgorithm("RS256");
    const verifier = new Jose.JoseJWS.Verifier(cryptographer, idToken, keyId =>
      this.getSignerPublicKey()
    );
    let verificationResults: any = undefined;
    try {
      verificationResults = await verifier.verify();
    } catch (err) {
      throw new AuthnResponseError({
        error: "id_token_verification_failed",
        error_description: "ID token signature verification failed"
      });
    }
    debug("ID token verification results: %o", verificationResults);
    const verificationResult = verificationResults[0];
    if (
      !verificationResult.verified ||
      verificationResult.payload === undefined
    ) {
      throw new AuthnResponseError({
        error: "invalid_id_token",
        error_description: "signature verification failed"
      });
    }
    return verificationResult;
  }

  protected async getSignerPublicKey(): Promise<CryptoKey> {
    return await getMockSignerPublicKey();
  }

  private async redirectAuthnResponse(
    authnResponse: URLSearchParams
  ): Promise<string | undefined> {
    const authnRequestState = authnResponse.get("state");
    if (
      authnRequestState === undefined ||
      authnRequestState === null ||
      authnRequestState.length === 0
    ) {
      return undefined;
    }

    const stateParams = new URLSearchParams(authnRequestState);
    if (!stateParams.has(AppGatekeeperAuthenticatorBase.STATE_PARAM_URL)) {
      return undefined;
    }

    let currentUrl = this.getCurrentUrlWithoutHash();
    const targetUrl = stateParams.get(
      AppGatekeeperAuthenticatorBase.STATE_PARAM_URL
    ) as string;
    if (currentUrl === targetUrl || !targetUrl.startsWith(currentUrl)) {
      return undefined;
    }

    const redirectTo =
      stateParams.get(AppGatekeeperAuthenticatorBase.STATE_PARAM_URL) +
      "#" +
      authnResponse.toString();
    window.location.replace(redirectTo);
    return redirectTo;
  }

  private notifyAuthnResponseError(error: AuthnResponseError): void {
    if (error && error.error) {
      store.dispatch(
        addErrorForAuthnResponse(error.error, "HANDLE_AUTHN_RESPONSE")
      );
    }
  }

  private notifyErrorResponse(authnResponse: URLSearchParams): void {
    store.dispatch(
      addErrorForErrorFromServer(
        authnResponse.get("error") as string,
        "HANDLE_AUTHN_RESPONSE",
        authnResponse.get("error_description") || undefined,
        authnResponse.get("error_uri") || undefined
      )
    );
  }

  private readAuthenticationResponse(): URLSearchParams | undefined {
    if (
      window.location.hash === undefined ||
      window.location.hash === null ||
      window.location.hash.length < 2
    ) {
      return undefined;
    }

    const responseParams = new URLSearchParams(window.location.hash.substr(1));
    window.location.hash = "";
    if (!responseParams.has("access_token") && !responseParams.has("error")) {
      return undefined;
    }
    return responseParams;
  }

  private async onStateChange(state?: string): Promise<void> {
    const pendingAuthentication = getPendingAuthentication();
    if (pendingAuthentication) {
      if (this.unsubscribeStateChange) {
        this.unsubscribeStateChange();
        this.unsubscribeStateChange = undefined;
      }

      try {
        await this.startAuthentication(pendingAuthentication, state);
      } catch (err) {
        debug("starting authentication failed: %o", err);
        this.clearAuthenticationStatus();
        this.notifyAuthnResponseError(err);
      }
    }
  }

  private async startAuthentication(
    pendingAuthentication: PendingAuthentication,
    state?: string
  ): Promise<void> {
    const authnUrl = this.buildAuthenticationRequestUrl(
      pendingAuthentication.nonce,
      state
    );
    saveStore();

    debug(`start authn by sending to ${authnUrl}`);
    await this.sendAuthenticationRequest(authnUrl);
  }

  protected async sendAuthenticationRequest(authnUrl: URL): Promise<void> {
    debug(
      "mock authentication, do not start authn but return mock authn response instead"
    );
    const authnResponseUrl = await this.buildMockAuthnResponseUrl(authnUrl);
    const urlStr = authnResponseUrl.toString();
    debug("send mock authn response by redirecting to %s", urlStr);
    window.location.assign(urlStr);
    if (
      AppGatekeeperAuthenticatorBase.getUrlWithoutHash(urlStr) ===
      this.getCurrentUrlWithoutHash()
    ) {
      window.location.reload();
    }
  }

  /**
   * Builds URL for navigating to identity provider for authentication.
   */
  private buildAuthenticationRequestUrl(nonce: string, state?: string): URL {
    let authnUrl = new URL(this.authnUri.toString());
    const query = authnUrl.searchParams || new URLSearchParams();
    const authnRequestState = this.buildAuthenticationRequestState(state);
    query.append("response_type", "id_token token");
    query.append("scope", "openid profile email");
    query.append("client_id", this.clientId);
    query.append("redirect_uri", this.redirectUri.toString());
    query.append("nonce", nonce);
    query.append("state", authnRequestState);
    authnUrl.search = query.toString();
    return authnUrl;
  }

  private buildAuthenticationRequestState(state?: string): string {
    const stateParams = new URLSearchParams();
    let currentUrl = this.getCurrentUrlWithoutHash();
    stateParams.append(
      AppGatekeeperAuthenticatorBase.STATE_PARAM_URL,
      currentUrl
    );
    if (state) {
      stateParams.append(
        AppGatekeeperAuthenticatorBase.STATE_PARAM_STATE,
        state
      );
    }
    return stateParams.toString();
  }

  private static getIdpIssuerId(authnUrl: URL): string {
    return `${authnUrl.protocol}//${authnUrl.host}`;
  }

  private async buildMockAuthnResponseUrl(authnUrl: URL): Promise<string> {
    const authnParams = authnUrl.searchParams;
    let authnResponseUrl = new URL(authnParams.get("redirect_uri") as string);

    const accessToken = generateRandomString(32);
    const idToken = await this.buildMockIdToken(authnUrl, accessToken);
    const state = authnParams.get("state");

    const responseParams = new URLSearchParams();
    responseParams.append("token_type", "Bearer");
    responseParams.append("access_token", accessToken);
    responseParams.append("id_token", idToken);
    responseParams.append("expires_in", "3600");
    if (state) {
      responseParams.append("state", state);
    }

    return `${authnResponseUrl}#${responseParams.toString()}`;
  }

  private async buildMockIdToken(
    authnUrl: URL,
    accessToken: string
  ): Promise<string> {
    const payload = this.buildMockIdTokenPayload(authnUrl, accessToken);
    const key = await getMockSignerPrivateKey();
    const cryptographer = new Jose.WebCryptographer();
    cryptographer.setContentSignAlgorithm("RS256");
    const signer = new Jose.JoseJWS.Signer(cryptographer);
    await signer.addSigner(key, "idp");
    const signResult = await signer.sign(payload);
    return signResult.CompactSerialize();
  }

  private buildMockIdTokenPayload(
    authnUrl: URL,
    accessToken: string
  ): IdTokenFields {
    const authnParams = authnUrl.searchParams;
    const now = new Date();
    const expiresInMillis = 3600000;

    const iss = AppGatekeeperAuthenticatorBase.getIdpIssuerId(authnUrl);
    const sub = "6d862e41-cbc9-4ad5-8951-7d26b0143e79";
    const aud = authnParams.get("client_id") as string;
    const exp = Math.floor((now.getTime() + expiresInMillis) / 1000);
    const iat = Math.floor(now.getTime() / 1000);
    const nonce = authnParams.get("nonce") as string;
    const at_hash = oidcTokenHash.generate(accessToken, "RS256");

    const name = "April S. Workman";
    const given_name = "April";
    const family_name = "Workman";
    const email = "AprilSWorkman@acme.inc";
    const email_verified = true;

    return {
      iss,
      sub,
      aud,
      exp,
      iat,
      nonce,
      at_hash,
      name,
      given_name,
      family_name,
      email,
      email_verified
    };
  }

  private static getUrlWithoutHash(url: string): string {
    return url.split("#")[0];
  }

  private getCurrentUrlWithoutHash(): string {
    return AppGatekeeperAuthenticatorBase.getUrlWithoutHash(
      window.location.href
    );
  }

  private handleLogoutRequest(): AuthnHandlingStatus {
    if (!this.isLogoutRequest()) {
      return AuthnHandlingStatus.Continue;
    }

    if (getAuthentication()) {
      const unsubscribe = store.subscribe(() => {
        const currentAuthn = getAuthentication();
        if (currentAuthn === undefined || currentAuthn === null) {
          unsubscribe();
          this.sendLogoutResponse();
        }
      });

      this.clearAuthenticationStatus();
    } else {
      this.sendLogoutResponse();
    }

    return AuthnHandlingStatus.Handled;
  }

  private sendLogoutResponse(): void {
    const logoutRequestParams = new URLSearchParams(
      window.location.search.substr(1)
    );
    const relayState = logoutRequestParams.get("RelayState");

    let logoutResponseUrl = new URL(this.sloUri.toString());
    if (relayState) {
      const query = logoutResponseUrl.searchParams || new URLSearchParams();
      query.append("RelayState", relayState);
      logoutResponseUrl.search = query.toString();
    }

    window.location.assign(logoutResponseUrl.toString());
  }

  private handleLogoutResponse(): AuthnHandlingStatus {
    if (!this.isLogoutResponse()) {
      return AuthnHandlingStatus.Continue;
    }

    const logoutResponseParams = new URLSearchParams(
      window.location.search.substr(1)
    );
    const relayState = logoutResponseParams.get("RelayState") || undefined;

    store.dispatch(setLogoutCompleted({ state: relayState }));

    return AuthnHandlingStatus.Handled;
  }

  private isLogoutRequest(): boolean {
    return (
      this.isLogoutCase() &&
      window.location.search.indexOf("success=") === -1 &&
      window.location.search.indexOf("RelayState=") !== -1
    );
  }

  private isLogoutResponse(): boolean {
    return (
      this.isLogoutCase() && window.location.search.indexOf("success=") !== -1
    );
  }

  private isLogoutCase(): boolean {
    let retVal = window.location.pathname.endsWith(this.logoutPath);
    if (!retVal && this.logoutPath.endsWith("/")) {
      retVal = window.location.pathname.endsWith(
        this.logoutPath.substr(0, this.logoutPath.length - 1)
      );
    } else if (!retVal) {
      retVal = window.location.pathname.endsWith(this.logoutPath + "/");
    }
    return retVal;
  }
}
