import { LicensedItem } from "../../../model/entitlement/LicensedItem";

export const LICENSED_ITEM_1: LicensedItem = {
  id: "a77bdcc2-42d0-4375-9d4d-3225dad6aed9",
  authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  created: "2020-06-11T06:00:00.000Z",
  editorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  modified: "2020-06-11T06:00:00.001Z",
  name: "EarthSim",
  displayName: "Earth simulator",
  description: "Simulates life on Earth",
  type: "feature"
};
export const LICENSED_ITEM_3: LicensedItem = {
  id: "16c8bb90-a12a-4af5-8f93-3d14339a2c35",
  authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  created: "2020-06-11T06:00:00.000Z",
  editorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  modified: "2020-06-11T06:00:00.001Z",
  name: "SolarSim",
  displayName: "Solar system simulator",
  description: "Simulates the solar system",
  type: "feature"
};

export const LICENSED_ITEM_2: LicensedItem = {
  id: "0a14e11c-08d5-4de0-a101-9253083b31b7",
  authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  created: "2020-06-11T06:00:00.000Z",
  name: "UniverseSim",
  displayName: "Universe simulator",
  description: "Simulates the universe",
  type: "feature"
};

export const LICENSED_ITEMS: LicensedItem[] = [
  LICENSED_ITEM_1,
  LICENSED_ITEM_2,
  LICENSED_ITEM_3
];
