import React, { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import Modal, { ModalProps } from "../modal";
import { LicenseUsersWithLicensedItemReservations } from "../../model/entitlement/LicenseUsersWithLicensedItemReservations";
import Table from "../table";
import { TableColumn } from "../table/table-view";
import "./license-usage-by-users.scss";
import Feedback from "../feedback/feedback-container";

const messages = defineMessages({
  noLicenses: {
    id: "license-usage-by-users-modal.no-licenses-message",
    defaultMessage: "There are no licenses to show"
  }
});
export interface LicenseUsageByUsersModalDataEntry {
  id: string;
  username: string;
  [key: string]: any; // has to be any for some reason, should be boolean
}

export interface LicenseUsageByUsersModalStateProps {
  tableData?: LicenseUsageByUsersModalDataEntry[];
  columns: TableColumn[];
}
export interface LicenseUsageByUsersModalProps
  extends LicenseUsageByUsersModalStateProps,
    ModalProps {
  data?: LicenseUsersWithLicensedItemReservations;
  onLoadData: () => void;
}

function LicenseUsageByUsersModal(props: LicenseUsageByUsersModalProps) {
  const { data, show, onLoadData, tableData, columns, ...other } = props;
  const noData = data === undefined;
  const noLicenses = columns.length <= 2;
  const intl = useIntl();

  useEffect(() => {
    if (show && noData) {
      onLoadData();
    }
  }, [show, noData, onLoadData]);
  return (
    <Modal
      {...other}
      show={show}
      id={"license-usage-by-users"}
      data-test-view-user-licenses
    >
      <>
        {noLicenses && (
          <Feedback
            key={"license-usage-by-users_no-licenses"}
            type={"danger"}
            show={true}
            autoClose={false}
            asChild={true}
          >
            <p>{intl.formatMessage(messages.noLicenses)}</p>
          </Feedback>
        )}
        {!noLicenses && columns && columns.length > 0 && (
          <Table<LicenseUsageByUsersModalDataEntry>
            pagination={false}
            identifyingColumn={"id"}
            columns={columns}
            search={false}
            data={tableData ? tableData : undefined}
          ></Table>
        )}
      </>
    </Modal>
  );
}

export default LicenseUsageByUsersModal;
