import React from "react";
import LicensesPage, { ModalKeys } from "../../components/pages/licenses";
import { useHistory, useLocation, useParams } from "react-router-dom";
export { ModalKeys } from "../../components/pages/licenses";

export default function Licenses() {
  const history = useHistory();
  const location = useLocation();
  let { modal, licenseId } = useParams();
  const onShowModal = (key: ModalKeys, licId?: string) => {
    let base: string | undefined;
    if (modal) {
      if (licenseId) {
        base = location.pathname.replace(`/${licenseId}/${modal}`, "");
      } else {
        base = location.pathname.replace(`/${modal}`, "");
      }
    } else {
      base = location.pathname;
    }
    if (licId) {
      history.push(`${base}/${licId}/${key}`);
    } else {
      history.push(`${base}/${key}`);
    }
  };
  const onHideModal = () => {
    if (licenseId) {
      history.push(location.pathname.replace(`/${licenseId}/${modal}`, ""));
    } else {
      history.push(location.pathname.replace(`/${modal}`, ""));
    }
  };
  return (
    <LicensesPage
      showModal={
        modal ? { key: modal as ModalKeys, licenseId: licenseId } : undefined
      }
      onShowModal={onShowModal}
      onHideModal={onHideModal}
    />
  );
}
