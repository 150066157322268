import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//<editor-fold desc="Props">
interface RowToolsToggleProps {
  /**
   * This is actually requried, but can't be marked as required due to usage via JSX "as" attribute
   * @param e
   */
  onClick?: (e: any) => void;
}
//</editor-fold>

const RowToolsToggle = React.forwardRef(
  (props: RowToolsToggleProps, ref: React.Ref<HTMLButtonElement>) => {
    const { onClick, ...other } = props;
    if (!props.onClick) {
      throw new Error("RowToolsToggle: Invalid props, onClick is required");
    }
    return (
      <button
        {...other}
        className={"unstyled"}
        ref={ref}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          if (props.onClick) {
            props.onClick(e);
          }
        }}
      >
        <FontAwesomeIcon icon="ellipsis-h" />
      </button>
    );
  }
);
export default RowToolsToggle;
