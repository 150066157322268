import * as ActionTypes from "../actions/actionTypes";
import { OrgUserIdsByOrgId } from "../store/OrganizationState";

export default function orgUserIds(
  state: OrgUserIdsByOrgId,
  action: ActionTypes.AppAction
): OrgUserIdsByOrgId | null {
  const currentState = state || ({} as OrgUserIdsByOrgId);
  switch (action.type) {
    case ActionTypes.LIST_ORG_USERS:
      const listOrgUsersCompleted = action as ActionTypes.ListOrgUsersAction;
      const userIds = listOrgUsersCompleted.users.map(
        user => user.id as string
      );
      return { ...currentState, [listOrgUsersCompleted.orgId]: userIds };
    case ActionTypes.DELETE_USER:
      const deleteUser = action as ActionTypes.DeleteUserAction;
      let result = currentState;
      for (const orgId in result) {
        const userIdsAfterDelete = result[orgId].filter(
          userId => userId !== deleteUser.userId
        );
        result = { ...result, [orgId]: userIdsAfterDelete };
      }
      return result;
    case ActionTypes.REMOVE_USER_FROM_ORG:
      const removeUserFromOrg = action as ActionTypes.RemoveUserFromOrgAction;
      const currentOrgUserIds = currentState[removeUserFromOrg.orgId] || [];
      const userIdsAfterRemoveUserFromOrg = currentOrgUserIds.filter(
        userId => userId !== removeUserFromOrg.userId
      );
      return {
        ...currentState,
        [removeUserFromOrg.orgId]: userIdsAfterRemoveUserFromOrg
      };
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}
