/**
 * Generates UUID according to RFC 4122
 */
export function generateRandomUuid(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, char => {
    let random = (Math.random() * 16) | 0;
    let value = char === "x" ? random : (random % 4) + 8;
    return value.toString(16);
  });
}
