import { Jose } from "jose-jwe-jws";
import AppGatekeeperAuthenticatorBase from "./AppGatekeeperAuthenticatorBase";
import _debug from "debug";
const debug = _debug("AppGatekeeperAuthenticator");

export default class AppGatekeeperAuthenticator extends AppGatekeeperAuthenticatorBase {
  private jwskUri: URL;
  private mock: boolean;

  /**
   * Initializes a new instance of the authenticator.
   * @param authnUri URL of identity provider endpoint for authentication.
   * @param sloUri URL of identity provider endpoint for single logout (with 10Duke custom SLO protocol).
   * @param clientId OAuth client id used by this application when communicating with the IdP.
   * @param jwksUri URL of identity provider endpoint for JWKS key service.
   * @param redirectUri OAuth redirect_uri for redirecting back to this application from the IdP.
   * @param logoutPath Local route path of this application for logout requests and logout
   *    responses from the IdP.
   * @param mock If true, use mock authentication instead of real authentication.
   */
  public constructor(
    authnUri: URL,
    sloUri: URL,
    clientId: string,
    jwksUri: URL,
    redirectUri: URL,
    logoutPath: string,
    mock: boolean = false
  ) {
    super(authnUri, sloUri, clientId, redirectUri, logoutPath);
    this.jwskUri = jwksUri;
    this.mock = mock;
  }

  protected async sendAuthenticationRequest(authnUrl: URL): Promise<void> {
    if (this.mock) {
      return super.sendAuthenticationRequest(authnUrl);
    }

    window.location.assign(authnUrl.toString());
  }

  protected async getSignerPublicKey(): Promise<CryptoKey> {
    if (this.mock) {
      return super.getSignerPublicKey();
    }

    const keys = await this.fetchKeys();
    if (keys && keys.length > 0) {
      const signerKey = keys[0];
      debug("Signer key from JWKS endpoint: %o", signerKey);
      return Jose.Utils.importRsaPublicKey(keys[0], "RS256");
    }
    throw new Error("No signer keys found");
  }

  private async fetchKeys(): Promise<JWKRSA[]> {
    const response = await fetch(this.jwskUri.toString());
    if (response.ok) {
      return (await response.json())["keys"] as JWKRSA[];
    }
    throw new Error(
      `Fetching signer keys failed with response status ${response.status}`
    );
  }
}
