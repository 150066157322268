import { LicenseModel } from "../../../model/entitlement/LicenseModel";

export const LICENSE_MODEL_1: LicenseModel = {
  id: "a77bdcc2-42d0-4375-9d4d-3225dad6aed9",
  authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  created: "2020-06-11T06:00:00.000Z",
  editorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  modified: "2020-06-11T06:00:00.001Z",
  name: "Subscription",
  description: "Default license model for one-month subscriptions",
  assignedLicenseConstraint: {
    requireReservation: true,
    allowReassign: true
  },
  concurrentSessions: {
    maxCache: 2,
    maxCheckOut: 2,
    maxTotal: 2,
    leaseTrackingMode: "useLeaseIdIfPresent",
    sessionAnchors: ["Hardware"]
  },
  leaseTimeBehavior: {
    leaseTimeCache: 3600000,
    leaseTimeCheckOut: 604800000,
    refreshTimeCache: 1800000,
    refreshTimeCheckOut: 518400000,
    cooldownTime: 300000,
    allowLeaseExtension: true,
    allowLeaseRelease: true
  },
  seatCountConstraint: {
    overuseExpr: "1"
  }
};
