/**
 *
 * @param className
 * @param requiredClassNames
 */
function ensureClassNames(
  className: string | undefined,
  requiredClassNames: string[]
): string | undefined {
  let retVal = className ? "" + className : undefined;
  for (let i in requiredClassNames) {
    if (!className || className.indexOf(requiredClassNames[i]) < 0) {
      retVal = (retVal ? retVal + " " : "") + requiredClassNames[i];
    }
  }
  return retVal;
}

export default {
  ensureClassNames
};
