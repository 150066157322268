import React, { useEffect, useRef, useState } from "react";

import ProgressIndicatorView, {
  ProgressIndicatorDOMProps as _ProgressIndicatorDOMProps,
  ProgressIndicatorProps as _ProgressIndicatorProps
} from "./progress-indicator-view";

const MINIMUM_DISPLAY_TIME = 400;

export type ProgressIndicatorDOMProps = _ProgressIndicatorDOMProps;
export interface ProgressIndicatorProps extends _ProgressIndicatorProps {}

export default function ProgressIndicator(props: _ProgressIndicatorProps) {
  const { show, ...other } = props;
  // The actual view state
  // time of render with show:true
  const [shown, setShown] = useState<number | undefined>();
  // delayed hide reference
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();
  useEffect(() => {
    if (show) {
      // input was true, store time and update view state if needed.
      // TODO: useEffect equality issue: this could cause an infite loop as the use effect is hit on every render
      setShown(new Date().getTime());
      if (timeoutRef.current) {
        // Cancel delayed hide
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
      }
    } else if (!timeoutRef.current) {
      // input was false
      if (shown && shown > 0) {
        // view was shown
        const timeout = MINIMUM_DISPLAY_TIME - (new Date().getTime() - shown);
        if (timeout > 0) {
          // delay hide to avoid a quick flicker of output
          timeoutRef.current = setTimeout(() => {
            if (timeoutRef.current) {
              // if delayed hide was not cancelled, update state
              setShown(undefined);
              timeoutRef.current = undefined;
            }
          }, timeout);
        } else {
          // minimum display time reached, hide immediately
          setShown(undefined);
        }
      }
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [show, shown]);
  return <ProgressIndicatorView show={!!shown} {...other} />;
}
