import React, { useState } from "react";
import ColumnToolsView, {
  ColumnToolsProps as _ColumnToolsProps,
  ColumnToolsVisibilityProps
} from "./column-tools-view";

type ColumnToolsProps = Omit<
  _ColumnToolsProps,
  keyof ColumnToolsVisibilityProps
>;

export default function ColumnTools(props: ColumnToolsProps) {
  const [showColumnTool, setShowColumnTool] = useState(false);
  const viewProps: ColumnToolsVisibilityProps = {
    onHide: () => {
      setShowColumnTool(false);
    },
    onShow: () => {
      setShowColumnTool(true);
    },
    show: showColumnTool
  };
  return <ColumnToolsView {...props} {...viewProps} />;
}
