import { connect } from "react-redux";
import { AppState } from "../../../store/AppState";
import LicensesView, {
  LicensesDOMProps,
  LicensesModalVisibilityProps,
  LicensesProps as _LicensesProps
} from "./licenses-container";
import { listEntitlementLicensesWithUsage } from "../../../actions/entActions";
import OrganizationUtils from "../../../utils/organization";
import {
  aggregateValidSeatCountCreditDataForLicense,
  getOrganizationLicenses
} from "../../../util/licenseUtil";
import {
  PickReduxDispatchProps,
  PickReduxStateProps
} from "../../../util/typeUtil";
import store from "../../../store";

export { ModalKeys } from "./licenses-container";

const sender = { key: "licensesPage" };

interface LicensesProps
  extends LicensesDOMProps,
    LicensesModalVisibilityProps {}
// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<_LicensesProps, LicensesProps>;
// Dispatch props provided to the wrapped component by this connect component, using mapDispatchToProps
type ReduxDispatchProps = PickReduxDispatchProps<_LicensesProps, LicensesProps>;
function mapStateToProps(state: AppState): ReduxStateProps {
  const org = OrganizationUtils.resolveSelectedFromState(state);
  let orgLicenses = undefined;
  if (org) {
    orgLicenses = getOrganizationLicenses(org.id as string, "any", state);
    if (orgLicenses) {
      orgLicenses = orgLicenses.map(
        aggregateValidSeatCountCreditDataForLicense
      );
    }
  }
  return {
    licenses: orgLicenses
  };
}

function mapDispatchToProps(dispatch: (t: any) => void): ReduxDispatchProps {
  return {
    onLoadLicenses: onLoadLicensesImpl
  };
}

function onLoadLicensesImpl() {
  store.dispatch(listEntitlementLicensesWithUsage(sender, "any"));
}

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  LicensesProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps
)(LicensesView);
