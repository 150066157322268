import * as ActionTypes from "../actions/actionTypes";
import { UsersById } from "../store/UserState";

export default function users(
  state: UsersById,
  action: ActionTypes.AppAction
): UsersById | null {
  const currentState = state || ({} as UsersById);
  switch (action.type) {
    case ActionTypes.LIST_ORG_USERS:
      const listOrgUsersCompleted = action as ActionTypes.ListOrgUsersAction;
      const newUsersById = listOrgUsersCompleted.users.reduce<UsersById>(
        (map, user) => {
          map[user.id as string] = user;
          return map;
        },
        {}
      );
      return { ...currentState, ...newUsersById };
    case ActionTypes.LIST_USERS_IN_ORG_GROUP:
      const listUsersInOrgGroup = action as ActionTypes.ListUsersInOrgGroupAction;
      const groupUsersById = listUsersInOrgGroup.users.reduce<UsersById>(
        (map, user) => {
          map[user.id as string] = user;
          return map;
        },
        {}
      );
      return { ...currentState, ...groupUsersById };
    case ActionTypes.GET_USER:
      const getUser = action as ActionTypes.GetUserAction;
      return { ...currentState, [getUser.user.id as string]: getUser.user };
    case ActionTypes.DELETE_USER:
      const deleteUser = action as ActionTypes.DeleteUserAction;
      const { [deleteUser.userId]: _, ...remaining } = currentState;
      return remaining;
    case ActionTypes.SET_USER_SUSPENDED:
      const setUserSuspended = action as ActionTypes.SetUserSuspendedAction;
      return {
        ...currentState,
        [setUserSuspended.user.id as string]: setUserSuspended.user
      };
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}
