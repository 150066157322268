import React, { useEffect } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "../confirm-modal";
import { UserLicensedItemAssignments } from "../../model/entitlement/UserLicensedItemAssignments";
import { resolveFreeSeatsForLicenseAndAssignmentsArray } from "../../util/licenseUtil";
import { User } from "../../model/User";
import UserUtils from "../../utils/user";
import Modal, { ModalProps } from "../modal";
import Feedback, { FeedbackEntry } from "../feedback";
import * as ActionTypes from "../../actions/actionTypes";
import LicenseUtils from "../../utils/licensed-item";
import TooltipTrigger from "../tooltip-trigger";
import "./user-licenses-modal-view.scss";
const messages = defineMessages({
  isConsumingTip: {
    id: "user-licenses-modal.is-consuming.tip",
    defaultMessage:
      "This user is currently consuming this license and will be allowed to continue until the lease expires. Assignments or unassignments will therefore not have any impact on remaining licenses count."
  },
  userAssignmentsLicenseNameFallback: {
    id: "user-licenses-modal.license-name-fallback",
    defaultMessage: "Unknown",
    description: "Shown if license name could not be resolved"
  },
  userAssignmentsUpdatedSuccessMessage: {
    id: "user-licenses-modal.license-update-success.message",
    defaultMessage: "{name}'s licenses updated for: {licenses}",
    description: "Success message for updating license"
  },
  userAssignmentsUpdatedFailureMessage: {
    id: "user-licenses-modal.license-update-failure.message",
    defaultMessage: "Updating {name}'s licenses failed for: {licenses}",
    description: "Failure message for updating license"
  },
  userLicensesModalNotFoundTitle: {
    id: "user-licenses-modal.not-found.title",
    defaultMessage: "User not found",
    description: "modal title"
  },
  userLicensesModalNotFoundPrimaryButtonLabel: {
    id: "user-licenses-modal.not-found.primary-button-label",
    defaultMessage: "Close",
    description: "button label"
  },
  userLicensesModalTitle: {
    id: "user-licenses-modal.title",
    defaultMessage: "{name}'s Licenses",
    description: "modal title"
  },
  userLicensesModalUnknownUserName: {
    id: "user-licenses-modal.unknown-user-name",
    defaultMessage: "User",
    description: "user name to use when real user name can not be resolved"
  },
  userLicensesModalPrimaryButtonTooltip: {
    id: "user-licenses-modal.primary-button-tooltip",
    defaultMessage: "No changes to save",
    description: "button label"
  },
  userLicensesModalPrimaryButtonLabel: {
    id: "user-licenses-modal.primary-button-label",
    defaultMessage: "Save",
    description: "button label"
  },
  userLicensesModalNoLicensesPrimaryButtonLabel: {
    id: "user-licenses-modal.no-licenses.primary-button-label",
    defaultMessage: "Close",
    description: "button label"
  },
  userLicensesModalSecondaryButtonLabel: {
    id: "user-licenses-modal.secondary-button-label",
    defaultMessage: "Cancel",
    description: "button label"
  },
  userLicensesModalConfirmTitle: {
    id: "user-licenses-modal.confirm.title",
    defaultMessage: "Edit Licenses",
    description: "dialog title"
  },
  userLicensesModalConfirmAcceptButtonLabel: {
    id: "user-licenses-modal.confirm.accept-button.label",
    defaultMessage: "Yes",
    description: "dialog title"
  },
  userLicensesModalConfirmCancelButtonLabel: {
    id: "user-licenses-modal.confirm.cancel-button.label",
    defaultMessage: "No",
    description: "dialog title"
  }
});
function resolveLicensedItemNameFromAssignments(
  licenses: UserLicensedItemAssignments[] | undefined,
  licenseId: string,
  fallback: string
): string {
  let retVal = fallback;
  if (licenses !== undefined && licenseId) {
    const t = licenses
      ? licenses.find(
          val =>
            val &&
            val.assignments &&
            val.assignments.length &&
            val.assignments[0] &&
            val.assignments[0].license &&
            val.assignments[0].license.id === licenseId
        )
      : undefined;
    retVal = resolveLicensedItemName(t, fallback);
  }
  return retVal;
}

function resolveLicensedItemName(
  lic: UserLicensedItemAssignments | undefined,
  fallback: string
): string {
  let retVal = lic
    ? lic.licensedItemDisplayName || lic.licensedItemName || fallback
    : fallback;
  return retVal;
}
export interface UserLicensesModalStateProps {
  licensesChanged?: boolean;
  onToggleReservation: (licensedItemId: string) => void;
  feedback?: FeedbackEntry[];
  onShowFeedback: (feedback: FeedbackEntry) => void;
  onHideFeedback: (id: string) => void;
}
export interface UserLicensesModalVisibilityProps
  extends Pick<ModalProps, "show" | "onClose"> {}
export interface UserLicensesModalDataProps {
  userId?: string;
}

export interface UserLicensesModalProps
  extends UserLicensesModalStateProps,
    UserLicensesModalVisibilityProps,
    UserLicensesModalDataProps {
  user?: User;
  onLoadUser: (userId: string) => void;
  licenses?: UserLicensedItemAssignments[];
  onLoadLicenses: (userId: string) => void;
  onApply: (
    lics: UserLicensedItemAssignments[] | undefined,
    userId: string
  ) => Promise<Array<ActionTypes.ManageUserLicenseAssignmentsAction | any>>;
}

export default function UserLicensesModalView(props: UserLicensesModalProps) {
  const {
    userId,
    user,
    onLoadUser,
    show,
    onClose,
    licenses,
    licensesChanged,
    onApply,
    onLoadLicenses,
    onToggleReservation,
    feedback,
    onShowFeedback,
    onHideFeedback,
    ...other
  } = props;
  const intl = useIntl();
  useEffect(() => {
    if (
      show &&
      userId &&
      (user === undefined || userId !== (user.id as string))
    ) {
      onLoadUser(userId);
    }
  }, [show, user, userId, onLoadUser]);
  useEffect(() => {
    if (show && user && licenses === undefined) {
      onLoadLicenses(user.id as string);
    }
  }, [show, user, licenses, onLoadLicenses]);
  const resourceUser = user
    ? UserUtils.resolveDisplayName(
        user,
        intl.formatMessage(messages.userLicensesModalUnknownUserName)
      )
    : intl.formatMessage(messages.userLicensesModalUnknownUserName);
  return (
    <>
      {feedback &&
        feedback.map(f => {
          return (
            <Feedback
              key={"user_licenses_modals_" + f.id}
              type={f.type}
              show={true}
              onClose={() => {
                onHideFeedback(f.id);
              }}
              autoClose={f.autoclose === true}
            >
              {f.msg}
            </Feedback>
          );
        })}
      {!user && (
        <Modal
          {...other}
          title={intl.formatMessage(messages.userLicensesModalNotFoundTitle)}
          show={show}
          onClose={onClose}
          primaryButton={{
            label: intl.formatMessage(
              messages.userLicensesModalNotFoundPrimaryButtonLabel
            )
          }}
          onPrimaryAction={() => {
            if (onClose) {
              onClose();
            }
          }}
        >
          <Feedback type={"danger"} show={true} asChild={true}>
            <p>
              <FormattedMessage
                id="user-licenses-modal.not-found.copy"
                defaultMessage="Something went wrong and the user could not be loaded. The user may have been removed or you don't have sufficient access rights."
                description="copy to be shown when there is no user to display"
              />
            </p>
          </Feedback>
        </Modal>
      )}
      {user && (!licenses || licenses.length === 0) ? (
        <Modal
          {...other}
          title={intl.formatMessage(messages.userLicensesModalTitle, {
            name: resourceUser
          })}
          show={show}
          onClose={onClose}
          primaryButton={{
            label: intl.formatMessage(
              messages.userLicensesModalNoLicensesPrimaryButtonLabel
            )
          }}
          onPrimaryAction={() => {
            if (onClose) {
              onClose();
            }
          }}
        >
          <>
            {!licenses || licenses.length === 0 ? (
              <p>
                <FormattedMessage
                  id="user-licenses-modal.no-licenses.copy"
                  defaultMessage="There are no licenses available for assignment."
                  description="copy to be shown when there are no licenses"
                />
              </p>
            ) : (
              undefined
            )}
          </>
        </Modal>
      ) : (
        undefined
      )}
      {user && licenses && licenses.length > 0 ? (
        <ConfirmModal
          {...other}
          size={"lg"}
          title={intl.formatMessage(messages.userLicensesModalTitle, {
            name: resourceUser
          })}
          confirmTitle={intl.formatMessage(
            messages.userLicensesModalConfirmTitle
          )}
          confirmContent={
            <p>
              <FormattedMessage
                id="user-licenses-modal.confirm.copy"
                defaultMessage="Are you sure you want to make these changes to {name}'s licenses?"
                description="copy to be shown when asking for confirmation to license edit."
                values={{
                  name: resourceUser
                }}
              />
            </p>
          }
          acceptButton={{
            label: intl.formatMessage(
              messages.userLicensesModalConfirmAcceptButtonLabel
            )
          }}
          cancelButton={{
            label: intl.formatMessage(
              messages.userLicensesModalConfirmCancelButtonLabel
            )
          }}
          show={show}
          onClose={onClose}
          primaryButton={{
            label: intl.formatMessage(
              messages.userLicensesModalPrimaryButtonLabel
            ),
            disabled: !licensesChanged,
            tooltip: !licensesChanged
              ? intl.formatMessage(
                  messages.userLicensesModalPrimaryButtonTooltip
                )
              : ""
          }}
          onPrimaryAction={() => {
            onApply(licenses, user.id as string).then(result => {
              const invalidResults: any[] = [];
              const validresults = result.filter(res => {
                if (res.errors && res.errors[userId as string]) {
                  invalidResults.push(res);
                }
                return !res.error;
              });
              if (validresults.length) {
                // some success, show combined feedback
                onShowFeedback({
                  id: "manageAssignments_success_" + userId,
                  msg: intl.formatMessage(
                    messages.userAssignmentsUpdatedSuccessMessage,
                    {
                      name: resourceUser,
                      licenses: validresults
                        .map(r =>
                          LicenseUtils.resolveDisplayName(
                            r.licenseUsage.licensedItem,
                            intl.formatMessage(
                              messages.userAssignmentsLicenseNameFallback
                            )
                          )
                        )
                        .join(", ")
                    }
                  ),
                  autoclose: true,
                  type: "success"
                });
              }
              if (invalidResults.length) {
                // some fail, show combined feedback
                onShowFeedback({
                  id: "manageAssignments_failure_" + userId,
                  msg: intl.formatMessage(
                    messages.userAssignmentsUpdatedFailureMessage,
                    {
                      name: resourceUser,
                      licenses: invalidResults
                        .map(
                          r =>
                            resolveLicensedItemNameFromAssignments(
                              licenses,
                              r.licenseId,
                              intl.formatMessage(
                                messages.userAssignmentsLicenseNameFallback
                              )
                            ) +
                            " (" +
                            r.errors[userId as string].message +
                            ")"
                        )
                        .join(", ")
                    }
                  ),
                  type: "danger"
                });
              }
            });
            if (onClose) {
              onClose();
            }
          }}
          secondaryButton={{
            label: intl.formatMessage(
              messages.userLicensesModalSecondaryButtonLabel
            )
          }}
          onSecondaryAction={onClose}
        >
          <>
            <div id="user-licenses-deck" className={"card-deck"}>
              {licenses && licenses.length > 0
                ? licenses.map(lic => {
                    // This is just a template for developing the html & scss.
                    const firstLevelAssignment = lic.assignments[0];
                    const secondLevelAssignment =
                      firstLevelAssignment.assignments &&
                      firstLevelAssignment.assignments.length
                        ? firstLevelAssignment.assignments[0]
                        : undefined;
                    const freeSeats = resolveFreeSeatsForLicenseAndAssignmentsArray(
                      lic.assignments
                    );
                    return (
                      <React.Fragment key={"itm_" + lic.licensedItemId}>
                        <input
                          type="checkbox"
                          className={"d-none"}
                          id={"customCheck" + lic.licensedItemId}
                          checked={
                            !!(
                              secondLevelAssignment &&
                              secondLevelAssignment.type === "reserved"
                            )
                          }
                          onChange={() => {
                            onToggleReservation(lic.licensedItemId);
                          }}
                          disabled={
                            freeSeats === 0 &&
                            !(
                              secondLevelAssignment &&
                              secondLevelAssignment.type === "reserved"
                            ) &&
                            !lic.isConsuming
                          }
                        />
                        <label
                          htmlFor={"customCheck" + lic.licensedItemId}
                          data-test-license-reservation={
                            secondLevelAssignment &&
                            secondLevelAssignment.type === "reserved"
                              ? "active"
                              : "inactive"
                          }
                          className={
                            "card flex-row align-items-stretch flex-sm-column mx-sm-1 my-1 text-left px-1 py-2 p-sm-0 w-100" +
                            (secondLevelAssignment &&
                            secondLevelAssignment.type === "reserved"
                              ? " active"
                              : "")
                          }
                        >
                          <div
                            className={
                              "card-body justify-content-end justify-content-sm-start align-items-center text-sm-center d-flex flex-row-reverse flex-sm-column"
                            }
                          >
                            <h3 className={"card-title h5"}>
                              {resolveLicensedItemName(
                                lic,
                                intl.formatMessage(
                                  messages.userAssignmentsLicenseNameFallback
                                )
                              )}
                            </h3>
                            <div
                              className={
                                "indicator mr-3 flex-shrink-0 mx-sm-auto"
                              }
                            >
                              <FontAwesomeIcon icon="check" />
                            </div>
                          </div>
                          <div className={"card-footer"}>
                            {lic.isConsuming && (
                              <TooltipTrigger
                                tipKey={"consumer" + lic.licensedItemId}
                                tip={intl.formatMessage(
                                  messages.isConsumingTip
                                )}
                              >
                                <small
                                  className={
                                    "badge badge-pill badge-warning mr-2"
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={"user-check"}
                                    className={"mr-1"}
                                  />
                                  <FormattedMessage
                                    id="user-licenses-modal.is-consuming.label"
                                    defaultMessage="Active"
                                  />
                                </small>
                              </TooltipTrigger>
                            )}
                            <small
                              className={
                                "badge badge-pill" +
                                (freeSeats === 0
                                  ? " badge-danger"
                                  : freeSeats < 3
                                  ? " badge-warning"
                                  : " badge-secondary")
                              }
                            >
                              <FormattedMessage
                                id="user-licenses-modal.remaining-seats-label"
                                defaultMessage="Remaining: {count}"
                                values={{
                                  count: freeSeats ? freeSeats : 0
                                }}
                              />
                            </small>
                          </div>
                        </label>
                      </React.Fragment>
                    );
                  })
                : undefined}
            </div>
          </>
        </ConfirmModal>
      ) : (
        undefined
      )}
    </>
  );
}
