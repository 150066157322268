import React, { ReactNode } from "react";
import Modal, { ModalProps } from "../modal";
import { ModalActionButton } from "../modal/modal-view";

export interface ConfirmActionButton extends ModalActionButton {
  disabled?: false;
}
export interface ConfirmModalConfirmStateProps {
  /**
   * confirmation state
   */
  confirm: boolean;
  /**
   * confirmation state
   */
  onUpdateConfirm: (b: boolean) => void;
}
export interface ConfirmModalProps
  extends ModalProps,
    ConfirmModalConfirmStateProps {
  /**
   * The title of the modal dialog
   */
  confirmTitle: ReactNode;
  /**
   * The content of the modal dialog
   */
  confirmContent: ReactNode;

  acceptButton: ConfirmActionButton;
  cancelButton: ConfirmActionButton;
}

function ConfirmModal(props: ConfirmModalProps) {
  const {
    children,
    confirm,
    title,
    confirmTitle,
    confirmContent,
    acceptButton,
    primaryButton,
    cancelButton,
    secondaryButton,
    onPrimaryAction,
    onSecondaryAction,
    onUpdateConfirm,
    ...other
  } = props;

  const mProps = {
    title: confirm ? confirmTitle : title,
    children: confirm ? confirmContent : children,
    primaryButton: confirm ? acceptButton : primaryButton,
    secondaryButton: confirm ? cancelButton : secondaryButton,
    onPrimaryAction: !confirm
      ? () => {
          onUpdateConfirm(true);
        }
      : onPrimaryAction,
    onSecondaryAction: confirm
      ? () => {
          onUpdateConfirm(false);
        }
      : onSecondaryAction,
    ...other
  };
  return <Modal {...mProps} />;
}
export default ConfirmModal;
