import { connect } from "react-redux";
import AppHeader, {
  AppHeaderProps as _AppHeaderProps,
  AppHeaderDOMProps,
  AppHeaderUser
} from "./app-header-view";
import { AppState } from "../../store/AppState";
import OrganizationUtils from "../../utils/organization";
import { Organization } from "../../model/Organization";
import { setOrg } from "../../actions";
import {
  PickReduxDispatchProps,
  PickReduxStateProps
} from "../../util/typeUtil";

// Own props, i.e. props that this component takes as input
interface AppHeaderProps extends AppHeaderDOMProps {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<_AppHeaderProps, AppHeaderProps>;
// Dispatch props provided to the wrapped component by this connect component, using mapDispatchToProps
type ReduxDispatchProps = PickReduxDispatchProps<
  _AppHeaderProps,
  AppHeaderProps
>;

function mapStateToProps(state: AppState): ReduxStateProps {
  const retVal: ReduxStateProps = {};

  const { organizations, appOrganizationIds, authentication } = state;
  const org = OrganizationUtils.resolveSelectedFromState(state);
  const organizationsToManage: Organization[] = [];
  if (appOrganizationIds && organizations) {
    appOrganizationIds.forEach(appOrgId =>
      organizationsToManage.push(organizations[appOrgId])
    );
  }
  retVal.selectedOrganization = org;
  retVal.organizations = organizationsToManage;

  let id: string | null = null;
  let email: string | null = null;
  let name: string | null = null;

  if (authentication) {
    if (authentication.userId) {
      id = authentication.userId;
    }
    if (authentication.userEmail) {
      email = authentication.userEmail;
    }
    if (authentication.userDisplayName) {
      name = authentication.userDisplayName;
    }
  }
  if (id) {
    retVal.user = {
      id,
      name,
      email
    } as AppHeaderUser;
  }
  return retVal;
}
function mapDispatchToProps(dispatch: (t: any) => void): ReduxDispatchProps {
  return {
    onSelectOrganization: (orgId: string) => {
      dispatch(setOrg(orgId));
    }
  };
}
export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  AppHeaderProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps
)(AppHeader);
