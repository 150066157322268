import React from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import IconLib from "./utils/icon-library";

import "./scss/styles.scss";
import App from "./components/app/";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store, {
  getAuthentication,
  getLogoutCompleted,
  hasErrors
} from "./store";
import { loadOrgs } from "./actions";
import MessageBundle from "./i18n/translations";
import AppGatekeeperAuthenticator from "./util/AppGatekeeperAuthenticator";
import { initializeApis } from "./api";
import {
  getAuthzUrl,
  getJwksUrl,
  getClientId,
  getSelectedIdpApiImpl,
  ApiImplementation,
  getLogoutPath,
  getSloUrl
} from "./api/ApiImplementation";
import _debug from "debug";
import LogoutScreen from "./components/logout-screen";
import ErrorScreen from "./components/error-screen";
import { getEnvParam } from "./util/env";
const debug = _debug("App:bootstrap");

// To support localization, resolve requested locale from path and select best match from available languages.
const selectedLocale = "en";
localStorage.setItem("debug", "*");

IconLib.init();

const currentUrl = new URL(window.location.href);

const idpAuthzUrl = getAuthzUrl();
debug("authz url: %s", idpAuthzUrl.toString());
const idpSloUrl = getSloUrl();
debug("single logout url: %s", idpSloUrl.toString());
const idpJwksUrl = getJwksUrl();
debug("JWKS url: %s", idpJwksUrl.toString());
const clientId = getClientId();
debug("client_id: %s", clientId);
const reactAppBase = getEnvParam("REACT_APP_BASE", "/");
debug("appBase: %s", reactAppBase);
const authnResponseUrl = new URL(
  `${currentUrl.protocol}//${currentUrl.host}${reactAppBase}`
);
debug("callback_uri: %s", authnResponseUrl.toString());
const logoutPath = getLogoutPath();
debug("local logout route: %s", logoutPath);
const apiImpl = getSelectedIdpApiImpl();
debug("API implementation: %s", ApiImplementation[apiImpl]);
const authenticator = new AppGatekeeperAuthenticator(
  idpAuthzUrl,
  idpSloUrl,
  clientId,
  idpJwksUrl,
  authnResponseUrl,
  logoutPath,
  apiImpl === ApiImplementation.internalMock
);

const unsubcribe = store.subscribe(() => {
  const auth = getAuthentication();
  const logoutCompleted = getLogoutCompleted();
  const errors = hasErrors();
  if (auth || logoutCompleted || errors) {
    unsubcribe();
    if (auth) {
      initializeApis();
      store.dispatch(loadOrgs({ key: "index" }));
    }

    ReactDOM.render(
      <IntlProvider
        defaultLocale={"en"}
        locale={selectedLocale}
        messages={MessageBundle[selectedLocale]}
      >
        {auth && (
          <Provider store={store}>
            <App />
          </Provider>
        )}
        {!auth && logoutCompleted && <LogoutScreen />}
        {!auth && !logoutCompleted && errors && (
          <ErrorScreen errors={store.getState().errors} />
        )}
      </IntlProvider>,
      document.getElementById("root")
    );
  }
});

authenticator.handleAuthentication();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
