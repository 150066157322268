import React from "react";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import Screen from "../screen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const messages = defineMessages({
  metaDescription: {
    id: "logout-screen.meta-description",
    defaultMessage: "Logout completed successfully"
  },
  metaTitle: {
    id: "logout-screen.meta-title",
    defaultMessage: "Logout completed"
  }
});
function LogoutScreen() {
  const intl = useIntl();
  return (
    <Screen
      id={"logout-screen"}
      className={"foobar"}
      data-test-logout-screen
      meta={{
        title: intl.formatMessage(messages.metaTitle),
        description: intl.formatMessage(messages.metaDescription)
      }}
      icon={
        <FontAwesomeIcon
          icon={"check-circle"}
          className={"text-primary"}
          size="4x"
        />
      }
      header={
        <h2 className={"text-primary"}>
          <FormattedMessage
            id="logout-screen.title"
            defaultMessage="Logout completed"
          />
        </h2>
      }
    >
      <p>
        <FormattedMessage
          id="logout-screen.copy"
          defaultMessage="You've been logged out successfully. In case you're using a public or shared computer, you may want to clear your browser history and cache before closing the browser."
        />
      </p>
      <div className={"py-2"}>
        <a
          className={"btn btn-lg btn-block btn-primary"}
          href={process.env.PUBLIC_URL + "/"}
        >
          <FormattedMessage
            id="logout-screen.btn-label"
            defaultMessage="Continue"
          />
        </a>
      </div>
    </Screen>
  );
}
export default LogoutScreen;
