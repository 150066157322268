import React, { useEffect } from "react";
import Page, { PageDOMProps } from "../page";
import { useIntl, defineMessages } from "react-intl";
import LicenseSummaryContent from "../../license-summary-content";
import { LicenseWithCredits } from "../../../model/entitlement/LicenseWithCredits";

//<editor-fold desc="Props">
export interface MyLicensesDOMProps extends PageDOMProps {}
export interface MyLicensesProps extends MyLicensesDOMProps {
  licenses?: LicenseWithCredits[];
  onLoadLicenses: (userId: string) => void;
  userId: string;
}

//</editor-fold>

//<editor-fold desc="Messages">
const messages = defineMessages({
  title: {
    id: "pages.my-licenses.title",
    defaultMessage: "My licenses",
    description: "Title for the My licenses screen"
  },
  description: {
    id: "pages.my-licenses.meta-description",
    defaultMessage: "Personal license overview",
    description: "Description for the My licenses screen"
  },
  licensingTitle: {
    id: "pages.my-licenses.licensing-card-title",
    defaultMessage: "Licensing",
    description: "Title for the Licensing card"
  }
});
//</editor-fold>

export default function MyLicenses(props: MyLicensesProps) {
  const intl = useIntl();
  const title = intl.formatMessage(messages.title);
  const description = intl.formatMessage(messages.description);
  const { licenses, userId, onLoadLicenses, ...other } = props;
  useEffect(() => {
    if (licenses === undefined && userId && onLoadLicenses) {
      onLoadLicenses(userId);
    }
  }, [licenses, userId, onLoadLicenses]);
  return (
    <Page
      data-test-my-licenses-page
      header={<h1>{title}</h1>}
      meta={{
        title,
        description
      }}
      {...other}
    >
      <div className={"row mb-md-4"}>
        <div className={"my-2 my-md-0 col-12 col-md-6"}>
          <div className={"card content-section"}>
            <div className={"card-header clearfix"}>
              <h2 className={"card-title d-inline-block"}>
                {intl.formatMessage(messages.licensingTitle)}
              </h2>
            </div>
            <div className={"card-body"}>
              <LicenseSummaryContent licenses={licenses} hideSeats={true} />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
