import { InternalApiError } from "../../model/ApiError";
import { User } from "../../model/User";
import { MOCK_DB } from "./mockData";
import { Organization } from "../../model/Organization";
import { generateRandomUuid } from "../../util/uuidUtil";

abstract class MockBase {
  initialize(initProvider: (name: string) => string): void {}

  protected getUserInternal(userId: string): { users: User[]; index: number } {
    let index = -1;
    let users: User[] | undefined = undefined;
    for (const orgUsers of MOCK_DB.ALL_USERS) {
      index = orgUsers.findIndex(user => user.id === userId);
      if (index !== -1) {
        users = orgUsers;
        break;
      }
    }

    if (index === -1) {
      throw this.build404();
    }

    return { users: users as User[], index };
  }

  protected getOrgIdsByUserId(userId: string): string[] {
    const retValue: string[] = [];
    for (const orgId in MOCK_DB.ALL_USERS_BY_ORG_ID) {
      const orgUsers = MOCK_DB.ALL_USERS_BY_ORG_ID[orgId];
      if (orgUsers && orgUsers.find(orgUser => orgUser.id === userId)) {
        retValue.push(orgId);
      }
    }

    return retValue;
  }

  protected getOrgById(orgId: string): Organization {
    const index = MOCK_DB.APP_ORGS.findIndex(org => org.id === orgId);
    if (index === -1) {
      throw new Error(`No organization found by id ${orgId}`);
    }
    return MOCK_DB.APP_ORGS[index];
  }

  protected undefinedTo404<T>(obj?: T): T {
    if (obj) {
      return obj;
    }

    throw this.build404();
  }

  protected emptyTo404<T>(objs?: T[]): T[] {
    if (objs && objs.length !== 0) {
      return objs;
    }

    throw this.build404();
  }

  protected minusOneTo404(index: number): number {
    if (index !== -1) {
      return index;
    }

    throw this.build404();
  }

  protected build404() {
    return new InternalApiError("not_found", "Resource not found");
  }

  protected buildTestError() {
    return new InternalApiError("test_error", "A failure waiting to happen");
  }

  protected notMinusOneTo409(index: number): number {
    if (index === -1) {
      return index;
    }

    throw this.build409();
  }

  protected build409() {
    return new InternalApiError(
      "conflict",
      "Conflict / resource already exists"
    );
  }

  protected randomUuid(): string {
    return generateRandomUuid();
  }
}

export default MockBase;
