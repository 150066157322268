import { connect } from "react-redux";
import SelectOrganization, {
  SelectOrganizationProps as _SelectOrganizationProps,
  SelectOrganizationDOMProps
} from "./select-organization-view";
import { AppState } from "../../../store/AppState";
import { Organization } from "../../../model/Organization";
import { setOrg } from "../../../actions";
import OrganizationUtils from "../../../utils/organization";
import {
  PickReduxDispatchProps,
  PickReduxStateProps
} from "../../../util/typeUtil";

export interface SelectOrganizationInputProps {}
export interface SelectOrganizationDispatchProps {}
// Own props
interface SelectOrganizationProps extends SelectOrganizationDOMProps {}
// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _SelectOrganizationProps,
  SelectOrganizationProps
>;
// Dispatch props provided to the wrapped component by this connect component, using mapDispatchToProps
type ReduxDispatchProps = PickReduxDispatchProps<
  _SelectOrganizationProps,
  SelectOrganizationProps
>;
function mapStateToProps(state: AppState): ReduxStateProps {
  const { organizations, appOrganizationIds } = state;
  const org = OrganizationUtils.resolveSelectedFromState(state);
  const organizationsToManage: Organization[] = [];
  if (appOrganizationIds && organizations) {
    appOrganizationIds.forEach(appOrgId =>
      organizationsToManage.push(organizations[appOrgId])
    );
  }
  return {
    selectedOrganization: org,
    organizations: organizationsToManage
  };
}

function mapDispatchToProps(dispatch: (t: any) => void): ReduxDispatchProps {
  return {
    onSelectOrganization: (orgId: string) => {
      dispatch(setOrg(orgId));
    }
  };
}

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  SelectOrganizationProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps
)(SelectOrganization);
