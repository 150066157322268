import * as ActionTypes from "../actions/actionTypes";
import { OrgRoleUserIdsByOrgRoleId } from "../store/OrganizationRoleState";

export default function orgRoleUserIds(
  state: OrgRoleUserIdsByOrgRoleId,
  action: ActionTypes.AppAction
): OrgRoleUserIdsByOrgRoleId | null {
  const currentState = state || ({} as OrgRoleUserIdsByOrgRoleId);
  switch (action.type) {
    case ActionTypes.LIST_USERS_IN_ORG_ROLE:
      const listUsersInOrgRole = action as ActionTypes.ListUsersInOrgRoleAction;
      const userIds = listUsersInOrgRole.users.map(user => user.id as string);
      return { ...currentState, [listUsersInOrgRole.orgRoleId]: userIds };
    case ActionTypes.DELETE_USER: {
      const deleteUser = action as ActionTypes.DeleteUserAction;
      let result = currentState;
      for (const orgRoleId in result) {
        const userIdsAfterDelete = result[orgRoleId].filter(
          userId => userId !== deleteUser.userId
        );
        result = { ...result, [orgRoleId]: userIdsAfterDelete };
      }
      return result;
    }
    case ActionTypes.REMOVE_USER_FROM_ORG: {
      const removeUserFromOrg = action as ActionTypes.RemoveUserFromOrgAction;
      let result = currentState;
      for (const orgRoleId of removeUserFromOrg.orgRoleIds) {
        if (orgRoleId in result) {
          const userIdsAfterRemove = result[orgRoleId].filter(
            userId => userId !== removeUserFromOrg.userId
          );
          result = { ...result, [orgRoleId]: userIdsAfterRemove };
        }
      }
      return result;
    }
    case ActionTypes.ADD_USERS_TO_ORG_ROLE: {
      const addUsersToOrgRole = action as ActionTypes.AddUsersToOrgRoleAction;
      return addUsersToOrgRoleInteral(
        currentState,
        addUsersToOrgRole.orgRoleId,
        addUsersToOrgRole.userIds
      );
    }
    case ActionTypes.REMOVE_USERS_FROM_ORG_ROLE: {
      const removeUsersFromOrgRole = action as ActionTypes.RemoveUsersFromOrgRoleAction;
      return removeUsersFromOrgRoleInternal(
        currentState,
        removeUsersFromOrgRole.orgRoleId,
        removeUsersFromOrgRole.userIds
      );
    }
    case ActionTypes.ADD_USER_TO_ORG_ROLE: {
      const addUserToOrgRole = action as ActionTypes.AddUserToOrgRoleAction;
      return addUsersToOrgRoleInteral(
        currentState,
        addUserToOrgRole.orgRoleId,
        [addUserToOrgRole.userId]
      );
    }
    case ActionTypes.REMOVE_USER_FROM_ORG_ROLE: {
      const removeUserFromOrgRole = action as ActionTypes.RemoveUserFromOrgRoleAction;
      return removeUsersFromOrgRoleInternal(
        currentState,
        removeUserFromOrgRole.orgRoleId,
        [removeUserFromOrgRole.userId]
      );
    }
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }

  function addUsersToOrgRoleInteral(
    currentState: OrgRoleUserIdsByOrgRoleId,
    roleId: string,
    userIds: string[]
  ): OrgRoleUserIdsByOrgRoleId {
    const updatedUsersOfRole: string[] = currentState[roleId]
      ? [...currentState[roleId]]
      : [];

    for (const userId of userIds) {
      if (!updatedUsersOfRole.includes(userId)) {
        updatedUsersOfRole.push(userId);
      }
    }
    return { ...currentState, [roleId]: updatedUsersOfRole };
  }

  function removeUsersFromOrgRoleInternal(
    currentState: OrgRoleUserIdsByOrgRoleId,
    roleId: string,
    userIds: string[]
  ): OrgRoleUserIdsByOrgRoleId {
    const updatedUsersOfRole = currentState[roleId]
      ? currentState[roleId].filter(userId => !userIds.includes(userId))
      : [];
    return { ...currentState, [roleId]: updatedUsersOfRole };
  }
}
