import React from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import CssUtils from "../../utils/css-utils";
import "./apply-input-view.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TooltipTrigger from "../tooltip-trigger";

//<editor-fold desc="Props">
export interface ApplyInputValueDispatchProps {
  /**
   * Event handler for the input change event
   */
  onValueChange: (value: string) => void;
}
type ApplyInputDOMProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "children"
>;
export interface ApplyInputProps
  extends ApplyInputDOMProps,
    ApplyInputValueDispatchProps {
  /**
   * Apply button label
   */
  applyLabel: string;
  /**
   * Placeholder label to be shown when the input has no value, and no active value
   */
  placeholder?: string;
  /**
   * Tooltip for the clear active icon button
   */
  clearInputTip: string;
  /**
   * Tooltip for the clear unapplied input icon button
   */
  clearDirtyInputTip: string;
  /**
   * Value of the input
   */
  value?: string;
  /**
   *  Active value ie. applied value
   */
  activeValue?: string;
  /**
   * Event handler for the apply event
   */
  onApply: (value: string) => void;
}
//</editor-fold>

function ApplyInput(props: ApplyInputProps) {
  //<editor-fold desc="Local variables">
  const requiredClassNames = ["duke-apply-input"];
  let {
    applyLabel,
    placeholder,
    clearInputTip,
    clearDirtyInputTip,
    value,
    onValueChange,
    onApply,
    className,
    activeValue,
    ...others
  } = props;
  let unappliedChanges = false;
  if (value !== activeValue && (value || activeValue)) {
    unappliedChanges = true;
    requiredClassNames.push("duke-apply-input-edited");
  }
  className = CssUtils.ensureClassNames(className, requiredClassNames);
  const handleApply = (e: any) => {
    onApply(value || "");
  };
  const handleChange = (e: any) => {
    onValueChange(e.target.value);
  };
  const handleEnter = (event: any) => {
    if (event.key === "Enter") {
      handleApply(event);
    }
  };
  //</editor-fold>

  return (
    <div className={className} {...others}>
      <InputGroup>
        <FormControl
          data-test-apply-input-input
          placeholder={activeValue || placeholder}
          value={value || ""}
          onKeyPress={handleEnter}
          onChange={handleChange}
          className={
            activeValue && activeValue.length ? "border-primary" : undefined
          }
        />
        <InputGroup.Append>
          {unappliedChanges && (
            <TooltipTrigger
              tip={clearDirtyInputTip}
              tipKey={"clearDirtyTip"}
              placement={"auto"}
            >
              <button
                className={"unstyled duke-apply-input-info m-2"}
                onClick={() => {
                  onValueChange(activeValue || "");
                }}
              >
                <span className={"d-block badge badge-warning text-black "}>
                  <FontAwesomeIcon icon="pen" />
                </span>
              </button>
            </TooltipTrigger>
          )}
          {!unappliedChanges && activeValue && activeValue.length && (
            <TooltipTrigger
              tip={clearInputTip}
              tipKey={"clearInputTip"}
              placement={"auto"}
            >
              <button
                data-test-apply-input-clear
                className={"unstyled duke-apply-input-info m-2"}
                onClick={() => {
                  onApply("");
                  onValueChange("");
                }}
              >
                <span className={"d-block badge badge-danger text-white"}>
                  <FontAwesomeIcon icon="times" />
                </span>
              </button>
            </TooltipTrigger>
          )}
          <Button
            data-test-apply-input-apply
            variant={
              unappliedChanges
                ? "warning"
                : activeValue && activeValue.length
                ? "primary"
                : "outline-secondary"
            }
            onClick={handleApply}
          >
            {applyLabel}
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </div>
  );
}

export default ApplyInput;
