import { AppState } from "../store/AppState";
import { Organization } from "../model/Organization";
import { User } from "../model/User";
import { OrganizationGroup } from "../model/OrganizationGroup";
import { OrganizationGroupInvitation } from "../model/OrganizationGroupInvitation";
import { OrganizationRole } from "../model/OrganizationRole";
import { OrgUserIdsByOrgId } from "../store/OrganizationState";

/**
 * Resolves selected organization either from explicitly set selection state or if there is only one
 * @param state
 */
function resolveSelectedFromState(state: AppState): Organization | undefined {
  const { selectedOrganizationId, organizations, appOrganizationIds } = state;
  let retVal = undefined;
  if (organizations && Object.keys(organizations).length) {
    if (selectedOrganizationId) {
      retVal = organizations[selectedOrganizationId];
    } else if (appOrganizationIds && appOrganizationIds.length === 1) {
      retVal = organizations[appOrganizationIds[0]];
    }
  }
  return retVal;
}
function resolveOrganizationGroupsFromState(
  state: AppState
): OrganizationGroup[] | undefined {
  let retVal = undefined;
  const { orgOrgGroupIds, orgGroups } = state;
  const org = resolveSelectedFromState(state);
  const selectedOrganizationId = org ? org.id : undefined;
  if (
    selectedOrganizationId &&
    orgOrgGroupIds &&
    orgOrgGroupIds[selectedOrganizationId] &&
    orgGroups
  ) {
    retVal = orgOrgGroupIds[selectedOrganizationId].map(
      (id: string) => orgGroups[id]
    );
  }
  return retVal;
}
function resolveOrganizationUsersFromState(
  state: AppState
): User[] | undefined {
  const { orgUserIds, users } = state;
  const org = resolveSelectedFromState(state);
  const selectedOrganizationId = org ? org.id : undefined;
  let retVal = undefined;
  if (selectedOrganizationId && orgUserIds && users) {
    if (selectedOrganizationId) {
      retVal = orgUserIds[selectedOrganizationId]
        ? orgUserIds[selectedOrganizationId].map(val => {
            return users[val];
          })
        : undefined;
    }
  }
  return retVal;
}
function resolveOrganizationInvitationsFromState(
  state: AppState
): OrganizationGroupInvitation[] | undefined {
  const { orgOrgInvitationIds, orgInvitations } = state;
  const org = resolveSelectedFromState(state);
  const selectedOrganizationId = org ? org.id : undefined;
  let retVal = undefined;
  if (selectedOrganizationId && orgOrgInvitationIds && orgInvitations) {
    retVal = orgOrgInvitationIds[selectedOrganizationId]
      ? orgOrgInvitationIds[selectedOrganizationId].map(val => {
          return orgInvitations[val];
        })
      : undefined;
  }
  return retVal;
}

/**
 * Check if given user is orgadmin for selected organization.
 * @param userId Id of user.
 * @param state Redux state.
 * @returns true if given user is orgadmin in given organization or false if not.
 * can return undefined if store is not properly initialized.
 */
export function resolveIsUserOrgAdminFromState(
  userId: string,
  state: AppState
): boolean | undefined {
  const orgAdmins = resolveOrgAdminsFromState(state, false);
  return orgAdmins
    ? orgAdmins.findIndex(id => id === userId) !== -1
    : undefined;
}

/**
 * Get count of orgadmins in selected organization.
 * @param state Redux state.
 * @returns Number of orgadmins in given organization. undefined if state not properly initialized.
 */
export function resolveOrgAdminCountFromState(
  state: AppState
): number | undefined {
  const result = resolveOrgAdminsFromState(state, true);
  return result ? result.length : undefined;
}

/**
 * Retrieve array of user id's that have this organization's org admin role assigned.
 * @param state Redux state.
 * @param requireMembership set to true if result set is to be filter by membership
 * (user must be part of organization group for this organization in addition to having org admin role.)
 * @returns Array of user ids that are org admin for selected organization or undefined.
 */
export function resolveOrgAdminsFromState(
  state: AppState,
  requireMembership: boolean
): string[] | undefined {
  const org = resolveSelectedFromState(state);
  if (!org) {
    return undefined;
  }

  // Retrieve orgadmin roles from store.
  const orgAdminRoles = resolveOrgAdminRolesFromState(org.id as string, state);
  if (!orgAdminRoles) {
    return undefined;
  }

  // Check store state for roles.
  const { orgRoleUserIds } = state;
  if (!orgRoleUserIds) {
    return undefined;
  }

  let userIds: string[] = [];

  // Get users that belong to orgadmin roles.
  for (const role of orgAdminRoles) {
    if (!orgRoleUserIds[role.id as string]) {
      return undefined;
    }

    userIds = userIds.concat(orgRoleUserIds[role.id as string]);
  }

  // filter by organization membership if required.
  if (requireMembership) {
    const { orgUserIds } = state;
    if (!orgUserIds || !orgUserIds[org.id as string]) {
      return undefined;
    }

    userIds = userIds.filter(userId =>
      (orgUserIds as OrgUserIdsByOrgId)[org.id as string].includes(userId)
    );
  }

  // Return result.
  return userIds;
}

/**
 * Resolve id of one orgadmin role for selected organization.
 * @param state Current state.
 * @returns Organization admin role or undefined if none set.
 */
export function resolveOrgAdminRoleFromState(
  state: AppState
): string | undefined {
  const org = resolveSelectedFromState(state);
  if (!org) {
    return undefined;
  }

  const orgadminRoles = resolveOrgAdminRolesFromState(org.id as string, state);
  if (!orgadminRoles || orgadminRoles.length === 0) {
    return undefined;
  }

  return orgadminRoles[0].id;
}

function resolveOrgAdminRolesFromState(
  orgId: string,
  state: AppState
): OrganizationRole[] | undefined {
  const { orgOrgRoleIds, orgRoles } = state;

  if (!orgOrgRoleIds || !orgRoles || !orgOrgRoleIds[orgId]) {
    return undefined;
  }

  const orgAdminRoles: OrganizationRole[] = [];

  for (const idOfOrgRole of orgOrgRoleIds[orgId]) {
    const orgRole = orgRoles[idOfOrgRole];
    if (orgRole && orgRole.designator === "orgadmin") {
      orgAdminRoles.push(orgRole);
    }
  }

  return orgAdminRoles;
}

export default {
  resolveSelectedFromState,
  resolveOrganizationUsersFromState,
  resolveOrganizationGroupsFromState,
  resolveOrganizationInvitationsFromState,
  resolveIsUserOrgAdminFromState,
  resolveOrgAdminCountFromState,
  resolveOrgAdminsFromState,
  resolveOrgAdminRoleFromState
};
