import React, { useEffect } from "react";
import Page, { PageDOMProps } from "../page";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../modal";
import { Organization as OrganizationModel } from "../../../model/Organization";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { User } from "../../../model/User";
import TooltipTrigger from "../../tooltip-trigger";
import { Link } from "react-router-dom";
import "./organization-view.scss";
import LicenseUsageByUsersModal from "../../license-usage-by-users-modal";
import { LicenseWithCredits } from "../../../model/entitlement/LicenseWithCredits";
import LicenseUsersModalView from "../../license-users-modal/license-users-modal-redux";
import LicenseSummaryContent from "../../license-summary-content";

export enum ModalKeys {
  viewUserLicenses = "view-user-licenses",
  assignLicenses = "assign",
  edit = "edit"
}
//<editor-fold desc="Props">

export interface OrganizationModalProps {
  showModal?: { key: ModalKeys; id?: string };
  onShowModal: (key: ModalKeys, id?: string) => void;
  onHideModal: () => void;
}
export interface OrganizationDOMProps extends PageDOMProps {}

export interface OrganizationProps
  extends OrganizationDOMProps,
    OrganizationModalProps {
  licenses?: LicenseWithCredits[];
  onLoadLicenses: () => void;
  organization: OrganizationModel;
  onUpdateOrganization: (org: OrganizationModel) => Promise<OrganizationModel>;
  adminCount?: number;
  onLoadAdminCount: () => void;
  members?: User[];
  inactiveCount: number;
  onLoadMembers: () => void;
}
//</editor-fold>

//<editor-fold desc="Messages">
const messages = defineMessages({
  viewMemberLicensesTitle: {
    id: "pages.organization.view-member-licenses.title",
    defaultMessage: "User licenses"
  },
  viewMemberLicensesCloseButtonLabel: {
    id: "pages.organization.view-member-licenses.close-button-label",
    defaultMessage: "Close"
  },
  title: {
    id: "pages.organization.title",
    defaultMessage: "Organization",
    description: "Title for the Organization screen"
  },
  description: {
    id: "pages.organization.meta-description",
    defaultMessage: "Organization overview and management",
    description: "Description for the Organization screen"
  },
  editOrgTitle: {
    id: "pages.organization.edit-org.title",
    defaultMessage: "Edit Organization",
    description: "title for the edit organization dialog"
  },
  editOrgSaveButtonLabel: {
    id: "pages.organization.edit-org.save-button.label",
    defaultMessage: "Save",
    description: "label for the save button"
  },
  editOrgSaveButtonErrorsTip: {
    id: "pages.organization.edit-org.save-button.error-tip",
    defaultMessage: "Please correct errors before saving",
    description: "tooltip for the save button when there are validation errors"
  },
  editOrgSaveButtonNoChangesTip: {
    id: "pages.organization.edit-org.save-button.no-changes-tip",
    defaultMessage: "There are no changes to save",
    description: "tooltip for the save button when there are no changes"
  },
  editOrgCancelButtonLabel: {
    id: "pages.organization.edit-org.cancel-button.label",
    defaultMessage: "Cancel",
    description: "label for the cancels button"
  },
  editOrgNameFieldLabel: {
    id: "pages.organization.edit-org.name-field.label",
    defaultMessage: "Name",
    description: "label for field"
  },
  editOrgNameFieldRequired: {
    id: "pages.organization.edit-org.name-field.required",
    defaultMessage: "Name is required",
    description: "Error message "
  },
  editOrgDescriptionFieldLabel: {
    id: "pages.organization.edit-org.description-field.label",
    defaultMessage: "Description",
    description: "label for field"
  },
  membersInactiveInfo: {
    id: "pages.organization.members.inactive-info",
    defaultMessage:
      "Inactive means that the user has not logged in for 30 days."
  }
});
//</editor-fold>

//<editor-fold desc="Utils">
const resolveValidityClassName = (key: string, errors: any) =>
  key in errors ? "is-invalid" : undefined;
//</editor-fold>

//<editor-fold desc="Partial renderers">

function renderMembersCard(props: {
  handleShow: (s: ModalKeys) => void;
  intl: { formatMessage: (s: any) => string };
  members: User[] | undefined;
  adminCount: number;
  inactiveCount: number;
}) {
  const { handleShow, members, intl, adminCount, inactiveCount } = props;
  return (
    <div className={"card content-section"} data-test-organization-members>
      <div className={"card-header clearfix"}>
        <h2 className={"mb-0 d-inline-block"}>
          <FormattedMessage
            id="pages.organization.members.title"
            defaultMessage="Users"
          />
        </h2>
        <button
          data-test-view-member-licenses-trigger
          className={"btn btn-sm btn-outline-primary float-right"}
          onClick={() => {
            handleShow(ModalKeys.viewUserLicenses);
          }}
        >
          <FormattedMessage
            id="pages.organization.members.view-licenses-button-label"
            defaultMessage="View licenses"
          />
        </button>
      </div>
      <div className={"card-body"}>
        <ul className={"list-unstyled"}>
          <li>
            <FormattedMessage
              id="pages.organization.members.count-label"
              defaultMessage="Count"
            />
            : <span data-test-members-count>{members && members.length}</span>
          </li>
          <li>
            <FormattedMessage
              id="pages.organization.members.admins-label"
              defaultMessage="Admins"
            />
            : {adminCount}
          </li>
          <li>
            <FormattedMessage
              id="pages.organization.members.inactive-label"
              defaultMessage="Inactive"
            />
            : {inactiveCount}
            <TooltipTrigger
              tipKey={"inactiveInfoTip"}
              tip={intl.formatMessage(messages.membersInactiveInfo)}
            >
              <FontAwesomeIcon
                icon={"info-circle"}
                className={"ml-1 text-info"}
              />
            </TooltipTrigger>
          </li>
        </ul>
      </div>
    </div>
  );
}
function renderOrganizationCard(props: {
  handleShow: (s: ModalKeys) => void;
  organization: OrganizationModel;
}) {
  const { handleShow, organization } = props;
  return (
    <div className={"card content-section"} data-test-organization-details>
      <div className={"card-header clearfix"}>
        <h2 className={"mb-0 d-inline-block"}>
          <FormattedMessage
            id="pages.organization.general.title"
            defaultMessage="General"
          />
        </h2>
        <button
          data-test-edit-organization-trigger
          className={"btn btn-sm btn-outline-primary float-right"}
          onClick={() => {
            handleShow(ModalKeys.edit);
          }}
        >
          <FontAwesomeIcon icon={"pen"} className={"mr-2"} />
          <FormattedMessage
            id="pages.organization.general.edit-button-label"
            defaultMessage="edit"
          />
        </button>
      </div>
      <div className={"card-body"}>
        <ul className={"list-unstyled"}>
          <li>
            <FormattedMessage
              id="pages.organization.general.name-label"
              defaultMessage="Name"
            />
            : {organization.name}
          </li>
          <li>
            <FormattedMessage
              id="pages.organization.general.description-label"
              defaultMessage="Description"
            />
            : {organization.description}
          </li>
        </ul>
      </div>
    </div>
  );
}
function renderEditOrganizationModal(props: {
  intl: { formatMessage: (s: any) => string };
  errors: { [key: string]: any };
  register: (s: any) => any;
  messages: any;
  handleClose: () => void;
  handleSubmit: (s: (d: any) => any) => () => void;
  onSubmit: (d: any) => any;
  formState: any;
  showModal: ModalKeys | undefined;
}) {
  const {
    intl,
    errors,
    register,
    messages,
    handleClose,
    handleSubmit,
    onSubmit,
    formState,
    showModal
  } = props;
  return (
    <Modal
      data-test-edit-organization
      title={intl.formatMessage(messages.editOrgTitle)}
      show={showModal === ModalKeys.edit}
      primaryButton={{
        label: intl.formatMessage(messages.editOrgSaveButtonLabel),
        disabled:
          (errors !== undefined &&
            errors !== null &&
            Object.keys(errors).length > 0) ||
          !formState.dirty,
        tooltip:
          errors !== undefined &&
          errors !== null &&
          Object.keys(errors).length > 0
            ? intl.formatMessage(messages.editOrgSaveButtonErrorsTip)
            : !formState.dirty
            ? intl.formatMessage(messages.editOrgSaveButtonNoChangesTip)
            : ""
      }}
      onPrimaryAction={() => {
        handleSubmit(data => {
          onSubmit(data).then((d: OrganizationModel) => {
            handleClose();
          });
        })();
      }}
      secondaryButton={{
        label: intl.formatMessage(messages.editOrgCancelButtonLabel)
      }}
      onSecondaryAction={() => {
        handleClose();
      }}
      closable={true}
      onClose={handleClose}
    >
      <Form noValidate>
        <Form.Group controlId="orgName">
          <Form.Label>
            {intl.formatMessage(messages.editOrgNameFieldLabel)}
          </Form.Label>
          <Form.Control
            name="name"
            ref={register({ required: true }) as RBRef}
            className={resolveValidityClassName("name", errors)}
          />
          {errors &&
            errors.name &&
            errors.name.type &&
            errors.name.type === "required" && (
              <Form.Control.Feedback type="invalid">
                {intl.formatMessage(messages.editOrgNameFieldRequired)}
              </Form.Control.Feedback>
            )}
        </Form.Group>
        <Form.Group controlId="orgDescription">
          <Form.Label>
            {intl.formatMessage(messages.editOrgDescriptionFieldLabel)}
          </Form.Label>
          <Form.Control
            as={"textarea"}
            name="description"
            ref={register({ required: false }) as RBRef}
            className={resolveValidityClassName("description", errors)}
          />
        </Form.Group>
      </Form>
    </Modal>
  );
}
//</editor-fold>

function Organization(props: OrganizationProps) {
  //<editor-fold desc="Local variables">
  const intl = useIntl();
  const title = intl.formatMessage(messages.title);
  const description = intl.formatMessage(messages.description);
  const {
    licenses,
    onLoadLicenses,
    organization,
    onHideModal,
    onShowModal,
    showModal,
    onUpdateOrganization,
    onLoadMembers,
    members,
    adminCount,
    inactiveCount,
    onLoadAdminCount,
    ...other
  } = props;
  useEffect(() => {
    if (licenses === undefined) {
      onLoadLicenses();
    }
  }, [licenses, onLoadLicenses]);
  const onSubmit = (data: any) => {
    return onUpdateOrganization({ ...organization, ...data });
  };
  //</editor-fold>

  //<editor-fold desc="Hooks">
  const { register, handleSubmit, reset, errors, formState } = useForm({
    mode: "onBlur",
    defaultValues: organization
  });
  useEffect(() => {
    // TODO: useEffect equality issue: this may cause bugs as the effect is hit on every render
    reset(organization);
  }, [organization, reset]);

  // reload users on initial mount
  useEffect(() => {
    if (members === undefined) {
      onLoadMembers();
    }
  }, [members, onLoadMembers]);

  // reload adminCount on initial mount
  useEffect(() => {
    if (adminCount === undefined) {
      onLoadAdminCount();
    }
  }, [adminCount, onLoadAdminCount]);
  //</editor-fold>

  return (
    <Page
      data-test-organization-page
      id={"organization-page"}
      header={<h1>{title}</h1>}
      meta={{
        title,
        description
      }}
      {...other}
    >
      <div className={"row mb-md-4"}>
        <div className={"my-2 my-md-0 col-12 col-md-6"}>
          {renderOrganizationCard({
            handleShow: (key: ModalKeys) => {
              onShowModal(key, undefined);
            },
            organization: organization
          })}
          {renderEditOrganizationModal({
            intl,
            errors,
            register,
            messages,
            handleClose: onHideModal,
            handleSubmit,
            onSubmit,
            formState,
            showModal: showModal ? showModal.key : undefined
          })}
        </div>
        <div className={"my-2 my-md-0 col-12 col-md-6"}>
          <div
            className={"card content-section"}
            data-test-organization-licenses
          >
            <div className={"card-header clearfix"}>
              <h2 className={"mb-0 d-inline-block"}>
                <FormattedMessage
                  id="pages.organization.licensing.title"
                  defaultMessage="Licenses"
                />
              </h2>
              <Link
                className={"btn btn-sm btn-outline-primary float-right"}
                to={"/licenses"}
                data-test-licenses-link
              >
                <FormattedMessage
                  id="pages.organization.licensing.edit-button.label"
                  defaultMessage="Manage licenses"
                />
              </Link>
            </div>
            <div className={"card-body"}>
              <LicenseSummaryContent
                licenses={licenses}
                createManageAssignmentsLink={(lic: LicenseWithCredits) => {
                  return (
                    <Link
                      data-test-license-summary-item-assign-link
                      className={"float-right btn btn-outline-secondary btn-sm"}
                      to={
                        "/organization/" +
                        (lic.id as string) +
                        "/" +
                        ModalKeys.assignLicenses
                      }
                    >
                      <FontAwesomeIcon icon={"pen"} className={"mr-2"} />
                      <FormattedMessage
                        id="pages.organization.licensing.edit-label"
                        defaultMessage="Licenses"
                      />
                    </Link>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={"row mb-md-2"}>
        <div className={"my-2 my-md-0 col-12 col-md-6"}>
          {renderMembersCard({
            intl,
            handleShow: (key: ModalKeys) => {
              onShowModal(key, undefined);
            },
            members,
            adminCount: adminCount || 0,
            inactiveCount: inactiveCount || 0
          })}
          <LicenseUsageByUsersModal
            data-test-view-member-licenses
            show={showModal && showModal.key === ModalKeys.viewUserLicenses}
            title={intl.formatMessage(messages.viewMemberLicensesTitle)}
            primaryButton={{
              label: intl.formatMessage(
                messages.viewMemberLicensesCloseButtonLabel
              )
            }}
            onPrimaryAction={onHideModal}
            closable={true}
            onClose={onHideModal}
          />
        </div>
      </div>
      <LicenseUsersModalView
        licenseId={showModal ? showModal.id : undefined}
        show={showModal && showModal.key === ModalKeys.assignLicenses}
        onClose={onHideModal}
      />
    </Page>
  );
}
/**
Not sure what this is, but seems to be required for the ref to function
 **/
type RBRef = string & ((ref: Element | null) => void);
export default Organization;
