import { LicenseWithCredits } from "../../../model/entitlement/LicenseWithCredits";
import { LICENSED_ITEM_1, LICENSED_ITEM_3 } from "./licensedItems";
import { LICENSE_MODEL_1 } from "./licenseModels";
import { LicenseUserWithAssignmentsAndSessions } from "../../../model/entitlement/LicenseUserWithAssignmentsAndSessions";
import { ORG_1_USERS } from "./org1Members";

const ORG_1_ENTITLEMENT_1_ID = "57797e0e-aa30-480f-b1d1-7a522afd4148";
const ORG_1_ENTITLEMENT_3_ID = "68e53df8-7db7-456a-8a1b-846175904d2e";

const ORG_1_LICENSE_1_ID: string = "5c5ac5e7-077e-403e-bafb-839404e603e1";
const ORG_1_LICENSE_3_ID: string = "7a8421e9-bfbf-4de3-af6b-b6e19b954245";
const ORG_1_LICENSE_1: LicenseWithCredits = {
  id: ORG_1_LICENSE_1_ID,
  authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  created: "2020-06-11T07:00:00.000Z",
  editorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  modified: "2020-06-11T07:01:00.000Z",
  validFrom: "2020-06-11T07:00:00.000Z",
  validUntil: "2120-06-11T07:00:00.000Z",
  active: true,
  entitlementId: ORG_1_ENTITLEMENT_1_ID,
  licensedItem: LICENSED_ITEM_1,
  licenseModelId: LICENSE_MODEL_1.id as string,
  licenseModelName: LICENSE_MODEL_1.name,
  effectiveLicenseModel: LICENSE_MODEL_1,
  seatsTaken: 1,
  seatsReserved: 1,
  seatsTotal: 10,
  seatCountCredits: [
    {
      id: "1f0fe667-3ad8-4a1b-aa25-87ff0137eabc",
      authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
      created: "2020-06-11T07:00:00.000Z",
      validFrom: "2020-06-11T07:00:00.000Z",
      validUntil: "2100-06-11T07:00:00.000Z",
      active: true,
      licenseId: ORG_1_LICENSE_1_ID,
      licenseOrderLineId: "aa971543-a76a-40fd-9fbf-0e6c49b7aeb3",
      seatCount: 10,
      seatsConsumed: 1
    }
  ]
};
const ORG_1_LICENSE_3: LicenseWithCredits = {
  id: ORG_1_LICENSE_3_ID,
  authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  created: "2020-06-11T07:00:00.000Z",
  editorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  modified: "2020-06-11T07:01:00.000Z",
  validFrom: "2020-06-11T07:00:00.000Z",
  validUntil: "2120-06-11T07:00:00.000Z",
  active: true,
  entitlementId: ORG_1_ENTITLEMENT_3_ID,
  licensedItem: LICENSED_ITEM_3,
  licenseModelId: LICENSE_MODEL_1.id as string,
  licenseModelName: LICENSE_MODEL_1.name,
  effectiveLicenseModel: LICENSE_MODEL_1,
  seatsTaken: 1,
  seatsReserved: 1,
  seatsTotal: 10,
  seatCountCredits: [
    {
      id: "60cc7471-0782-4663-87cc-4123d7b824f3",
      authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
      created: "2020-06-11T07:00:00.000Z",
      validFrom: "2020-06-11T07:00:00.000Z",
      validUntil: "2100-06-11T07:00:00.000Z",
      active: true,
      licenseId: ORG_1_LICENSE_3_ID,
      licenseOrderLineId: "237bb6bc-d1d1-4996-88de-3c91a9897032",
      seatCount: 10,
      seatsConsumed: 0
    }
  ]
};

export const ORG_1_LICENSES: LicenseWithCredits[] = [
  ORG_1_LICENSE_1,
  ORG_1_LICENSE_3
];

const ORG_1_LICENSE_1_USER_1: LicenseUserWithAssignmentsAndSessions = {
  ...ORG_1_USERS[0],
  assignments: [
    {
      id: "8476a28f-e847-4e02-89b3-e8c571bf96e3",
      authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
      created: "2020-06-11T07:00:00.000Z",
      validFrom: "2020-06-11T07:00:00.000Z",
      validUntil: "2100-06-11T07:00:00.000Z",
      type: "reserved",
      sessions: [
        {
          id: "645ef0c8-4f53-4716-b7f0-581177be098e",
          authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
          created: "2020-06-11T07:00:00.000Z",
          validFrom: "2020-06-11T07:00:00.000Z",
          validUntil: "2100-06-11T07:00:00.000Z",
          leases: [
            {
              id: "3576bb41-8f31-41bf-952e-fc7cb7a2c79d",
              authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
              created: "2020-06-11T07:00:00.000Z",
              validFrom: "2020-06-11T07:00:00.000Z",
              validUntil: "2100-06-11T07:00:00.000Z",
              mode: "online"
            }
          ]
        }
      ]
    }
  ]
};

const ORG_1_LICENSE_3_USER_1: LicenseUserWithAssignmentsAndSessions = {
  ...ORG_1_USERS[0],
  assignments: [
    {
      id: "51a0e8b9-6daa-42ce-8f00-e2c3543738e9",
      authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
      created: "2020-06-11T07:00:00.000Z",
      validFrom: "2020-06-11T07:00:00.000Z",
      validUntil: "2100-06-11T07:00:00.000Z",
      type: "reserved",
      sessions: [
        {
          id: "62e57b8a-c02c-4790-b374-d52577f04a49",
          authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
          created: "2020-06-11T07:00:00.000Z",
          validFrom: "2020-06-11T07:00:00.000Z",
          validUntil: "2020-07-11T07:00:00.000Z",
          leases: [
            {
              id: "6fa88812-c4f7-4055-b748-72ad3479109a",
              authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
              created: "2020-06-11T07:00:00.000Z",
              validFrom: "2020-06-11T07:00:00.000Z",
              validUntil: "2020-07-11T07:00:00.000Z",
              mode: "online"
            }
          ]
        }
      ]
    }
  ]
};

const ORG_1_LICENSE_1_USERS: LicenseUserWithAssignmentsAndSessions[] = [
  ORG_1_LICENSE_1_USER_1
];

const ORG_1_LICENSE_3_USERS: LicenseUserWithAssignmentsAndSessions[] = [
  ORG_1_LICENSE_3_USER_1
];

export const ORG_1_LICENSE_USERS_BY_LICENSE_ID = {
  [ORG_1_LICENSE_1.id as string]: ORG_1_LICENSE_1_USERS,
  [ORG_1_LICENSE_3.id as string]: ORG_1_LICENSE_3_USERS
};
