import React from "react";
import Page, { PageDOMProps } from "../page";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export interface NotFoundDOMProps extends PageDOMProps {}
export interface NotFoundProps extends NotFoundDOMProps {}
const messages = defineMessages({
  title: {
    id: "pages.not-found.title",
    defaultMessage: "Page not found",
    description: "Title for the Not found screen"
  },
  description: {
    id: "pages.not-found.meta-description",
    defaultMessage: "Page not found",
    description: "Description for the Not found screen"
  }
});
function NotFound(props: NotFoundProps) {
  const intl = useIntl();
  const title = intl.formatMessage(messages.title);
  const description = intl.formatMessage(messages.description);
  return (
    <Page
      data-test-not-found-page
      header={
        <h1 className={"text-danger"}>
          <FontAwesomeIcon icon="exclamation-triangle" className="mr-2" />{" "}
          {title}
        </h1>
      }
      meta={{
        title,
        description
      }}
      {...props}
    >
      <p>
        <FormattedMessage
          id="pages.not-found.copy1"
          defaultMessage="The page you were trying to reach could not be found."
        />
      </p>
      <p>
        <FormattedMessage
          id="pages.not-found.copy2"
          defaultMessage="This could be because the page may have been moved or you may not be authorized to view it."
        />
      </p>
      <p>
        <FormattedMessage
          id="pages.not-found.copy3"
          defaultMessage="If you entered a web address manually, please verify that the URL is entered correctly."
        />
      </p>
    </Page>
  );
}
export default NotFound;
