import { IdpApi } from "../IdpApi";
import { Organization } from "../../model/Organization";
import { User } from "../../model/User";
import { OrganizationGroup } from "../../model/OrganizationGroup";
import { OrganizationRole } from "../../model/OrganizationRole";
import { OrganizationGroupInvitation } from "../../model/OrganizationGroupInvitation";
import { Permission } from "../../model/Permission";
import { InternalPermissionWithGrantedActions } from "../../model/InternalPermissionWithGrantedActions";
import { PermissionGrantsForPermission } from "../../model/PermissionGrantsForPermission";
import { MOCK_DB } from "./mockData";
import MockBase from "./MockBase";

class MockIdp extends MockBase implements IdpApi {
  public async getAppOrganizations(): Promise<Organization[]> {
    return MOCK_DB.APP_ORGS;
  }

  public async getOrganization(orgId?: string): Promise<Organization> {
    return this.emptyTo404(MOCK_DB.APP_ORGS.filter(org => org.id === orgId))[0];
  }

  public async replaceOrganization(org: Organization): Promise<Organization> {
    const updatedOrg = { ...org };
    const index = this.minusOneTo404(
      MOCK_DB.APP_ORGS.findIndex(
        existingOrg => existingOrg.id === updatedOrg.id
      )
    );
    MOCK_DB.APP_ORGS[index] = updatedOrg;
    return updatedOrg;
  }

  public setMfaRequired(required: boolean, orgId?: string): Promise<void> {
    throw new Error("Not implemented by the mock API");
  }

  public async listAllOrganizationUsers(orgId?: string): Promise<User[]> {
    if (orgId) {
      return MOCK_DB.ALL_USERS_BY_ORG_ID[orgId];
    }
    throw this.build404();
  }

  public async listOrganizationGroups(
    orgId?: string
  ): Promise<OrganizationGroup[]> {
    return this.requireOrgGroups(orgId);
  }

  public async createOrganizationGroup(
    orgGroup: OrganizationGroup,
    orgId?: string
  ): Promise<OrganizationGroup> {
    const groups = this.requireOrgGroups(orgId);
    const orgGroupToCreate = { ...orgGroup };
    if (orgGroupToCreate.id) {
      this.notMinusOneTo409(
        groups.findIndex(group => group.id === orgGroupToCreate.id)
      );
    } else {
      orgGroupToCreate.id = this.randomUuid();
    }
    groups.push(orgGroupToCreate);
    return orgGroupToCreate;
  }

  public async replaceOrganizationGroup(
    orgGroup: OrganizationGroup,
    orgId?: string
  ): Promise<OrganizationGroup> {
    const { groups, index } = this.getOrganizationGroupInternal(
      orgGroup.id as string,
      orgId
    );
    const updatedGroup = { ...orgGroup };
    groups[index] = updatedGroup;
    return updatedGroup;
  }

  public async getOrganizationGroup(
    orgGroupId: string,
    orgId?: string
  ): Promise<OrganizationGroup> {
    const { groups, index } = this.getOrganizationGroupInternal(
      orgGroupId,
      orgId
    );
    return groups[index];
  }

  public async deleteOrganizationGroup(
    orgGroupId: string,
    orgId?: string
  ): Promise<void> {
    const { groups, index } = this.getOrganizationGroupInternal(
      orgGroupId,
      orgId
    );
    groups.splice(index, 1);
  }

  public async listOrganizationRoles(
    orgId?: string
  ): Promise<OrganizationRole[]> {
    return this.requireOrgRoles(orgId);
  }

  public async createOrganizationRole(
    orgRole: OrganizationRole,
    orgId?: string
  ): Promise<OrganizationRole> {
    const roles = this.requireOrgRoles(orgId);
    const orgRoleToCreate = { ...orgRole };
    if (orgRoleToCreate.id) {
      this.notMinusOneTo409(
        roles.findIndex(role => role.id === orgRoleToCreate.id)
      );
    } else {
      orgRoleToCreate.id = this.randomUuid();
    }
    roles.push(orgRoleToCreate);
    return orgRoleToCreate;
  }

  public async replaceOrganizationRole(
    orgRole: OrganizationRole,
    orgId?: string
  ): Promise<OrganizationRole> {
    const { roles, index } = this.getOrganizationRoleInternal(
      orgRole.id as string,
      orgId
    );
    const updatedRole = { ...orgRole };
    roles[index] = updatedRole;
    return updatedRole;
  }

  public async getOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<OrganizationRole> {
    const { roles, index } = this.getOrganizationRoleInternal(orgRoleId, orgId);
    return roles[index];
  }

  public async deleteOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<void> {
    const { roles, index } = this.getOrganizationRoleInternal(orgRoleId, orgId);
    roles.splice(index, 1);
  }

  public async getUser(userId: string): Promise<User> {
    const { users, index } = this.getUserInternal(userId);
    return users[index];
  }

  public async deleteUser(userId: string): Promise<void> {
    const { users, index } = this.getUserInternal(userId);
    if (users[index] && users[index].lastName === "McPhailure") {
      throw this.buildTestError();
    }
    users.splice(index, 1);
  }

  public async replaceUser(user: User): Promise<User> {
    const { users, index } = this.getUserInternal(user.id as string);
    if (user && user.lastName === "McPhailure") {
      throw this.buildTestError();
    }
    const updatedUser = { ...user };
    users[index] = updatedUser;
    return updatedUser;
  }

  public async setUserSuspended(
    suspended: boolean,
    userId: string
  ): Promise<User> {
    const user = await this.getUser(userId);
    if (user && user.lastName === "McPhailure") {
      throw this.buildTestError();
    }
    const updatedUser = { ...user };
    if (suspended) {
      updatedUser.validUntil = new Date().toISOString();
    } else {
      updatedUser.validUntil = undefined;
    }
    return await this.replaceUser(updatedUser);
  }

  public async deleteOtpCredential(userId: string): Promise<void> {
    throw new Error("Not implemented by the mock API");
  }

  public async listOrganizationGroupsOfUser(
    userId: string
  ): Promise<OrganizationGroup[]> {
    await this.getUser(userId);
    const userGroupIds = this.getOrganizationGroupIdsOfUser(userId);
    const retValue: OrganizationGroup[] = [];
    for (const groupId of userGroupIds) {
      const { groupsOfOrg, index } = this.getOrganizationGroupByIdInternal(
        groupId
      );
      retValue.push(groupsOfOrg[index]);
    }

    return retValue;
  }

  public async addOrganizationGroupForUser(
    groupId: string,
    userId: string
  ): Promise<void> {
    await this.getUser(userId);
    const { groupsOfOrg, index } = this.getOrganizationGroupByIdInternal(
      groupId
    );
    const orgGroup = groupsOfOrg[index];
    const orgGroupMembers =
      MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[orgGroup.id as string];
    if (orgGroupMembers.indexOf(userId) !== -1) {
      throw this.build409();
    }
    orgGroupMembers.push(userId);
  }

  public async removeOrganizationGroupOfUser(
    groupId: string,
    userId: string
  ): Promise<void> {
    const { users, index: indexOfUser } = this.getUserInternal(userId);
    const user = users[indexOfUser];
    if (user && user.lastName === "McPhailure") {
      throw this.buildTestError();
    }

    await this.getUser(userId);
    const { groupsOfOrg, index } = this.getOrganizationGroupByIdInternal(
      groupId
    );
    const orgGroup = groupsOfOrg[index];
    const orgGroupMembers =
      MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[orgGroup.id as string];
    const memberIndex = orgGroupMembers.indexOf(userId);
    if (memberIndex === -1) {
      throw this.build404();
    }
    orgGroupMembers.splice(memberIndex, 1);
  }

  public async listUsersInOrganizationGroup(
    groupId: string,
    orgId?: string
  ): Promise<User[]> {
    const { groupsOfOrg, index } = this.getOrganizationGroupByIdInternal(
      groupId
    );
    const orgGroup = groupsOfOrg[index];
    const orgGroupMembers =
      MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[orgGroup.id as string];
    return orgGroupMembers.map(userId => {
      const { users, index } = this.getUserInternal(userId);
      return users[index];
    });
  }

  public async addUsersToOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    const { groupsOfOrg, index } = this.getOrganizationGroupByIdInternal(
      groupId
    );
    const orgGroup = groupsOfOrg[index];
    const orgGroupMembers =
      MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[orgGroup.id as string];
    for (const userId of userIds) {
      if (orgGroupMembers.indexOf(userId) !== -1) {
        throw this.build409();
      }
    }

    MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[orgGroup.id as string] = [
      ...orgGroupMembers,
      ...userIds
    ];
  }

  public async setUsersInOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    const { groupsOfOrg, index } = this.getOrganizationGroupByIdInternal(
      groupId
    );
    const orgGroup = groupsOfOrg[index];
    MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[orgGroup.id as string] = [
      ...userIds
    ];
  }

  public async removeUsersFromOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    const { groupsOfOrg, index } = this.getOrganizationGroupByIdInternal(
      groupId
    );
    const orgGroup = groupsOfOrg[index];
    const orgGroupMembers =
      MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[orgGroup.id as string];
    for (const userId of userIds) {
      if (orgGroupMembers.indexOf(userId) === -1) {
        throw this.build404();
      }
    }

    MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[
      orgGroup.id as string
    ] = orgGroupMembers.filter(memberId => userIds.indexOf(memberId) === -1);
  }

  public async listOrganizationsOrganizationGroupInvitations(
    orgId?: string
  ): Promise<OrganizationGroupInvitation[]> {
    if (orgId) {
      return MOCK_DB.ALL_ORG_GROUP_INVITATIONS_BY_ORG_ID[orgId];
    }
    throw this.build404();
  }

  public async getOrganizationsOrganizationGroupInvitation(
    invitationId: string,
    orgId?: string
  ): Promise<OrganizationGroupInvitation> {
    const { invitations, index } = this.getOrganizationGroupInvitationInternal(
      invitationId,
      orgId
    );
    return invitations[index];
  }

  public async createOrganizationGroupInvitation(
    invitation: OrganizationGroupInvitation
  ): Promise<OrganizationGroupInvitation> {
    const invitations = this.requireOrgInvitations(invitation.organizationId);
    const orgInvitationToCreate = { ...invitation };
    if (orgInvitationToCreate.id) {
      this.notMinusOneTo409(
        invitations.findIndex(inv => inv.id === orgInvitationToCreate.id)
      );
    } else {
      orgInvitationToCreate.id = this.randomUuid();
    }
    orgInvitationToCreate.invitationState = "created";
    invitations.push(orgInvitationToCreate);
    return orgInvitationToCreate;
  }

  public async sendOrganizationGroupInvitation(
    invitationId: string
  ): Promise<OrganizationGroupInvitation> {
    const invitation = MOCK_DB.ALL_INVITATIONS.flat(1).find(
      val => val.id === invitationId
    );
    if (invitation) {
      const {
        invitations,
        index
      } = this.getOrganizationGroupInvitationInternal(
        invitation.id as string,
        invitation.organizationId
      );
      const updatedInvitation = { ...invitations[index] };
      updatedInvitation.invitationState = "deliveryRequested";
      invitations[index] = updatedInvitation;
      return updatedInvitation;
    } else {
      throw this.build404();
    }
  }

  public async revokeOrganizationGroupInvitation(
    invitationId: string
  ): Promise<OrganizationGroupInvitation> {
    const invitation = MOCK_DB.ALL_INVITATIONS.flat(1).find(
      val => val.id === invitationId
    );
    if (invitation) {
      const {
        invitations,
        index
      } = this.getOrganizationGroupInvitationInternal(
        invitation.id as string,
        invitation.organizationId
      );
      const updatedInvitation = { ...invitations[index] };
      updatedInvitation.invitationState = "revoked";
      updatedInvitation.revokedAt = "" + new Date().getTime();
      invitations[index] = updatedInvitation;
      return updatedInvitation;
    } else {
      throw this.build404();
    }
  }

  public async deleteOrganizationGroupInvitation(
    invitationId: string
  ): Promise<void> {
    const invitation = MOCK_DB.ALL_INVITATIONS.flat(1).find(
      val => val.id === invitationId
    );
    if (invitation) {
      const {
        invitations,
        index
      } = this.getOrganizationGroupInvitationInternal(
        invitation.id as string,
        invitation.organizationId
      );
      invitations.splice(index, 1);
    } else {
      throw this.build404();
    }
  }

  public async listPermissionsOfOrganizationRole(
    orgRoleId: string
  ): Promise<InternalPermissionWithGrantedActions[]> {
    const { rolesOfOrg, index } = this.getOrganizationRoleByIdInternal(
      orgRoleId
    );
    const role = rolesOfOrg[index];
    const rolePermissions =
      MOCK_DB.ALL_ROLE_PERMISSION_IDS_BY_ROLE_ID[role.id as string];
    const retValue: InternalPermissionWithGrantedActions[] = [];
    for (const rolePermission of rolePermissions) {
      const permission = this.getPermissionById(rolePermission.id);
      retValue.push({
        ...permission,
        grantedActions: { allowedActions: rolePermission.actions }
      });
    }

    return retValue;
  }

  public async addPermissionsForOrganizationRole(
    grants: PermissionGrantsForPermission[],
    orgRoleId: string
  ): Promise<void> {
    const { rolesOfOrg, index } = this.getOrganizationRoleByIdInternal(
      orgRoleId
    );
    const role = rolesOfOrg[index];
    const rolePermissions =
      MOCK_DB.ALL_ROLE_PERMISSION_IDS_BY_ROLE_ID[role.id as string];
    const permissionsToAdd: { id: string; actions: string[] }[] = [];
    for (const grant of grants) {
      if (
        rolePermissions.findIndex(
          rolePermission => rolePermission.id === grant.id
        ) !== -1
      ) {
        throw this.build409();
      }
      permissionsToAdd.push({
        id: grant.id as string,
        actions: grant.grantedActions as string[]
      });
    }

    MOCK_DB.ALL_ROLE_PERMISSION_IDS_BY_ROLE_ID[role.id as string] = [
      ...rolePermissions,
      ...permissionsToAdd
    ];
  }

  public async setPermissionsOfOrganizationRole(
    grants: PermissionGrantsForPermission[],
    orgRoleId: string
  ): Promise<void> {
    const { rolesOfOrg, index } = this.getOrganizationRoleByIdInternal(
      orgRoleId
    );
    const role = rolesOfOrg[index];
    const permissionsToSet: { id: string; actions: string[] }[] = [];
    for (const grant of grants) {
      permissionsToSet.push({
        id: grant.id as string,
        actions: grant.grantedActions as string[]
      });
    }

    MOCK_DB.ALL_ROLE_PERMISSION_IDS_BY_ROLE_ID[
      role.id as string
    ] = permissionsToSet;
  }

  public async removePermissionsOfOrganizationRole(
    permissionIds: string[],
    orgRoleId: string
  ): Promise<void> {
    const { rolesOfOrg, index } = this.getOrganizationRoleByIdInternal(
      orgRoleId
    );
    const role = rolesOfOrg[index];
    const rolePermissions =
      MOCK_DB.ALL_ROLE_PERMISSION_IDS_BY_ROLE_ID[role.id as string];
    for (const permissionId of permissionIds) {
      if (
        rolePermissions.findIndex(
          rolePermission => rolePermission.id === permissionId
        ) === -1
      ) {
        throw this.build404();
      }
    }

    MOCK_DB.ALL_ROLE_PERMISSION_IDS_BY_ROLE_ID[
      role.id as string
    ] = rolePermissions.filter(
      rolePermission => permissionIds.indexOf(rolePermission.id) === -1
    );
  }

  public async removePermissionOfOrganizationRole(
    permissionId: string,
    orgRoleId: string
  ): Promise<void> {
    return await this.removePermissionsOfOrganizationRole(
      [permissionId],
      orgRoleId
    );
  }

  public async listUsersInOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<User[]> {
    const role = this.getSingleOrganizationRoleByIdInternal(orgRoleId);
    const orgRoleMembers =
      MOCK_DB.ALL_ROLE_MEMBER_IDS_BY_ROLE_ID[role.id as string];
    return orgRoleMembers.map(userId => {
      const { users, index } = this.getUserInternal(userId);
      return users[index];
    });
  }

  public async addUsersToOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    userIds.forEach(userId =>
      this.addUserToOrganizationRole(orgRoleId, userId, orgId)
    );
  }

  public getUserInOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<User> {
    throw new Error("Not implemented by the mock API");
  }

  public async addUserToOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<void> {
    const role = this.getSingleOrganizationRoleByIdInternal(orgRoleId);
    const orgRoleMembers =
      MOCK_DB.ALL_ROLE_MEMBER_IDS_BY_ROLE_ID[role.id as string];
    const userIndex = orgRoleMembers.indexOf(userId);
    if (userIndex === -1) {
      orgRoleMembers.push(userId);
    }
  }

  public async removeUsersFromOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    userIds.forEach(userId =>
      this.removeUserFromOrganizationRole(orgRoleId, userId, orgId)
    );
  }

  public async removeUserFromOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<void> {
    const role = this.getSingleOrganizationRoleByIdInternal(orgRoleId);
    const orgRoleMembers =
      MOCK_DB.ALL_ROLE_MEMBER_IDS_BY_ROLE_ID[role.id as string];
    const userIndex = orgRoleMembers.indexOf(userId);
    if (userIndex !== -1) {
      orgRoleMembers.splice(userIndex, 1);
    }
  }

  public async setUsersInOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    const role = this.getSingleOrganizationRoleByIdInternal(orgRoleId);
    MOCK_DB.ALL_ROLE_MEMBER_IDS_BY_ROLE_ID[role.id as string] = [...userIds];
  }

  private getPermissionById(permissionId: string): Permission {
    const index = MOCK_DB.INTERNAL_PERMISSIONS.findIndex(
      permission => permission.id === permissionId
    );
    if (index === -1) {
      throw this.build404();
    }

    return MOCK_DB.INTERNAL_PERMISSIONS[index];
  }

  private getOrganizationRoleByIdInternal(
    orgRoleId: string
  ): { orgId: string; rolesOfOrg: OrganizationRole[]; index: number } {
    let index = -1;
    let roles: OrganizationRole[] | undefined = undefined;
    let orgId: string | undefined = undefined;
    for (const currentOrgId in MOCK_DB.ALL_ROLES_BY_ORG_ID) {
      const rolesOfOrg = MOCK_DB.ALL_ROLES_BY_ORG_ID[currentOrgId];
      index = rolesOfOrg.findIndex(
        role => role.id === orgRoleId || role.designator === orgRoleId
      );
      if (index !== -1) {
        roles = rolesOfOrg;
        orgId = currentOrgId;
        break;
      }
    }

    if (index === -1) {
      throw this.build404();
    }

    return {
      orgId: orgId as string,
      rolesOfOrg: roles as OrganizationRole[],
      index
    };
  }

  private getSingleOrganizationRoleByIdInternal(
    orgRoleId: string
  ): OrganizationRole {
    for (const currentOrgId in MOCK_DB.ALL_ROLES_BY_ORG_ID) {
      const index = MOCK_DB.ALL_ROLES_BY_ORG_ID[currentOrgId].findIndex(
        role => role.id === orgRoleId || role.designator === orgRoleId
      );

      if (index !== -1) {
        return MOCK_DB.ALL_ROLES_BY_ORG_ID[currentOrgId][index];
      }
    }

    throw this.build404();
  }

  private getOrganizationGroupByIdInternal(
    orgGroupId: string
  ): { orgId: string; groupsOfOrg: OrganizationGroup[]; index: number } {
    let index = -1;
    let groups: OrganizationGroup[] | undefined = undefined;
    let orgId: string | undefined = undefined;
    for (const currentOrgId in MOCK_DB.ALL_GROUPS_BY_ORG_ID) {
      const groupsOfOrg = MOCK_DB.ALL_GROUPS_BY_ORG_ID[currentOrgId];
      index = groupsOfOrg.findIndex(group => group.id === orgGroupId);
      if (index !== -1) {
        groups = groupsOfOrg;
        orgId = currentOrgId;
        break;
      }
    }

    if (index === -1) {
      throw this.build404();
    }

    return {
      orgId: orgId as string,
      groupsOfOrg: groups as OrganizationGroup[],
      index
    };
  }

  private getOrganizationGroupIdsOfUser(userId: string): string[] {
    const retValue: string[] = [];
    for (const groupId in MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID) {
      const memberIds = MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[groupId];
      if (memberIds.indexOf(userId) !== -1) {
        retValue.push(groupId);
      }
    }
    return retValue;
  }

  private requireOrgGroups(orgId?: string): OrganizationGroup[] {
    const groups = this.selectOrgGroups(orgId);
    if (groups) {
      return groups;
    }
    throw this.build404();
  }

  private requireOrgInvitations(orgId?: string): OrganizationGroupInvitation[] {
    const invitations = this.selectOrgInvitations(orgId);
    if (invitations) {
      return invitations;
    }
    throw this.build404();
  }

  private selectOrgGroups(orgId?: string): OrganizationGroup[] | undefined {
    if (orgId) {
      return MOCK_DB.ALL_GROUPS_BY_ORG_ID[orgId];
    }
  }

  private selectOrgInvitations(
    orgId?: string
  ): OrganizationGroupInvitation[] | undefined {
    if (orgId) {
      return MOCK_DB.ALL_ORG_GROUP_INVITATIONS_BY_ORG_ID[orgId];
    }
  }

  private getOrganizationGroupInternal(
    orgGroupId: string,
    orgId?: string
  ): { groups: OrganizationGroup[]; index: number } {
    const groups = this.requireOrgGroups(orgId);
    const index = this.minusOneTo404(
      groups.findIndex(existingOrg => existingOrg.id === orgGroupId)
    );
    return { groups, index };
  }

  private getOrganizationGroupInvitationInternal(
    orgInvitationId: string,
    orgId?: string
  ): { invitations: OrganizationGroupInvitation[]; index: number } {
    const invitations = this.requireOrgInvitations(orgId);
    const index = this.minusOneTo404(
      invitations.findIndex(existingInv => existingInv.id === orgInvitationId)
    );
    return { invitations, index };
  }

  private requireOrgRoles(orgId?: string): OrganizationRole[] {
    const roles = this.selectOrgRoles(orgId);
    if (roles) {
      return roles;
    }
    throw this.build404();
  }

  private selectOrgRoles(orgId?: string): OrganizationRole[] | undefined {
    if (orgId) {
      return MOCK_DB.ALL_ROLES_BY_ORG_ID[orgId];
    }
  }

  private getOrganizationRoleInternal(
    orgRoleId: string,
    orgId?: string
  ): { roles: OrganizationRole[]; index: number } {
    const roles = this.requireOrgRoles(orgId);
    const index = this.minusOneTo404(
      roles.findIndex(existingRole => existingRole.id === orgRoleId)
    );
    return { roles, index };
  }
}

export default MockIdp;
