import React, { useEffect, useState } from "react";
import ConfirmModalView, {
  ConfirmModalProps as _ConfirmModalProps,
  ConfirmModalConfirmStateProps
} from "./confirm-modal-view";

type ConfirmModalProps = Omit<
  _ConfirmModalProps,
  keyof ConfirmModalConfirmStateProps
>;

export default function ConfirmModal(props: ConfirmModalProps) {
  const { show, ...other } = props;
  const [confirm, onUpdateConfirm] = useState(false);
  // reset confirm state when dialog is hidden
  useEffect(() => {
    if (!show && confirm) {
      onUpdateConfirm(false);
    }
  }, [show, confirm]);
  return (
    <ConfirmModalView
      confirm={confirm}
      show={show}
      onUpdateConfirm={onUpdateConfirm}
      {...other}
    />
  );
}
