import { ThunkDispatch } from "redux-thunk";
import * as ActionTypes from "./actionTypes";
import { generateRandomString } from "../util/random";
import { AppState } from "../store/AppState";
import { ActionSender } from "../model/ActionSender";
import { addError, addApiError } from "./errorActions";
import { SENDER_AUTHENTICATION } from "../util/authentication";
import { ApiError } from "../model/ApiError";
import { Authentication } from "../model/Authentication";
import { LogoutCompleted } from "../model/LogoutCompleted";

export function startAuthnWithGeneratedNonce(
  sender: ActionSender = SENDER_AUTHENTICATION
): ActionTypes.AppThunkAction<ActionTypes.StartAuthnAction> {
  return async (
    dispatch: ThunkDispatch<AppState, unknown, ActionTypes.StartAuthnAction>
  ): Promise<ActionTypes.StartAuthnAction> => {
    const nonce = generateRandomString(10);
    const nonceIssuedAt = new Date().getTime();
    return dispatch({
      type: ActionTypes.START_AUTHN,
      nonce,
      nonceIssuedAt
    });
  };
}

export function setAuthn(authn: Authentication): ActionTypes.SetAuthnAction {
  return {
    type: ActionTypes.SET_AUTHN,
    authn
  };
}

export function setLogoutCompleted(
  logoutCompleted: LogoutCompleted
): ActionTypes.SetLogoutCompletedAction {
  return {
    type: ActionTypes.SET_LOGOUT_COMPLETED,
    logoutCompleted
  };
}

export function clearAuthnStatus(): ActionTypes.ClearAuthnStatusAction {
  return {
    type: ActionTypes.CLEAR_AUTHN_STATUS
  };
}

export function addErrorForErrorFromServer(
  error: string,
  operationType: string,
  errorDescription?: string,
  errorUri?: string,
  sender: ActionSender = SENDER_AUTHENTICATION
): ActionTypes.AddErrorAction<any> {
  return addError(
    sender,
    error,
    operationType,
    undefined,
    errorDescription,
    errorUri
  );
}

export function addErrorForAuthnResponse(
  error: ApiError,
  operationType: string,
  sender: ActionSender = SENDER_AUTHENTICATION
): ActionTypes.AddErrorAction<any> {
  return addApiError(sender, operationType, error);
}
