import React, { ReactNode } from "react";
import AppHeader from "../app-header";
import "./app-layout.scss";

//<editor-fold desc="Props">

interface AppLayoutProps {
  /**
   * Optional component that provides the 'in-page' navigation.
   */
  pageNavigation?: ReactNode;
  children?: ReactNode;
}
//</editor-fold>

function AppLayout(props: AppLayoutProps) {
  //<editor-fold desc="Local variables">
  let { children, pageNavigation } = props;
  /**
   * Full width layout if no page navigation
   */
  let mainContentClassName = "col-12";
  if (pageNavigation) {
    /**
     * Fluid 2 column layout
     */
    mainContentClassName =
      "col-12 col-sm-7 col-md-8 col-lg-9 col-xl-10 d-flex flex-grow-1";
  }
  //</editor-fold>

  return (
    <>
      <AppHeader />
      <div className="container-fluid flex-grow-1 d-flex flex-column">
        <div className="row flex-column flex-sm-row flex-grow-1">
          {pageNavigation && (
            <div className="col-12 col-sm-5 col-md-4 col-lg-3 col-xl-2 sidebar">
              {pageNavigation}
            </div>
          )}
          <div className={mainContentClassName}>{children}</div>
        </div>
      </div>
    </>
  );
}

export default AppLayout;
