import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import CssUtils from "../../../utils/css-utils";
import "./page-view.scss";

//<editor-fold desc="Props">
// Omit the children here, as the PageDOMProps is used by the actual page implementations, which do not allow children
export interface PageDOMProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "children"> {}
// Instead add the children here, as this is not used elsewhere
interface PageProps
  extends PageDOMProps,
    Pick<React.HTMLAttributes<HTMLDivElement>, "children"> {
  /**
   * HTML meta data for the page
   */
  meta?: {
    /**
     * HTML>HEAD>TITLE
     */
    title: string;
    /**
     * HTML>HEAD>META[description]
     */
    description: string;
  };
  /**
   * Header for the page content
   */
  header?: ReactNode;
  /**
   * Footer for the page content
   */
  footer?: ReactNode;
}
//</editor-fold>

/**
 * Wraps content
 * @param props
 * @constructor
 */
function Page(props: PageProps) {
  //<editor-fold desc="Local variables">
  let { className, header, footer, meta, children, ...other } = props;
  className = CssUtils.ensureClassNames(className, [
    "p-4",
    "page",
    "flex-grow-1"
  ]);
  //</editor-fold>
  return (
    <>
      {meta && (
        <Helmet>
          {meta.title && <title>{meta.title}</title>}
          {meta.description && (
            <meta name="description" content={meta.description} />
          )}
        </Helmet>
      )}
      <article className={className} {...other}>
        {header && <header className={"mx-3 my-4"}>{header}</header>}
        <div className="content py-2">{children}</div>
        {footer && <footer className="py-2">{footer}</footer>}
      </article>
    </>
  );
}

export default Page;
