import React, { useEffect } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "../confirm-modal";
import UserUtils from "../../utils/user";
import { LicenseUsersByReservation } from "../../util/licenseUtil";
import { Button } from "react-bootstrap";
import { LicenseUsage } from "../../model/entitlement/LicenseUsage";
import { AppError } from "../../model/AppError";
import { resolveErrorsByOperationType } from "../../utils/error";
import { QUERY_LICENSE_USAGE } from "../../actions/actionTypes";
import * as ActionTypes from "../../actions/actionTypes";
import Modal, { ModalProps } from "../modal";
import Feedback, { FeedbackEntry } from "../feedback";
import TooltipTrigger from "../tooltip-trigger";
import "./license-users-modal.scss";
import LicenseUtils from "../../utils/licensed-item";
import { LicenseUser } from "../../model/entitlement/LicenseUser";
//<editor-fold desc="Messages">
const messages = defineMessages({
  isConsumingTip: {
    id: "license-users-modal.is-consuming.tip",
    defaultMessage:
      "This user is currently consuming a license and will be allowed to continue until the lease expires. Assignments or unassignments will therefore not have any impact on available licenses count."
  },
  assignButtonNothingToAssignTip: {
    id: "license-users-modal.assign-button.nothing-to-assign-tip",
    defaultMessage: "Select user(s) for assignment first"
  },
  assignButtonNotEnoughFreeSeatsTip: {
    id: "license-users-modal.assign-button.not-enough-free-seats-tip",
    defaultMessage: "Not enough free licenses for all selected users"
  },
  assignButtonTip: {
    id: "license-users-modal.assign-button.tip",
    defaultMessage: "Assign licenses"
  },
  unassignButtonNothingToUnassignTip: {
    id: "license-users-modal.unassign-button.nothing-to-unassign-tip",
    defaultMessage: "Select user(s) to unassign first"
  },
  unassignButtonTip: {
    id: "license-users-modal.unassign-button.tip",
    defaultMessage: "Unassign licenses"
  },
  licenseUsersLicenseNameFallback: {
    id: "license-users-modal.unknown-license-name",
    defaultMessage: "Unknown",
    description: "shown when license name could not be resolved"
  },
  licenseUsersUpdateSuccess: {
    id: "license-users-modal.update-success-message",
    defaultMessage: "Updated {name} assignments",
    description: "Success notification for updated license assignments"
  },
  licenseUsersUpdatePartialSuccess: {
    id: "license-users-modal.update-partial-success-message",
    defaultMessage: "Updated {name} assignments for {userNames}",
    description:
      "Success notification for updated license assignments, when some assignments also failed"
  },
  licenseUsersUpdateFailed: {
    id: "license-users-modal.update-failed-message",
    defaultMessage: "Updating {name} assignments failed due to: {error}",
    description: "Failure notification for updated license assignments"
  },
  licenseUsersUpdatePartiallyFailed: {
    id: "license-users-modal.update-partially-failed-message",
    defaultMessage:
      "Updating {name} assignments for {userNames} failed due to: {error}",
    description:
      "Failure notification for updated license assignments, when there are multiple causes or successful assignments"
  },
  licenseUsersModalLicenseErrorLoadFailureCloseButtonLabel: {
    id: "license-users-modal.error.load-failure.close-button-label",
    defaultMessage: "Close"
  },
  licenseUsersModalLicenseErrorLoadFailureTitle: {
    id: "license-users-modal.error.load-failure.title",
    defaultMessage: "Loading license failed",
    description:
      "Title for the assign licenses dialog when license loading has failed"
  },
  licenseUsersModalLicenseErrorLoadFailureCopy: {
    id: "license-users-modal.error.load-failure.copy",
    defaultMessage:
      "Something went wrong and the License could not be loaded. The license may have been removed or you don't have sufficient access rights.",
    description: "Error message for license loading error"
  },
  licenseUsersModalTitle: {
    id: "license-users-modal.title",
    defaultMessage: "{name} licenses ({totalSeats})",
    description: "Title for the assign licenses dialog"
  },
  licenseUsersModalAnonymousUserLabel: {
    id: "license-users-modal.anonymous-user-label",
    defaultMessage: "Anonymous"
  },
  licenseUsersModalConfirmTitle: {
    id: "license-users-modal.confirm-title",
    defaultMessage: "Edit Licenses",
    description: "Title for the assign licenses dialog confirmation"
  },
  licenseUsersModalConfirmAcceptButtonLabel: {
    id: "license-users-modal.confirm-accept-label",
    defaultMessage: "Yes",
    description: "Confirm button label"
  },
  licenseUsersModalConfirmCancelButtonLabel: {
    id: "license-users-modal.confirm-cancel-label",
    defaultMessage: "No",
    description: "Cancel button label"
  },
  licenseUsersModalOkButtonLabel: {
    id: "license-users-modal.ok-button-label",
    defaultMessage: "Ok",
    description: "Ok button label"
  },
  licenseUsersModalCancelButtonLabel: {
    id: "license-users-modal.cancel-button-label",
    defaultMessage: "Cancel",
    description: "Cancel button label"
  },
  licenseUsersModalOkButtonDisabledTooltip: {
    id: "license-users-modal.ok-button-disabled-tooltip",
    defaultMessage: "No changes to apply"
  }
});
//</editor-fold>

//<editor-fold desc="Messages">
export interface LicenseUsersModalVisibilityProps
  extends Pick<ModalProps, "show" | "onClose"> {}
export interface LicenseUsersModalLicenseIdProps {
  licenseId?: string;
}
export interface LicenseUsersModalStateProps {
  usersChanged?: boolean;
  freeSeats: number;
  totalSeats: number;
  onSetToAssign: (sel: string[]) => void;
  onSetToUnassign: (sel: string[]) => void;
  onAssign: (usrIds: string[]) => void;
  onUnassign: (usrIds: string[]) => void;
  toAssign: string[];
  toUnassign: string[];
  feedback?: FeedbackEntry[];
  onShowFeedback: (feedback: FeedbackEntry[]) => void;
  onHideFeedback: (id: string) => void;
}

export interface LicenseUsersModalProps
  extends LicenseUsersModalStateProps,
    LicenseUsersModalLicenseIdProps,
    LicenseUsersModalVisibilityProps {
  users?: LicenseUsersByReservation | null;
  license?: LicenseUsage | null;
  errors?: AppError<any>[];
  onLoadUsers: (licenseId: string) => void;
  onLoadLicense?: (licenseId: string) => void;
  onApply: (
    usrs: LicenseUsersByReservation | undefined
  ) => Promise<ActionTypes.ManageUsersLicenseAssignmentsAction | undefined>;
}

//</editor-fold>

export default function LicenseUsersModalView(props: LicenseUsersModalProps) {
  const {
    license,
    licenseId,
    usersChanged,
    show,
    users,
    toAssign,
    toUnassign,
    onSetToAssign,
    onSetToUnassign,
    onAssign,
    onUnassign,
    freeSeats,
    totalSeats,
    errors,
    feedback,
    onHideFeedback,
    onShowFeedback,
    onApply,
    onClose,
    onLoadLicense,
    onLoadUsers
  } = props;
  let licenseLoadErrors = resolveErrorsByOperationType(
    errors,
    QUERY_LICENSE_USAGE
  );
  // ignore errors for wrong ids
  if (licenseId && licenseLoadErrors && licenseLoadErrors.length > 0) {
    licenseLoadErrors = licenseLoadErrors.filter(err => {
      return (
        (err.action as ActionTypes.QueryLicenseUsageAction).licenseId ===
        licenseId
      );
    });
  }
  const intl = useIntl();
  // declare effect dependencies as primitives to avoid triggering the hook due to object inequality
  const licensesId = license ? license.id : undefined;
  const hasLicenseLoadErrors =
    licenseLoadErrors && licenseLoadErrors.length > 0;
  const hasUsers = !!users;

  const allusrs = users
    ? users.usersWithoutReservation.concat(users.usersWithReservation)
    : [];
  const toAssignWithoutConsumptionCount = toAssign.filter((val: string) => {
    return !UserUtils.isConsuming(
      allusrs.find(usr => usr.id === val) as LicenseUser,
      license
    );
  }).length;
  useEffect(() => {
    if (show && onLoadLicense && !hasLicenseLoadErrors) {
      if (licensesId === undefined && licenseId) {
        onLoadLicense(licenseId);
      } else if (licenseId && licensesId !== licenseId) {
        onLoadLicense(licenseId);
      }
    }
  }, [show, licenseId, licensesId, hasLicenseLoadErrors, onLoadLicense]);
  useEffect(() => {
    if (show && licenseId && !hasUsers && !hasLicenseLoadErrors) {
      onLoadUsers(licenseId);
    }
  }, [show, licenseId, hasUsers, hasLicenseLoadErrors, onLoadUsers]);
  return (
    <>
      {feedback &&
        feedback.map((f, i) => {
          return (
            <Feedback
              key={"license-users-modal_" + i + f.id}
              type={f.type}
              show={true}
              onClose={() => {
                onHideFeedback(f.id);
              }}
              autoClose={f.autoclose === true}
              dangerouslySetInnerHTML={{ __html: f.msg }}
            ></Feedback>
          );
        })}
      {licenseLoadErrors && licenseLoadErrors.length > 0 && (
        <Modal
          id={"license-users-modal"}
          data-test-assign-licenses
          title={intl.formatMessage(
            messages.licenseUsersModalLicenseErrorLoadFailureTitle
          )}
          show={show}
          onClose={onClose}
          primaryButton={{
            label: intl.formatMessage(
              messages.licenseUsersModalLicenseErrorLoadFailureCloseButtonLabel
            )
          }}
          onPrimaryAction={() => {
            if (onClose) {
              onClose();
            }
          }}
        >
          <Feedback show={true} type={"danger"} asChild={true}>
            <p>
              {intl.formatMessage(
                messages.licenseUsersModalLicenseErrorLoadFailureCopy
              )}
            </p>
          </Feedback>
        </Modal>
      )}
      {(!licenseLoadErrors || licenseLoadErrors.length === 0) && (
        <ConfirmModal
          id={"license-users-modal"}
          data-test-assign-licenses
          title={intl.formatMessage(messages.licenseUsersModalTitle, {
            name:
              license && license.licensedItem
                ? LicenseUtils.resolveDisplayName(
                    license.licensedItem,
                    intl.formatMessage(messages.licenseUsersLicenseNameFallback)
                  )
                : intl.formatMessage(messages.licenseUsersLicenseNameFallback),
            totalSeats: totalSeats
          })}
          confirmTitle={intl.formatMessage(
            messages.licenseUsersModalConfirmTitle
          )}
          confirmContent={
            <>
              <p>
                <FormattedMessage
                  id="license-users-modal.confirm-copy"
                  defaultMessage="Are you sure you want to make these changes to {name}"
                  values={{
                    name:
                      license && license.licensedItem
                        ? LicenseUtils.resolveDisplayName(
                            license.licensedItem,
                            intl.formatMessage(
                              messages.licenseUsersLicenseNameFallback
                            )
                          )
                        : intl.formatMessage(
                            messages.licenseUsersLicenseNameFallback
                          )
                  }}
                />
              </p>
            </>
          }
          acceptButton={{
            label: intl.formatMessage(
              messages.licenseUsersModalConfirmAcceptButtonLabel
            )
          }}
          cancelButton={{
            label: intl.formatMessage(
              messages.licenseUsersModalConfirmCancelButtonLabel
            )
          }}
          show={show}
          onClose={onClose}
          primaryButton={{
            label: intl.formatMessage(messages.licenseUsersModalOkButtonLabel),
            disabled: !usersChanged,
            tooltip: !usersChanged
              ? intl.formatMessage(
                  messages.licenseUsersModalOkButtonDisabledTooltip
                )
              : undefined
          }}
          onPrimaryAction={() => {
            onApply(users ? users : undefined).then(res => {
              if (res) {
                const allusrs = users
                  ? users.usersWithoutReservation.concat(
                      users.usersWithReservation
                    )
                  : [];
                let success;
                let failure;
                if (res.userAssignments) {
                  success = Object.keys(res.userAssignments)
                    .map(id => allusrs.find(val => val.id === id))
                    .filter(val => !!val);
                }
                if (res.errors) {
                  failure = Object.keys(res.errors)
                    .map(id => allusrs.find(val => val.id === id))
                    .filter(val => !!val);
                }
                const feedbacks: FeedbackEntry[] = [];
                if (success && success.length) {
                  if (failure && failure.length) {
                    // some succeeded, some failed
                    feedbacks.push({
                      id: "manageAssignments_success_" + res.licenseId,
                      msg: intl.formatMessage(
                        messages.licenseUsersUpdatePartialSuccess,
                        {
                          name:
                            "<strong>" +
                            (license && license.licensedItem
                              ? LicenseUtils.resolveDisplayName(
                                  license.licensedItem,
                                  intl.formatMessage(
                                    messages.licenseUsersLicenseNameFallback
                                  )
                                )
                              : intl.formatMessage(
                                  messages.licenseUsersLicenseNameFallback
                                )) +
                            "</strong>",
                          userNames:
                            "<strong>" +
                            success
                              .map(usr =>
                                UserUtils.resolveDisplayName(
                                  usr,
                                  intl.formatMessage(
                                    messages.licenseUsersModalAnonymousUserLabel
                                  )
                                )
                              )
                              .join(", ") +
                            "</strong>"
                        }
                      ),
                      autoclose: true,
                      type: "success"
                    });
                  } else {
                    // All succeeded
                    feedbacks.push({
                      id: "manageAssignments_success_" + res.licenseId,
                      msg: intl.formatMessage(
                        messages.licenseUsersUpdateSuccess,
                        {
                          name:
                            "<strong>" +
                            (license && license.licensedItem
                              ? LicenseUtils.resolveDisplayName(
                                  license.licensedItem,
                                  intl.formatMessage(
                                    messages.licenseUsersLicenseNameFallback
                                  )
                                )
                              : intl.formatMessage(
                                  messages.licenseUsersLicenseNameFallback
                                )) +
                            "</strong>"
                        }
                      ),
                      autoclose: true,
                      type: "success"
                    });
                  }
                }
                if (res.errors && failure && failure.length) {
                  const errorsByMessage: any = {};
                  const errKeys = Object.keys(res.errors);
                  for (let i = 0; i < errKeys.length; i += 1) {
                    errorsByMessage[
                      res.errors[errKeys[i]].message
                    ] = errorsByMessage[res.errors[errKeys[i]].message]
                      ? errorsByMessage[res.errors[errKeys[i]].message].push(
                          errKeys[i]
                        )
                      : [errKeys[i]];
                  }
                  const errorMessages = Object.keys(errorsByMessage);
                  if (errorMessages.length === 1) {
                    // Single failure cause
                    if (success && success.length) {
                      // with some succeeding
                      feedbacks.push({
                        id: "manageAssignments_partial_error_" + res.licenseId,
                        msg: intl.formatMessage(
                          messages.licenseUsersUpdatePartiallyFailed,
                          {
                            name:
                              "<strong>" +
                              (license && license.licensedItem
                                ? LicenseUtils.resolveDisplayName(
                                    license.licensedItem,
                                    intl.formatMessage(
                                      messages.licenseUsersLicenseNameFallback
                                    )
                                  )
                                : intl.formatMessage(
                                    messages.licenseUsersLicenseNameFallback
                                  )) +
                              "</strong>",
                            error: "<strong>" + errorMessages[0] + "</strong>",
                            userNames:
                              "<strong>" +
                              failure
                                .filter(
                                  usr =>
                                    errorsByMessage[errorMessages[0]].findIndex(
                                      (id: string) =>
                                        usr && (usr.id as string) === id
                                    ) >= 0
                                )
                                .map(usr =>
                                  UserUtils.resolveDisplayName(
                                    usr,
                                    intl.formatMessage(
                                      messages.licenseUsersModalAnonymousUserLabel
                                    )
                                  )
                                )
                                .join(", ") +
                              "</strong>"
                          }
                        ),
                        type: "danger"
                      });
                    } else {
                      // without succeeding
                      feedbacks.push({
                        id: "manageAssignments_error_" + res.licenseId,
                        msg: intl.formatMessage(
                          messages.licenseUsersUpdateFailed,
                          {
                            name:
                              "<strong>" +
                              (license && license.licensedItem
                                ? LicenseUtils.resolveDisplayName(
                                    license.licensedItem,
                                    intl.formatMessage(
                                      messages.licenseUsersLicenseNameFallback
                                    )
                                  )
                                : intl.formatMessage(
                                    messages.licenseUsersLicenseNameFallback
                                  )) +
                              "</strong>",
                            error: "<strong>" + errorMessages[0] + "</strong>"
                          }
                        ),
                        type: "danger"
                      });
                    }
                  } else if (errorMessages.length > 1) {
                    // Multiple causes
                    for (let i = 0; i < errorMessages.length; i += 1) {
                      feedbacks.push({
                        id:
                          "manageAssignments_partial_error_" +
                          res.licenseId +
                          errorMessages[i],
                        msg: intl.formatMessage(
                          messages.licenseUsersUpdatePartiallyFailed,
                          {
                            name:
                              (license && license.licensedItem
                                ? LicenseUtils.resolveDisplayName(
                                    license.licensedItem,
                                    intl.formatMessage(
                                      messages.licenseUsersLicenseNameFallback
                                    )
                                  )
                                : intl.formatMessage(
                                    messages.licenseUsersLicenseNameFallback
                                  )) + "</strong>",
                            error: "<strong>" + errorMessages[i] + "</strong>",
                            userNames:
                              "<strong>" +
                              failure
                                .filter(
                                  usr =>
                                    errorsByMessage[errorMessages[i]].findIndex(
                                      (id: string) =>
                                        usr && (usr.id as string) === id
                                    ) >= 0
                                )
                                .map(usr =>
                                  UserUtils.resolveDisplayName(
                                    usr,
                                    intl.formatMessage(
                                      messages.licenseUsersModalAnonymousUserLabel
                                    )
                                  )
                                )
                                .join(", ") +
                              "</strong>"
                          }
                        ),
                        type: "danger"
                      });
                    }
                  }
                }
                if (feedbacks.length) {
                  onShowFeedback(feedbacks);
                }
              }
            });
            if (onClose) {
              onClose();
            }
          }}
          secondaryButton={{
            label: intl.formatMessage(
              messages.licenseUsersModalCancelButtonLabel
            )
          }}
          onSecondaryAction={() => {
            if (onClose) {
              onClose();
            }
          }}
          size={"lg"}
        >
          <>
            <div className={"row"}>
              <div className={"col-md-5 d-flex flex-column"}>
                <h3 className={"h5 flex-grow-0 text-center text-md-left"}>
                  <FormattedMessage
                    id="license-users-modal.unassigned-title"
                    defaultMessage="Unlicensed"
                  />
                </h3>
                <div className={"card flex-grow-1"}>
                  <div className="list-group list-group-flush border-bottom">
                    {users &&
                      users.usersWithoutReservation.map(val => {
                        return (
                          <button
                            data-test-set-to-assign
                            key={"btn_" + val.id}
                            onClick={() => {
                              if (toAssign.indexOf(val.id as string) >= 0) {
                                onSetToAssign(
                                  toAssign.filter(v => v !== val.id)
                                );
                              } else {
                                onSetToAssign(
                                  toAssign.concat(val.id as string)
                                );
                                onSetToAssign(
                                  toAssign.concat(val.id as string)
                                );
                              }
                            }}
                            className={
                              "list-group-item list-group-item-action" +
                              (toAssign.findIndex(s =>
                                s === val.id ? s : undefined
                              ) >= 0
                                ? " active"
                                : "")
                            }
                          >
                            {UserUtils.resolveDisplayName(
                              val,
                              intl.formatMessage(
                                messages.licenseUsersModalAnonymousUserLabel
                              )
                            )}
                            {UserUtils.isConsuming(val, license) && (
                              <TooltipTrigger
                                tipKey={"consumer" + val.id}
                                tip={intl.formatMessage(
                                  messages.isConsumingTip
                                )}
                              >
                                <span
                                  className={
                                    "badge badge-pill badge-warning ml-2 mt-1 float-right"
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={"user-check"}
                                    className={"mr-1"}
                                  />
                                  <FormattedMessage
                                    id="license-users-modal.is-consuming.label"
                                    defaultMessage="Active"
                                  />
                                </span>
                              </TooltipTrigger>
                            )}
                          </button>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div
                className={"col-md-2 px-md-0 d-flex flex-row flex-md-column"}
              >
                <div
                  className={
                    "d-flex align-items-end py-2 px-2 px-md-0 flex-grow-1"
                  }
                >
                  <TooltipTrigger
                    tipKey={
                      "assignButtonTip" +
                      (toAssign.length === 0
                        ? "nousers"
                        : freeSeats < toAssignWithoutConsumptionCount
                        ? "noseats"
                        : "default")
                    }
                    wrapDisabled={
                      toAssign.length === 0 ||
                      freeSeats < toAssignWithoutConsumptionCount
                    }
                    tip={intl.formatMessage(
                      toAssign.length === 0
                        ? messages.assignButtonNothingToAssignTip
                        : freeSeats < toAssignWithoutConsumptionCount
                        ? messages.assignButtonNotEnoughFreeSeatsTip
                        : messages.assignButtonTip
                    )}
                    className={"w-100"}
                  >
                    <Button
                      data-test-assign-trigger
                      size={"sm"}
                      variant={"primary"}
                      block
                      onClick={() => {
                        onAssign(toAssign);
                      }}
                      disabled={
                        toAssign.length === 0 ||
                        freeSeats < toAssignWithoutConsumptionCount
                      }
                    >
                      <span
                        className={"d-inline-block d-md-none d-lg-inline-block"}
                      >
                        <FormattedMessage
                          id="license-users-modal.assign-button-label"
                          defaultMessage="Assign"
                        />
                      </span>
                      <FontAwesomeIcon
                        icon={"chevron-right"}
                        className={
                          "d-none d-md-inline-block ml-2 ml-md-0 ml-lg-2"
                        }
                      />
                      <FontAwesomeIcon
                        icon={"chevron-down"}
                        className={
                          "d-inline-block d-md-none ml-2 ml-md-0 ml-lg-2"
                        }
                      />
                    </Button>
                  </TooltipTrigger>
                </div>
                <div
                  className={
                    "d-flex align-items-start py-2 px-2 px-md-0 flex-grow-1"
                  }
                >
                  <TooltipTrigger
                    tipKey={
                      "unassignButtonTip" +
                      (toUnassign.length === 0 ? "nousers" : "default")
                    }
                    wrapDisabled={toUnassign.length === 0}
                    tip={intl.formatMessage(
                      toUnassign.length === 0
                        ? messages.unassignButtonNothingToUnassignTip
                        : messages.unassignButtonTip
                    )}
                    className={"w-100"}
                  >
                    <Button
                      data-test-unassign-trigger
                      variant={"primary"}
                      size={"sm"}
                      block
                      onClick={() => {
                        onUnassign(toUnassign);
                      }}
                      disabled={toUnassign.length === 0}
                      className={"btn btn-primary btn-block btn-sm"}
                    >
                      <FontAwesomeIcon
                        icon={"chevron-left"}
                        className={
                          "d-none d-md-inline-block mr-2 mr-md-0 mr-lg-2"
                        }
                      />
                      <FontAwesomeIcon
                        icon={"chevron-up"}
                        className={
                          "d-inline-block d-md-none mr-2 mr-md-0 mr-lg-2"
                        }
                      />
                      <span
                        className={"d-inline-block d-md-none d-lg-inline-block"}
                      >
                        <FormattedMessage
                          id="license-users-modal.unassign-button-label"
                          defaultMessage="Unassign"
                        />
                      </span>
                    </Button>
                  </TooltipTrigger>
                </div>
              </div>
              <div className={"col-md-5 d-flex flex-column"}>
                <h3
                  className={
                    "h5 flex-grow-0 order-2 order-md-1 text-center text-md-left"
                  }
                >
                  <FormattedMessage
                    id="license-users-modal.assigned-title"
                    defaultMessage="Licensed"
                  />
                </h3>
                <div
                  className={"card flex-grow-1 order-1 order-md-2 mb-2 mb-md-0"}
                >
                  <div className="list-group list-group-flush border-bottom">
                    {users &&
                      users.usersWithReservation.map(val => {
                        return (
                          <button
                            data-test-set-to-unassign
                            key={"btn_" + val.id}
                            onClick={() => {
                              if (toUnassign.indexOf(val.id as string) >= 0) {
                                onSetToUnassign(
                                  toUnassign.filter(v => v !== val.id)
                                );
                              } else {
                                onSetToUnassign(
                                  toUnassign.concat(val.id as string)
                                );
                              }
                            }}
                            className={
                              "list-group-item list-group-item-action" +
                              (toUnassign.findIndex(s =>
                                s === val.id ? s : undefined
                              ) >= 0
                                ? " active"
                                : "")
                            }
                          >
                            {UserUtils.resolveDisplayName(
                              val,
                              intl.formatMessage(
                                messages.licenseUsersModalAnonymousUserLabel
                              )
                            )}
                            {UserUtils.isConsuming(val, license) && (
                              <TooltipTrigger
                                tipKey={"consumer" + val.id}
                                tip={intl.formatMessage(
                                  messages.isConsumingTip
                                )}
                              >
                                <span
                                  className={
                                    "badge badge-pill badge-warning ml-2 mt-1 float-right"
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={"user-check"}
                                    className={"mr-1"}
                                  />
                                  <FormattedMessage
                                    id="license-users-modal.is-consuming.label"
                                    defaultMessage="Active"
                                  />
                                </span>
                              </TooltipTrigger>
                            )}
                          </button>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <p className={freeSeats === 0 ? "text-danger" : undefined}>
              <FormattedMessage
                id="license-users-modal.free-licenses-copy"
                defaultMessage="Available licenses remaining: {freeSeats} "
                values={{
                  freeSeats: freeSeats
                }}
              />
              <em className={"small text-muted"}>
                <TooltipTrigger
                  tipKey={"inactiveInfoTip"}
                  tip={
                    <FormattedMessage
                      id="license-users-modal.free-licenses-copy-info"
                      defaultMessage="Unassigning a license reservation will not always free the license immediately. The user may continue to use the license in currently active session."
                    />
                  }
                >
                  <FontAwesomeIcon
                    icon={"info-circle"}
                    className={"ml-1 text-info"}
                  />
                </TooltipTrigger>
              </em>
            </p>
          </>
        </ConfirmModal>
      )}
    </>
  );
}
