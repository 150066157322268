import { LicensedItem } from "../model/entitlement/LicensedItem";

function resolveDisplayName(
  licensedItem: LicensedItem,
  fallback: string
): string {
  let retVal = fallback;
  if (licensedItem.displayName) {
    retVal = licensedItem.displayName;
  } else if (licensedItem.name) {
    retVal = licensedItem.name;
  }
  return retVal;
}

export default {
  resolveDisplayName
};
