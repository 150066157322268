import React, { useEffect, useState } from "react";
import TableView, {
  TableStateProps,
  TableProps as _TableProps,
  TableColumn as _TableColumn
} from "./table-view";

export type TableColumn = _TableColumn;

export interface TableProps<D>
  extends Omit<_TableProps<D>, keyof TableStateProps> {}

export default function Table<D>(props: TableProps<D>) {
  /**
   * The underlying table supports hierarchical column field definition, added similar support for resolving column
   * value for search
   * @param row
   * @param colkey
   */
  const resolveColumnValue = (row: D, colkey: string) => {
    let retVal = undefined;
    if (row && colkey) {
      retVal = (row as any)[colkey];
      if (colkey.indexOf(".")) {
        let tmp = colkey.split(".");
        const parts = tmp
          .map(part => {
            let rv: string | string[] = part;
            if (part.indexOf("[") && part.endsWith("]")) {
              rv = part.split("[").map(spart => {
                return spart.replace("]", "");
              });
            }
            return rv;
          })
          .flat(1);
        let v: any = row;
        parts.forEach(part => {
          if (v) {
            if (
              !isNaN((part as any) as number) &&
              v[(part as any) as number] !== undefined
            ) {
              v = v[(part as any) as number];
            } else {
              v = v[part];
            }
          }
        });
        retVal = v;
      }
    }
    return retVal;
  };
  const { columns, data, activeSearch, sort, ...other } = props;
  const [viewColumns, setViewColumns] = useState(columns);
  const [activeSort, setActiveSort] = useState(sort);
  useEffect(() => {
    // TODO: useEffect equality issue: this is potential bug as the effect is hit on every render
    setViewColumns(columns);
  }, [columns]);
  const viewProps: _TableProps<D> = {
    activeSearch: activeSearch,
    data: data
      ? data.filter((itm: any) => {
          let retVal: boolean;
          if (activeSearch && activeSearch.length > 0) {
            retVal = false;
            for (let i = 0; i < columns.length; i += 1) {
              const colVal = columns[i].isDummy
                ? columns[i].rendererData &&
                  columns[i].rendererData.resolveValue
                  ? columns[i].rendererData.resolveValue(itm)
                  : undefined
                : resolveColumnValue(itm, columns[i].key);
              retVal = colVal
                ? colVal
                    .toString()
                    .toLowerCase()
                    .indexOf(activeSearch ? activeSearch.toLowerCase() : "") >=
                  0
                : false;
              if (retVal) {
                break;
              }
            }
          } else {
            retVal = true;
          }
          return retVal;
        })
      : data,
    sort: activeSort,
    onSort: (column: string, ascending: boolean) => {
      setActiveSort({ column, ascending });
    },
    onToggleColumn: (key: string, visible: boolean) => {
      setViewColumns(
        viewColumns.map((itm: TableColumn) => {
          if (itm.key === key) {
            itm.hidden = !visible;
          }
          return itm;
        })
      );
    },
    columns: viewColumns,
    ...other
  };
  return <TableView<D> {...viewProps} />;
}
